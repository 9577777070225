var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('Header',{attrs:{"title":"訂單查詢"}}),_c('div',{staticClass:"main"},[_c('div',{staticClass:"flex justify-between sm:items-end mb-4 space-x-4"},[_c('DataFilter',{staticClass:"flex-1 border-0",staticStyle:{"background":"unset"},attrs:{"initial":"所有狀態","label":"訂單狀態","option":_vm.dataFilter.option},on:{"change":_vm.TSelectF1}}),_c('div',{staticClass:"stats shadow flex-none"},[_c('div',{staticClass:"stat"},[_c('div',{staticClass:"stat-title"},[_vm._v("訂單數量")]),_c('div',{staticClass:"stat-value text-center"},[_vm._v(_vm._s(_vm.dataItems.length))])])])],1),_c('div',{staticClass:"mb-8 rounded-3",staticStyle:{"background":"#fff"}},[_c('table',{staticClass:"table w-full tableGrid"},[_vm._m(0),_c('tbody',{staticStyle:{"background":"#fff"}},[_vm._l((_vm.dataItems),function(item,index){return _c('tr',{key:index},[_c('td',{attrs:{"data-label":"購買日期："}},[_vm._v(_vm._s(item.OD_DATE))]),_c('td',{attrs:{"data-label":"來源訂單："}},[_vm._v(" "+_vm._s(item.OD_ID)+" ")]),_c('td',{staticClass:"whitespace-pre-wrap",attrs:{"data-label":"商品名稱"}},_vm._l((item.OP_NAME),function(item2,index2){return _c('div',{key:index2},[_vm._v(_vm._s(item2))])}),0),_c('td',{staticClass:"nt",attrs:{"data-label":"訂單金額："}},[_vm._v("NT$ "+_vm._s(item.OD_PRICE))]),_c('td',{attrs:{"data-label":"訂單狀態："}},[(item.status === '等待付款' || item.status === '退款申請中')?[_c('span',{staticClass:"text-yellow-400"},[_vm._v(_vm._s(item.status))])]:(item.status === '已付款')?[_c('span',{staticClass:"text-green-400"},[_vm._v(_vm._s(item.status))])]:[_c('span',{staticClass:"text-red-400"},[_vm._v(_vm._s(item.status))])]],2),_c('td',{staticStyle:{"background":"#fff"}},[_c('div',{staticClass:"space-x-2"},[(item.OD_STATUS === 0)?[(
                                            item.OD_PAYMENT_STATUS === 0 &&
                                            new Date() <= new Date(item.OD_PAYMENT_DEADLINE)
                                        )?_c('a',{attrs:{"href":_vm.$appDomain + '/FcuPay/FcuPay.aspx?OD_ID=' + item.OD_ID}},[_c('button',{staticClass:"btn btn-sm btn-primary"},[_vm._v("付款")])]):_c('a',{staticClass:"d-none",attrs:{"href":_vm.$appDomain + '/FcuPay/FcuPay.aspx?OD_ID=' + item.OD_ID}},[_c('button',{staticClass:"btn btn-sm btn-primary"},[_vm._v("測試付款失敗")])]),(item.OD_PAYMENT_STATUS === 0 && item.status != '已取消')?_c('button',{staticClass:"btn btn-sm btn-accent",on:{"click":function($event){return _vm.updateStatus2(item.OD_ID)}}},[_vm._v(" 取消 ")]):_vm._e()]:_vm._e(),(item.OD_STATUS === 1)?[(
                                            item.OD_PAYMENT_STATUS === 1 &&
                                            item.OP_REFUND_STATUS == 0 &&
                                            item.AE_STATUS == 0
                                        )?_c('button',{staticClass:"btn btn-sm",on:{"click":function($event){return _vm.checkAccessStatus(item.OP_ID)}}},[_vm._v(" 退款 ")]):_vm._e()]:_vm._e()],2)])])}),_c('tr',[(_vm.dataItems.length == 0)?_c('td',[_vm._v("無資料")]):_vm._e()])],2)])])]),_c('LoadingPage',{attrs:{"loadSet":_vm.loadSet}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',{staticStyle:{"background":"#e5e5e5"}},[_c('tr',[_c('th',{staticStyle:{"line-height":"1.5rem","border-bottom":"unset"},attrs:{"scope":"col","width":"1%"}},[_vm._v("日期")]),_c('th',{staticStyle:{"line-height":"1.5rem","border-bottom":"unset"},attrs:{"scope":"col"}},[_vm._v("訂單編號")]),_c('th',{staticStyle:{"line-height":"1.5rem","border-bottom":"unset"},attrs:{"scope":"col"}},[_vm._v("商品名稱")]),_c('th',{staticStyle:{"line-height":"1.5rem","border-bottom":"unset"},attrs:{"scope":"col"}},[_vm._v("金額")]),_c('th',{staticStyle:{"line-height":"1.5rem","border-bottom":"unset"},attrs:{"scope":"col","width":"1%"}},[_vm._v("訂單狀態")]),_c('th',{staticStyle:{"line-height":"1.5rem","border-bottom":"unset"},attrs:{"width":"1%"}},[_vm._v("結帳")])])])}]

export { render, staticRenderFns }