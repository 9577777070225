<template>
   <div>
      <div class="form-control mb-4">
         <input v-model="SQ_ID" name="account" type="text" class="input input-bordered w-full" placeholder="請輸入代碼" required>
      </div>
      <button class="btn btn-block" @click="checkIn">取票</button>
      <LoadingPage :loadSet="loadSet"></LoadingPage>
   </div>
</template>
<script>
import { apiToken, apiSPLIT_CODE_CHECKIN } from '../../api/API'
export default {
   name: 'TakeTicket',
   data() {
      return {
         SQ_ID: null,
         loadSet: { show: false, fullPage: true, type: 'dots', color: 'rgba(0,0,0,0.7)' }
      }
   },
   methods: {
      //取票
      async checkIn() {
         if (confirm("確定要取票？") == false) {
            return;
         }

         var msg = ''

         if (this.IsNull(this.SQ_ID) == '')
            msg += "請輸入取票碼\n"

         if (msg == '') {
            try {
               this.loadSet.show = true
               var Token = await (await apiToken()).data
               //console.log(Token);
               var data = {
                  SQ_ID: this.SQ_ID,
                  Token: Token
               }
               data = this.BaseModel(data)


               var result = JSON.parse(await (await apiSPLIT_CODE_CHECKIN(data)).data);
               this.loadSet.show = false

               if (result.Status) {
                  // this.$router.push("/member/owns")
                  alert("取票成功")
                  
                  this.$emit('click:checked') 
               } else {
                  if (result.Data != "") {
                     alert(result.Data)
                  } else {
                     alert(result.Message)
                  }
               }

               //console.log(result.Message)
            } catch (e) {
               this.loadSet.show = false
               //console.log(e)
               alert(e)
            }
         } else {
            alert(msg)
         }
      },
   }
}
</script>
