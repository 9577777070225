<template>
    <div>
        <div class="splide" role="group" aria-label="Splide Basic HTML Example">
            <div class="splide__track mb-8">
                <ul class="splide__list">
                    <li class="splide__slide" v-for="(item, index) in items" :key="index">
                        <label @click="sendToParent(item)">
                            <div class="splide-item" data-aos="fade-down">
                                <figure :class="[item.trainer ? 'trainer' : 'figure']">
                                    <img v-if="item.url" :src="item.url" :alt="item.title" />
                                    <img v-else src="../assets/image/no-image.png" :alt="item.title" />
                                </figure>
                                <div class="description">
                                    <h3 class="title" :class="[item.trainer ? 'trainer' : '!text-right !pr-4']">
                                        <span>{{ item.title }}</span>
                                    </h3>
                                </div>
                            </div>
                        </label>
                    </li>
                </ul>
            </div>
            <div class="splide__arrows text-center space-x-4">
                <button class="splide__arrow--prev btn btn-circle">
                    <svg
                        class="fill-white"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                    >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                    </svg>
                </button>
                <button class="splide__arrow--next btn btn-circle">
                    <svg
                        class="fill-white"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                    >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
    import SplideOld from '@splidejs/splide';
    import '@splidejs/splide/dist/css/splide.min.css';
    export default {
        data() {
            return {
                morInfo: false,
                moreInfo: {
                    title: null,
                    introduce: null,
                },
            };
        },
        mounted() {
            this.coursesSplide();
        },
        updated() {
            this.coursesSplide();
        },
        methods: {
            sendToParent: function (info) {
                this.$emit('childValue', info);
            },
            reverseLoad: function () {
                this.$store.commit('switchModal');
            },
            coursesSplide: function () {
                new SplideOld('.splide', {
                    perPage: this.perPage,
                    perMove: 1,
                    start: 0,
                    focus: 'center',
                    trimSpace: false,
                    pagination: false,
                    // type     : 'loop',
                    snap: true,
                    rewind: true,
                    autoplay: true,
                    breakpoints: {
                        640: {
                            perPage: 1,
                        },
                    },
                    // autoWidth: true,
                }).mount();
            },
            showDetails(item) {
                console.log(item);
                this.moreInfo.title = item.title;
                this.moreInfo.url = item.url;
                this.moreInfo.introduce = item.introduce;
                this.moreInfo.discription = item.discription;
                this.moreInfo.experience = item.experience;
                this.moreInfo.expertise = item.expertise;
                this.moreInfo.license = item.license;
                this.moreInfo.trainer = item.trainer;
                this.$store.commit('switchModal');
            },
        },
        props: {
            items: {
                type: Array,
                default: null,
            },
            perPage: {
                type: Number,
                default: 4,
            },
        },
    };
</script>

<style lang="postcss" scoped>
    .splide__slide {
        @apply opacity-50;
        transition: all 0.3s;
        &.is-visible {
            @apply opacity-70;
        }
        &.is-active {
            @apply opacity-100;
        }
    }
    .splide-item {
        @apply sm:px-3 hover:cursor-pointer;
        figure {
            /* @apply w-full mb-4 rounded-2xl overflow-hidden aspect-square; */
            &.trainer {
                @apply rounded-full border-8 border-secondary-200 w-full mb-4 overflow-hidden aspect-square;
            }
            img {
                @apply scale-100 hover:scale-110 duration-300 ease-in-out;
            }
        }
        .figure {
            @apply flex-initial relative mb-4;
            &::after {
                clip-path: polygon(0 10%, 100% 0%, 100% 90%, 0% 100%);
                @apply w-11/12 mt-3  h-full absolute right-0 top-0 z-30 block bg-primary-500  aspect-square rounded-lg;
            }
            img {
                clip-path: polygon(0 10%, 100% 0%, 100% 90%, 0% 100%);
                @apply w-11/12 mr-4 relative z-40 mt-8 rounded-lg;
            }
        }
        .title {
            @apply text-2xl text-center mb-4;
            &.name {
                @apply -mt-12;
                span {
                    @apply origin-center rotate-6 border bg-white inline-block px-6 py-2 shadow;
                }
            }
        }
        .description {
            @apply rounded-2xl hover:border-secondary-500 leading-loose;
            text-align: justify;
        }
    }
</style>
