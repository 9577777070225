<template>
   <main>
      <Header backTo="/member/rental" title="場地租借紀錄"></Header>
      <section class=" main">
         <div class="stats stats-vertical sm:stats-horizontal  shadow border w-full mb-4">
            <div class="stat">
               <div class="stat-title">使用空間</div>
               <div class="stat-value text-primary">{{ details.AE_NAME }}</div>
            </div>
            <div class="stat">
               <div class="stat-title">場租日期</div>
               <div class="stat-value text-primary">{{ details.day }}</div>
               <div class="stat-desc">{{ details.sTime }} ~ {{ details.eTime }}</div>
            </div>
            <div class="stat">
               <div class="stat-title">已開通人數</div>
               <div class="stat-value text-primary">{{ productAll.length }}</div>
               <div class="stat-desc">人數上限：{{ details.AE_SPLIT_QUANTITY }}</div>
            </div>
            <div class="stat">
               <div class="stat-title">門禁開通代碼</div>
               <div class="stat-value text-green-500">{{ codeDetails.SQ_ID }}</div>
            </div>
         </div>

         <div class="overflow-x-auto mb-8">
            <table class="table w-full">
               <thead>
                  <tr>
                     <th class="w-px">開通時間</th>
                     <th>開通會員</th>
                  </tr>
               </thead>
               <tbody v-if="productAll.length != 0">
                  <tr v-for="(item, index) in productAll" :key="index">
                     <td>{{ item.AE_CREATION_DATE }}</td>
                     <td>
                        {{ item.PPL_NAME }}
                     </td>
                  </tr>
               </tbody>
               <tbody v-else>
                  <tr>
                     <td colspan="2" class="text-center">尚未有會員取票</td>
                  </tr>
               </tbody>
            </table>
         </div>
      </section>
      <LoadingPage :loadSet="loadSet"></LoadingPage>
   </main>
</template>
<script>
import {
   apiToken,
   apiACCESS_GET,
   apiACCESS_SPLIT_CHIDREN_LIST,
   apiACCESS_SPLIT_CODE,
} from '../../api/API'
import Header from '@/components/MemberHeader.vue'
export default {
   name: 'SpaceRentalDetails',
   components: {
      Header
   },
   data() {
      return {
         productAll: [],
         details: {
            AE_ID: null,
         },
         codeDetails: {

         },

         loadSet: { show: false, fullPage: true, type: 'dots', color: 'rgba(0,0,0,0.7)' }
      }
   },
   mounted() {
      this.init()
   },
   methods: {
      async init() {
         this.loadSet.show = false
         if (this.$route.query.AE_ID != undefined) {
            this.details.AE_ID = this.$route.query.AE_ID
            await this.getAccess()
            await this.getAccessSplitCode()
            await this.getAccessChidren()
         } else {
            this.$router.push('/member/rental')
         }
      },
      async getAccess() {
         try {
            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               AE_ID: this.details.AE_ID,
               Token: Token
            }
            data = this.BaseModel(data)


            var result = JSON.parse(await (await apiACCESS_GET(data)).data);
            this.loadSet.show = false

            if (result.Status) {
               var jsData = JSON.parse(result.Data)
               jsData.day = this.TransformTimeFormatYYYMMDD(jsData.AE_USE_DATE)
               jsData.sTime = this.TransformTimeFormathhmm(jsData.AE_USE_DATE)
               jsData.eTime = this.TransformTimeFormathhmm(jsData.AE_EXPIRATION_DATE)
               this.details = jsData
               //console.log(jsData)

            } else {
               this.$router.push('/member/rental')
               alert(result.Message)
            }

            //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false
            //console.log(e)
            alert(e)
         }
      },
      async getAccessSplitCode() {
         try {
            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               AE_ID: this.details.AE_ID,
               Token: Token
            }
            data = this.BaseModel(data)


            var result = JSON.parse(await (await apiACCESS_SPLIT_CODE(data)).data);
            this.loadSet.show = false

            if (result.Status) {
               var jsData = JSON.parse(result.Data)
               this.codeDetails = jsData
               //console.log(jsData)

            } else {
               alert(result.Message)
            }

            //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false
            //console.log(e)
            alert(e)
         }
      },

      async getAccessChidren() {
         try {
            this.productAll = []
            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               AE_ID: this.details.AE_ID,
               Token: Token
            }
            data = this.BaseModel(data)


            var result = JSON.parse(await (await apiACCESS_SPLIT_CHIDREN_LIST(data)).data);
            this.loadSet.show = false

            if (result.Status) {
               var jsData = JSON.parse(result.Data)
               // console.log(jsData)
               if (jsData.length > 0) {
                  for (let i in jsData) {

                     jsData[i].AE_USE_DATE = this.TransformTimeFormatYYYMMDDhhmm(jsData[i].AE_USE_DATE)
                     jsData[i].AE_EXPIRATION_DATE = this.TransformTimeFormatYYYMMDDhhmm(jsData[i].AE_EXPIRATION_DATE)
                     jsData[i].AE_CREATION_DATE = this.TransformTimeFormatYYYMMDDhhmm(jsData[i].AE_CREATION_DATE)

                     this.productAll.push(jsData[i])
                  }
               }
               console.log(this.productAll)

            } else {
               this.$router.push('/member/owns')
               alert(result.Message)
            }

            //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false
            //console.log(e)
            alert(e)
         }
      },
   }
}
</script>