<template>
   <div class="signin">
      <div class="card">
         <div v-if="details.Token == null" class="card-body items-center text-center">
            <h2 class="card-title">忘記密碼</h2>
            <div class="form-control w-full max-w-xs mb-4">
               <label class="label">
                  <span class="label-text">請輸入電子郵件</span>
               </label>
               <input v-model="PPL_MAIL" type="mail" placeholder="user@mail.com" class="input input-bordered w-full max-w-xs" />
            </div>
            <button class="btn btn-block mb-4" type="submit" @click="LastPass">送出</button>
            <div class="text-center">
               <router-link to="/login"><svg class="inline-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16"><path fill="none" d="M0 0h24v24H0z"/><path d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z"/></svg> 返回</router-link>
            </div>
         </div>

         <div v-if="details.Token != null" class="card-body items-center text-center">
            <h2 class="card-title">修改密碼</h2>
            <div class="form-control w-full max-w-xs mb-4">
               <label class="label">
                  <span class="label-text">請輸入密碼</span>
               </label>
               <input v-model="details.newPasswd" type="password" class="input input-bordered w-full max-w-xs" />
            </div>
            <div class="form-control w-full max-w-xs mb-4">
               <label class="label">
                  <span class="label-text">請確認密碼</span>
               </label>
               <input v-model="details.newPasswd2" type="password" class="input input-bordered w-full max-w-xs" />
            </div>
            <button class="btn btn-block mb-4" type="submit" @click="updatePassword">送出</button>
            <div class="text-center">
               <router-link to="/login"><svg class="inline-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16"><path fill="none" d="M0 0h24v24H0z"/><path d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z"/></svg> 返回</router-link>
            </div>
         </div>
      </div>

   <LoadingPage :loadSet="loadSet"></LoadingPage>
   </div>
</template>
<script>
import { apiToken, 
         apiLastPass,
         apiMemberCheckPassToken,
         apiMemberUpdateLastPass,
         apiMEMBER_GET,
      } from '../../api/API'
export default {
  name: 'site-header',
  data() {
    return {
      PPL_MAIL:null,
      details:{
         newPasswd:null,
         newPasswd2:null,
         Token:null,
      },
      loadSet:{
        show: false,
        fullPage: true,
        type:'dots',
        color:'rgba(0,0,0,0.7)'
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init(){
      this.loadSet.show = false
      if (this.$route.query.Token != undefined) {
            this.details.Token = this.$route.query.Token
            await this.CheckPassToken()
      }
    },
    async LastPass(){
      var msg = ""

      if(this.IsNull(this.PPL_MAIL) == '')
         msg = '請輸入電子郵件'

      if(msg == "")
      {
         try {
            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               PPL_MAIL:this.PPL_MAIL,
               Token:Token
            }
            data = this.BaseModel(data)

            var result = await (await apiLastPass(data)).data
             this.loadSet.show = false

            if (result.Status) {
               //var jsData = JSON.parse(result.Data)
               //console.log(jsData)
               //console.log(this.TicketCatalog)
               alert("已寄送郵件至信箱")
            } else {
               alert(result.Message)
            }

            //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false
            //console.log(e)
            alert(e)
         }
      }else{
         alert(msg)
      }
      
    },
    async CheckPassToken(){
      var msg = ""

      if(msg == "")
      {
         try {
            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               PassToken:this.details.Token,
               DecryptionPass:null,
               Token:Token
            }
            data = this.BaseModel(data)

            var result = await (await apiMemberCheckPassToken(data)).data
            this.loadSet.show = false

            if (result.Status) {
               //var jsData = JSON.parse(result.Data)
               //console.log(jsData)
               //console.log(this.TicketCatalog)
               alert("驗證成功請修改密碼")
            } else {
               this.details.Token = null
               alert(result.Message)
            }

            //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false
            //console.log(e)
            alert(e)
         }
      }else{
         alert(msg)
      }
      
    },
    async updatePassword(){
      var msg = ""
      if(this.IsNull(this.details.newPasswd) == '')
         msg = '請輸入密碼'
         
      if(this.IsNull(this.details.newPasswd2) == '')
         msg = '請確認密碼'

      if((this.details.newPasswd != this.details.newPasswd2) && this.IsNull(this.details.newPasswd2) != '')
         msg = '確認密碼錯誤'


      if(msg == "")
      {
         try {
            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               PassToken:this.details.Token,
               DecryptionPass:this.details.newPasswd2,
               Token:Token
            }
            data = this.BaseModel(data)

            var result = await (await apiMemberUpdateLastPass(data)).data
            this.loadSet.show = false

            if (result.Status) {
               var id = result.Data;
               //console.log(jsData)
               //console.log(this.TicketCatalog)
               this.details.Token = null
               this.details.newPasswd = null
               this.details.newPasswd2 = null
               this.$session.set('ACID', JSON.parse(id).ID)
               this.$session.set('NAME', JSON.parse(id).NAME)
               await this.getMember()

               alert("修改密碼成功")
            } else {
               this.details.Token = null
               this.details.newPasswd = null
               this.details.newPasswd2 = null
               alert(result.Message)
            }

            //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false
            //console.log(e)
            alert(e)
         }
      }else{
         alert(msg)
      }
      
    },
    
      async getMember() {

         try {
            this.loadSet.show = true
            var Token = await (await apiToken()).data;
            //console.log(Token);
            var data = {
               Token: Token
            }
            data = this.BaseModel(data)

            var result = JSON.parse(await (await apiMEMBER_GET(data)).data);

            this.loadSet.show = false;

            if (result.Status) {
               var jsData = JSON.parse(result.Data);
               // console.log(jsData)
               this.$session.set('MBR_GRADE', jsData.MBR_GRADE)
               this.$session.set('MG_NAME', jsData.MG_NAME)
               this.$session.set('MG_DISCOUNT',jsData.MG_DISCOUNT)
               this.$session.set('PPL_SSN', jsData.PPL_SSN)
               this.$session.set('MBR_TOTAL_AMOUNT', jsData.MBR_TOTAL_AMOUNT)
               this.$session.set('PPL_NID_IDENTITY', jsData.PPL_NID_IDENTITY)
               switch (jsData.PPL_NID_IDENTITY) {
                  case 0:
                     this.$session.set('PPL_NID_IDENTITYcn', "無")
                     break
                  case 1:
                     this.$session.set('PPL_NID_IDENTITYcn', "學生")
                     break
                  case 2:
                     this.$session.set('PPL_NID_IDENTITYcn', "教職員")
                     break
                  case 3:
                     this.$session.set('PPL_NID_IDENTITYcn', "校友")
                     break
                  case 4:
                     this.$session.set('PPL_NID_IDENTITYcn', "眷屬")
                     break
               }
               if(this.IsNull(this.$session.get('ShoppingCart')) != ''){
                  var shop = this.$session.get('ShoppingCart')

                  if(shop.type == "ticket")
                     this.$router.push('/buyTickets')
                  else if(shop.type == "course")
                     this.$router.push({ name: 'Course', query: {TKT_CAT_ID: shop.values.TKT_CAT_ID }})
                  else if(shop.type == "space")
                     this.$router.push('/reservationSpace')

                  
               }else{
                  this.$router.push('/member/owns')
               }
            } else {
               alert(result.Message)
            }

            //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false;
            //console.log(e)
            alert(e)
         }

      },
  },
}
</script>