<template>
   <div>
      <main>
         <Header title="分票"></Header>
         <p>請選取您要分票</p>
         <div class="owned mb-32">
            <a v-for="(item, index) in productAll" :key="index" @click="modal.qrcode = !modal.qrcode">
               <TicketView :ticketDetails="item">
                     <template v-slot:receipt>
                        <!-- <i class="icon-calendar text-4xl"></i> -->
                        <template v-if="(item.info.isSplit == 1 && item.info.quantity >= (item.info.quantityUse+item.info.quantitySplit))" >
                           <button class="btn small line" @click="openModal(index)">分票</button>
                        </template>
                        <router-link  :to="{ path:'/member/package' ,query: { AE_ID:item.id  }}"  >
                           <button class="btn small line">預約</button>
                        </router-link>
                     </template>
               </TicketView>
            </a>
         </div>
      </main>
      <!-- The button to open modal -->
      <label for="my-modal" class="btn modal-button">open modal</label>

      <!-- Put this part before </body> tag -->
      <input type="checkbox" v-model="modal.show" id="my-modal" class="modal-toggle" />
      <div class="modal">
         <div class="modal-box">
            <!-- <h3 class="font-bold text-lg">Congratulations random Internet user!</h3> -->
            <div class="">
               <div class="note">
                  <a v-if="modal.splitCode != null">分票碼：{{modal.splitCode}}</a>
                  <h4 class="font-bold mb-1">數量</h4>
                  <ol class="list-disc">
                     <li class="ml-6"><input type="number" v-model="modal.count" @change="countChange" :max="modal.max"  :min="modal.min" class="w-full"></li>
                  </ol>
                  <h4 v-if="modal.ues != null" class="font-bold mb-1">已取數量</h4>
                  <ol v-if="modal.ues != null" class="list-disc">
                     <li class="ml-6">{{modal.ues}}張</li>
                  </ol>
               </div>
            </div>
            <div class="modal-action">
               <button v-if="modal.splitCode == null" @click="addSplitCode" class="btn primary">新增</button>
               <button v-if="modal.splitCode != null" @click="updateSplitCode" class="btn primary">修改</button>
               <button @click="modal.show=!modal.show" class="btn primary">關閉</button>
               <label for="my-modal" class="btn">Yay!</label>
            </div>
         </div>
      </div>
      <LoadingPage :loadSet="loadSet"></LoadingPage>
   </div>
</template>

<script>
import { 
   apiToken,
   apiACCESS_LIST_SPLIT,
   apiSPLIT_CODE_GET,
   apiACCESS_SPLIT_ADD,
   apiACCESS_SPLIT_UPDATE,
} from '../../api/API'
import TicketView from '@/components/TicketView.vue'
import Header from '@/components/MemberHeader.vue'
export default {
   name: 'CreateSplitTicket',
   data(){
      return{
         modal:{
            show:false,
            AE_ID:null,
            splitCode:null,
            count:0,
            min:0,
            max:0,
            ues:null,
         },
         productAll:[],
         details:{
            AE_ID:null,
         },
         
         loadSet:{show: false,fullPage: true,type:'dots',color:'rgba(0,0,0,0.7)'}
      }
   },
   mounted(){
      this.init()
   },
   methods:{
      async init(){
         this.loadSet.show = false
         await this.getAccessList()
      },
      async openModal(index){
         this.modal.show = true
         this.modal.AE_ID = this.productAll[index].id
         this.modal.splitCode = this.productAll[index].info.splitCode
         this.modal.count = this.productAll[index].info.quantitySplit
         this.modal.min = 0
         this.modal.ues = null
         this.modal.max = this.productAll[index].info.quantity - this.productAll[index].info.quantityUse

         if(this.modal.splitCode != null)
            await this.getSplitCode()

         //console.log(this.productAll[index])
      },
      countChange(){
         if(this.modal.count > this.modal.max)
            this.modal.count = this.modal.max
         else if(this.modal.count < this.modal.min)
            this.modal.count = this.modal.min
      },
      async getSplitCode(){
         try {

            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               SQ_ID:this.modal.splitCode,
               Token:Token
            }
            data = this.BaseModel(data)

            
            var result = JSON.parse(await (await apiSPLIT_CODE_GET(data)).data);
            this.loadSet.show = false;

            if (result.Status) {
               var jsData = JSON.parse(result.Data);
               this.modal.min = jsData.SQ_USE_QUANTITY
               this.modal.ues = jsData.SQ_USE_QUANTITY
               //console.log(jsData)

               //console.log(this.productAll)
            } else {
               alert(result.Message)
            }


         //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false;
            //console.log(e)
            alert(e)
         }
      },
      async addSplitCode(){
         if (confirm("確定要分票？") == false) {
            return;
         }
         var msg = ''
         if(this.modal.count == 0)
            msg +="數量請至少輸入1\n"

         if(msg == ''){

            try {
               this.loadSet.show = true
               var Token = await (await apiToken()).data
               //console.log(Token);
               var data = {
                  AE_ID:this.modal.AE_ID,
                  SQ_QUANTITY:this.modal.count,
                  Token:Token
               }
               data = this.BaseModel(data)

               
               var result = JSON.parse(await (await apiACCESS_SPLIT_ADD(data)).data);
               this.loadSet.show = false;

               if (result.Status) {
                  this.modal.show = false
                  this.init()
                  //var jsData = JSON.parse(result.Data);
                  //this.modal.min = jsData.SQ_USE_QUANTITY
                  //console.log(jsData)

                  //console.log(this.productAll)
               } else {
                  if(result.Data != "")
                  {
                     alert(result.Data)
                  }else{
                     alert(result.Message)
                  }
               }


            //console.log(result.Message)
            } catch (e) {
               this.loadSet.show = false;
               //console.log(e)
               alert(e)
            }
         }else{
            alert(msg)
         }

      },
      async updateSplitCode(){
         if (confirm("確定要分票？") == false) {
            return;
         }
         var msg = ''
         if(this.modal.count == 0)
            msg +="數量請至少輸入1\n"

         if(msg == ''){

            try {
               this.loadSet.show = true
               var Token = await (await apiToken()).data
               //console.log(Token);
               var data = {
                  AE_ID:this.modal.AE_ID,
                  SQ_ID:this.modal.splitCode,
                  SQ_QUANTITY:this.modal.count,
                  Token:Token
               }
               data = this.BaseModel(data)

               
               var result = JSON.parse(await (await apiACCESS_SPLIT_UPDATE(data)).data);
               this.loadSet.show = false;

               if (result.Status) {
                  this.modal.show = false
                  this.init()
                  //var jsData = JSON.parse(result.Data);
                  //this.modal.min = jsData.SQ_USE_QUANTITY
                  //console.log(jsData)

                  //console.log(this.productAll)
               } else {
                  if(result.Data != "")
                  {
                     alert(result.Data)
                  }else{
                     alert(result.Message)
                  }
               }


            //console.log(result.Message)
            } catch (e) {
               this.loadSet.show = false;
               //console.log(e)
               alert(e)
            }
         }else{
            alert(msg)
         }

      },
      async getAccessList(){
         try {
            this.productAll = []

            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               Token:Token
            }
            data = this.BaseModel(data)

            
            var result = JSON.parse(await (await apiACCESS_LIST_SPLIT(data)).data);
            this.loadSet.show = false;

            if (result.Status) {
               var jsData = JSON.parse(result.Data);
               console.log(jsData)
               if(jsData.length > 0){
                  for(let i in jsData){
                     //多張
                     if(jsData[i].AE_TYPE == 1){
                        jsData[i].AE_USE_DATE = this.TransformTimeFormatYYYMMDDhhmm(jsData[i].AE_USE_DATE)
                        jsData[i].AE_EXPIRATION_DATE = this.TransformTimeFormatYYYMMDDhhmm(jsData[i].AE_EXPIRATION_DATE)

                        var array = {
                                       id:jsData[i].AE_ID,
                                       title: jsData[i].AE_NAME,
                                       type: jsData[i].AE_TYPE,
                                       ticketType: 'ticketPackage',
                                       sportType: null,
                                       catalog: jsData[i].AE_CATALOG,
                                       SPORT_ID:jsData[i].SPORT_ID,
                                       status: 1,
                                       usetime:jsData[i].AE_USE_DATE,
                                       deadline: jsData[i].AE_EXPIRATION_DATE,
                                       info: {
                                          title: null,
                                          date: null,
                                          time: null,
                                          trainer: null,
                                          location: jsData[i].AE_SP_NAME,
                                          isSplit:null,
                                          splitCode:jsData[i].AE_SPLIT_CODE,
                                          quantity:jsData[i].AE_QUANTITY,
                                          quantityUse:jsData[i].AE_USE_QUANTITY,
                                          quantitySplit:jsData[i].AE_SPLIT_QUANTITY,
                                       },
                                       isRefund:false,
                                    }


                        var status =true

                        //票卷 多張
                        if((jsData[i].AE_CATALOG == 0 || jsData[i].AE_CATALOG == 1) && jsData[i].AE_TYPE == 1){
                           array.info.isSplit = jsData[i].AE_ISSPLIT
                        }
                        //租借則不顯示
                        else if( jsData[i].AE_CATALOG == 3){
                           if(jsData[i].AE_TYPE == 1)
                              status = false
                        }


                        //類型-------------
                        //健身期課
                        if(jsData[i].SPORT_ID == "1"){
                           array.sportType = 'cycle-aerobic'
                        }
                        //租借
                        else if(jsData[i].SPORT_ID == "2"){
                           array.sportType = null
                        }
                        //瑜珈有氧
                        else if(jsData[i].SPORT_ID == "TKC08e0a3ba-8016-4ca1-9dde-eabf181c0767"){
                           array.sportType = 'aerobic'
                        }
                        //私人教練
                        else if( jsData[i].SPORT_ID == "3"){
                           array.sportType = 'trainer'
                        }
                        //健身房
                        else if( jsData[i].SPORT_ID == "TKC7df7eda9-a4c5-4959-9db9-76ba53f674d7"){
                           array.sportType = 'weight'
                        }
                        //游泳池
                        else if( jsData[i].SPORT_ID == "TKC38874b78-198c-48d0-b08c-3b429cb20603"){
                           array.sportType = 'swimming'
                        }

                        if(jsData[i].AE_STATUS == 0){
                           array.status = 1
                           array.isRefund = true
                        }else if(jsData[i].AE_STATUS == 1){
                           if(new Date(jsData[i].AE_EXPIRATION_DATE) > new Date()){
                              array.status = 2 //使用中
                           }else{
                              array.status = 4 //已使用
                           }
                           if(this.IsNull(array.usetime) != ''){
                              var sdate = array.usetime.split(" ")
                              var edate = array.deadline.split(" ")
                              array.info.date =  sdate[0] 
                              array.info.time =  sdate[1] + " ~ "+ edate[1]
                           }

                        }

                        //判斷有無過期
                        if(new Date() > new Date(array.deadline)){
                           array.status = 3
                           //過期不予分票
                           status = false
                        }

                        if(status){
                           this.productAll.push(array)
                        }
                     }
                  }
               }

               console.log(this.productAll)
            } else {
               alert(result.Message)
            }


         //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false;
            //console.log(e)
            alert(e)
         }
      },
   },
   components: {
      TicketView,
      Header

   }
}
</script>

<style lang="postcss" scoped>
   .owned {
      @apply flex flex-wrap gap-8 justify-center px-4 w-full;
      a {
         @apply block w-full sm:w-auto;
      }
   }
</style>