<template>
   <div class="bg-secondary-100 min-h-screen pt-[64px] pb-px relative">
      <div class="container mx-auto">
       
         <Header  title="場地預約"></Header>
         <div class="main">
         <div class="hidden md:flex flex-wrap items-center justify-center mb-8">
            <ul class="steps">
               <li class="step w-48" :class="{ 'step-primary': step >= 1 }">場地用途 </li>
               <li class="step" :class="{ 'step-primary': step >= 2 }">日期時段</li>
               <li class="step" :class="{ 'step-primary': step >= 3 }">前往付款</li>
               <li class="step" :class="{ 'step-primary': step >= 4 }">預約完成</li>
               <!-- <li class="step" :class="{'step-primary':step >= 5}"></li> -->
            </ul>
         </div>
        

        
         <!-- 步驟1 條件：運動類型  -->
         <transition name="fade">
            <section v-if="step === 1" class="buyTickets catalog">
               <div v-for="(item, index) in sportType" :key="index" class="option">
                  <input type="radio" :id="'sportType' + index" name="sportType" :value="item" v-model="select.sport"
                     @change="nextStep(2)" class="radio checked:bg-red-500">
                  <label :for="'sportType' + index">
                     <div class="option-btn flex justify-center items-center flex-col space-y-4">
                        <img class="w-1/3" :src="require('../../assets/image/sportType/' + item.icon + '.png')" alt="">
                        <span>{{ item.title }}</span>
                     </div>
                  </label>
               </div>
            </section>
         </transition>


         <transition name="fade">
            <section v-if="step === 2" >

               <!-- The button to open modal -->


               <!-- Put this part before </body> tag -->
               <input type="checkbox" id="my-modal" class="modal-toggle" />
               <div class="modal">
                  <div class="modal-box max-w-screen-lg relative p-4 sm:p-8">
                     <label for="my-modal" class="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                           <div class="card-body p-0">
                              <h3 class="card-title">{{select.sport.title}}場地出借情形</h3>
                              <p>本場單僅供可租借時間查詢，若該空間有多個使用場地以報到時場地為主。</p>
                              <select v-model="search.SP_ID" @change="updateData(0)"
                                 class="select border border-black mb-4">
                                 <option :value="null">無</option>
                                 <option v-for="(item, index) in search.options.SP_ID" :value="item.value" :key="index">
                                    {{ item.text }} </option>
                              </select>
                              <div class="flex items-center space-x-2 mb-4">
                                 <button class="link">今天</button>
                                 <button class="link" @click="updateData(-7)"><svg class=" fill"
                                       xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
                                       <path fill="none" d="M0 0h24v24H0z" />
                                       <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                                    </svg></button>
                                 <button class="link" @click="updateData(7)"><svg xmlns="http://www.w3.org/2000/svg"
                                       viewBox="0 0 24 24" width="16" height="16">
                                       <path fill="none" d="M0 0h24v24H0z" />
                                       <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                                    </svg></button>
                                 <div>{{ dateRange.min.year }}年{{ dateRange.min.month }}月{{ dateRange.min.day }}日<span
                                       class=" hidden sm:inline">星期{{ dateRange.min.week }}</span> -
                                    {{ dateRange.max.month }}月{{ dateRange.max.day }}日<span
                                       class=" hidden sm:inline">星期{{ dateRange.max.week }}</span></div>
                              </div>
                              <div class="timetable overflow-hidden ">
                                 <div class="bg-secondary-200 ">
                                    <div class="h-12 text-center">
                                    </div>
                                    <div v-for="(time, index) in spaceTime" :key="index"
                                       class="border-t border-transparent h-8 text-right ">
                                       <div class="-mt-2.5 mx-1 text-xs sm:text-sm">
                                          {{ time }}
                                       </div>
                                    </div>
                                 </div>
                                 <div class="flex-1 border-l border-secondary-300"
                                    v-for="(item, index) in spaceCalender" :key="index">
                                    <div class="h-12 flex justify-center items-center text-xs sm:text-sm bg-secondary-100">
                                       <!-- <div>{{day.year}}/{{day.month}}/{{day.day}}</div> -->
                                       <div>{{dateRange.days[index]}}（{{ item.week }}）</div>
                                    </div>
                                    <div v-for="(item2, index2) in item.rent" :key="index2"
                                       class="border-t border-slate-200 h-8 relative">
                                       <div v-for="(item4, index4) in spaceCalenderTemp[index].rent[index2]"
                                          :key="index4"
                                          class="flex justify-center items-center z-20 text-white absolute w-full rounded"
                                          :class="item4.class" :style="item4.style">
                                          <span class=" hidden sm:inline-block">{{ item4.title }}</span>
                                       </div>
                                       <div v-for="(item3, index3) in item2" :key="index3" class="isActive h-8 z-10 "
                                          :style="item3.style">{{ item3.title }}</div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                  </div>
               </div>

               <div class="card bg-base-100 shadow-none sm:shadow-xl rounded-none mb-4 sm:w-1/2 mx-auto">
                  <div class="card-body">
                     <h3 class="card-title">日期時段</h3>

                     <div class="form-control">
                        <label class="label">
                           <span class="label-text">場地用途</span>
                        </label>
                        <div class="flex flex-col sm:flex-row gap-6">
                           <div class="input-group">
                              <input type="text" v-model="select.sport.title" placeholder="Search…"
                                 class="input input-bordered w-full" disabled />
                              <button class="btn btn-square" @click="back(1)">
                                 變更
                              </button>
                           </div>
                           <label for="my-modal" class="btn">{{select.sport.title}}場地出借情形</label>
                        </div>
                     </div>
                     <div class="form-control w-full">
                        <label class="label">
                           <span class="label-text">選取租借日期</span>
                        </label>
                        <input type="date" :min="params.minDate" :max="params.maxDate" v-model="select.product.d"
                           placeholder="Type here" class="input input-bordered w-full" />
                     </div>
                     <div class="form-control w-full">
                        <label class="label">
                           <span class="label-text">開始時間</span>
                           <span class="label-text-alt">可租借時間以半點為單位</span>
                        </label>
                        <select v-model="select.product.t" @change="changeTime" class="select select-bordered">
                           <option v-for="(item, index) in timeOption" :key="index">{{ item }}</option>
                        </select>
                     </div>
                     <div class="form-control w-full">
                        <label class="label">
                           <span class="label-text">結束時間</span>
                           <span class="label-text-alt">可租借時間以半點為單位</span>
                        </label>
                        <select v-model="select.product.et" @change="changeTime" class="select select-bordered">
                           <option v-for="(item, index) in timeOption" :key="index">{{ item }}</option>
                        </select>
                     </div>
                     <div class="form-control w-full">
                        <label class="label">
                           <span class="label-text">場地</span>
                        </label>
                        <!-- <select v-model="select.space" @change="changeSelectSpace" class="select select-bordered">
                           <option v-for="(item, index) in spaceOption" :key="index" :value="item.values[0].values">
                              {{ item.name }}</option>
                        </select> -->
                        <select v-model="select.spaceTemp" @change="changeSelectSpace" class="select select-bordered">
                              <option v-for="(item, index) in spaceOption" :key="index" :value="item">
                              {{ item.prototype.SP_NAME }}</option>
                        </select>
                     </div>
                     <label class="label">
                        <span class="label-text">金額計算</span>
                     </label>
                     <div class=" overflow-y-scroll">
                     <table class="table w-full  ">
                        <thead>
                           <tr>
                              <th>時段</th>
                              <th class="text-right">單價</th>
                              <th class="text-center">數量</th>
                              <th class="text-right">小計</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr v-for="(item, index) in select.space" :key="index">
                              <td>{{ select.product.d }} {{ item.sTime }}~{{ item.eTime }}</td>
                              <td class="text-right"><span class="text-sm text-gray-400">{{ item.SPR_USETIME }}分鐘／</span>
                                 <span class="nt"><span class="text-sm">NT$</span>{{ item.PRICE }}
                                    <template
                                       v-if="IsNull($session.get('PPL_NID_IDENTITYcn')) != '' && $session.get('PPL_NID_IDENTITY') != 0">({{ $session.get('PPL_NID_IDENTITYcn') }})</template>
                                 </span></td>
                              <td class="text-center">{{ item.count }}</td>
                              <td class="text-right nt"><span class="text-sm">NT$</span>{{ item.PRICE * item.count }}</td>
                           </tr>
                        </tbody>
                        <tfoot>
                           <template
                              v-if="$session.get('MG_DISCOUNT') < 1 && IsNull($session.get('MG_DISCOUNT')) != ''">
                              <tr>
                                 <td colspan="3">會員折扣（{{ $session.get('MG_NAME') }}）</td>
                                 <td class="text-right nt"> X {{ $session.get('MG_DISCOUNT') }}</td>
                              </tr>
                           </template>
                           <tr>
                              <td colspan="3">總計</td>
                              <td class="text-right nt">NT$ <strong class="text-xl">
                                    <template
                                       v-if="$session.get('MG_DISCOUNT') < 1 && IsNull($session.get('MG_DISCOUNT')) != ''">
                                       {{ select.product.price * $session.get('MG_DISCOUNT') }}
                                    </template>
                                    <template v-else>
                                       {{ select.product.price }}
                                    </template>

                                 </strong></td>
                           </tr>
                        </tfoot>
                     </table>
                  </div>
                     <div class="divider"></div>
                     <div class="form-control w-full">
                           <label class="label">
                              <span class="label-text">收據類型</span>
                           </label>
                           <label class=" flex items-center space-x-2 mb-2">
                              <input v-model="select.receipt.OD_RECEIPT_TYPE" :value="0" type="radio" name="radio-1" class="radio" @click="clearReceipt" /> <span>個人收據</span>
                           </label>
                           <label  v-if="$session.get('PPL_NID_IDENTITY') == 0" class=" flex items-center space-x-2">
                              <input v-model="select.receipt.OD_RECEIPT_TYPE" :value="1" type="radio" name="radio-1" class="radio" /><span>公司收據</span>
                           </label>
                     </div>
                        <template v-if="select.receipt.OD_RECEIPT_TYPE == 1">
                           <div class="form-control w-full">
                              <label class="label">
                                 <span class="label-text">收據抬頭</span>
                              </label>
                              <input v-model="select.receipt.OD_RECIPT_TITLE" type="text" placeholder="" class="input input-bordered w-full" />
                           </div>
                           <div class="form-control w-full">
                              <label class="label">
                                 <span class="label-text">統一編號</span>
                              </label>
                              <input v-model="select.receipt.OD_UNICODE_NUMBER" type="text" placeholder="" class="input input-bordered w-full" />
                           </div>
                        </template>
                        
                        <div class="divider"></div>
                        <h6>注意事項</h6>
                        <ol class="list-disc mb-3 ml-6">
                           <li>場地零租僅提供至該日閉館前30分鐘。</li>
                           <li>借用時間包含架場、撤場，請自行預留時間。</li>
                           <li>場地使用前，請先至一樓櫃檯報到並繳交證件，工作人員將會說明您使用的場地位置。（校內計價－羽球、桌球、壁球：1張學生/職員證；籃球、排球5張學生/職員證。校外計價－1張身分證件）</li>
                           <li>逾時使用超過15分鐘者，將酌收0.5小時超時費；超過30分鐘者，將酌收1小時超時費，並於超時30分鐘後強制清場，因清場產生之損失須自行負責。</li>
                           <li>訂單已完成繳費者，場地因故需取消使用或變動使用場地或使用時間，依照<a target="_blank" href="https://drive.google.com/file/d/1uX0CXctPu_rmfw7m7TSByjfWKj6yU2BF/view?usp=sharing">『場地使用規範』</a>辦理</li>
                        </ol>
                        <label for="">
                           <input v-model="select.regulations" type="checkbox" class="checkbox" /> 我已閱讀並同意 <a target="_blank" href="https://drive.google.com/file/d/1uX0CXctPu_rmfw7m7TSByjfWKj6yU2BF/view?usp=sharing">『場地使用規範』</a> 相關條款之說明。並同意接受本館場地使用規範之一切條款所約束。
                        </label>
                     <div class="card-actions justify-end mt-5">
                        <button @click="addShopCart" class="btn primary w-full">租借</button>
                     </div>

                  </div>
               </div>

            </section>
         </transition>

         <!-- <transition name="fade">
            <div v-if="step===3">
               <template >
                  <div class="card bg-base-100 shadow-xl">
                     <div class="card-body">
                        <h3 class="item-title">金額計算</h3>
                           <table class="calculatetable">
                              <thead>
                                 <tr>
                                    <th class="text-left">租用時段</th>
                                    <th class="text-right">單價</th>
                                    <th class="text-center">數量</th>
                                    <th class="text-right">總計</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 <tr>
                                    <td class="time"><span>{{select.product.title}}</span></td>
                                    <td class="text-right">{{select.product.price/select.product.count}}</td>
                                    <td class="text-center">{{select.product.count}}</td>
                                    <td class="text-right">{{select.product.price}}</td>
                                 </tr>
                              </tbody>
                           </table>
                        <div class="card-actions justify-end">
                           <button class="btn primary w-full" @click="addShopCart" >租借</button>
                        </div>
                     </div>
                  </div>
                  <div class="px-6 sm:p-0">
                     
                  </div>
               </template> 
            </div>
             
         </transition> -->

         <transition name="fade">
            <div v-if="step === 3">
               <div class="card border shadow bg-base-100 mt-12 sm:w-1/2 mx-auto" v-if="orderDetails.ORDER">
                  <div class="card-body">
                     <h6 class="mb-4">租借明細</h6>

                     <table class="table w-full mb-4">
                        <tbody>
                           <tr>
                              <th>訂單編號</th>
                              <td>{{ orderDetails.ORDER.OD_ID }}</td>
                           </tr>
                           <tr>
                              <th>會員明稱</th>
                              <td>{{ $session.get('NAME') }}</td>
                           </tr>
                           <tr>
                              <th>商品明細</th>
                              <td v-for="(item,index) in orderDetails.ORDER_PRODUCT" :key="index" class=" whitespace-pre-line">
                                 <div v-for="(item2,index2) in item.OP_NAME" :key="index2">{{item2}}</div>
                              </td>
                           </tr>
                           <tr>
                              <th>訂單金額</th>
                              <td class="nt">NT$<strong class="text-2xl">${{ orderDetails.ORDER.OD_PRICE }}</strong></td>
                           </tr>
                           <tr>
                              <th>付款方式</th>
                              <td>
                                 <ul class="paymentMethod">
                                    <li><img class=" h-4" alt="逢甲大學體育館" src="@/assets/image/paymentIcon/atm.jpg"></li>
                                    <li><img class=" h-4" alt="逢甲大學體育館"
                                          src="@/assets/image/paymentIcon/LINE_Pay_logo.png"></li>
                                    <li><img class=" h-4" alt="逢甲大學體育館"
                                          src="@/assets/image/paymentIcon/taiwanpaylogo.png"></li>
                                    <li><img class=" h-4" alt="逢甲大學體育館"
                                          src="@/assets/image/paymentIcon/1200px-JKOPAY_logo.svg.png"></li>
                                    <li><img class=" h-4" alt="逢甲大學體育館" src="@/assets/image/paymentIcon/logo-mark.svg">
                                    </li>
                                    <li><img class=" h-4" alt="逢甲大學體育館"
                                          src="@/assets/image/paymentIcon/Alipay_logo.png"></li>
                                    <li>超商代收</li>
                                    <li>信用卡</li>
                                 </ul>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                     <div class="alert alert-warning shadow-lg">
                        <div>
                           <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                           <span v-if="deadLine == 0">訂單成立後，請於48小時內完成繳費，超過繳費期限系統將自動取消訂單。</span>
                           <span v-else-if="deadLine == 1">訂單成立後，請於2小時內完成繳費，超過繳費期限系統將自動取消訂單。</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="text-center mt-12">
                  <a :href="$appDomain + '/FcuPay/FcuPay?OD_ID=' + orderDetails.ORDER.OD_ID"><button
                        class="btn">前往付款</button></a>
                  <!-- <button @click="step = 5" class="btn btn-primary">付款成功就到-步驟5</button> -->
               </div>
            </div>
         </transition>

         <transition name="fade">
            <div v-if="step === 4">
               <div class="card  border shadow bg-base-100 mt-12 sm:w-1/2 mx-auto">

                  <div class="card-body">
                     <h2 class="card-title flex-col">
                        <svg xmlns="http://www.w3.org/2000/svg" class=" fill-green-500" viewBox="0 0 24 24" width="48" height="48"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-.997-6l7.07-7.071-1.414-1.414-5.656 5.657-2.829-2.829-1.414 1.414L11.003 16z"/></svg> 付款成功，感謝您的購買。
                     </h2>
                     <table class="table w-full">
                        <tbody>
                           <tr>
                              <th>訂單編號</th>
                              <td>{{ orderDetails.ORDER.OD_ID }}</td>
                           </tr>
                           <tr>
                              <th>會員明稱</th>
                              <td>{{ $session.get('NAME') }}</td>
                           </tr>
                           <tr>
                              <th>商品明細</th>
                              <td v-for="(item,index) in orderDetails.ORDER_PRODUCT" :key="index" class=" whitespace-pre-line">
                                 <div v-for="(item2,index2) in item.OP_NAME" :key="index2">{{item2}}</div>
                              </td>
                           </tr>
                           <tr>
                              <th>分票碼</th>
                              <td class=" whitespace-pre-line">{{ codeDetails.SQ_ID }}</td>
                           </tr>
                           <tr>
                              <th>付款金額</th>
                              <!-- <td>NT<strong class="text-2xl text-green-500">$980</strong> 【1200 x 0.9（學生）】</td> -->
                              <td>NT<strong class="text-2xl text-green-500">${{ orderDetails.ORDER.OD_PRICE }}</strong>
                              </td>
                           </tr>
                           <tr>
                              <th>付款方式</th>
                              <td>
                                 逢甲pay
                              </td>
                           </tr>
                        </tbody>
                     </table>
                     <p>報到時請至服務櫃台報到、繳交證件；使用完畢請恢復場地並至一樓櫃台領取證件。</p>
                     <div class="step-control">
                        <button class="btn ">繼續場地預約</button>
                        <router-link to="/member/purchase" class="btn">前往訂單記錄</router-link>
                     </div>
                  </div>
               </div>
            </div>
         </transition>
      </div>
   </div>
      <LoadingPage :loadSet="loadSet"></LoadingPage>
   </div>
</template>

<script>
import {
   apiToken,
   apiSPACE_LIST,
   apiSPACE_USE_LIST,
   apiSPACE_RENT_LIST,
   apiSPACE_RENT_LIST2,
   apiORDER_ADD,
   apiORDER_PRODUCT_GET_SPLIT_CODE,
   apiCHECK_SPACE_RENT_TIME,
   apiSPACE_UPPER_LIST,
   apSPACE_RENT_LIST_DATE,
   apiSPACE_RENT_USED_LIST,
   apiORDER_GET,
   apiMEMBER_PROFILE_CHECK,
   apiCHECK_CLOSED_DATE,
} from '../../api/API'
import Header from '@/components/MemberHeader.vue'
export default {
   head: {
      title: "場地預約",
      meta:[
         { name: 'description', content: '場地預約'}
      ]
   },
   data() {
      return {
         step: 1,
         deadLine:0,
         select: {
            sport: {},
            space: [],
            spaceTemp:[],
            spaceRent: [],
            product: {
               date: null,
               d: null,
               time: null,
               spaceRent: null,
               count: 0,
               price: 0,
               title: null,
               t: null,
               et: null,
            },
            receipt:{
               OD_RECEIPT_TYPE:0,
               OD_RECIPT_TITLE:null,
               OD_UNICODE_NUMBER:null,
            },
            regulations:false,
         },

         sportTypeSelect: null,
         sportType: [],
         space: {},
         spaceOption: {},
         //現在可選的場地上層
         spaceUpper: {},
         spaceRent: [],
         minRent: 0,
         maxRent: 0,

         spaceTime: [
            "07:00",
            "07:30",
            "08:00",
            "08:30",
            "09:00",
            "09:30",
            "10:00",
            "10:30",
            "11:00",
            "11:30",
            "12:00",
            "12:30",
            "13:00",
            "13:30",
            "14:00",
            "14:30",
            "15:00",
            "15:30",
            "16:00",
            "16:30",
            "17:00",
            "17:30",
            "18:00",
            "18:30",
            "19:00",
            "19:30",
            "20:00",
            "20:30",
            "21:00",
            "21:30",
            "22:00",
            "22:30",
         ],
         spaceCalender: [{
            day: "7",
            week: "星期日",
            rent: []
         },
         {
            day: "1",
            week: "星期一",
            rent: []
         },
         {
            day: "2",
            week: "星期二",
            rent: []
         },
         {
            day: "3",
            week: "星期三",
            rent: []
         },
         {
            day: "4",
            week: "星期四",
            rent: []
         },
         {
            day: "5",
            week: "星期五",
            rent: []
         },
         {
            day: "6",
            week: "星期六",
            rent: []
         }
         ],
         spaceCalenderTemp: [{
            day: "7",
            week: "星期日",
            rent: []
         },
         {
            day: "1",
            week: "星期一",
            rent: []
         },
         {
            day: "2",
            week: "星期二",
            rent: []
         },
         {
            day: "3",
            week: "星期三",
            rent: []
         },
         {
            day: "4",
            week: "星期四",
            rent: []
         },
         {
            day: "5",
            week: "星期五",
            rent: []
         },
         {
            day: "6",
            week: "星期六",
            rent: []
         }
         ],

         params: {
            SPR_ID: [],
            minDay: null,
            maxDay: null,

            minDate: null,
            maxDate: null,
         },

         dateRange: {
            day: 0,
            min: {
               week: null,
               day: null,
               month: null,
               year: null
            },
            max: {
               week: null,
               day: null,
               month: null,
               year: null
            },
         },

         OD_ID: null,
         orderDetails: {
            ORDER: null,
            ORDER_PRODUCT: null,
            ORDER_DISCOUNT: null,
         },
         AE_ID: null,
         codeDetails: {

         },

         timeOption: [
         ],

         search: {
            SP_ID: null,
            options: {
               SP_ID: [],
            }
         },

         space_list:[],//排序後的場地列表



         checkSP: 0,

         loadSet: { show: false, fullPage: true, type: 'dots', color: 'rgba(0,0,0,0.7)' }
      }
   },
   mounted() {
      this.init()
   },
   components: {
      Header
   },
   watch: {
      'select.product.d': function (){
         this.timeInit()
         console.log(this.select)

         var localThis = this;
         this.checkCloseDate(this.select.product.d).then(function(value){
            if(value == 0){
               if (localThis.IsNull(localThis.select.product.d) != '' && localThis.IsNull(localThis.select.product.t) != '' && localThis.IsNull(localThis.select.product.et) != '' && localThis.IsNull(localThis.$session.get('ShoppingCart')) == '') {
                  localThis.select.product.date = localThis.select.product.d + "T" + localThis.select.product.t
                  localThis.select.space = []
                  localThis.checkCanSpaceRent()
               }
            }
            else{
               alert(localThis.select.product.d +"為閉館日")
               localThis.select.product.d = null
               localThis.select.product.t = null
               localThis.select.product.et = null
               localThis.select.product.date = null
               localThis.select.product.price = 0
               localThis.select.space = []
               localThis.select.spaceTemp = {}
               localThis.spaceOption = {}
            }
            
         });

         
      },
      'select.product.t': function () {
         if (this.IsNull(this.select.product.d) != '' && this.IsNull(this.select.product.t) != '') {
            this.select.product.date = this.select.product.d + "T" + this.select.product.t
         }
      },
   },
   methods: {
      async init() {
          if (this.IsNull(this.$session.get('ACID')) != ""){
            var status = await this.getMemberProfileCheck();
            if(!status){
               alert("請先填妥基本資料，以利進行後續購買程序。")
               this.$router.push({name:"profile"})
            }
          }

         this.loadSet.show = false
         var today = new Date()
         this.params.minDay = today.getFullYear() + "-" + this.TransformTime2String(today.getMonth() + 1) + "-" + this.TransformTime2String(today.getDate()) + "T00:00"
         this.params.minDate = today.getFullYear() + "-" + this.TransformTime2String(today.getMonth() + 1) + "-" + this.TransformTime2String(today.getDate())
         await this.getSpaceUseList()
         this.changeDate(0)

         if (this.IsNull(this.$session.get('ShoppingCart')) != '') {
            this.loadSet.show = true
            var shop = this.$session.get('ShoppingCart')
            await (this.select.sport = shop.values.select.sport)
            this.nextStep()
            await (this.select.product = shop.values.select.product)
            await (this.select.space = shop.values.select.space)
            await (this.select.spaceRent = shop.values.select.spaceRent)
            await (this.select.receipt = shop.values.select.receipt)
            await (this.select.regulations == shop.values.select.regulations)
            this.checkCanSpaceRent()

            this.$session.set('ShoppingCart', '')
            await this.changeSelectSpace()
            this.loadSet.show = false
         } else if (this.IsNull(this.$route.query.OD_ID) != '') {
            this.OD_ID = this.$route.query.OD_ID
            await this.getOrder()
            await this.getOrderSplitCode()
            this.step = 4
         }
      },
      async nextStep() {
         this.step++
         switch (this.step) {
            case 2:
               await this.getSpaceList()
               await this.getSpaceRentList2()
               this.timeInit()
               if (this.IsNull(this.select.product.d) != '' && this.IsNull(this.select.product.t) != '' && this.IsNull(this.select.product.et) != '' && this.IsNull(this.$session.get('ShoppingCart')) == '') {
                  this.select.space = []
                  this.checkCanSpaceRent()
               }
               // console.log(this.select)
               break;
            // case 3:
            //    console.log(this.select)
            //    break;

            default:
               break;
         }
      },
      back(step) {
         this.step = step;
         switch (step) {
            case 1:
               this.select.sport = {
                  title: '',
                  value: '',
                  icon: '',
               }
               this.space = {}
               this.select.space = []
               break;
            case 2:
               this.space = {}
               this.select.space = []
               break;
            case 3:
               break;

            default:
               break;
         }

      },
      async getSpaceUseList() {
         try {
            this.sportType = []
            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               Token: Token
            }
            data = this.BaseModel(data)


            var result = JSON.parse(await (await apiSPACE_USE_LIST(data)).data);
            this.loadSet.show = false

            if (result.Status) {
               var jsData = JSON.parse(result.Data)
               //console.log(jsData)
               for (let i in jsData) {
                  this.sportType.push({
                     title: jsData[i].SU_NAME,
                     value: jsData[i].SU_ID,
                     icon: jsData[i].SU_ICON,
                  })

               }
               //console.log(this.priceList)


            } else {
               alert(result.Message)
            }

            //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false
            //console.log(e)
            alert(e)
         }
      },
      timeInit() {
         this.timeOption = []

         var openTime = "07:00"
         var closeTime = "22:00"
         var week = new Date(this.select.product.d).getDay()
         if (week == 0)
            week = 7

         this.select.spaceRent = []
         for (let i = 0; i < this.spaceRent.length; i++) {

            if (this.spaceRent[i].SPR_RENT_DAYS.includes(week)) {
               this.select.spaceRent.push(this.spaceRent[i])
            }

         }
         if (week == 7) {
            openTime = "08:00"
            closeTime = "17:00"
         } else if (week == 6) {
            openTime = "08:00"
            closeTime = "22:00"
         }


         var sH = parseInt(openTime.split(':')[0])
         var sM = parseInt(openTime.split(':')[1])
         var eH = parseInt(closeTime.split(':')[0])
         var eM = parseInt(closeTime.split(':')[1])

         var count = (eH - sH) + 1
         var temp = []
         for (let i = 0; i < count; i++) {
            if (i == 0) {
               if (sM < 30 && sM == 0) {
                  temp.push(this.TransformTime2String(sH + i) + ":00")
                  temp.push(this.TransformTime2String(sH + i) + ":30")
               }
               else if (sM < 30 && sM != 0)
                  temp.push(this.TransformTime2String(sH + i) + ":30")
               else if (sM > 30)
                  temp.push(this.TransformTime2String(sH + i) + ":30")

            }
            else if (i == (count - 1)) {
               if (eM == 0)
                  temp.push(this.TransformTime2String(sH + i) + ":00")
               else if (eM > 30)
                  temp.push(this.TransformTime2String(sH + i) + ":30")
            }
            else {
               temp.push(this.TransformTime2String(sH + i) + ":00")
               temp.push(this.TransformTime2String(sH + i) + ":30")
            }

         }

         for (let i = 0; i < temp.length; i++) {
            if (new Date() < new Date(this.select.product.d + "T" + temp[i])) {
               this.timeOption.push(temp[i])
            }
         }
      },
      async getSpaceRentList2() {
         try {
            this.spaceRent = []
            this.search.options.SP_ID = []
            //this.params.SPR_ID = []
            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               SPR_USE: this.select.sport.value,
               Token: Token
            }
            data = this.BaseModel(data)


            var result = JSON.parse(await (await apiSPACE_RENT_LIST2(data)).data);
            this.loadSet.show = false

            if (result.Status) {
               var jsData = JSON.parse(result.Data)
               var maxDay = 0

               for (let i in jsData) {
                  if (maxDay < jsData[i].SPR_RESERVE_DAY)
                     maxDay = jsData[i].SPR_RESERVE_DAY
                  //金額設定-------------------------------
                  jsData[i].PRICE = jsData[i].SPR_PRICE
                  if (this.IsNull(this.$session.get('PPL_NID_IDENTITY')) != "") {
                     switch (this.$session.get('PPL_NID_IDENTITY')) {
                        case 1:
                           if (jsData[i].SPR_EXEMPATION_STUDENT != null)
                              jsData[i].PRICE = jsData[i].SPR_EXEMPATION_STUDENT
                           break
                        case 2:
                           if (jsData[i].SPR_EXEMPATION_STAFF != null)
                              jsData[i].PRICE = jsData[i].SPR_EXEMPATION_STAFF
                           break
                        case 3:
                           if (jsData[i].SPR_EXEMPATION_ALUMNI != null)
                              jsData[i].PRICE = jsData[i].SPR_EXEMPATION_ALUMNI
                           break
                        case 4:
                           if (jsData[i].SPR_EXEMPATION_DEPENDENTS != null)
                              jsData[i].PRICE = jsData[i].SPR_EXEMPATION_DEPENDENTS
                           break
                     }
                  }

                  //是否交集
                  let Intersection = this.search.options.SP_ID.filter((e) => {
                     return (e.value == jsData[i].SPR_SPACE)
                  })
                  if (Intersection.length == 0){
                     this.search.options.SP_ID.push({ text: jsData[i].SP_NAME, value: jsData[i].SPR_SPACE, upper: jsData[i].SP_UPPER, sort: jsData[i].SP_SORT })
                     //run以排序好的場地列表 show 改為true 以便等等顯示
                     for(let j = 0;j < this.space_list.length;j++){
                        if(this.space_list[j].SP_ID == jsData[i].SPR_SPACE){
                           this.space_list[j].show = true
                           break;
                        }
                     }
                  }
                     
               }

               //重新創立arry 將需要顯示的依序加入
               var tempArray = []
               for(let j = 0;j < this.space_list.length;j++){
                  if(this.space_list[j].show){
                     tempArray.push({ text: this.space_list[j].SP_NAME, value: this.space_list[j].SP_ID})
                  }
               }
               this.search.options.SP_ID = tempArray

               this.spaceRent = jsData
               if (maxDay != 0)
                  this.params.maxDate = this.TransformTimeFormatYYYMMDD(new Date(new Date().setDate(new Date().getDate() + maxDay)))


            } else {
               alert(result.Message)
            }

            //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false
            //console.log(e)
            alert(e)
         }
      },
      async checkSpaceRentTime(SP_ID, DATE, STIME, ETIME) {
         var count = null
         try {
            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               SP_ID: SP_ID,
               DATE: DATE,
               STIME: STIME,
               ETIME: ETIME,
               Token: Token
            }
            data = this.BaseModel(data)


            var result = JSON.parse(await (await apiCHECK_SPACE_RENT_TIME(data)).data);
            this.loadSet.show = false

            if (result.Status) {
               var jsData = JSON.parse(result.Data)
               count = jsData.count
               //console.log(jsData)
            } else {
               this.loadSet.show = false
               alert(result.Message)
            }

            //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false
            //console.log(e)
            alert(e)
         }
         return count
      },
      async checkCloseDate(date) {
         var count = 0
         try {
            //this.params.SPR_ID = []
            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               CD_DATE: date,
               Token: Token
            }
            data = this.BaseModel(data)


            var result = JSON.parse(await (await apiCHECK_CLOSED_DATE(data)).data);
            this.loadSet.show = false

            if (result.Status) {
               var jsData = JSON.parse(result.Data)
               count = jsData.count

            }

            //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false
            console.log(e)
         }
         
         return count
      },
      //抓取時間段內可行場地 並進行排序
      async checkCanSpaceRent() {

         var openTime = "07:00"
         var closeTime = "22:00"
         var week = new Date(this.select.product.d).getDay()
         if (week == 0)
            week = 7

         if (week == 7) {
            openTime = "08:00"
            closeTime = "17:00"
         } else if (week == 6) {
            openTime = "08:00"
            closeTime = "22:00"
         }

         if (this.IsNull(this.select.product.d) == '') {
            alert("請選擇日期")
            return
         }

         if (new Date(this.select.product.d + "T" + openTime) > new Date(this.select.product.d + "T" + this.select.product.t) || this.IsNull(this.select.product.t) == '') {
            alert("請選擇正確開始時間")
            return
         }
         if (new Date(this.select.product.d + "T" + closeTime) < new Date(this.select.product.d + "T" + this.select.product.et) || this.IsNull(this.select.product.et) == '') {
            alert("請選擇正確結束時間")
            return
         }
         if (new Date(this.select.product.d + "T" + this.select.product.t) >= new Date(this.select.product.d + "T" + this.select.product.et)) {
            alert("開始時間與結束時間衝突")
            return
         }

         //每30分鐘一個數量
         this.select.product.count = this.date_diff(new Date(this.select.product.d + "T" + this.select.product.et), new Date(this.select.product.d + "T" + this.select.product.t), 'minute') / 30

         //先塞資料
         this.space = {}
         for (let i = 0; i < this.select.spaceRent.length; i++) {
            if (this.space[this.select.spaceRent[i].SPR_SPACE] == undefined)
               this.space[this.select.spaceRent[i].SPR_SPACE] = []
            this.space[this.select.spaceRent[i].SPR_SPACE].push(this.select.spaceRent[i])
         }
         // console.log(this.space,'space1')

         //判斷租借設定是否在使用者選擇時間內  放入tempObject
         var tempObject = {}
         var SP_ID = Object.keys(this.space)
         for (let i = 0; i < SP_ID.length; i++) {
            if (this.space[SP_ID[i]].length == 1 &&
               new Date(this.select.product.d + "T" + this.space[SP_ID[i]][0].SPR_RENT_START) <= new Date(this.select.product.d + "T" + this.select.product.t) &&
               new Date(this.select.product.d + "T" + this.select.product.et) <= new Date(this.select.product.d + "T" + this.space[SP_ID[i]][0].SPR_RENT_END)) {
                  
               //該時段開放預約時間
               let maxDate = new Date(new Date().setDate(new Date().getDate() + this.space[SP_ID[i]][0].SPR_RESERVE_DAY))
               
               if (new Date(this.select.product.d + "T00:00") <= maxDate) {
                  //檢查該時段
                  let timeCount = await this.checkSpaceRentTime(SP_ID[i], this.select.product.d, this.select.product.t, this.select.product.et)
                  if (timeCount == 0) {
                     if (tempObject[SP_ID[i]] == undefined)
                        tempObject[SP_ID[i]] = { values: [], prototype: {} }

                     this.space[SP_ID[i]][0].count = this.select.product.count
                     tempObject[SP_ID[i]].values.push(this.space[SP_ID[i]][0])
                     if (tempObject[SP_ID[i]].prototype.SP_NAME == undefined) {
                        tempObject[SP_ID[i]].prototype.SP_NAME = this.space[SP_ID[i]][0].SP_NAME
                        tempObject[SP_ID[i]].prototype.SP_SORT = this.space[SP_ID[i]][0].SP_SORT
                        tempObject[SP_ID[i]].prototype.SP_UPPER = this.space[SP_ID[i]][0].SP_UPPER
                     }
                  }
               }



            }
            else if (this.space[SP_ID[i]].length > 1) {
               var continuous = false
               var startIndex = null
               var endIndex = null
               for (let j = 0; j < this.space[SP_ID[i]].length; j++) {
                  //該時段開放預約時間
                  let maxDate = new Date(new Date().setDate(new Date().getDate() + this.space[SP_ID[i]][j].SPR_RESERVE_DAY))
                  if (new Date(this.select.product.d + "T00:00") <= maxDate) {
                     //抓開始位置  必須在這個場租設定段範圍裡
                     if (startIndex == null &&
                        new Date(this.select.product.d + "T" + this.space[SP_ID[i]][j].SPR_RENT_START) <= new Date(this.select.product.d + "T" + this.select.product.t) &&
                        new Date(this.select.product.d + "T" + this.select.product.t) < new Date(this.select.product.d + "T" + this.space[SP_ID[i]][j].SPR_RENT_END)) {
                        startIndex = j
                        continuous = true
                     }

                     //抓結束位置 必須在這個場租設定段範圍裡
                     if (startIndex != null && endIndex == null &&
                        new Date(this.select.product.d + "T" + this.space[SP_ID[i]][j].SPR_RENT_START) <= new Date(this.select.product.d + "T" + this.select.product.et) &&
                        new Date(this.select.product.d + "T" + this.select.product.et) <= new Date(this.select.product.d + "T" + this.space[SP_ID[i]][j].SPR_RENT_END)) {
                        endIndex = j
                     }

                     //如果時間是在同一個場租設定直接跳出迴圈不用判斷時否連續
                     if (startIndex != null && endIndex != null && startIndex == endIndex) {
                        break
                     }

                     //continuous = true 代表startIndex 抓到開始位置 去判斷是否連續
                     if (j != 0 && continuous) {
                        //如果不是連續時間則不能跨域
                        if (!(this.space[SP_ID[i]][j].SPR_RENT_START == this.space[SP_ID[i]][j - 1].SPR_RENT_END))
                           continuous = false
                     }
                  }
                  else {
                     continuous = false
                     break;
                  }
               }

               //如果連續才放入
               // console.log(startIndex,endIndex,continuous)

               if (continuous) {
                  var startTime = new Date(this.select.product.d + "T" + this.select.product.t)
                  for (let j = startIndex; j <= endIndex; j++) {
                     //檢查該時段
                     let timeCount = await this.checkSpaceRentTime(SP_ID[i], this.select.product.d, this.select.product.t, this.select.product.et)
                     if (timeCount == 0) {
                        if (tempObject[SP_ID[i]] == undefined)
                           tempObject[SP_ID[i]] = { values: [], prototype: {} }

                        if (j != startIndex) {
                           startTime = new Date(this.select.product.d + "T" + this.space[SP_ID[i]][j].SPR_RENT_START)
                        }
                        if (j == endIndex) {
                           this.space[SP_ID[i]][j].count = this.date_diff(new Date(this.select.product.d + "T" + this.select.product.et), startTime, 'minute') / 30
                        } else {
                           this.space[SP_ID[i]][j].count = this.date_diff(new Date(this.select.product.d + "T" + this.space[SP_ID[i]][j].SPR_RENT_END), startTime, 'minute') / 30
                        }


                        tempObject[SP_ID[i]].values.push(this.space[SP_ID[i]][j])
                        if (tempObject[SP_ID[i]].prototype.SP_NAME == undefined) {
                           tempObject[SP_ID[i]].prototype.SP_NAME = this.space[SP_ID[i]][j].SP_NAME
                           tempObject[SP_ID[i]].prototype.SP_SORT = this.space[SP_ID[i]][j].SP_SORT
                           tempObject[SP_ID[i]].prototype.SP_UPPER = this.space[SP_ID[i]][j].SP_UPPER
                        }
                     }

                  }
               }

            }
         }
         // console.log(tempObject,'space2')

         //檢查裡面是否足夠選取時段 (上面有檢查連續 所以只需要判斷最後一筆是否有滿足選取的結束時間)
         var IsNoLeastUnit = false
         var tempName = Object.keys(tempObject)
         var tempObject2 = {}
         for (let i = 0; i < tempName.length; i++) {
            let sts = true
            if (new Date(this.select.product.d + "T" + this.select.product.et) >
               new Date(this.select.product.d + "T" + tempObject[tempName[i]].values[tempObject[tempName[i]].values.length - 1].SPR_RENT_END)) {
               sts = false
            }

            //最少單位取第一個時間域
            var leastUnit = tempObject[tempName[i]].values[0].SPR_LEAST_UNIT
            var allUnit = 0
            //計算所有租借單位
            for(let j = 0;j < tempObject[tempName[i]].values.length;j++){
               allUnit += tempObject[tempName[i]].values[j].count
            }

            if(sts &&  allUnit < leastUnit){
               sts = false
               IsNoLeastUnit = true
            }

            if(sts)
               tempObject2[tempName[i]] = tempObject[tempName[i]]
         }
         // console.log(tempObject2,IsNoLeastUnit,'space3')
         
         tempObject = tempObject2
         var le = Object.keys(tempObject).length
         if(le == 0 && IsNoLeastUnit){
            this.spaceOption = {}
            alert("•最少租借單位為2單位")
            return
         }
         
         // console.log(tempObject,'space3')



         //場館空間進行排序
         //先建立樹
         var tree = []
         var objName = Object.keys(tempObject)
         //不是根的資料
         var objName2 = []
         //先run一次將是根的資料建立  不是的塞入objName2進行下輪
         for (let i = 0; i < objName.length; i++) {
            if (tempObject[objName[i]].prototype.SP_UPPER == null) {
               tempObject[objName[i]].prototype.SP_ID = objName[i]
               tree.push({
                  values: tempObject[objName[i]].values,
                  prototype: tempObject[objName[i]].prototype,
                  children: []
               })
            } else {
               objName2.push(objName[i])
            }

         }

         //不是根的子資料去抓取上層資料後建立
         for (let i = 0; i < objName2.length; i++) {
            try {
               this.loadSet.show = true
               var Token = await (await apiToken()).data
               //console.log(Token);
               var data = {
                  SP_ID: objName2[i],
                  Token: Token
               }
               data = this.BaseModel(data)

               //回傳當前場地 所有上層資料(包括自己) 從下往上排序
               var result = JSON.parse(await (await apiSPACE_UPPER_LIST(data)).data);
               this.loadSet.show = false

               if (result.Status) {
                  var jsData = JSON.parse(result.Data)

                  //當前根分支
                  let TreeBranch = null
                  //run一次tree 抓現在的根分支
                  for (let j = 0; j < tree.length; j++) {
                     if (tree[j].prototype.SP_ID == jsData[jsData.length - 1].SP_ID) {
                        TreeBranch = tree[j]
                        break;
                     }
                  }
                  //如果沒有根分支則建一個並指定為當前根分支
                  if (TreeBranch == null) {
                     tree.push({
                        values: null,
                        prototype: {
                           SP_ID: jsData[jsData.length - 1].SP_ID,
                           SP_NAME: jsData[jsData.length - 1].SP_NAME,
                           SP_SORT: jsData[jsData.length - 1].SP_SORT,
                           SP_UPPER: null
                        },
                        children: []
                     })

                     TreeBranch = tree[tree.length - 1]
                  }

                  // console.log(TreeBranch)

                  //由於資料層是從下往上排序 這裡反著比對
                  for (let j = jsData.length - 2; j >= 0; j--) {
                     //跑到最後就是我們要加進去的資料
                     if (j == 0) {
                        TreeBranch.children.push({
                           values: tempObject[objName2[i]].values,
                           prototype: {
                              SP_ID: jsData[j].SP_ID,
                              SP_NAME: jsData[j].SP_NAME,
                              SP_SORT: jsData[j].SP_SORT,
                              SP_UPPER: TreeBranch.prototype.SP_ID
                           },
                           children: []
                        })
                        //最後進行排序
                        TreeBranch.children.sort(function (a, b) {
                           // console.log(a,b,b.prototype.SP_SORT - a.prototype.SP_SORT,'sort')
                           return a.prototype.SP_SORT - b.prototype.SP_SORT
                        })
                     } else {
                        var status = false
                        //比對tree有沒有當層資料
                        for (let k = 0; k < TreeBranch.children.length; k++) {
                           if (TreeBranch.children[k].prototype.SP_ID == jsData[j].SP_ID) {
                              TreeBranch = TreeBranch.children[k]
                              status = true
                           }
                        }

                        //沒有則新增並指定為新層
                        if (!status) {
                           TreeBranch.children.push({
                              values: null,
                              prototype: {
                                 SP_ID: jsData[j].SP_ID,
                                 SP_NAME: jsData[j].SP_NAME,
                                 SP_SORT: jsData[j].SP_SORT,
                                 SP_UPPER: jsData[j].SP_UPPER
                              },
                              children: []
                           })

                           TreeBranch = TreeBranch.children[TreeBranch.children.length - 1]
                        }
                     }
                  }

               } else {
                  alert(result.Message)
               }

               //console.log(result.Message)
            } catch (e) {
               this.loadSet.show = false
               //console.log(e)
               alert(e)
            }

         }

         //在run一次根對子資料做排序
         for(let i = 0; i < tree.length; i++){
            tree[i].children.sort(function (a, b) {
               // console.log(a,b,b.prototype.SP_SORT - a.prototype.SP_SORT,'sort')
               return a.prototype.SP_SORT - b.prototype.SP_SORT
            })
         }
         
         // console.log(tree,'tree')
         
         //新自動選擇方法  將樹存起來
         this.spaceOption = tree



         //在對樹輸出
         // var temp = {}
         // this.spaceOption = {}
         // for (let i = 0; i < tree.length; i++) {
         //    let TreeBranch = tree[i]

         //    temp = this.outChildren(temp, TreeBranch, 0)

         // }
         
         // this.spaceOption = temp

         // console.log(temp, 'temp')
         // for(var key in this.spaceOption){
         //    console.log(this.spaceOption[key], 'spaceOption')

         // }

         // var count = Object.keys(this.spaceOption).length
         var count = this.spaceOption.length

         if (count == 0) {
            this.spaceOption = {}
            alert("•目前選擇時段已無空場")
         }



      },
      //舊版
      outChildren(temp, TreeBranch, level) {
         //第一層直接且能場租直接加進去
         if (TreeBranch.values != null && level == 0) {
            if (temp[TreeBranch.prototype.SP_ID + '(全部)'] == undefined) {
               temp[TreeBranch.prototype.SP_ID + '(全部)'] = {
                  name: null,
                  values: []
               }
            }

            temp[TreeBranch.prototype.SP_ID + '(全部)'].name = TreeBranch.prototype.SP_NAME + "(全部)"
            temp[TreeBranch.prototype.SP_ID + '(全部)'].values.push({
               name: TreeBranch.prototype.SP_NAME,
               values: TreeBranch.values
            })

         }

         level++//怪怪的????
         //用遞迴方式跑子項目
         for (var i = 0; i < TreeBranch.children.length; i++) {
            if (TreeBranch.children[i].values != null) {
               if (temp[TreeBranch.prototype.SP_ID] == undefined) {
                  temp[TreeBranch.prototype.SP_ID] = {
                     name: null,
                     values: []
                  }
               }

               temp[TreeBranch.prototype.SP_ID].name = TreeBranch.prototype.SP_NAME
               temp[TreeBranch.prototype.SP_ID].values.push({
                  name: TreeBranch.children[i].prototype.SP_NAME,
                  values: TreeBranch.children[i].values
               })
            }

            temp = this.outChildren(temp, TreeBranch.children[i],level)
         }

         return temp
      },
      //新版
      outChildren2(temp, TreeBranch, level) {
         
         level++
         //用遞迴方式跑子項目
         for (var i = 0; i < TreeBranch.children.length; i++) {
            temp = this.outChildren2(temp, TreeBranch.children[i],level)
         }

         if (TreeBranch.values != null) {
            temp.push({
               prototype: TreeBranch.prototype,
               values: TreeBranch.values,
               level:level
            })

         }

         return temp
      },
      changeSelectSpace() {
         // ---------------------新自動選擇--------------------------
         //根據選擇的樹根取跑遞迴傳出階層
         var temp = []
         let TreeBranch = this.select.spaceTemp
         temp = this.outChildren2(temp, TreeBranch, 0)
         temp.sort(function (a, b) {
            // console.log(a,b,b.prototype.SP_SORT - a.prototype.SP_SORT,'sort')
            return b.level - a.level
         })
         // console.log(temp)
         //選擇第一個
         this.select.space = temp[0].values
         // ---------------------end--------------------------


         var date = new Date(this.select.product.date)
         this.select.product.title = this.select.space[0].SP_NAME + "　" + this.TransformTimeFormatYYYMMDDhhmm(date)
         var eTime = new Date(this.select.product.d + "T" + this.select.product.et)
         this.select.product.title += " ~ " + this.TransformTime2String(eTime.getHours()) + ":" + this.TransformTime2String(eTime.getMinutes()) + " " + this.select.space[0].SP_MAX_PEOPEL + "人"

         this.select.product.price = 0
         // console.log(this.select.space)
         // console.log(this.select.product)
         for (let i = 0; i < this.select.space.length; i++) {
            if (i == 0) {
               let tempD = new Date(this.select.product.d + "T" + this.select.product.t)
               let endD = new Date(tempD.setMinutes(tempD.getMinutes() + this.select.space[i].count * 30))
               this.select.space[i].sTime = this.select.product.t
               this.select.space[i].eTime = this.TransformTimeFormathhmm(endD)
            } else {
               let tempD = new Date(this.select.product.d + "T" + this.select.space[i - 1].eTime)
               let endD = new Date(tempD.setMinutes(tempD.getMinutes() + this.select.space[i].count * 30))
               this.select.space[i].sTime = this.select.space[i - 1].eTime
               this.select.space[i].eTime = this.TransformTimeFormathhmm(endD)
            }

            this.select.product.price += this.select.space[i].count * this.select.space[i].PRICE
         }
         // console.log(this.select)

         // this.nextStep()
      },
      changeTime() {
         // var sTime = new Date (this.select.product.d + "T" + this.select.product.t)
         // var eTime = new Date (this.select.product.d + "T" + this.select.product.spaceRent.SPR_RENT_END)
         // var minute = this.date_diff(eTime,sTime,"minute")
         // this.maxRent = Math.floor(minute / 30)
         // if(this.minRent > this.maxRent){
         //    this.select.product.t = null
         //    alert("超過時間")
         // }
         if (this.IsNull(this.select.product.d) != '' && this.IsNull(this.select.product.t) != '' && this.IsNull(this.select.product.et) != '' && this.IsNull(this.select.product.et) != '' && this.IsNull(this.$session.get('ShoppingCart')) == '') {
            this.select.space = []
            this.checkCanSpaceRent()
         }

      },
      //沒有購物車 直接購買  寫法改變
      async addShopCart() {
         if (this.IsNull(this.$session.get('ACID')) != "") {
            var status = await this.getMemberProfileCheck();
            if(status){
               this.$session.set('ShoppingCart', '')
               this.addOrder()
            }else{
               alert("請先填妥基本資料，以利進行後續購買程序。")
               this.$router.push({name:"profile"})
            }
            
         } else {
            alert("請先登入")
            var data = {
               type: "space",
               values: {
                  select: this.select
               },
            }
            this.$session.set('ShoppingCart', data)

            this.$router.push('/Login')
         }
      },
      async addOrder() {
               // console.log(this.select.product);
         if (confirm("確定要送出訂單？") == false) {
            return;
         }

         var msg = ""

         if (this.select.space != null) {
            if (this.select.space.length == 0) {
               msg += "請選擇場地\n"
            }
         }

         if (this.select.receipt.OD_RECEIPT_TYPE == 1) {
            if (this.IsNull(this.select.receipt.OD_RECIPT_TITLE) == '') {
               msg += "請填寫收據抬頭\n"
            }

            if (this.IsNull(this.select.receipt.OD_UNICODE_NUMBER) == '') {
               msg += "請填寫統一編號\n"
            }
         }

         if(!this.select.regulations)
            msg += "請勾選同意『場地使用規範』\n"

         if (msg == "") {
            try {
               this.loadSet.show = true
               var Token = await (await apiToken()).data
               //console.log(Token);
               //console.log(this.select.product);
               var SPACE_RENT = []

               var dateTemp = new Date(this.select.product.date)
               for (let i = 0; i < this.select.space.length; i++) {
                  SPACE_RENT.push({
                     ID: this.select.space[i].SPR_ID,
                     COUNT: this.select.space[i].count,
                     DATE: this.select.product.date,
                     USE:this.select.sport.title,
                     // TIME: this.select.product.t + " ~ " + this.select.product.et
                     TIME: this.select.space[i].sTime + " ~ " + this.select.space[i].eTime
                  })
               }

               var data = {
                  TICKET: [],
                  TICKET_ONE_COURSE: [],
                  COURSE: [],
                  SPACE_RENT: SPACE_RENT,
                  DIS_ID: null,
                  OD_RECEIPT_TYPE:this.select.receipt.OD_RECEIPT_TYPE,
                  OD_RECIPT_TITLE:this.select.receipt.OD_RECIPT_TITLE,
                  OD_UNICODE_NUMBER:this.select.receipt.OD_UNICODE_NUMBER,
                  Token: Token
               };
               data = this.BaseModel(data)

               //console.log(data)

               var result = JSON.parse(await (await apiORDER_ADD(data)).data)
               //console.log(result)

               this.loadSet.show = false

               if (result.Status) {
                  var jsData = JSON.parse(result.Data)
                  // console.log(jsData)
                  this.select = {
                     sport: {},
                     space: {},
                     product: {
                        date: null,
                        spaceRent: null,
                        count: 0,
                        title: null,
                     },
                  }
                  for(let i in jsData.ORDER_PRODUCT){
                     let name = jsData.ORDER_PRODUCT[i].OP_NAME.split("§")
                     jsData.ORDER_PRODUCT[i].OP_NAME = name
                  }
                  this.orderDetails = jsData

                  if(new Date(new Date().setHours(new Date().getHours() + 48 )) > dateTemp)
                     this.deadLine = 1

                  if(this.orderDetails.ORDER.OD_PAYMENT_STATUS == 1){
                     this.step = 4
                     await this.getOrderSplitCode()
                  }
                  else{
                     this.step = 3
                  }
                     
                  alert("訂單已送出")
               } else {
                  //console.log(result.Message)
                  if (result.Data != "") {
                     var message = ""
                     var list = result.Data.split('§')
                     for(let j in list){
                        message += list[j]+"\n"
                     }

                     alert(message)
                  } else {
                     alert(result.Message)
                  }
               }

               //console.log(result.Message)
            } catch (e) {
               this.loadSet.show = false;
               //console.log(e,"66666666666")
            }
         } else {
            alert(msg)
         }

      },
      async getOrder(){
         try {

            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               OD_ID:this.OD_ID,
               Token:Token
            }
            data = this.BaseModel(data)

            
            var result = JSON.parse(await (await apiORDER_GET(data)).data);
            this.loadSet.show = false;

            if (result.Status) {
               var jsData = JSON.parse(result.Data);
               for(let i in jsData.ORDER_PRODUCT){
                  let name = jsData.ORDER_PRODUCT[i].OP_NAME.split("§")
                  jsData.ORDER_PRODUCT[i].OP_NAME = name
               }
               this.orderDetails = jsData

               this.orderDetails = jsData
               //console.log(jsData)
            } else {
               alert(result.Message)
            }

         //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false;
            //console.log(e)
            alert(e)
         }
      },
      async getOrderSplitCode() {
         try {
            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               OP_ID: this.orderDetails.ORDER_PRODUCT[0].OP_ID,
               Token: Token
            }
            data = this.BaseModel(data)


            var result = JSON.parse(await (await apiORDER_PRODUCT_GET_SPLIT_CODE(data)).data);
            this.loadSet.show = false

            if (result.Status) {
               var jsData = JSON.parse(result.Data)
               this.codeDetails = jsData
               //console.log(jsData)

            } else {
               alert(result.Message)
            }

            //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false
            //console.log(e)
            alert(e)
         }
      },
      async getMemberProfileCheck(){
         var status = false
         try {
            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               Token:Token
            }
            data = this.BaseModel(data)

            
            var result = JSON.parse(await (await apiMEMBER_PROFILE_CHECK(data)).data);
            this.loadSet.show = false

            if (result.Status) {
               var jsData = JSON.parse(result.Data)
               // console.log(jsData)
               status = jsData.status

            } else {
               alert(result.Message)
            }

            //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false
            //console.log(e)
            alert(e)
         }

         return status
      },

      //時刻表
      async updateData(day) {
         this.changeDate(day)
         await this.getSpaceRentList()
         await this.getSpaceRentTimeList()

         if(this.IsNull(this.search.SP_ID) != '')
            await this.getSpaceRentUsedList()
      },
      changeDate(day) {
         this.dateRange.days = []
         this.dateRange.day += day

         if (this.dateRange.day < 0)
            this.dateRange.day += Math.abs(day)

         var nowWeek = new Date().getDay()
         var today = new Date(new Date().setDate(new Date().getDate() + (this.dateRange.day - 1 - nowWeek)))
         //console.log(today,"me")

         for (var i = 0; i < 7; i++) {
            new Date(today.setDate(today.getDate() + 1))

            if (i == 0) {
               if (new Date() >= today)
                  this.params.minDay = new Date().getFullYear() + "-" + this.TransformTime2String(new Date().getMonth() + 1) + "-" + this.TransformTime2String(new Date().getDate()) + "T00:00"
               else
                  this.params.minDay = today.getFullYear() + "-" + this.TransformTime2String(today.getMonth() + 1) + "-" + this.TransformTime2String(today.getDate()) + "T00:00"
            }

            if (i == 6)
               this.params.maxDay = today.getFullYear() + "-" + this.TransformTime2String(today.getMonth() + 1) + "-" + this.TransformTime2String(today.getDate()) + "T00:00"

            let year = today.getFullYear()
            let month = this.TransformTime2String(today.getMonth() + 1)
            let day = this.TransformTime2String(today.getDate())
            let week = this.getWeek2(today.getDay())

            this.dateRange.days.push(month+"/"+day)

            if (i == 0) {
               this.dateRange.min.year = year
               this.dateRange.min.month = month
               this.dateRange.min.day = day
               this.dateRange.min.week = week
            } else if (i == 6) {
               this.dateRange.max.year = year
               this.dateRange.max.month = month
               this.dateRange.max.day = day
               this.dateRange.max.week = week
            }

         }


      },
      getWeek2(week) {
         var result = ""
         switch (week) {
            case 0:
               result = "日"
               break
            case 1:
               result = "一"
               break
            case 2:
               result = "二"
               break
            case 3:
               result = "三"
               break
            case 4:
               result = "四"
               break
            case 5:
               result = "五"
               break
            case 6:
               result = "六"
               break
         }
         return result
      },
      
      //場地列表
      async getSpaceList(){
         try {
            this.space_list = []

            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               Token:Token
            }
            data = this.BaseModel(data)

            
            var result = JSON.parse(await (await apiSPACE_LIST(data)).data);
            this.loadSet.show = false;

            if (result.Status) {
               var jsData = JSON.parse(result.Data);
               //console.log(jsData);

               var tempTree = []
               var tempData = []

               if(jsData.length > 0){
                  for(let i in jsData){
                     if(jsData[i].SP_UPPER == null)
                     {
                        jsData[i].level = 1
                        tempTree.push(jsData[i])
                     }else{
                        tempData.push(jsData[i])
                     }
                  }

                  var level = 2
                  while(tempData.length > 0){
                     var res = this.checkUpper(tempTree,tempData,level)

                     tempTree = res.tree
                     tempData = res.data
                     level++
                  }

                  this.space_list = tempTree

                  // console.log(this.dataItems)
               } 
               
            } else {
               alert(result.Message)
            }

         //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false;
            //console.log(e)
            alert(e)
         }
      },
      //場地排序
      checkUpper(tree,data,level){
         var st = ""
         //這裡不幫名稱前加特殊符號
         // for(let i = 1;i < level;i++){
         //    st+="－"
         // }

         var result = {
            tree:[],
            data:[]
         }
         
         var status = false
         for(let i in tree){
            result.tree.push(tree[i])
            for(let j in data){
            if(tree[i].SP_ID == data[j].SP_UPPER){
               status = true //判斷是否斷層
               data[j].show = false // 加入顯示判斷
               data[j].check = true
               data[j].level = level
               data[j].SP_NAME = st+data[j].SP_NAME//先用字串顯示第幾層
               result.tree.push(data[j])
            }
            }
         }

         if(status){
         for(let i in data){
            if((data[i].check == null || data[i].check == undefined)){
               result.data.push(data[i])
            }
         }
         }else{
            result.data = []
         }
         
         return result
      },

      //場地租借範圍
      async getSpaceRentList() {
         try {
            // console.log(this.dateRange,789789)
            this.spaceCalender = [{
               day: "7",
               week: "星期日",
               rent: []
            },
            {
               day: "1",
               week: "星期一",
               rent: []
            },
            {
               day: "2",
               week: "星期二",
               rent: []
            },
            {
               day: "3",
               week: "星期三",
               rent: []
            },
            {
               day: "4",
               week: "星期四",
               rent: []
            },
            {
               day: "5",
               week: "星期五",
               rent: []
            },
            {
               day: "6",
               week: "星期六",
               rent: []
            }
            ]
            // 禁用下一行eslint检查
            // eslint-disable-next-line 
            for (let i in this.spaceTime) {
               this.spaceCalender[0].rent.push([])
               this.spaceCalender[1].rent.push([])
               this.spaceCalender[2].rent.push([])
               this.spaceCalender[3].rent.push([])
               this.spaceCalender[4].rent.push([])
               this.spaceCalender[5].rent.push([])
               this.spaceCalender[6].rent.push([])
            }

            //this.params.SPR_ID = []
            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               SP_ID: this.search.SP_ID,
               Token: Token
            }
            data = this.BaseModel(data)


            var result = JSON.parse(await (await apiSPACE_RENT_LIST(data)).data);
            this.loadSet.show = false

            if (result.Status) {
               var jsData = JSON.parse(result.Data)
               for (let i in jsData) {
                  //金額設定-------------------------------
                  jsData[i].PRICE = jsData[i].SPR_PRICE
                  if (this.IsNull(this.$session.get('PPL_NID_IDENTITY')) != "") {
                     switch (this.$session.get('PPL_NID_IDENTITY')) {
                        case 1:
                           if (jsData[i].SPR_EXEMPATION_STUDENT != null)
                              jsData[i].PRICE = jsData[i].SPR_EXEMPATION_STUDENT
                           break
                        case 2:
                           if (jsData[i].SPR_EXEMPATION_STAFF != null)
                              jsData[i].PRICE = jsData[i].SPR_EXEMPATION_STAFF
                           break
                        case 3:
                           if (jsData[i].SPR_EXEMPATION_ALUMNI != null)
                              jsData[i].PRICE = jsData[i].SPR_EXEMPATION_ALUMNI
                           break
                        case 4:
                           if (jsData[i].SPR_EXEMPATION_DEPENDENTS != null)
                              jsData[i].PRICE = jsData[i].SPR_EXEMPATION_DEPENDENTS
                           break
                     }
                  }

                  //日曆設定-------------------------------
                  var day = jsData[i].SPR_RENT_DAYS.split("§")
                  var sTime = jsData[i].SPR_RENT_START.split(":")
                  var eTime = jsData[i].SPR_RENT_END.split(":")

                  //判斷計算時間 是否超過行事曆最低點及最高點
                  if(parseInt(sTime[0]) < parseInt(this.spaceTime[0].split(":")[0])){
                     sTime[0] =  parseInt(this.spaceTime[0].split(":")[0])
                     sTime[1] =  parseInt(this.spaceTime[0].split(":")[1])
                  }
                  else if(parseInt(sTime[0]) > parseInt(this.spaceTime[this.spaceTime.length - 1].split(":")[0]) || 
                         (parseInt(sTime[0]) == parseInt(this.spaceTime[this.spaceTime.length - 1].split(":")[0]) && parseInt(sTime[1]) > parseInt(this.spaceTime[this.spaceTime.length - 1].split(":")[1]))){
                     sTime[0] =  parseInt(this.spaceTime[this.spaceTime.length-1].split(":")[0])
                     sTime[1] =  parseInt(this.spaceTime[this.spaceTime.length-1].split(":")[1])
                  }

                  if(parseInt(eTime[0]) < parseInt(this.spaceTime[0].split(":")[0])){
                     eTime[0] =  parseInt(this.spaceTime[0].split(":")[0])
                     eTime[1] =  parseInt(this.spaceTime[0].split(":")[1])
                  }
                  else if(parseInt(eTime[0]) > parseInt(this.spaceTime[this.spaceTime.length - 1].split(":")[0]) || 
                         (parseInt(eTime[0]) == parseInt(this.spaceTime[this.spaceTime.length - 1].split(":")[0]) && parseInt(eTime[1]) > parseInt(this.spaceTime[this.spaceTime.length - 1].split(":")[1]))){
                     eTime[0] =  parseInt(this.spaceTime[this.spaceTime.length-1].split(":")[0])
                     eTime[1] =  parseInt(this.spaceTime[this.spaceTime.length-1].split(":")[1])
                  }

                  //抓日歷設定的開始小時
                  var origailTime = parseInt(this.spaceTime[0].split(":")[0])
                  var hour = parseInt(sTime[0]) - origailTime
                  var min = parseInt(sTime[1])
                  var minAll = (parseInt(eTime[0]) - parseInt(sTime[0])) * 60 + (parseInt(eTime[1]) - parseInt(sTime[1]))

                  var rent = {
                     title: '',
                     style: ("padding:0;top:" + (min * 0.96) + "px;height:" + (minAll * 0.0666) + "rem")
                  }

                  for (let j = 0; j < day.length; j++) {
                     if (day[j] == "7")
                        this.spaceCalender[0].rent[hour * 2].push(rent)
                     else
                        this.spaceCalender[parseInt(day[j])].rent[hour * 2].push(rent)
                  }

                  //產品設定-------------------------------
                  // this.params.SPR_ID.push({
                  //                      value:jsData[i].SPR_ID,
                  //                      text:"租用時段:"+jsData[i].SPR_RENT_START+"~"+jsData[i].SPR_RENT_END + " / " + jsData[i].SPR_USETIME + "分鐘 NT$" + jsData[i].PRICE
                  //                      })

               }
               //console.log(this.spaceCalender)
               //console.log(jsData)

            } else {
               alert(result.Message)
            }

            //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false
            //console.log(e)
            alert(e)
         }
      },
      //場地已租借範圍
      async getSpaceRentTimeList() {
         try {
            this.spaceCalenderTemp = [{
               day: "7",
               week: "星期日",
               rent: []
            },
            {
               day: "1",
               week: "星期一",
               rent: []
            },
            {
               day: "2",
               week: "星期二",
               rent: []
            },
            {
               day: "3",
               week: "星期三",
               rent: []
            },
            {
               day: "4",
               week: "星期四",
               rent: []
            },
            {
               day: "5",
               week: "星期五",
               rent: []
            },
            {
               day: "6",
               week: "星期六",
               rent: []
            }]
            // 禁用下一行eslint检查
            // eslint-disable-next-line 
            for (let i in this.spaceTime) {
               this.spaceCalenderTemp[0].rent.push([])
               this.spaceCalenderTemp[1].rent.push([])
               this.spaceCalenderTemp[2].rent.push([])
               this.spaceCalenderTemp[3].rent.push([])
               this.spaceCalenderTemp[4].rent.push([])
               this.spaceCalenderTemp[5].rent.push([])
               this.spaceCalenderTemp[6].rent.push([])
            }


            this.loadSet.show = true
            var Token = await (await apiToken()).data
            // console.log(this.select);
            var data = {
               SP_ID: this.search.SP_ID,
               sDate: this.params.minDay,
               eDate: this.params.maxDay,
               Token: Token
            }
            data = this.BaseModel(data)

            var result = JSON.parse(await (await apSPACE_RENT_LIST_DATE(data)).data);
            this.loadSet.show = false

            if (result.Status) {
               var jsData = JSON.parse(result.Data)
               // alert(result.Data)
               // console.log(jsData)
               for (let i in jsData) {

                  var day = this.date_diff(new Date(jsData[i].OP_RENT_DATE), new Date(this.dateRange.min.year + "-" + this.dateRange.min.month + "-" + this.dateRange.min.day + "T00:00"), "day")

                  var description = jsData[i].OP_DESCRIPTION.split("§")


                  //日曆設定-------------------------------

                  var date = new Date(description[4].split('T')[0] + "T" + description[5])

                  var sTime = description[5].split(":")
                  // var e = new Date(date.setMinutes(date.getMinutes() + description[3] * description[2]))
                  var e = new Date(date.setMinutes(date.getMinutes() + jsData[i].OP_QUANTITY * description[2]))
                  var eTime = []
                  eTime.push(this.TransformTime2String(e.getHours()))
                  eTime.push(this.TransformTime2String(e.getMinutes()))

                  //判斷計算時間 是否超過行事曆最低點及最高點
                  if(parseInt(sTime[0]) < parseInt(this.spaceTime[0].split(":")[0])){
                     sTime[0] =  parseInt(this.spaceTime[0].split(":")[0])
                     sTime[1] =  parseInt(this.spaceTime[0].split(":")[1])
                  }
                  else if(parseInt(sTime[0]) > parseInt(this.spaceTime[this.spaceTime.length - 1].split(":")[0]) || 
                         (parseInt(sTime[0]) == parseInt(this.spaceTime[this.spaceTime.length - 1].split(":")[0]) && parseInt(sTime[1]) > parseInt(this.spaceTime[this.spaceTime.length - 1].split(":")[1]))){
                     sTime[0] =  parseInt(this.spaceTime[this.spaceTime.length-1].split(":")[0])
                     sTime[1] =  parseInt(this.spaceTime[this.spaceTime.length-1].split(":")[1])
                  }

                  if(parseInt(eTime[0]) < parseInt(this.spaceTime[0].split(":")[0])){
                     eTime[0] =  parseInt(this.spaceTime[0].split(":")[0])
                     eTime[1] =  parseInt(this.spaceTime[0].split(":")[1])
                  }
                  else if(parseInt(eTime[0]) > parseInt(this.spaceTime[this.spaceTime.length - 1].split(":")[0]) || 
                         (parseInt(eTime[0]) == parseInt(this.spaceTime[this.spaceTime.length - 1].split(":")[0]) && parseInt(eTime[1]) > parseInt(this.spaceTime[this.spaceTime.length - 1].split(":")[1]))){
                     eTime[0] =  parseInt(this.spaceTime[this.spaceTime.length-1].split(":")[0])
                     eTime[1] =  parseInt(this.spaceTime[this.spaceTime.length-1].split(":")[1])
                  }

                  //抓日歷設定的開始小時
                  var origailTime = parseInt(this.spaceTime[0].split(":")[0])
                  var hour = parseInt(sTime[0]) - origailTime
                  var min = parseInt(sTime[1])
                  var minAll = (parseInt(eTime[0]) - parseInt(sTime[0])) * 60 + (parseInt(eTime[1]) - parseInt(sTime[1]))

                  var rent = {
                     title: "已外借",
                     style: ("padding:0;top:" + (min * 0.96) + "px;height:" + (minAll * 0.0666) + "rem;z-index:21;"),
                     class: "!bg-rose-500"
                  }

                  this.spaceCalenderTemp[day].rent[hour * 2].push(rent)
               }
               // console.log(this.spaceCalender)


            } else {
               alert(result.Message)
            }

            //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false
            //console.log(e)
            alert(e)
         }
      },
      //場地已使用範圍
      async getSpaceRentUsedList() {
         try {
            
            this.loadSet.show = true
            var Token = await (await apiToken()).data
            var data = {
               SP_ID: this.search.SP_ID,
               sDate:this.params.minDay.split('T')[0] + " 00:00",
               eDate:this.params.maxDay.split('T')[0] + " 23:59",
               Token: Token
            }
            data = this.BaseModel(data)

            var result = JSON.parse(await (await apiSPACE_RENT_USED_LIST(data)).data);
            this.loadSet.show = false

            if (result.Status) {
               var jsData = JSON.parse(result.Data)
               // console.log(jsData)
               for (let i in jsData) {

                 var day = this.date_diff(new Date(jsData[i].SRU_SDATE),new Date(this.dateRange.min.year+"-" + this.dateRange.min.month+ "-" +this.dateRange.min.day + "T00:00"),"day")

                  //日曆設定-------------------------------
                  
                  var sTime = this.TransformTimeFormathhmm(jsData[i].SRU_SDATE).split(":")
                  var eTime = this.TransformTimeFormathhmm(jsData[i].SRU_EDATE).split(":")

                  //判斷計算時間 是否超過行事曆最低點及最高點
                  if(parseInt(sTime[0]) < parseInt(this.spaceTime[0].split(":")[0])){
                     sTime[0] =  parseInt(this.spaceTime[0].split(":")[0])
                     sTime[1] =  parseInt(this.spaceTime[0].split(":")[1])
                  }
                  else if(parseInt(sTime[0]) > parseInt(this.spaceTime[this.spaceTime.length - 1].split(":")[0]) || 
                         (parseInt(sTime[0]) == parseInt(this.spaceTime[this.spaceTime.length - 1].split(":")[0]) && parseInt(sTime[1]) > parseInt(this.spaceTime[this.spaceTime.length - 1].split(":")[1]))){
                     sTime[0] =  parseInt(this.spaceTime[this.spaceTime.length-1].split(":")[0])
                     sTime[1] =  parseInt(this.spaceTime[this.spaceTime.length-1].split(":")[1])
                  }

                  if(parseInt(eTime[0]) < parseInt(this.spaceTime[0].split(":")[0])){
                     eTime[0] =  parseInt(this.spaceTime[0].split(":")[0])
                     eTime[1] =  parseInt(this.spaceTime[0].split(":")[1])
                  }
                  else if(parseInt(eTime[0]) > parseInt(this.spaceTime[this.spaceTime.length - 1].split(":")[0]) || 
                         (parseInt(eTime[0]) == parseInt(this.spaceTime[this.spaceTime.length - 1].split(":")[0]) && parseInt(eTime[1]) > parseInt(this.spaceTime[this.spaceTime.length - 1].split(":")[1]))){
                     eTime[0] =  parseInt(this.spaceTime[this.spaceTime.length-1].split(":")[0])
                     eTime[1] =  parseInt(this.spaceTime[this.spaceTime.length-1].split(":")[1])
                  }


                  //抓日歷設定的開始小時
                  var origailTime = parseInt(this.spaceTime[0].split(":")[0])
                  var hour = parseInt(sTime[0]) - origailTime;

                  var min = parseInt(sTime[1])
                  var minAll = (parseInt(eTime[0])-parseInt(sTime[0])) * 60 + (parseInt(eTime[1])-parseInt(sTime[1]))
                 
                  var rent = {
                     title: "已外借",
                     style: ("padding:0;top:" + (min * 0.96) + "px;height:" + (minAll * 0.0666) + "rem;z-index:21;"),
                     class: "!bg-rose-500"
                  }
                  this.spaceCalenderTemp[day].rent[hour * 2].push(rent)
               }
               // console.log(this.spaceCalender)


            } else {
               alert(result.Message)
            }

            //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false
            //console.log(e)
            alert(e)
         }
      },
        
      clearReceipt(){
         this.select.receipt.OD_RECIPT_TITLE = null
         this.select.receipt.OD_UNICODE_NUMBER = null
      },

   },
}
</script>
<style lang="postcss" scoped>
.fade-select-enter {
   opacity: 0;
}

.fade-select-enter-active {
   transition: all 0.5s ease;
}

.fade-select-enter-to {}

.fade-select-leave {}

.fade-select-leave-active {
   transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.fade-select-leave-to {
   /* transform: translateX(10px); */
   opacity: 0;
}

.fade-enter-active {
   transition: all .3s ease;
   transition-delay: 0.5s;

}

.fade-leave-active {
   transition: all;
   /* transition-delay: 0.6s; */
   opacity: 0;
}

.fade-enter {
   transform: translatey(10px);
   opacity: 0;
}

.fade-leave-to {
   transform: translatey(10px);
   opacity: 0;
}

.timetable {
   @apply flex border-2 border-secondary-400 rounded-xl bg-white;

   .isActive {
      @apply absolute left-0 top-0 bg-secondary-500 bg-opacity-20 w-full z-20 border border-secondary-400 rounded;
   }
}

.price {
   @apply w-full border-collapse border border-secondary-500 text-center;

   thead {
      th {
         @apply text-sm;
      }
   }

   th,
   td {
      @apply border border-secondary-500 p-1;
   }

   td.isActive {
      @apply bg-primary-500;
   }

   .isActive {
      @apply bg-green-500 border border-green-400 rounded-full h-full aspect-square;
   }
}

.calculatetable {
   @apply w-full whitespace-nowrap;

   .time {
      @apply flex flex-col sm:flex-row;
   }

   td {
      @apply p-1
   }

   tbody {
      tr {
         @apply border-b border-secondary-500
      }
   }
}


.selected {
   @apply px-4 sm:px-0;

   li {
      @apply bg-secondary-700 px-4 py-2 mb-2 rounded-lg flex justify-between;

      &:last-child {
         /* @apply mb-8; */
      }
   }
}
</style>
