<template>
    <main>
        <Header backTo="/member/owns" :title="ticketDetails.ticketCatalog + ticketDetails.ticketTypeName"></Header>
        <div class="flex flex-col sm:flex-row main flex-wrap">
            <section class="col-lg-5 col-12">
                <TicketView class="mb-12 w-100" :ticketDetails="ticketDetails">
                    <div class="flex flex-col space-y-2 p-2 pb-md-5">
                        <label
                            for="my-modal"
                            v-if="
                                details.AE_ISSPLIT == 1 &&
                                details.AE_QUANTITY > details.AE_USE_QUANTITY + details.AE_SPLIT_QUANTITY &&
                                details.AE_CATALOG != 4
                            "
                            class="btn btn-sm btn-outline btn-secondary"
                            >分票</label
                        >
                        <template v-if="details.AE_CATALOG == 0">
                            <router-link
                                v-if="details.AE_QUANTITY > details.AE_USE_QUANTITY + details.AE_SPLIT_QUANTITY"
                                :to="{ path: '/member/reservation', query: { AE_ID: details.AE_ID } }"
                                class="btn btn-sm btn-outline btn-secondary"
                            >
                                領取
                            </router-link>
                        </template>
                        <template v-if="details.AE_CATALOG == 1">
                            <buttona
                                v-if="details.AE_QUANTITY > details.AE_USE_QUANTITY + details.AE_SPLIT_QUANTITY"
                                @click="reserveSpace2"
                                class="btn btn-sm btn-outline btn-secondary"
                            >
                                領取
                            </buttona>
                        </template>
                    </div>
                </TicketView>
            </section>
            <section class="flex-1 col-lg-7 col-12 mt-md-0 mt-5 ps-0 ps-md-3">
                <div class="overflow-x-auto">
                    <table class="table w-full tableGrid">
                        <thead style="background: #e6e5e5">
                            <tr>
                                <th width="1%">序號</th>
                                <th width="1%">狀態</th>
                                <td>使用明細</td>
                                <th width="1%">操作</th>
                            </tr>
                        </thead>
                        <tbody style="background: #fff">
                            <tr v-for="(item, index) in productAll" :key="index">
                                <td data-label="編號：">{{ index + 1 }}</td>
                                <td data-label="狀態：">
                                    <div class="badge badge-primary">已使用</div>
                                </td>
                                <td data-label="明細：">
                                    <ul>
                                        <li>日期：{{ item.info.date }}</li>
                                        <li>時間：{{ item.info.time }}</li>
                                        <li v-if="details.AE_CATALOG == 0">課程：{{ item.info.CUS_NAME }}</li>
                                        <li>地點：{{ item.info.location }}</li>
                                    </ul>
                                </td>
                                <td class="action" data-label="操作：">
                                    <template v-if="item.status == 4"> 轉為紙本票卷 </template>
                                    <template v-else>
                                        <label
                                            for="open-qrcode"
                                            @click="openModal(item.id)"
                                            class="btn btn-sm btn-outline btn-secondary"
                                            >領取QRcode</label
                                        >
                                    </template>
                                </td>
                            </tr>
                            <template v-for="(item, aaaa) in CodeList">
                                <tr v-for="(item2, index2) in item.SQ_QUANTITY" :key="index2">
                                    <td data-label="編號：">{{ index2 + 1 + productAll.length + aaaa }}</td>
                                    <template v-if="index2 == 0">
                                        <td data-label="狀態：">
                                            <div v-if="item.SQ_USE_QUANTITY == 0" class="badge badge-ghost">分票中</div>
                                            <div v-else class="badge badge-ghost">已分票</div>
                                        </td>
                                        <td data-label="明細：" :rowspan="item.SQ_QUANTITY">
                                            <ul class="text-sm">
                                                <li>分票代碼：{{ item.SQ_ID }}</li>
                                                <li>分票數量：{{ item.SQ_QUANTITY }}</li>
                                            </ul>
                                        </td>
                                        <td class="action" data-label="操作：" :rowspan="item.SQ_QUANTITY">
                                            <button
                                                v-if="item.SQ_USE_QUANTITY == 0"
                                                @click="cancelSplit(item.SQ_ID)"
                                                class="btn btn-sm btn-outline btn-secondary"
                                            >
                                                取消
                                            </button>
                                        </td>
                                    </template>
                                    <template v-else>
                                        <td data-label="狀態：">
                                            <div v-if="item.SQ_USE_QUANTITY == 0" class="badge badge-ghost">
                                                分票中1
                                            </div>
                                            <div v-else class="badge badge-ghost">已分票</div>
                                        </td>
                                    </template>
                                </tr>
                            </template>
                            <tr
                                v-for="(item, index) in details.AE_QUANTITY -
                                (details.AE_USE_QUANTITY + details.AE_SPLIT_QUANTITY)"
                                :key="index"
                            >
                                <td data-label="編號：">
                                    {{ index + 1 + details.AE_USE_QUANTITY + details.AE_SPLIT_QUANTITY }}
                                </td>
                                <td colspan="3" data-label="狀態："><div class="badge badge-secondary">未使用</div></td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <div class="mb-4 text-center">
                        <router-link class="btn" to="/member/owns">返回列表</router-link>
                    </div> -->
                </div>
            </section>
        </div>

        <input type="checkbox" id="open-qrcode" v-model="modal.qrcode" class="modal-toggle" />
        <div class="modal">
            <div class="modal-box relative">
                <h3 class="font-bold text-lg">進場QRcode</h3>
                <div class="note">
                    <div class="p-4 flex justify-center">
                        <img
                            :src="'data:image/png;base64,' + modal.details.AQ_CODE"
                            class="w-2/3 border p-6 bg-white my-8 rounded-lg shadow-md"
                            alt=""
                        />
                    </div>
                </div>
                <div class="info mb-4">
                    <ul>
                        <!-- <li><span>票券編號</span>{{modal.details.AE_ID}}</li> -->
                        <li><span>票券編號</span></li>
                        <li><span>課程日期</span>{{ modal.details.day }}</li>
                        <li><span>課程時段</span>{{ modal.details.sTime }}~{{ modal.details.eTime }}</li>
                        <li><span>課程地點</span>{{ modal.details.AE_SP_NAME }}</li>
                        <!-- <li>失效日期：{{modal.details.AQ_USE_DATE}} ~ {{modal.details.AQ_EXPIRATION_DATE}}</li> -->
                    </ul>
                </div>
                <label for="open-qrcode" class="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
            </div>
        </div>
        <!-- 分票 -->
        <input type="checkbox" v-model="modal2.show" id="my-modal" class="modal-toggle" />
        <div class="modal">
            <div class="modal-box relative">
                <label for="my-modal" class="btn btn-sm btn-circle absolute right-2 top-2">
                    <svg xmlns="http://www.w3.org/2000/svg" class="fill-white h-6" viewBox="0 0 24 24">
                        <path
                            d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z"
                        />
                    </svg>
                </label>
                <h3 class="font-bold text-lg">分票</h3>

                <div class="note">
                    <a v-if="modal2.splitCode != null">分票碼：{{ modal2.splitCode }}</a>
                    <h4 class="font-bold mb-1"></h4>
                    <h4 v-if="modal2.ues != null" class="font-bold mb-1">已取數量</h4>
                    <ol v-if="modal2.ues != null" class="list-disc">
                        <li class="ml-6">{{ modal2.ues }}張</li>
                    </ol>
                </div>
                <div class="form-control w-full mb-5">
                    <label class="label">
                        <span class="label-text">請輸入您要分票的數量</span>
                    </label>
                    <label class="input-group">
                        <input
                            type="number"
                            v-model="modal2.count"
                            @change="countChange"
                            :max="modal2.max"
                            :min="modal2.min"
                            class="input input-bordered w-full"
                        />
                        <span>張</span>
                    </label>
                </div>
                <div class="mb-5">
                    <h6 class="font-bold mb-1">注意事項</h6>
                    <ol class="list-disc mb-3">
                        <li class="ml-6">可分票給同身分別或是分享給原身份票價較低者。</li>
                        <li class="ml-6">建立分票後系統將產出取票代碼供其他會員取票。</li>
                        <li class="ml-6">未取走之票券將呈現鎖定狀態，您可以取消分票或繼續等待其他會員取票。</li>
                    </ol>
                </div>
                <div class="modal-action justify-center">
                    <!-- <button v-if="modal2.splitCode == null" @click="addSplitCode" class="btn primary">新增</button>
                <button v-if="modal2.splitCode != null" @click="updateSplitCode" class="btn primary">修改</button> -->
                    <!-- <button @click="modal2.show=!modal2.show" class="btn primary">關閉</button> -->
                    <button @click="addSplitCode" class="btn">建立分票</button>
                </div>
            </div>
        </div>
        <LoadingPage :loadSet="loadSet"></LoadingPage>
    </main>
</template>

<script>
    import {
        apiToken,
        apiACCESS_GET,
        apiACCESS_MAKE_CHIDREN_LIST,
        apiACCESS_RESERVATION_TYPE1_CATALOG1,
        apiACCESS_SPLIT_ADD,
        apiACESS_SPLIT_CODE_LIST,
        apiACCESS_SPLIT_CANCEL,
        apiACCESS_QRCODE_ADD,
    } from '../../api/API';
    import TicketView from '@/components/TicketView.vue';
    import Header from '@/components/MemberHeader.vue';
    import moment from 'moment';
    export default {
        data() {
            return {
                list: 'swimming',
                group: false,
                getQrcode: false,
                modal: {
                    qrcode: false,
                    difference: false,
                    enable: false,
                    split: false,
                    refund: false,
                    autonomous: false,
                    details: {},
                },
                modal2: {
                    show: false,
                    AE_ID: null,
                    splitCode: null,
                    count: 1,
                    min: 0,
                    max: 0,
                    ues: null,
                },
                item: {
                    title: '瑜珈有氧',
                    type: 'ticket',
                    type2: 'aerobic',
                    status: 1,
                },
                cycle: [
                    {
                        title: '哈達瑜珈',
                        date: '2021/01/01',
                        time: '13:00~13:50',
                        location: '第一舞蹈教室',
                        status: true,
                    },
                    {
                        title: '皮拉提斯',
                        date: '2021/01/01',
                        time: '13:00~13:50',
                        location: '第一舞蹈教室',
                        status: true,
                    },
                    {
                        title: '拉丁有氧',
                        date: '2021/01/01',
                        time: '13:00~13:50',
                        location: '第一舞蹈教室',
                        status: false,
                    },
                    {
                        title: '調和瑜珈',
                        date: '2021/01/01',
                        time: '13:00~13:50',
                        location: '第一舞蹈教室',
                        status: false,
                    },
                ],
                courses: [
                    {
                        name: '哈達瑜珈',
                        date: '2021/01/01',
                        time: '13:00~13:50',
                        location: '第一舞蹈教室',
                        status: '0',
                    },
                    {
                        name: '哈達瑜珈',
                        date: '2021/01/01',
                        time: '13:00~13:50',
                        location: '第一舞蹈教室',
                        status: '1',
                    },
                    {
                        name: '哈達瑜珈',
                        date: '2021/01/01',
                        time: '13:00~13:50',
                        location: '第一舞蹈教室',
                        status: '2',
                    },
                    {
                        name: '哈達瑜珈',
                        date: '2021/01/01',
                        time: '13:00~13:50',
                        location: '第一舞蹈教室',
                        status: '2',
                    },
                    {
                        name: '哈達瑜珈',
                        date: '2021/01/01',
                        time: '13:00~13:50',
                        location: '第一舞蹈教室',
                        status: '2',
                    },
                    {
                        name: '哈達瑜珈',
                        date: '2021/01/01',
                        time: '13:00~13:50',
                        location: '第一舞蹈教室',
                        status: '2',
                    },
                    {
                        name: '哈達瑜珈',
                        date: '2021/01/01',
                        time: '13:00~13:50',
                        location: '第一舞蹈教室',
                        status: '2',
                    },
                    {
                        name: '哈達瑜珈',
                        date: '2021/01/01',
                        time: '13:00~13:50',
                        location: '第一舞蹈教室',
                        status: '2',
                    },
                    {
                        name: '哈達瑜珈',
                        date: '2021/01/01',
                        time: '13:00~13:50',
                        location: '第一舞蹈教室',
                        status: '2',
                    },
                ],
                cards: [
                    {
                        category: '單次票券2',
                        type: '瑜珈有氧-唱跳無極限',
                        datetime: '2021-01-01',
                        location: '第一舞蹈教室',
                        number: 'T123456',
                    },
                ],

                productAll: [],
                details: {
                    AE_ID: null,
                },
                ticketDetails: {},
                CodeList: [],

                loadSet: { show: false, fullPage: true, type: 'dots', color: 'rgba(0,0,0,0.7)' },
            };
        },
        mounted() {
            this.NowTime = moment(new Date()).format('YYYY-MM-DD HH:mm');
            this.init();
        },
        methods: {
            async init() {
                this.loadSet.show = false;
                if (this.$route.query.AE_ID != undefined) {
                    this.details.AE_ID = this.$route.query.AE_ID;
                    await this.getAccess();
                    await this.getAccessChidren();
                    await this.getSplitCodeList();
                } else {
                    this.$router.push('/member/owns');
                }
            },
            countChange() {
                if (this.modal.count > this.modal.max) this.modal.count = this.modal.max;
                else if (this.modal.count < this.modal.min) this.modal.count = this.modal.min;
            },
            async openModal(AE_ID) {
                await this.getModalAccess(AE_ID);
                // console.log(this.modal.details)

                //前30分鐘能產製QRCODE
                var USE_DATE = new Date(this.modal.details.AE_USE_DATE);
                USE_DATE = new Date(USE_DATE.setMinutes(USE_DATE.getMinutes() - 30));

                var EXPIRATION_DATE = new Date(this.modal.details.AE_EXPIRATION_DATE);

                //如果是課程類的當天都能申請
                if (this.modal.details.AE_CATALOG == 0 || this.modal.details.AE_CATALOG == 2) {
                    var day = this.TransformTimeFormatYYYMMDD(this.modal.details.AE_USE_DATE);

                    USE_DATE = new Date(day + 'T00:00');
                    EXPIRATION_DATE = new Date(day + 'T23:59:59');
                }

                // if (this.IsNull(this.modal.details.AQ_ID) == '' && new Date(this.modal.details.AE_USE_DATE) < new Date() && new Date < new Date(this.modal.details.AE_EXPIRATION_DATE))
                //     await this.addQRcode(AE_ID)
                // else if (new Date(this.modal.details.AQ_EXPIRATION_DATE) < new Date() && new Date < new Date(this.modal.details.AE_EXPIRATION_DATE))
                //     await this.addQRcode(AE_ID)

                if (
                    this.IsNull(this.modal.details.AQ_ID) == '' &&
                    USE_DATE <= new Date() &&
                    new Date() <= EXPIRATION_DATE
                )
                    await this.addQRcode(AE_ID);
                else if (new Date(this.modal.details.AQ_EXPIRATION_DATE) < new Date() && new Date() <= EXPIRATION_DATE)
                    await this.addQRcode(AE_ID);

                // this.modal.qrcode = !this.modal.qrcode;
            },
            async getAccess() {
                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        AE_ID: this.details.AE_ID,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiACCESS_GET(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        // console.log(jsData)
                        this.details = jsData;

                        if (jsData.AE_CATALOG != 0 && jsData.AE_CATALOG != 1 && jsData.AE_CATALOG != 4)
                            this.$router.push('/member/owns');

                        this.modal2.max = jsData.AE_QUANTITY - (jsData.AE_USE_QUANTITY + jsData.AE_SPLIT_QUANTITY);

                        jsData.AE_USE_DATE = this.TransformTimeFormatYYYMMDDhhmm(jsData.AE_USE_DATE);
                        jsData.AE_EXPIRATION_DATE = this.TransformTimeFormatYYYMMDDhhmm(jsData.AE_EXPIRATION_DATE);

                        var array = {
                            id: jsData.AE_ID,
                            title: jsData.AE_NAME,
                            type: jsData.AE_TYPE,
                            ticketType: null,
                            sportType: null,
                            catalog: jsData.AE_CATALOG,
                            SPORT_ID: jsData.SPORT_ID,
                            status: 1,
                            usetime: jsData.AE_USE_DATE,
                            deadline: jsData.AE_EXPIRATION_DATE,
                            info: {
                                title: null,
                                date: null,
                                time: null,
                                trainer: null,
                                location: jsData.AE_SP_NAME,
                                isSplit: null,
                                quantity: jsData.AE_QUANTITY,
                                quantityUse: jsData.AE_USE_QUANTITY,
                                quantitySplit: jsData.AE_SPLIT_QUANTITY,
                            },
                            isRefund: false,
                        };

                        switch (array.type) {
                            case 0:
                                array.ticketType = 'ticket';
                                break;
                            case 1:
                                array.ticketType = 'ticketPackage';
                                break;
                            case 2:
                                array.ticketType = 'card';
                                break;
                        }

                        array.info.isSplit = jsData.AE_ISSPLIT;

                        //類型-------------
                        //健身期課
                        if (jsData.SPORT_ID == '1') {
                            (array.sportType = 'cycle-aerobic'), (array.ticketCatalog = '健身期課');
                            var list = jsData.AE_NAME.split('§');
                            if (list.length > 3) {
                                array.info.title = list[0].split('：')[1];
                                array.info.date = list[1].split('：')[1];
                                array.info.time = list[2].split('：')[1];
                            }
                        }
                        //租借
                        else if (jsData.SPORT_ID == '2') {
                            (array.sportType = null), (array.ticketCatalog = '場館空間租借');
                        }
                        //瑜珈有氧
                        else if (jsData.SPORT_ID == 'TKC08e0a3ba-8016-4ca1-9dde-eabf181c0767') {
                            (array.sportType = 'aerobic'), (array.ticketCatalog = '瑜珈有氧');
                        }
                        //私人教練
                        else if (jsData.SPORT_ID == '3') {
                            (array.sportType = 'trainer'), (array.ticketCatalog = '私人教練');
                        }
                        //健身房
                        else if (jsData.SPORT_ID == 'TKC7df7eda9-a4c5-4959-9db9-76ba53f674d7') {
                            (array.sportType = 'weight'), (array.ticketCatalog = '健身房');
                        }
                        //游泳池
                        else if (jsData.SPORT_ID == 'TKC38874b78-198c-48d0-b08c-3b429cb20603') {
                            (array.sportType = 'swimming'), (array.ticketCatalog = '游泳池');
                        }

                        array.ticketTypeName = '單次套票';

                        this.ticketDetails = array;
                        //console.log(jsData)
                    } else {
                        this.$router.push('/member/owns');
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            async getModalAccess(AE_ID) {
                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        AE_ID: AE_ID,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiACCESS_GET(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        jsData.day = this.TransformTimeFormatYYYMMDD(jsData.AE_USE_DATE);
                        jsData.sTime = this.TransformTimeFormathhmm(jsData.AE_USE_DATE);
                        jsData.eTime = this.TransformTimeFormathhmm(jsData.AE_EXPIRATION_DATE);
                        jsData.AQ_USE_DATE = this.TransformTimeFormatYYYMMDDhhmm(jsData.AQ_USE_DATE);
                        jsData.AQ_EXPIRATION_DATE = this.TransformTimeFormatYYYMMDDhhmm(jsData.AQ_EXPIRATION_DATE);
                        this.modal.details = jsData;
                        //console.log(jsData)
                    } else {
                        this.$router.push('/member/rental');
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            async getAccessChidren() {
                try {
                    this.productAll = [];
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        AE_ID: this.details.AE_ID,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiACCESS_MAKE_CHIDREN_LIST(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        // console.log(jsData)
                        if (jsData.length > 0) {
                            for (let i in jsData) {
                                jsData[i].AE_USE_DATE = this.TransformTimeFormatYYYMMDDhhmm(jsData[i].AE_USE_DATE);
                                jsData[i].AE_EXPIRATION_DATE = this.TransformTimeFormatYYYMMDDhhmm(
                                    jsData[i].AE_EXPIRATION_DATE,
                                );

                                var array = {
                                    id: jsData[i].AE_ID,
                                    title: jsData[i].AE_NAME,
                                    type: jsData[i].AE_TYPE,
                                    ticketType: null,
                                    sportType: null,
                                    catalog: jsData[i].AE_CATALOG,
                                    SPORT_ID: jsData[i].SPORT_ID,
                                    status: 1,
                                    usetime: jsData[i].AE_USE_DATE,
                                    deadline: jsData[i].AE_EXPIRATION_DATE,
                                    info: {
                                        CUS_NAME: jsData[i].CUS_NAME + '。' + jsData[i].CUS_CAT_NAME,
                                        title: null,
                                        date: null,
                                        time: null,
                                        trainer: null,
                                        location: jsData[i].AE_SP_NAME,
                                        isSplit: null,
                                        quantity: jsData[i].AE_QUANTITY,
                                        quantityUse: jsData[i].AE_USE_QUANTITY,
                                        quantitySplit: jsData[i].AE_SPLIT_QUANTITY,
                                    },
                                    isRefund: false,
                                };

                                switch (array.type) {
                                    case 0:
                                        array.ticketType = 'ticket';
                                        break;
                                    case 1:
                                        array.ticketType = 'ticketPackage';
                                        break;
                                    case 2:
                                        array.ticketType = 'card';
                                        break;
                                }

                                var status = true;

                                //票卷 多張
                                if (
                                    (jsData[i].AE_CATALOG == 0 || jsData[i].AE_CATALOG == 1) &&
                                    jsData[i].AE_TYPE == 1
                                ) {
                                    array.info.isSplit = jsData[i].AE_ISSPLIT;
                                }
                                //健身期課
                                else if (jsData[i].AE_CATALOG == 2) {
                                    array.title = '健身期課';
                                    var list = jsData[i].AE_NAME.split('§');
                                    if (list.length > 3) {
                                        array.info.title = list[0].split('：')[1];
                                        array.info.date = list[1].split('：')[1];
                                        array.info.time = list[2].split('：')[1];
                                    }
                                    //將style改為全版
                                    array.ticketType = 'card';
                                } //主租借則不顯示
                                else if (jsData[i].AE_CATALOG == 3) {
                                    if (jsData[i].AE_TYPE == 1) status = false;
                                }

                                //類型-------------
                                //健身期課
                                if (jsData[i].SPORT_ID == '1') {
                                    array.sportType = 'cycle-aerobic';
                                }
                                //租借
                                else if (jsData[i].SPORT_ID == '2') {
                                    array.sportType = null;
                                }
                                //瑜珈有氧
                                else if (jsData[i].SPORT_ID == 'TKC08e0a3ba-8016-4ca1-9dde-eabf181c0767') {
                                    array.sportType = 'aerobic';
                                }
                                //私人教練
                                else if (jsData[i].SPORT_ID == '3') {
                                    array.sportType = 'trainer';
                                }
                                //健身房
                                else if (jsData[i].SPORT_ID == 'TKC7df7eda9-a4c5-4959-9db9-76ba53f674d7') {
                                    array.sportType = 'weight';
                                }
                                //游泳池
                                else if (jsData[i].SPORT_ID == 'TKC38874b78-198c-48d0-b08c-3b429cb20603') {
                                    array.sportType = 'swimming';
                                }

                                if (jsData[i].AE_STATUS == 0) {
                                    array.status = 1;
                                    array.isRefund = true;
                                } else {
                                    array.status = 2;
                                }

                                //判斷有無過期
                                if (new Date() > new Date(array.deadline)) {
                                    array.status = 3;
                                }

                                //如果轉為紙本
                                if (jsData[i].AE_STATUS == 2) {
                                    array.status = 4;
                                }

                                if (status) {
                                    if (this.IsNull(array.usetime) != '') {
                                        var sdate = array.usetime.split(' ');
                                        var edate = array.deadline.split(' ');
                                        array.info.date = sdate[0];
                                        array.info.time = sdate[1] + ' ~ ' + edate[1];
                                    }
                                    this.productAll.push(array);
                                }
                            }
                        }
                        //console.log(this.productAll)
                    } else {
                        this.$router.push('/member/owns');
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            //多張票卷預約
            async reserveSpace2() {
                if (confirm('確定要預約？') == false) {
                    return;
                }

                var msg = '';

                if (msg == '') {
                    //平日
                    var openTime = '07:00';
                    var closeTime = '22:00';

                    let week = new Date().getDay();
                    if (week == 6) {
                        openTime = '08:00';
                        closeTime = '22:00';
                    } else if (week == 0) {
                        openTime = '08:00';
                        closeTime = '17:00';
                    }

                    var sDate = new Date();
                    var openDate = new Date(this.TransformTimeFormatYYYMMDD(sDate) + 'T' + openTime);
                    var closeDate = new Date(this.TransformTimeFormatYYYMMDD(sDate) + 'T' + closeTime);

                    if (sDate < openDate) {
                        alert('尚未到開放時間');
                        return;
                    } else if (sDate > closeDate) {
                        alert('開放時間已過');
                        return;
                    }

                    try {
                        this.loadSet.show = true;
                        var Token = await (await apiToken()).data;
                        //console.log(Token);
                        var data = {
                            AE_ID: this.details.AE_ID,
                            S_DATE: null,
                            S_TIME: null,
                            Token: Token,
                        };
                        data = this.BaseModel(data);

                        var result = JSON.parse(await (await apiACCESS_RESERVATION_TYPE1_CATALOG1(data)).data);
                        this.loadSet.show = false;

                        if (result.Status) {
                            this.init();
                            alert('預約成功');
                            //var jsData = JSON.parse(result.Data)
                            //console.log(this.curriclumIndex)
                            //console.log(this.curriclum)
                            //console.log(jsData)
                        } else {
                            if (result.Data != '') {
                                alert(result.Data);
                            } else {
                                alert(result.Message);
                            }
                        }

                        //console.log(result.Message)
                    } catch (e) {
                        this.loadSet.show = false;
                        //console.log(e)
                        alert(e);
                    }
                } else {
                    alert(msg);
                }
            },

            //---------------------- 分票--------------------------
            async getSplitCodeList() {
                try {
                    this.CodeList = [];

                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        AE_ID: this.details.AE_ID,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiACESS_SPLIT_CODE_LIST(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        if (jsData.length > 0) {
                            for (let i in jsData) {
                                jsData[i].AE_EXPIRATION_DATE = this.TransformTimeFormatYYYMMDDhhmm(
                                    jsData[i].AE_EXPIRATION_DATE,
                                );
                                jsData[i].SQ_DATE = this.TransformTimeFormatYYYMMDDhhmm(jsData[i].SQ_DATE);
                                this.CodeList.push(jsData[i]);
                                // console.log(this.CodeList)
                            }
                        }
                        //console.log(jsData)
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            async addSplitCode() {
                if (confirm('確定要分票？') == false) {
                    return;
                }

                var msg = '';
                if (this.modal2.count == 0) msg += '數量請至少輸入1\n';

                if (msg == '') {
                    try {
                        this.loadSet.show = true;
                        var Token = await (await apiToken()).data;
                        //console.log(Token);
                        var data = {
                            AE_ID: this.details.AE_ID,
                            SQ_QUANTITY: this.modal2.count,
                            Token: Token,
                        };
                        data = this.BaseModel(data);

                        var result = JSON.parse(await (await apiACCESS_SPLIT_ADD(data)).data);
                        this.loadSet.show = false;

                        if (result.Status) {
                            alert('分票成功');
                            this.modal2.show = false;
                            this.modal2.count = 0;
                            this.init();
                            //var jsData = JSON.parse(result.Data);
                            //this.modal.min = jsData.SQ_USE_QUANTITY
                            //console.log(jsData)

                            //console.log(this.productAll)
                        } else {
                            if (result.Data != '') {
                                alert(result.Data);
                            } else {
                                alert(result.Message);
                            }
                        }

                        //console.log(result.Message)
                    } catch (e) {
                        this.loadSet.show = false;
                        //console.log(e)
                        alert(e);
                    }
                } else {
                    alert(msg);
                }
            },
            async cancelSplit(SQ_ID) {
                if (confirm('確定取消分票？') == false) {
                    return;
                }

                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        AE_ID: this.details.AE_ID,
                        SQ_ID: SQ_ID,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiACCESS_SPLIT_CANCEL(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        //var jsData = JSON.parse(result.Data);
                        alert('取消成功');
                        this.init();
                        //console.log(jsData)
                    } else {
                        if (result.Data != '') {
                            alert(result.Data);
                        } else {
                            alert(result.Message);
                        }
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },

            async addQRcode(AE_ID) {
                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        AE_ID: AE_ID,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiACCESS_QRCODE_ADD(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        await this.getModalAccess(AE_ID);
                    } else {
                        if (result.Data != '') {
                            alert(result.Data);
                        } else {
                            alert(result.Message);
                        }
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
        },
        components: {
            TicketView,
            Header,
        },
    };
</script>
<style lang="scss" scoped>
    @media screen and (max-width: 768px) {
        .member-header {
            width: 100%;
            left: 0;
        }
        table {
            tbody {
                background: unset !important;
                td {
                    padding: 10px;
                    background: #fff;
                    &::before {
                        width: 25%;
                        white-space: pre-wrap;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 640px) {
        .member-header {
            position: absolute;
        }
    }
</style>

<style lang="postcss" scoped>
    .info {
        dt {
            @apply text-gray-500 text-sm;
        }

        dd {
            @apply ml-2 mb-2;
        }

        li {
            @apply py-2 border-b;

            &:last-child {
                @apply border-b-0;
            }

            span {
                @apply text-gray-400 pr-3 text-xs;
            }
        }
    }

    .modal {
        @apply flex justify-center items-center fixed left-0 top-0 w-screen h-screen z-50 text-secondary-500;

        .mask {
            @apply fixed left-0 top-0 w-screen h-screen bg-black opacity-50 flex;
        }
    }
</style>
