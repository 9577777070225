import Vue from 'vue'
import VueRouter from 'vue-router'


import Error from '../views/home/Error.vue'


import Home from '../views/home/Home.vue'
import Space from '../views/home/Space.vue'
import Course from '../views/home/Course.vue'
import SpaceIntroduction from '../views/home/SpaceIntroduction.vue'
import Curriculum from '../views/home/Curriculum.vue'
import GymnasiumNews from '../views/home/GymnasiumNews.vue'
import GymnasiumNewsDetails from '../views/home/GymnasiumNewsDetails.vue'

// 購物
import BuyTickets from '../views/shop/BuyTickets.vue'
import BuyCourse from '../views/shop/BuyCourse.vue'
// import Tickets from '../views/shop/Tickets.vue'
import ReservationSpace from '../views/shop/ReservationSpace.vue'
//import CheckOut from '../views/shop/CheckOut.vue'

// 會員中心
import MemberCenter from '../views/memberCenter/MemberCenter.vue'
import Owns from '../views/memberCenter/OwnedProducts.vue'
import Cycle from '../views/memberCenter/CycleCourseDetails.vue'
import Package from '../views/memberCenter/PackageDetails.vue'
import Rental from '../views/memberCenter/SpaceRental.vue'
import RentalDetails from '../views/memberCenter/SpaceRentalDetails.vue'
import Purchase from '../views/memberCenter/PurchaseHistory.vue'
import Refund from '../views/memberCenter/RefundHistory.vue'
import Split from '../views/memberCenter/SplitTicket.vue'
import CreateSplit from '../views/memberCenter/CreateSplitTicket.vue'
import Take from '../views/memberCenter/TakeTicket.vue'
import Profile from '../views/memberCenter/MemberProfile.vue'
import Reservation from '../views/memberCenter/CourseReservation'

// 登入
import Login from '../views/home/MemberLogin.vue'
import Register from '../views/home/MemberRegister.vue'
import Forgot from '../views/home/ForgotPassword.vue'



import FcuError from '../views/shop/FcuError.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/Login',
    name: 'Login',
    component: Login,
    meta: {
      title: '登入'
    }
  },
  //註冊
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  //忘記密碼
  {
    path: '/forgot',
    name: 'forgot',
    component: Forgot
  },
  //首頁
  {
    path: '/',
    name: 'home',
    component: Home
  },
  //場地
  {
    path: '/Space',
    name: 'Space',
    component: Space
  },
  //課程
  {
    path: '/Course',
    name: 'Course',
    component: Course
  },
  //場地介紹
  {
    path: '/SpaceIntroduction',
    name: 'SpaceIntroduction',
    component: SpaceIntroduction
  },
  //課程行事曆
  {
    path: '/Curriculum',
    name: 'Curriculum',
    component: Curriculum
  },
  //公告
  {
    path: '/gymnasiumNews',
    name: 'gymnasiumNews',
    component: GymnasiumNews
  },
  //公告內容
  {
    path: '/gymnasiumNewsDetails',
    name: 'gymnasiumNewsDetails',
    component: GymnasiumNewsDetails
  },
  // 購票
  {
    path: '/buyTickets',
    name: 'buyTickets',
    component: BuyTickets,
  },
  // 購票
  {
    path: '/buyCourse',
    name: 'buyCourse',
    component: BuyCourse,
  },
  // 租借
  {
    path: '/reservationSpace',
    name: 'reservationSpace',
    component: ReservationSpace,
  },
  // 會員中心
  {
    path: '/member',
    name: 'member',
    component: MemberCenter,
    redirect: '/member/owns',
    children: [
      //首頁
      {
        path: 'owns',
        name: 'owns',
        component: Owns
      },
      //租借
      {
        path: 'rental',
        name: 'rental',
        component: Rental
      },
      //租借內容
      {
        path: 'rentalDetails',
        name: 'rentalDetails',
        component: RentalDetails
      },
      //購買紀錄
      {
        path: 'purchase',
        name: 'purchase',
        component: Purchase
      },

      //退款紀錄
      {
        path: 'refund',
        name: 'refund',
        component: Refund
      },
      //分票紀錄
      {
        path: 'split',
        name: 'split',
        component: Split
      },
      //個人資料
      {
        path: 'profile',
        name: 'profile',
        component: Profile
      },
      //週期票
      {
        path: 'cycle',
        name: 'cycle',
        component: Cycle
      },
      //套票
      {
        path: 'package',
        name: 'package',
        component: Package
      },
      //分票
      {
        path: 'createSplit',
        name: 'createSplit',
        component: CreateSplit
      },
      //取票
      {
        path: 'take',
        name: 'take',
        component: Take
      },
      //課程預約
      {
        path: 'reservation',
        name: 'reservation',
        component: Reservation
      },
    ]
  },
  // 會員中心
  {
    path: '/FcuPay',
    name: 'FcuPay',
    component: MemberCenter,
    redirect: '/FcuPay/FcuError',
    children: [
      {
        path: 'FcuError',
        name: 'FcuError',
        component: FcuError
      },
    ]
  },
  {
    path: '/Error',
    name: 'Error',
    component: Error,
  }
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.length == 0) {
    next('Error')
  }
  next()
})

export default router
