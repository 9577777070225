<template>

<div class="signin">
      <div class="card">
         <div class="card-body">
            <h2 class="card-title justify-center">註冊帳號</h2>
            <div class="form-control w-full max-w-xs">
               <label class="label">
                  <span class="label-text">姓名</span>
               </label>
               <input placeholder="姓名" v-model="registerData.name" type="text" class="input input-bordered w-full max-w-xs" required>
               <label class="label">
                  <span class="label-text-alt">為確保您的權益，請輸入真實姓名！</span>
               </label>
            </div>
            <div class="form-control w-full max-w-xs">
               <label class="label">
                  <span class="label-text">電子郵件</span>
               </label>
               <input placeholder="電子信箱" v-model="registerData.mail" type="text" class="input input-bordered w-full max-w-xs" required>
            </div>
            <div class="form-control w-full max-w-xs mb-4">
               <label class="label">
                  <span class="label-text">密碼</span>
               </label>
               <input placeholder="請輸入密碼"  v-model="registerData.pwd" type="password" class="input input-bordered w-full max-w-xs mb-1" required>
               <input placeholder="請再輸入一次密碼" v-model="registerData.pwd2" type="password" class="input input-bordered w-full max-w-xs" required>
            </div>
            <button class="btn btn-block mb-4" @click="register" type="submit">送出</button>
            <p class="mb-4 w-full text-left">按下註冊鈕即表示您同意我們的<a href="https://www.fcu.edu.tw/privacy/">『個資保護政策』</a>。</p>
            <div class="text-center">
               已經有帳號了？<router-link class="link" to="/login">馬上登入</router-link>
            </div>
            <LoadingPage :loadSet="loadSet"></LoadingPage>
         </div>
      </div>
   </div>

</template>

<script>
import {
   apiToken,
   apiMEMBER_ADD,
} from '@/api/API'

export default {
   name: 'MemberRegister',
   data() {
      return {
         registerData: {
            mail:null,
            name:null,
            pwd:null,
            pwd2:null,

            Token:null,
         },
         loadSet: {
            show: false,
            fullPage: true,
            type: 'dots',
            color: 'rgba(0,0,0,0.7)'
         }
      };
   },
   mounted() {
      if (this.IsNull(this.$session.get('ACID')) != "") {
         this.$router.push('/')
      }
   },
   methods: {
      async register() {
         var msg = ""

         if (this.IsNull(this.registerData.name) == "")
            msg += "請輸入真實姓名\n"

         if (this.IsNull(this.registerData.mail) == ""){
            msg += "請輸入電子信箱\n"
         }else{
            if(!this.CheckMail(this.registerData.mail))
               msg+='您的電子信箱格式不符\n'
         }

         if (this.IsNull(this.registerData.pwd) == "")
            msg += "請輸入密碼\n"

         if (this.IsNull(this.registerData.pwd2) == "")
            msg += "請再次輸入密碼\n"

         if(msg == "" && (this.registerData.pwd != this.registerData.pwd2)){
            msg += "確認密碼錯誤\n"
         }

         if (msg == "") {
            try {
               this.loadSet.show = true
               var Token = await (await apiToken()).data;
               //console.log(Token);
               var data = {
                  PPL_NAME:this.registerData.name,
                  PPL_MAIL:this.registerData.mail,
                  MBR_PASSWORD:this.registerData.pwd2,
                  Token:Token}
               data = this.BaseModel(data)

               var result = JSON.parse(await (await apiMEMBER_ADD(data)).data);

               this.loadSet.show = false;

               if (result.Status) {
                  alert("註冊成功")
                  this.$router.push('/Login')
               } else {
                  alert(result.Message)
               }

               //console.log(result.Message)
            } catch (e) {
               this.loadSet.show = false;
               //console.log(e)
               alert(e)
            }
         } else {
            alert(msg)
         }

      },
   }
}
</script>
