import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    Auth: {},
    SpaceList: [],
    Title: "",
    Page: {
      Paging: {
        pageNumber: 1,
        pageSize: 10,
      },
      Search: {
        // start: "",
        // end: ""
      },
      Sort: {
      },
    },
    CheckDetailSpaceId: "",
    // TestURL: 'https://checkin.artinuro.com/api/',
    TestURL: 'https://check.artinuro.com/api/',
    Loading: false,
    inModal: false
  },
  getters: {
    getToken: (state) => state.Token,
    getPage: (state) => state.Page,
    getTestURL: (state) => state.TestURL,
  },
  mutations: {
    switchModal(state) {
      state.inModal = !state.inModal
    }
  },
  actions: {
    PageTitle(data) {
      this.commit('mutationTitle', data)
    },
  },
  modules: {
  }
})
