<template>
    <main>
        <Header title="個人資料"></Header>
        <form class="w-full sm:w-auto items-start main">
            <div class="profile">
                <div class="card w-full sm:w-1/2 bg-base-100 shadow-xl mb-8">
                    <div class="card-body text-center">
                        <h3 class="card-title">基本資料</h3>
                        <div class="form-control w-full">
                            <label class="label">
                                <span class="label-text">姓名 <span class="text-red-500">*</span></span>
                            </label>
                            <input type="text" v-model="details.PPL_NAME" class="input input-bordered w-full" />
                        </div>
                        <div class="form-control w-full">
                            <label class="label">
                                <span class="label-text">身分證號 <span class="text-red-500">*</span></span>
                            </label>
                            <input type="text" v-model="details.PPL_SSN" class="input input-bordered w-full" />
                        </div>
                        <div class="form-control w-full">
                            <label class="label">
                                <span class="label-text">性別<span class="text-red-500">*</span></span>
                            </label>
                            <select class="select select-bordered" v-model="details.PPL_GENDER">
                                <option value="0">男</option>
                                <option value="1">女</option>
                            </select>
                        </div>
                        <div>
                            <div class="flex space-x-2 flex-row">
                                <div class="flex-1 sm:flex-none">
                                    <div class="form-control w-full">
                                        <label class="label">
                                            <span class="label-text">出生年<span class="text-red-500">*</span></span>
                                        </label>
                                        <select class="select select-bordered" v-model="profile.birthday.year">
                                            <option v-for="(item, index) in 80" :key="index" :value="2022 - index">
                                                {{ 2022 - index }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="flex-1 sm:flex-none">
                                    <div class="form-control w-full">
                                        <label class="label">
                                            <span class="label-text">月<span class="text-red-500">*</span></span>
                                        </label>
                                        <select class="select select-bordered" v-model="profile.birthday.month">
                                            <option v-for="(item, index) in 12" :key="index" :value="index + 1">
                                                {{ index + 1 }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="flex-1 sm:flex-none">
                                    <div class="form-control w-full">
                                        <label class="label">
                                            <span class="label-text">日<span class="text-red-500">*</span></span>
                                        </label>
                                        <select class="select select-bordered" v-model="profile.birthday.day">
                                            <option v-for="(item, index) in 31" :key="index" :value="index + 1">
                                                {{ index + 1 }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-control w-full">
                            <label class="label">
                                <span class="label-text">電子信箱<span class="text-red-500">*</span></span>
                            </label>
                            <input type="text" v-model="details.PPL_MAIL" class="input input-bordered w-full" />
                        </div>
                        <div class="form-control w-full">
                            <label class="label">
                                <span class="label-text">聯絡電話<span class="text-red-500">*</span></span>
                            </label>
                            <input type="text" v-model="details.PPL_PHONE" class="input input-bordered w-full" />
                        </div>

                        <div class="form-group">
                            <div class="flex space-x-2 flex-row">
                                <div class="flex-1 sm:flex-none">
                                    <label class="label">
                                        <span class="label-text">居住縣市</span>
                                    </label>
                                    <select
                                        class="select select-bordered w-full"
                                        v-model="details.PPL_COMM_COUNTY"
                                        @change="getTown"
                                    >
                                        <option
                                            v-for="(itme, index) in params.PPL_COMM_COUNTY"
                                            :key="index"
                                            :value="itme.value"
                                        >
                                            {{ itme.text }}
                                        </option>
                                    </select>
                                </div>
                                <div class="flex-1 sm:flex-none">
                                    <label class="label">
                                        <span class="label-text">居住區域</span>
                                    </label>
                                    <select class="select select-bordered w-full" v-model="details.PPL_COMM_DISTRICT">
                                        <option
                                            v-for="(itme, index) in params.PPL_COMM_DISTRICT"
                                            :key="index"
                                            :value="itme.value"
                                        >
                                            {{ itme.text }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-control w-full">
                            <label class="label">
                                <span class="label-text">詳細地址</span>
                            </label>
                            <input type="text" v-model="details.PPL_COMM_ADD" class="input input-bordered w-full" />
                        </div>

                        <div class="form-control w-full">
                            <label class="label">
                                <span class="label-text">密碼</span>
                            </label>
                            <input class="input input-bordered" value="123456789" type="password" disabled />
                        </div>
                        <div class="card-actions justify-star">
                            <div class="btn" @click.prevent="ModalOpen('pwd')">變更密碼</div>
                        </div>
                    </div>
                </div>
                <div class="w-full sm:w-auto">
                    <div class="card w-full sm:w-96 bg-base-100 shadow-xl mb-8">
                        <!-- <a ref="nid" href="http://opendata.fcu.edu.tw/fcuOauth/Auth.aspx?client_id=637987678038.0b81fda3e3ab4ad6a88a39e5b1025ed4.sportscenter.fcu.edu.tw&client_url=https://sportscenter.fcu.edu.tw/API/User/NidCheck.aspx"></a> -->
                        <div class="card-body text-center">
                            <h3 class="card-title">身分優惠驗證</h3>

                            <div class="form-control w-full">
                                <label class="label">
                                    <span class="label-text">身份別</span>
                                </label>
                                <input
                                    v-if="details.PPL_NID_IDENTITY == 0"
                                    type="text"
                                    value="社會人士"
                                    class="input input-bordered w-full"
                                    disabled
                                />
                                <input
                                    v-else-if="details.PPL_NID_IDENTITY == 1"
                                    type="text"
                                    value="學生"
                                    class="input input-bordered w-full"
                                    disabled
                                />
                                <input
                                    v-else-if="details.PPL_NID_IDENTITY == 2"
                                    type="text"
                                    value="教職員"
                                    class="input input-bordered w-full"
                                    disabled
                                />
                                <input
                                    v-else-if="details.PPL_NID_IDENTITY == 3"
                                    type="text"
                                    value="校友"
                                    class="input input-bordered w-full"
                                    disabled
                                />
                                <input
                                    v-else-if="details.PPL_NID_IDENTITY == 4"
                                    type="text"
                                    value="眷屬"
                                    class="input input-bordered w-full"
                                    disabled
                                />
                                <!-- <strong>{{details.PPL_EMERGENCY_NAME}}</strong> -->
                            </div>
                            <div class="form-control">
                                <template v-if="details.PPL_NID_IDENTITY == 0">
                                    <label class="label" :for="'test1'">
                                        <div>
                                            <input
                                                type="radio"
                                                v-model="idType"
                                                name="idType"
                                                :id="'test1'"
                                                :value="0"
                                                style="margin-right: 0.3rem"
                                            />
                                            <span class="label-text">學生／教職員證號</span>
                                        </div>
                                    </label>
                                    <label class="label" :for="'test2'">
                                        <div>
                                            <input
                                                type="radio"
                                                name="idType"
                                                v-model="idType"
                                                :id="'test2'"
                                                :value="1"
                                                style="margin-right: 0.3rem"
                                            />
                                            <span class="label-text">校友身份證字號。</span>
                                        </div>
                                    </label>
                                    <label class="label" :for="'test2'">
                                        <div>
                                            <span style="font-size: 0.8rem" class="label-text"
                                                >如為退休教職員、教職員眷屬請洽館方人員協助</span
                                            >
                                        </div>
                                    </label>
                                </template>
                                <input
                                    class="input input-bordered"
                                    type="text"
                                    v-model="details.PPL_NID"
                                    :disabled="details.PPL_NID_IDENTITY != 0"
                                />
                            </div>
                            <!-- 未驗證顯示 -->
                            <div class="card-actions justify-star" v-if="details.PPL_NID_IDENTITY == 0">
                                <div class="btn" @click="checkNID">NID 驗證</div>
                            </div>
                        </div>
                    </div>

                    <div class="card w-full sm:w-96 bg-base-100 shadow-xl mb-8">
                        <div class="card-body text-center">
                            <h3 class="card-title">緊急聯絡人</h3>
                            <div class="form-control w-full">
                                <label class="label">
                                    <span class="label-text">緊急聯絡人姓名</span>
                                </label>
                                <input
                                    type="text"
                                    v-model="details.PPL_EMERGENCY_NAME"
                                    placeholder="Type here"
                                    class="input input-bordered w-full"
                                />
                            </div>
                            <div class="form-control w-full">
                                <label class="label">
                                    <span class="label-text">緊急聯絡人聯絡電話</span>
                                </label>
                                <input
                                    type="text"
                                    v-model="details.PPL_EMERGENCY_PHONE"
                                    placeholder="Type here"
                                    class="input input-bordered w-full"
                                />
                            </div>
                            <div class="form-control w-full">
                                <label class="label">
                                    <span class="label-text">關係</span>
                                </label>
                                <select class="select select-bordered" v-model="details.PPL_EMERGENCY_RELATION">
                                    <option value="0">父子女</option>
                                    <option value="1">母子女</option>
                                    <option value="2">兄弟姊妹</option>
                                    <option value="3">配偶</option>
                                    <option value="4">其他</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center mb-12 space-x-1">
                <div class="btn btn-primary" style="margin-top: 10px" @click="updateProfile">確認</div>
            </div>
        </form>

        <!-- The button to open modal -->

        <!-- Put this part before </body> tag -->
        <input type="checkbox" v-model="pwdModal.open" id="change-pwd" class="modal-toggle" />
        <div class="modal">
            <div class="modal-box">
                <h3 class="font-bold text-lg">變更您的密碼</h3>
                <div class="form-group">
                    <label for="">舊密碼</label>
                    <input class="!w-full" v-model="pwdModal.pwd" type="password" />
                </div>
                <div class="form-group">
                    <label for="">新密碼</label>
                    <input class="!w-full" v-model="pwdModal.npwd" type="password" />
                </div>
                <div class="form-group">
                    <label for="">請再次輸入新密碼</label>
                    <input class="!w-full" v-model="pwdModal.npwd2" type="password" />
                </div>
                <div class="modal-action">
                    <div class="btn primary" @click="updatePassword">更新密碼</div>
                    <label for="change-pwd" class="btn">取消!</label>
                </div>
            </div>
        </div>
        <LoadingPage :loadSet="loadSet"></LoadingPage>
    </main>
</template>

<script>
    import {
        apiToken,
        apiMEMBER_PROFILE,
        apiMEMBER_PROFILE_UPDATE,
        // apiMEMBER_NID_UPDATE,
        apiCheckFcuAuth,
        apiMEMBER_UPDATE_PASSWORD,
        apiSelectCity,
        apiSelectTown,
    } from '../../api/API';
    import Header from '@/components/MemberHeader.vue';
    export default {
        name: 'MemberProfile',
        components: {
            Header,
        },
        data() {
            return {
                details: {
                    PPL_NAME: null,
                    PPL_SSN: null,
                    PPL_GENDER: 0,
                    PPL_MAIL: null,
                    PPL_PHONE: null,
                    PPL_COMM_COUNTY: null,
                    PPL_COMM_DISTRICT: null,
                    PPL_COMM_ADD: null,
                    PPL_BIRTHDAY: null,
                    PPL_EMERGENCY_NAME: null,
                    PPL_EMERGENCY_PHONE: null,
                    PPL_EMERGENCY_RELATION: 0,
                },
                idType: null,
                profile: {
                    birthday: {
                        year: 1990,
                        month: 7,
                        day: 7,
                    },
                    pwd: null,
                },
                params: {
                    PPL_COMM_COUNTY: null,
                    PPL_COMM_DISTRICT: null,
                },
                pwdModal: {
                    open: false,
                    pwd: null,
                    npwd: null,
                    npwd2: null,
                },

                loadSet: {
                    show: false,
                    fullPage: true,
                    type: 'dots',
                    color: 'rgba(0,0,0,0.7)',
                },
            };
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                if (this.IsNull(this.$session.get('ACID')) == '') {
                    this.$router.push('/Login');
                } else {
                    await this.getMember();

                    await this.getCity();
                    if (this.IsNull(this.details.PPL_COMM_COUNTY) != '') await this.getTown();
                }
            },
            ModalOpen(name) {
                switch (name) {
                    case 'pwd':
                        this.pwdModal.pwd = null;
                        this.pwdModal.npwd = null;
                        this.pwdModal.npwd2 = null;
                        this.pwdModal.open = true;
                        break;
                }
            },
            async getMember() {
                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiMEMBER_PROFILE(data)).data);

                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);

                        this.details = jsData;

                        this.profile.pwd = jsData.MBR_PASSWORD;

                        if (this.IsNull(jsData.PPL_BIRTHDAY) != '') {
                            var bd = jsData.PPL_BIRTHDAY.split('T')[0].split('-');

                            this.profile.birthday.year = parseInt(bd[0]);
                            this.profile.birthday.month = parseInt(bd[1]);
                            this.profile.birthday.day = parseInt(bd[2]);
                        }
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            async updateProfile() {
                if (confirm('確定要更新？') == false) {
                    return;
                }

                var msg = '';
                if (this.IsNull(this.details.PPL_NAME) == '') msg += '請輸入姓名\n';

                if (this.IsNull(this.details.PPL_SSN) == '') {
                    msg += '請輸入身分證\n';
                } else {
                    if (!this.CheckidNum(this.details.PPL_SSN)) {
                        msg += '身分證格式不符\n';
                    }
                }

                if (this.IsNull(this.details.PPL_MAIL) == '') {
                    msg += '請輸入電子信箱\n';
                } else {
                    if (!this.CheckMail(this.details.PPL_MAIL)) {
                        msg += '電子信箱格式不符\n';
                    }
                }

                if (this.IsNull(this.details.PPL_PHONE) == '') msg += '請輸入電話\n';

                // if(this.IsNull(this.details.PPL_COMM_COUNTY) == "")
                //    msg += "請選擇居住縣市\n"

                // if(this.IsNull(this.details.PPL_COMM_DISTRICT) == "")
                //    msg += "請選擇居住區域\n"

                // if(this.IsNull(this.details.PPL_COMM_ADD) == "")
                //    msg += "請輸入詳細地址\n"

                if (msg == '') {
                    try {
                        this.loadSet.show = true;
                        var Token = await (await apiToken()).data;
                        //console.log(Token);

                        var Oarray = Object.keys(this.details);
                        var data = new Object();
                        for (var i = 0; i < Oarray.length; i++) {
                            data[Oarray[i]] = this.details[Oarray[i]];
                        }

                        data.PPL_BIRTHDAY =
                            this.profile.birthday.year +
                            '-' +
                            this.profile.birthday.month +
                            '-' +
                            this.profile.birthday.day;

                        data.Token = Token;
                        data = this.BaseModel(data);

                        //console.log(data)

                        var result = JSON.parse(await (await apiMEMBER_PROFILE_UPDATE(data)).data);
                        //console.log(result)

                        this.loadSet.show = false;

                        if (result.Status) {
                            await this.getMember();
                            if (this.IsNull(this.details.PPL_COMM_COUNTY) != '') await this.getTown();
                            alert('修改成功');
                        } else {
                            //console.log(result.Message)
                            alert(result.Message);
                        }

                        //console.log(result.Message)
                    } catch (e) {
                        this.loadSet.show = false;
                        //console.log(e,"66666666666")
                        alert(e);
                    }
                } else {
                    alert(msg);
                }
            },
            async checkNID() {
                if (confirm('確定要驗證？') == false) {
                    return;
                }

                var msg = '';
                if (this.idType == null) msg += '請選擇驗證方式\n';

                if (this.IsNull(this.details.PPL_NID) == '') msg += '請輸入NID\n';

                if (msg == '') {
                    try {
                        this.loadSet.show = true;
                        var Token = await (await apiToken()).data;
                        //console.log(Token);

                        var id = btoa(this.details.PPL_NID);

                        var data = {
                            // PPL_NID:this.details.PPL_NID

                            NID: this.idType == 0 ? id : null,
                            ID: this.idType == 1 ? id : null,
                            TYPE: 'CHECK',
                        };
                        data.Token = Token;
                        data = this.BaseModel(data);

                        console.log(data);

                        // var result = JSON.parse(await (await apiMEMBER_NID_UPDATE(data)).data)
                        var result = (await apiCheckFcuAuth(data)).data;
                        console.log(result);

                        this.loadSet.show = false;

                        if (result.Status) {
                            // this.$refs.nid.click()
                            await this.getMember();
                            alert('驗證成功');
                            // console.log(result)
                        } else {
                            //console.log(result.Message)
                            alert(result.Message);
                        }

                        //console.log(result.Message)
                    } catch (e) {
                        this.loadSet.show = false;
                        //console.log(e,"66666666666")
                        alert(e);
                    }
                } else {
                    alert(msg);
                }
            },
            async updatePassword() {
                if (confirm('確定要修改密碼？') == false) {
                    return;
                }

                var msg = '';
                if (this.IsNull(this.pwdModal.pwd) == '') msg += '請輸入舊密碼\n';

                if (this.IsNull(this.pwdModal.npwd) == '') msg += '請輸入新密碼\n';

                if (this.IsNull(this.pwdModal.npwd2) == '') msg += '請輸入確認新密碼\n';

                if (msg == '' && this.pwdModal.npwd != this.pwdModal.npwd2) msg += '確認新密碼錯誤\n';

                if (msg == '') {
                    try {
                        this.loadSet.show = true;
                        var Token = await (await apiToken()).data;
                        //console.log(Token);

                        var data = {
                            MBR_PASSWORD_OLD: this.pwdModal.pwd,
                            MBR_PASSWORD: this.pwdModal.npwd,
                            Token: Token,
                        };
                        data = this.BaseModel(data);

                        //console.log(data)

                        var result = JSON.parse(await (await apiMEMBER_UPDATE_PASSWORD(data)).data);
                        //console.log(result)

                        this.loadSet.show = false;

                        if (result.Status) {
                            await this.getMember();
                            this.pwdModal.open = false;
                            alert('修改成功');
                        } else {
                            //console.log(result.Message)
                            alert(result.Message);
                        }

                        //console.log(result.Message)
                    } catch (e) {
                        this.loadSet.show = false;
                        //console.log(e,"66666666666")
                        alert(e);
                    }
                } else {
                    alert(msg);
                }
            },
            async getCity() {
                try {
                    this.params.PPL_COMM_COUNTY = null;
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiSelectCity(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        var optionsTemp = [];
                        for (let i in jsData) {
                            optionsTemp.push({ value: jsData[i].CT_CITY_ID, text: jsData[i].CT_NAME });
                        }
                        this.params.PPL_COMM_COUNTY = optionsTemp;
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            async getTown() {
                try {
                    this.params.PPL_COMM_DISTRICT = null;
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        TW_CITY_ID: this.details.PPL_COMM_COUNTY,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiSelectTown(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        var optionsTemp = [];
                        for (let i in jsData) {
                            optionsTemp.push({ value: jsData[i].TW_TOWN_ID, text: jsData[i].TW_TOWN_NAME });
                        }
                        this.params.PPL_COMM_DISTRICT = optionsTemp;
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
        },
    };
</script>

<style lang="postcss" scoped>
    .profile {
        @apply flex flex-col sm:flex-row justify-center items-start sm:space-x-8 mb-8;
        .header {
            @apply bg-secondary-900 py-8 flex justify-center items-center flex-col mb-8;
        }

        ul {
            @apply w-full text-center grid grid-cols-3 divide-x divide-secondary-600;

            li {
                @apply px-1;

                span {
                    @apply block text-sm text-secondary-400;
                }

                /* &::after {
            content: '|';
            @apply text-sm text-secondary-300 mx-4
         } */
            }
        }
    }
    @media (min-width: 576px) {
    }
</style>
