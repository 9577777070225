<template>
   <div class="bg-secondary-100 min-h-screen pt-[64px] relative">
      <div class="container mx-auto ">
         <Header  title="快速訂購"></Header>
         <div class="main h-full">

         
         <div class="hidden md:flex flex-wrap items-center justify-center mb-8">
            <ul class="steps steps-vertical md:steps-horizontal w-full">
               <li class="step" :class="{'step-primary':step >= 1}"> 運動類型</li>
               <li class="step" :class="{'step-primary':step >= 2}">商品類型</li>
               <li class="step" :class="{'step-primary':step >= 3}">選取商品</li>
               <li class="step" :class="{'step-primary':step >= 4}">前往付款</li>
               <li class="step" :class="{'step-primary':step >= 5}">購買完成</li>
            </ul>
         </div>
         <!-- {{ select.ticketCatalog }}
         <hr>
         {{ select.ticketsType }}
         <hr>
         {{ select.item }} -->
         <div class="divider hidden sm:flex"></div> 
         <!-- 步驟1 條件：運動類型  -->
         <transition name="fade">
            <div v-if="step===1">
               <div class="buyTickets catalog-2 ">
                  <div class="option catalog" v-for="(item,index) in ticketCatalog" :key="index" >
                     <input type="radio" :id="'ticketCatalog'+index" name="ticketCatalog" :value="item" v-model="select.ticketCatalog" @click="nextStep()">
                     <label :for="'ticketCatalog'+index">
                        <div class="option-btn image" v-bind:style="{ 'background-image': 'url(\'' + item.url + '\')' }">
                           <span class="title">{{item.name}}</span>
                        </div>
                     </label>                  
                  </div>
                  <div class="option catalog">
                     <router-link to="/Course?TKT_CAT_ID=TKC4a72a104-ec14-4fa9-b99c-93f5a59a76dd">
                        <div class="option-btn image">
                           <span class="title">健身期課</span>
                        </div>
                     </router-link>
                  </div>
               </div>
               <div class="divider hidden sm:flex"></div> 
            </div>
         </transition>

         <!-- 步驟2 條件：選取票券類型 -->
         <transition name="fade">
            <div v-if="step===2">
               <div class="buyTickets product">
                  <div class="option" v-for="(item, index) in ticketsType" :key="index">
                     <input type="radio" :id="'ticketsType'+index" name="ticketsType" :value="item.value" v-model="select.ticketsType">
                     <label :for="'ticketsType'+index">
                        <TicketView :ticketDetails="item"></TicketView>
                     </label>
                  </div>
               </div>
               <div class="divider hidden sm:flex"></div> 
               <div class="step-control">
                  <button class="btn" @click="back(1)">返回</button>
                  <button class="btn" v-if="select.ticketsType" @click="nextStep()">下一步</button>
               </div>  
            </div>
         </transition>

         <!-- 步驟3 選取該類型品項 -->
         <transition name="fade">
            <div v-if="step===3">
               <template v-if="select.ticketsType=='ticket'">
                  <!-- 票卷至少要有一個 -->
                  <!-- 瑜珈有氧日曆 -->
                  <template v-if="select.ticketCatalog.type == 0 && ticket.length > 0">
                     <div class="flex flex-col space-y-4 items-center mb-4">
                        <div class="text-center text-secondary-500">{{dateRange.min.year}} 年 {{dateRange.min.month}} 月 {{dateRange.min.day}} 日 <span class=" hidden sm:inline">星期{{dateRange.min.week}}</span>  ~  <span class=" hidden sm:inline">{{dateRange.max.year}}</span> 年 {{dateRange.max.month}} 月 {{dateRange.max.day}} 日 <span class=" hidden sm:inline">星期{{dateRange.max.week}}</span></div>
                        <div class="flex space-x-1 items-center justify-center">
                           <button class="btn btn-sm btn-outline btn-secondary" @click="resetData">今天</button>
                           <button class="btn btn-sm btn-outline btn-secondary" @click="updateData(-7)"> <svg class=" fill-secondary-500 hover:fill-secondary-200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M11.828 12l2.829 2.828-1.414 1.415L9 12l4.243-4.243 1.414 1.415L11.828 12z"/></svg> </button>
                           <button class="btn btn-sm btn-outline btn-secondary" @click="updateData(7)"> <svg class=" fill-secondary-500 hover:fill-secondary-200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12.172 12L9.343 9.172l1.414-1.415L15 12l-4.243 4.243-1.414-1.415z"/></svg> </button>
                        </div>
                     </div>
                     <div class="curriculum">
                        <div v-for="(item, index) in curriclum" class="day " :key="index">
                           <div class="p-2 flex justify-center text-white space-x-2 text-sm" :class="[ nowDate == item.month+'/'+item.day ? 'bg-primary-500 text-black' :'bg-secondary-500 ' ]">
                              <strong class="inline-block week mr-2">{{item.month}}月{{item.day}}日</strong>
                              <strong>星期{{item.week}}</strong>
                           </div>
                           <div class="pt-2 pb-10 sm:px-4">
                              <template v-for="(course, i) in item.courses">
                                 <template>
                                    <!-- {{ course.id }} -->
                                    
                                    <a :key="i" @click="changeItem2(ticket[0],course,index)" >    
                                       <Curriculum2  :info="course" v-bind:class="{ '!border-4': false, '!border-primary-500': false }" > </Curriculum2>
                                       <template v-if="select.item">
                                       <!-- <div>
                                          {{ select.item.course.id }}
                                       </div> -->
                                    </template>
                                    </a>
                                 </template>
                              </template>
                           </div>
                        </div>
                     </div>
                  </template>
                  <!-- 票券 -->
                  <template v-if="select.ticketCatalog.type == 1">
                     <div class="buyTickets product ">
                        <div class="option" v-for="(item, index) in ticket" :key="index">
                           <input type="radio" :id="'ticket'+index" tp name="ticket" :value="item.value" @click="changeItem(index,ticket)">
                           <label :for="'ticket'+index">
                              <TicketView :ticketDetails="item"></TicketView>
                           </label>
                        </div>
                     </div>
                     <!-- <div class="w-96 whitespace-pre">{{ticket}}</div> -->
                  </template>
               </template>
               <template v-if="select.ticketsType=='ticketPackage'">
                  <div class="buyTickets product">
                     <div class="option" v-for="(item, index) in Package" :key="index">
                        <input type="radio" :id="'ticketPackage'+index" tp name="ticketPackage" :value="item.value" @click="changeItem(index,Package)">
                        <label :for="'ticketPackage'+index">
                           <TicketView :ticketDetails="item"></TicketView>
                        </label>
                     </div>
                  </div>
                  <!-- <div class="w-96 whitespace-pre">{{Package}}</div> -->
               </template>
               <template v-if="select.ticketsType=='card'">
                  <div class="buyTickets product">
                     <div class="option" v-for="(item, index) in cycle" :key="index">
                        <!-- {{ item }} -->
                        <input type="radio" :id="'card'+index" name="card" :value="item.value" @click="changeItem(index,cycle)">
                        <label :for="'card'+index">
                           <TicketView :ticketDetails="item"></TicketView>
                        </label>
                     </div>
                  </div>
                  <!-- <div class="w-96 whitespace-pre">{{cycle}}</div> -->
               </template>
               <template v-if="select.item">
                  <div class="divider"></div> 
                  <div class="card  border shadow bg-base-100 sm:w-1/2 mx-auto">
                     <div class="card-body">
                        <h2 class="card-title">收據選項</h2>
                        <div class="form-control w-full">
                        
                           <label class=" flex items-center space-x-2 mb-2">
                              <input  v-model="select.receipt.OD_RECEIPT_TYPE" :value="0" type="radio" name="radio-1" class="radio" @click="clearReceipt"/> <span>個人收據</span>
                           </label>
                           <label v-if="$session.get('PPL_NID_IDENTITY') == 0" class=" flex items-center space-x-2">
                              <input v-model="select.receipt.OD_RECEIPT_TYPE" :value="1" type="radio" name="radio-1" class="radio" /><span>公司收據</span>
                           </label>
                        </div>
                        <template v-if="select.receipt.OD_RECEIPT_TYPE == 1">
                           <div class="form-control w-full max-w-xs">
                              <label class="label">
                                 <span class="label-text">收據抬頭</span>
                              </label>
                              <input v-model="select.receipt.OD_RECIPT_TITLE" type="text" placeholder="" class="input input-bordered w-full max-w-xs" />
                           </div>
                           <div class="form-control w-full max-w-xs">
                              <label class="label">
                                 <span class="label-text">統一編號</span>
                              </label>
                              <input v-model="select.receipt.OD_UNICODE_NUMBER"  type="text" placeholder="" class="input input-bordered w-full max-w-xs" />
                           </div>
                        </template>
                        <div class="divider"></div> 
                        <label for="" class="flex">
                           <input v-model="select.regulations" type="checkbox" class="checkbox mr-2" /> 
                           <div>
                              我已閱讀並同意
                              <template v-if="select.ticketCatalog.id == 'TKC08e0a3ba-8016-4ca1-9dde-eabf181c0767'">
                                 <a target="_blank" href="https://drive.google.com/file/d/1sRMPLFbmOYTxb5nGt2RDA_TUmJOSlM3r/view?usp=sharing">『瑜珈有氧票券相關規範』</a>相關條款之說明。並同意接受本館之會員規章之一切條款所約束。
                              </template>
                              <template v-else-if="select.ticketCatalog.id == 'TKC7df7eda9-a4c5-4959-9db9-76ba53f674d7'">
                                 <a target="_blank" href="https://drive.google.com/file/d/1rWSDqAk-Zj0c6L55Kv2okbZG7JyxGmht/view?usp=sharing">『會員規章』</a> 、<a target="_blank" href="https://drive.google.com/file/d/1sJXRf8PtP-X7DI6Y8px1W_jjFg-RdZSa/view?usp=sharing">『健身房使用規範』</a>相關條款之說明。並同意接受本館之會員規章之一切條款所約束。
                              </template>
                              <template v-else-if="select.ticketCatalog.id == 'TKC38874b78-198c-48d0-b08c-3b429cb20603'">
                                 <a target="_blank" href="https://drive.google.com/file/u/1/d/1Kc0nKVhPq68T30D3eE_fGD2_3qVgL1Ly/view?usp=sharing">『游泳池入場使用規範』</a> 相關條款之說明。並同意接受本館之會員規章之一切條款所約束。
                              </template>
                              <template v-else-if="select.ticketCatalog.id == 'TKC33a76759-4591-4277-8ae1-fea401a59a1d'">
                                 <a target="_blank" href="https://drive.google.com/file/d/1rWSDqAk-Zj0c6L55Kv2okbZG7JyxGmht/view?usp=sharing">『會員規章』</a> 、<a target="_blank" href="https://drive.google.com/file/d/1sJXRf8PtP-X7DI6Y8px1W_jjFg-RdZSa/view?usp=sharing">『健身房使用規範』</a>相關條款之說明。並同意接受本館之會員規章之一切條款所約束。
                              </template>
                              <template v-else>
                                 <a target="_blank" href="https://drive.google.com/file/d/1rWSDqAk-Zj0c6L55Kv2okbZG7JyxGmht/view?usp=sharing">『會員規章』</a> 相關條款之說明。並同意接受本館之會員規章之一切條款所約束。
                              </template>
                           </div>
                        </label>
                     </div>
                  </div>
               </template>
                  <div class="divider hidden sm:flex"></div> 
                  <div class="step-control">
                     <button class="btn" @click="back(2)">返回</button>
                     <button class="btn" v-if="select.item" @click="changeItem1()">送出訂單</button>
                  </div>
            </div>
         </transition>

         <transition name="fade">
            <div v-if="step===4">
               <div class="card border shadow bg-base-100 sm:w-1/2 mx-auto" v-if="orderDetails.ORDER">
                  <div class="card-body items-center">
                     <h2 class="card-title">購買明細</h2>
                     <table class="table w-full">
                        <tbody>
                           <tr>
                              <th>訂單編號</th>
                              <td>{{orderDetails.ORDER.OD_ID}}</td>
                           </tr>
                           <tr>
                              <th>會員明稱</th>
                              <td>{{$session.get('NAME')}}</td>
                           </tr>
                           <tr>
                              <th>商品明細</th>
                              <td v-for="(item,index) in orderDetails.ORDER_PRODUCT" :key="index" class=" whitespace-pre-line">
                                 <div v-for="(item2,index2) in item.OP_NAME" :key="index2">{{item2}}</div>
                              </td>
                           </tr>
                           <tr>
                              <th>訂單金額</th>
                              <td class="nt">NT$<strong class="text-2xl">{{orderDetails.ORDER.OD_PRICE}}</strong></td>
                           </tr>
                           <tr>
                              <th>付款方式</th>
                              <td>
                                 <ul class="paymentMethod">
                                    <li><img class=" h-4" alt="逢甲大學體育館" src="@/assets/image/paymentIcon/atm.jpg"></li>
                                    <li><img class=" h-4" alt="逢甲大學體育館" src="@/assets/image/paymentIcon/LINE_Pay_logo.png"></li>
                                    <li><img class=" h-4" alt="逢甲大學體育館" src="@/assets/image/paymentIcon/taiwanpaylogo.png"></li>
                                    <li><img class=" h-4" alt="逢甲大學體育館" src="@/assets/image/paymentIcon/1200px-JKOPAY_logo.svg.png"></li>
                                    <li><img class=" h-4" alt="逢甲大學體育館" src="@/assets/image/paymentIcon/logo-mark.svg"></li>
                                    <li><img class=" h-4" alt="逢甲大學體育館" src="@/assets/image/paymentIcon/Alipay_logo.png"></li>
                                    <li>超商代收</li>
                                    <li>信用卡</li>
                                 </ul>
                              </td>
                           </tr>
                        </tbody>
                     </table>                  
                  </div>
               </div>
               <div class="divider hidden sm:flex"></div> 
               <div class="step-control">
                  <a :href="$appDomain+'/FcuPay/FcuPay?OD_ID='+orderDetails.ORDER.OD_ID" class="btn">付款</a>
               </div>
            </div>
         </transition>
         
         <transition name="fade">
            <div v-if="step===5">
               <div class="card  border shadow bg-base-100 mt-12 sm:w-1/2 mx-auto">
                  <div class="card-body items-center text-center">
                     <h2 class="card-title flex-col">
                        <svg xmlns="http://www.w3.org/2000/svg" class=" fill-green-500" viewBox="0 0 24 24" width="48" height="48"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-.997-6l7.07-7.071-1.414-1.414-5.656 5.657-2.829-2.829-1.414 1.414L11.003 16z"/></svg> 付款成功，感謝您的購買。
                     </h2>
                     <!-- <table class="table w-full">
                           <tbody>
                              <tr>
                              <th>訂單編號</th>
                              <td>{{orderDetails.ORDER.OD_ID}}</td>
                           </tr>
                           <tr>
                              <th>會員明稱</th>
                              <td>{{$session.get('NAME')}}</td>
                           </tr>
                           <tr>
                              <th>商品明細</th>
                              <td v-for="(item,index) in orderDetails.ORDER_PRODUCT" :key="index" class=" whitespace-pre-line">
                                 <div v-for="(item2,index2) in item.OP_NAME" :key="index2">{{item2}}</div>
                              </td>
                           </tr>
                           <tr>
                              <th>付款金額</th>
                              <td>NT<strong class="text-2xl text-green-500">${{orderDetails.ORDER.OD_PRICE}}</strong></td>
                           </tr>
                           <tr>
                              <th>付款方式</th>
                              <td>
                                 逢甲pay
                              </td>
                           </tr>
                        </tbody>
                     </table>      -->
                     <p>您可以進行以下操作：</p>
                     <div class="step-control">
                        <button class="btn ">繼續購票</button>
                        <router-link to="/member/owns" class="btn">前往我的商品</router-link>
                        <router-link to="/member/purchase" class="btn">前往訂單記錄</router-link>
                     </div>
                  </div>
               </div>

           
            </div>
         </transition>
         <!-- <button class="btn" @click="step = 1; select.ticketsType = null; select.ticketCatalog = null; select.item = null">取消</button> -->
      </div>
      </div>
      <LoadingPage :loadSet="loadSet"></LoadingPage>
   </div>
</template>

<script>
import { apiToken,
         apiTICKET_CATALOG_LIST2,
         apiTICKET_CATALOG_TICKET_TYPE_LIST,
         apiCURRICULUM_LIST,
         apiTICKET_COURSE_CATALOG_UPPER_LIST,
         apiTICKET_LIST_TYPE0,
         apiTICKET_LIST_TYPE1,
         apiTICKET_LIST_TYPE2,
         apiORDER_GET,
         apiORDER_ADD,
         apiMEMBER_PROFILE_CHECK,
       } from '../../api/API'
import Curriculum2 from '@/components/CourseTemplate2.vue'
import TicketView from '@/components/TicketView.vue'
import moment from "moment";
import Header from '@/components/MemberHeader.vue'
export default {
   head: {
      title: "快速訂購",
      meta:[
         { name: 'description', content: '快速訂購'}
      ]
   },
   name: 'BuyTicket',
   data() {
      return {
         step: 1,
        
         ticketCatalog:[],
         ticketsType: [],

         ticket:[],
         //套票
         Package: [],
         //會員會期
         cycle: [],

        
         select: {
            ticketsType: null,
            item: null,
            ticketCatalog:null,
            receipt:{
               OD_RECEIPT_TYPE:0,
               OD_RECIPT_TITLE:null,
               OD_UNICODE_NUMBER:null,
            },
            regulations:false,
         },

         OD_ID:null,
         orderDetails:{
            ORDER:null,
            ORDER_PRODUCT:null,
            ORDER_DISCOUNT:null,
         },
         
         //瑜珈有氧課程表
         nowDate:new Date().getTime(),
         dateRange:{
               day:0,
               min:{
                  week:null,
                  day:null,
                  month:null,
                  year:null
               },
               max:{
                  week:null,
                  day:null,
                  month:null,
                  year:null
               },
         },
         curriclum:[],
         curriclumIndex:{},
      
         loadSet:{show: false,fullPage: true,type:'dots',color:'rgba(0,0,0,0.7)'}
      }
   },
   created(){
      // 转换时间格式年月日时分秒
      this.nowDate = moment(this.nowDate).format('MM/DD')
   },
   mounted() {
      this.init()
   },
   methods: {
      async init(){
         if (this.IsNull(this.$session.get('ACID')) != ""){
            var status = await this.getMemberProfileCheck();
            if(!status){
               alert("請先填妥基本資料，以利進行後續購買程序。")
               this.$router.push({name:"profile"})
            }
          }
          
         this.loadSet.show = false
         await this.getTicketCatalogList()

         if(this.IsNull(this.$session.get('ShoppingCart')) != ''){
            this.loadSet.show = true
            var shop = this.$session.get('ShoppingCart')
            this.select = shop.values.select
            this.step = 2
            this.nextStep()
            this.step = 4
            this.addShopCart()
         }else if(this.IsNull(this.$route.query.OD_ID) != ''){
            this.OD_ID = this.$route.query.OD_ID
            await this.getOrder()
            this.step = 5
         }else if(this.IsNull(this.$route.params.tkcid) != '' && this.$route.params.catalog != null){
            for(var i = 0 ;i < this.ticketCatalog.length;i++){
               if(this.ticketCatalog[i].id == this.$route.params.tkcid){
                  this.select.ticketCatalog = this.ticketCatalog[i]
                  this.nextStep()
                  if(this.$route.params.catalog == 0)
                     this.select.ticketsType='ticket'
                  else if(this.$route.params.catalog == 1)
                     this.select.ticketsType='ticketPackage'
                  else if(this.$route.params.catalog == 2)
                     this.select.ticketsType='card'
                  this.nextStep()
               }
            }

         }
      },
      preStep() {
         this.step--
      },
      nextStep() {
         this.step++
         
         switch (this.step) {
            case 2:
               this.getTicketTypeList()
               //console.log(this.select)
               break;
            case 3:
               //console.log(this.select)
               if(this.select.ticketsType=='ticket'){
                  // if(this.select.ticketCatalog.type == 0)
                  //    this.changeDate(0)
                  this.getTicketList(0)
               }else if(this.select.ticketsType=='ticketPackage'){
                  this.getTicketList(1)
               }else if(this.select.ticketsType=='card'){
                  this.getTicketList(2)
               }
               break;

            default:
               break;
         }
      },
      back(step) {
         this.step = step;
         switch (step) {
            case 1:
               this.select.ticketCatalog = null;
               this.select.ticketsType = null;
               this.select.item = null;
               break;
            case 2:
               this.select.ticketsType = null;
               this.select.item = null;
               break;

            default:
               break;
         }
      },
      
      async resetData(){
            this.dateRange.day = 0
            this.changeDate(0)
            await this.getTicketCourseCatalogUpperList()
            await this.getCurriclum()
      },
      async updateData(day){
         this.changeDate(day)
         await this.getTicketCourseCatalogUpperList()
         await this.getCurriclum()
      },
      changeDate(day){
         this.dateRange.day += day
         //將today改成每週的禮拜三
         //var today = new Date(new Date().setDate(new Date().getDate() + (this.dateRange.day - 1)))
         var nowWeek = new Date().getDay()
         var today = new Date(new Date().setDate(new Date().getDate() + (this.dateRange.day - 1 - nowWeek)))
         //console.log(today,"me")

         this.curriclumIndex = []
         this.curriclum = []
         for(var i = 0;i < 7;i++){
            new Date(today.setDate(today.getDate() + 1))
            let year = today.getFullYear()
            let month = this.TransformTime2String(today.getMonth() + 1)
            let day = this.TransformTime2String(today.getDate())
            let week = this.getWeek(today.getDay())

            if(i == 0){
               this.dateRange.min.year = year
               this.dateRange.min.month = month
               this.dateRange.min.day = day
               this.dateRange.min.week = week
            }else if(i == 6){
               this.dateRange.max.year = year
               this.dateRange.max.month = month
               this.dateRange.max.day = day
               this.dateRange.max.week = week
            }

            this.curriclumIndex[year+"-"+month+"-"+day] = i
            this.curriclum.push({
               year:year,
               month:month,
               day:day,
               week:week,
               courses:[]
            })
         }
         
      },
      getWeek(week){
         var result = ""
         switch(week){
               case 0:
                  result = "日"
                  break
               case 1:
                  result = "一"
                  break
               case 2:
                  result = "二"
                  break
               case 3:
                  result = "三"
                  break
               case 4:
                  result = "四"
                  break
               case 5:
                  result = "五"
                  break
               case 6:
                  result = "六"
                  break
         }
         return result
      },
      changeItem(index,item){
         this.select.item = item[index]
         // this.step = 4
         // this.addShopCart()
      },
      changeItem1(){
         // this.select.item = item[index]
         this.step = 4
         this.addShopCart()
      },
      //票卷單次
      changeItem2(item,course,curriclumIndex){
         course.day = this.curriclum[curriclumIndex].year + "-" + this.curriclum[curriclumIndex].month + "-" + this.curriclum[curriclumIndex].day
         item.course = course
         var sDate = new Date()
         var eDate = new Date(new Date().setDate(new Date().getDate() + 7 ))

         var sCourseDate = new Date(course.day +"T" +course.startTime)
         var eCourseDate = new Date(course.day +"T" +course.endTime)

         if((((sDate) <= (sCourseDate) && (eDate) >= (sCourseDate)) ||
            ((sDate) <= (eCourseDate) && (eDate) >= (eCourseDate)) ||
            ((sDate) <= (sCourseDate) && (eDate) >= (eCourseDate)) ||
            ((sDate) >= (sCourseDate) && (sDate) <= (eCourseDate))))
         {
            this.select.item = item
            // this.step = 4
            // this.addShopCart()
         }
         else
         {
            alert('本課程已結束')
         }
         
         //console.log(this.curriclum)
      },
      //沒有購物車 直接購買  寫法改變
      async addShopCart(){
         if (this.IsNull(this.$session.get('ACID')) != "") {
            // var data = {
            //    tickets:[],
            //    ticketsIndex:{},
            //    course:[],
            //    courseIndex:{},
            //    rent:[],
            //    rentIndex:{},
            // }
            // if(this.$session.get('ShoppingCart') != undefined){
            //    data = this.$session.get("ShoppingCart")
            // }

            
            // if(data.ticketsIndex[this.select.item.id] != null && data.ticketsIndex[this.select.item.id] != undefined){
            //    var index = data.ticketsIndex[this.select.item.id]
            //    data.tickets[index].count += 1 
            // }else{
            //    data.tickets.push({id:this.select.item.id,count:1})
            //    data.ticketsIndex[this.select.item.id] = data.tickets.length - 1
            // }

            // this.$session.set("ShoppingCart",data)
            // alert("已新增至購物車")
            // this.$router.push('/checkout')
            var status = await this.getMemberProfileCheck();
            if(status){
               this.$session.set('ShoppingCart', '')
               this.addOrder()
            }else{
               alert("請先填妥基本資料，以利進行後續購買程序。")
               this.$router.push({name:"profile"})
            }

         } else {
               alert("請先登入")
               var data = {
                  type:"ticket",
                  values:{select:this.select},
               }
               this.$session.set('ShoppingCart', data)

               this.$router.push('/Login')
         }
         
      },
      
      async getOrder(){
         try {

            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               OD_ID:this.OD_ID,
               Token:Token
            }
            data = this.BaseModel(data)

            
            var result = JSON.parse(await (await apiORDER_GET(data)).data);
            this.loadSet.show = false;

            if (result.Status) {
               var jsData = JSON.parse(result.Data);
               for(var i in jsData.ORDER_PRODUCT){
                  let name = jsData.ORDER_PRODUCT[i].OP_NAME.split("§")
                  jsData.ORDER_PRODUCT[i].OP_NAME = name
               }
               this.orderDetails = jsData
               //console.log(jsData)
            } else {
               alert(result.Message)
            }

         //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false;
            //console.log(e)
            alert(e)
         }
      },
      async addOrder(){
         if (confirm("確定要送出訂單？") == false) {
            this.step = 3
            return;
         }

         var msg = ""
         if(this.select.item == null )
            msg += "請先選購商品\n"

         if (this.select.receipt.OD_RECEIPT_TYPE == 1) {
            if (this.IsNull(this.select.receipt.OD_RECIPT_TITLE) == '') {
               msg += "請填寫收據抬頭\n"
            }

            if (this.IsNull(this.select.receipt.OD_UNICODE_NUMBER) == '') {
               msg += "請填寫統一編號\n"
            }
         }
         
         if(!this.select.regulations){
            if(this.select.ticketCatalog.id == "TKC08e0a3ba-8016-4ca1-9dde-eabf181c0767")
               msg += "請勾選同意『瑜珈有氧票券相關規範』\n"
            else if(this.select.ticketCatalog.id == "TKC7df7eda9-a4c5-4959-9db9-76ba53f674d7")
               msg += "請勾選同意 『會員規章』 、『健身房使用規範』\n"
            else
               msg += "請勾選同意『會員規章』\n"
         }

         if(msg == ""){
            try {
               this.loadSet.show = true
               var Token = await (await apiToken()).data
               var TICKET = []
               var TICKET_ONE_COURSE = []

               if(this.select.ticketsType=='ticket' && this.select.ticketCatalog.type==0){
                  //console.log(this.select.item)
                  if(new Date() > new Date(this.select.item.course.day+"T"+this.select.item.course.endTime))
                  {
                     this.loadSet.show = false
                     alert("日期已過")
                     return;
                  }else if(this.select.item.course.state == 1 ){
                     this.loadSet.show = false
                     alert("課程已額滿")
                     return;
                  }

                  TICKET_ONE_COURSE.push({ID:this.select.item.id,COUNT:1,CUS_ID:this.select.item.course.id})
               }else{
                  TICKET.push({ID:this.select.item.id,COUNT:1})
               }

               var data = {
                  TICKET:TICKET,
                  TICKET_ONE_COURSE:TICKET_ONE_COURSE,
                  COURSE:[],
                  SPACE_RENT:[],
                  OD_RECEIPT_TYPE:this.select.receipt.OD_RECEIPT_TYPE,
                  OD_RECIPT_TITLE:this.select.receipt.OD_RECIPT_TITLE,
                  OD_UNICODE_NUMBER:this.select.receipt.OD_UNICODE_NUMBER,
                  DIS_ID:null,
                  Token:Token
               };
               data = this.BaseModel(data)

               //console.log(data)
               
               var result = JSON.parse(await (await apiORDER_ADD(data)).data)
               //console.log(result)
               
               this.loadSet.show = false

               if (result.Status) {
                  var jsData = JSON.parse(result.Data)
                  // console.log(jsData)
                  this.select.item = null
                  for(var i in jsData.ORDER_PRODUCT){
                     let name = jsData.ORDER_PRODUCT[i].OP_NAME.split("§")
                     jsData.ORDER_PRODUCT[i].OP_NAME = name
                  }
                  this.orderDetails = jsData
                  if(this.orderDetails.ORDER.OD_PAYMENT_STATUS == 1){
                     this.step = 5
                  }
                  var deadLine = this.TransformTimeFormatYYYMMDDhhmm(new Date(this.orderDetails.ORDER.OD_PAYMENT_DEADLINE))
                  
                  alert("訂單已送出。請於 "+deadLine+" 內完成繳費。完成繳費後，可至[我的商品]查看，並可直接刷QRcode進場。")
               } else {
                  // console.log(result)
                  if(result.Data != ""){
                     alert(result.Data)
                  }else{
                     alert(result.Message)
                  }
               }

               //console.log(result.Message)
            } catch (e) {
               this.loadSet.show = false;
               //console.log(e,"66666666666")
            }
         }else{
            alert(msg)
         }
         
      },
      async getTicketCatalogList(){
         try {
            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               Token:Token
            }
            data = this.BaseModel(data)

            
            var result = JSON.parse(await (await apiTICKET_CATALOG_LIST2(data)).data);
            this.loadSet.show = false

            if (result.Status) {
               var jsData = JSON.parse(result.Data)
               // console.log(jsData)
               this.ticketCatalog = []
               for(let i in jsData){
                  if(!this.$Test && jsData[i].TKT_CAT_ID != 'TKC38874b78-198c-48d0-b08c-3b429cb20603'){
                     continue
                  }


                  //TKC4a72a104-ec14-4fa9-b99c-93f5a59a76dd 健身期課不會再票卷 屬於直接購買
                  if(jsData[i].TKT_CAT_ID != 'TKC4a72a104-ec14-4fa9-b99c-93f5a59a76dd' && jsData[i].TKT_CAT_ID != 'TKC33a76759-4591-4277-8ae1-fea401a59a1d'  && jsData[i].TKT_CAT_ID != '3')
                     this.ticketCatalog.push({name:jsData[i].TKT_CAT_NAME,
                     id:jsData[i].TKT_CAT_ID,
                     type:jsData[i].TKT_CAT_TYPE,
                     url:jsData[i].PHOTO_URL,
                     oneD:jsData[i].TKT_CAT_ONCE_DESCRIPTION,
                     packageD:jsData[i].TKT_CAT_PACKAGE_DESCRIPTION,
                     cycleD:jsData[i].TKT_CAT_CYCLE_DESCRIPTION})
               }
               // console.log(this.ticketCatalog)
            } else {
               alert(result.Message)
            }

            //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false
            //console.log(e)
            alert(e)
         }
      },
      async getTicketTypeList(){
         try {
            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               TKT_CAT_ID:this.select.ticketCatalog.id,
               Token:Token
            }
            data = this.BaseModel(data)

            
            var result = JSON.parse(await (await apiTICKET_CATALOG_TICKET_TYPE_LIST(data)).data);
            this.loadSet.show = false

            if (result.Status) {
               var jsData = JSON.parse(result.Data)
               // console.log(jsData)

               this.ticketsType = []
               
               for(let i in jsData){
                  var val = ''
                  var description = ''
                  switch(jsData[i].TKT_TYPE){
                     case 0:
                        val = 'ticket'
                        description = this.select.ticketCatalog.oneD
                        break;
                     case 1:
                        val = 'ticketPackage'
                        description = this.select.ticketCatalog.packageD
                        break;
                     case 2:
                        val = 'card'
                        description = this.select.ticketCatalog.cycleD
                        break;
                     default:
                        break;
                  }
                  //console.log(jsData[i]);
                  this.ticketsType.push({
                        ticketCatalog: this.select.ticketCatalog.name,
                        ticketType: val,
                        ticketTypeName: jsData[i].TKT_TYPEcn,
                        title: this.select.ticketCatalog.name,
                        value: val,
                        info:{
                           description:description,
                        }
                        // info: {}
                     })
               }
               //console.log(this.ticketCatalog)
            } else {
               alert(result.Message)
            }

            //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false
            //console.log(e)
            alert(e)
         }
      },
      async getTicketCourseCatalogUpperList(){
         try {
            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               TKT_CAT_ID:this.select.ticketCatalog.id,
               Token:Token
            }
            data = this.BaseModel(data)

            
            var result = JSON.parse(await (await apiTICKET_COURSE_CATALOG_UPPER_LIST(data)).data);
            this.loadSet.show = false

            if (result.Status) {
               var jsData = JSON.parse(result.Data)
               
               this.courseUpper = []
               for(var i in jsData){
                  this.courseUpper.push(jsData[i].CUS_CAT_ID)
               }
            } else {
               alert(result.Message)
            }

               //console.log(result.Message)
         } catch (e) {
               this.loadSet.show = false
               //console.log(e)
               alert(e)
         }
      },
      async getCurriclum(){
         try {
            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               CUS_CAT_UPPER:this.courseUpper,
               sDate:this.dateRange.min.year+"-"+this.dateRange.min.month+"-"+this.dateRange.min.day,
               eDate:this.dateRange.max.year+"-"+this.dateRange.max.month+"-"+this.dateRange.max.day,
               CUS_TYPE:0,
               Token:Token
            }
            data = this.BaseModel(data)

            
            var result = JSON.parse(await (await apiCURRICULUM_LIST(data)).data);
            this.loadSet.show = false

            if (result.Status) {
               
               var jsData = JSON.parse(result.Data)

               for(var i in jsData){
                  this.curriclum[this.curriclumIndex[jsData[i].CT_DATE]].courses.push({
                     id: jsData[i].CT_CUS_ID,
                     starTime: jsData[i].CT_STAR_TIME,
                     endTime: jsData[i].CT_END_TIME,
                     title:jsData[i].CUS_CAT_NAME,
                     name: jsData[i].CUS_NAME,
                     trainer: jsData[i].PPL_NAME,
                     location: jsData[i].SP_NAME,
                     state: jsData[i].status,
                     style:{
                           backgroundColor: jsData[i].CUS_CAT_COLOR
                        },
                     })
               }

               //  console.log(this.curriclumIndex)
               // console.log(this.curriclum)
               //  console.log(jsData)
            
      
            } else {
               alert(result.Message)
            }

               //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false
            //console.log(e)
            alert(e)
         }
      },
      async getTicketList(type){
         try {
            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token)
            //console.log(this.select.ticketCatalog)
            var data = {
               TKT_SPORT:this.select.ticketCatalog.id,
               Token:Token
            }
            data = this.BaseModel(data)

            
            var array = []
            var arrayTemp = null

            if(type == 0){
               let result = JSON.parse(await (await apiTICKET_LIST_TYPE0(data)).data);

               if (result.Status) {
                  let jsData = JSON.parse(result.Data)
                  for(let i in jsData){
                     arrayTemp = {
                        id:jsData[i].TKT_ID,
                        title: jsData[i].TKT_NAME,
                        price: jsData[i].TKT_PRICE,
                        priceOrigin: jsData[i].TKT_PRICE,
                        offer: jsData[i].TKT_IS_OFFER,
                        value: jsData[i].TKT_QUANTITY,
                        ticketCatalog: this.select.ticketCatalog.name,
                        ticketTypeName:'單次',
                        ticketType:'ticket',
                        info: {
                           title : jsData[i].TKT_QUANTITY+"張",
                           validDay:jsData[i].TKT_VALID_DATE,
                           enableDay:jsData[i].TKT_ENABLE_DATE,
                        }
                     }

                     //如果為特價時間
                     if(jsData[i].TKT_IS_OFFER == 1 && (new Date() <= new Date(jsData[i].TKT_OFFER_DATE)))
                     {
                        if(jsData[i].TKT_OFFER_AMOUNT != null)
                           arrayTemp.price = jsData[i].TKT_OFFER_AMOUNT
                     }
                     else if(this.IsNull(this.$session.get('PPL_NID_IDENTITY')) != ""){
                        switch(this.$session.get('PPL_NID_IDENTITY'))
                        {
                           case 1:
                              if(jsData[i].TKT_EXEMPATION_STUDENT != null)
                                 arrayTemp.price = jsData[i].TKT_EXEMPATION_STUDENT
                              break
                           case 2:
                              if(jsData[i].TKT_EXEMPATION_STAFF != null)
                                 arrayTemp.price = jsData[i].TKT_EXEMPATION_STAFF
                              break
                           case 3:
                              if(jsData[i].TKT_EXEMPATION_ALUMNI != null)
                                 arrayTemp.price = jsData[i].TKT_EXEMPATION_ALUMNI
                              break
                           case 4:
                              if(jsData[i].TKT_EXEMPATION_DEPENDENTS != null)
                                 arrayTemp.price = jsData[i].TKT_EXEMPATION_DEPENDENTS
                              break
                        }
                     }

                     array.push(arrayTemp)
                  }
                  this.ticket = array
                  //如果是單次課程更新課程表
                  if(this.select.ticketCatalog.type == 0){
                     this.updateData(0)
                  }
                  //console.log(jsData)

               } else {
                  alert(result.Message)
               }
            }
           else if(type == 1){
               let result = JSON.parse(await (await apiTICKET_LIST_TYPE1(data)).data);

               if (result.Status) {
                  let jsData = JSON.parse(result.Data)

                  
                  for(let i in jsData){
                     //console.log(jsData[i])
                     arrayTemp = {
                        id:jsData[i].TKT_ID,
                        title: jsData[i].TKT_NAME,
                        price: jsData[i].TKT_PRICE,
                        priceOrigin: jsData[i].TKT_PRICE,
                        offer: jsData[i].TKT_IS_OFFER,
                        //value: jsData[i].TKT_QUANTITY,
                        ticketCatalog: this.select.ticketCatalog.name,
                        // ticketTypeName: jsData[i].TKT_TYPEcn,
                        ticketTypeName: '單次套票',
                        ticketType:'ticketPackage',
                        info: {
                           quantity : jsData[i].TKT_QUANTITY,
                           validDay:jsData[i].TKT_VALID_DATE,
                           enableDay:jsData[i].TKT_ENABLE_DATE
                        }
                     }
                     
                     //如果為特價時間
                     if(jsData[i].TKT_IS_OFFER == 1 && (new Date() <= new Date(jsData[i].TKT_OFFER_DATE)))
                     {
                        if(jsData[i].TKT_OFFER_AMOUNT != null)
                           arrayTemp.price = jsData[i].TKT_OFFER_AMOUNT
                     }
                     else if(this.IsNull(this.$session.get('PPL_NID_IDENTITY')) != ""){
                        switch(this.$session.get('PPL_NID_IDENTITY'))
                        {
                           case 1:
                              if(jsData[i].TKT_EXEMPATION_STUDENT != null)
                                 arrayTemp.price = jsData[i].TKT_EXEMPATION_STUDENT
                              break
                           case 2:
                              if(jsData[i].TKT_EXEMPATION_STAFF != null)
                                 arrayTemp.price = jsData[i].TKT_EXEMPATION_STAFF
                              break
                           case 3:
                              if(jsData[i].TKT_EXEMPATION_ALUMNI != null)
                                 arrayTemp.price = jsData[i].TKT_EXEMPATION_ALUMNI
                              break
                           case 4:
                              if(jsData[i].TKT_EXEMPATION_DEPENDENTS != null)
                                 arrayTemp.price = jsData[i].TKT_EXEMPATION_DEPENDENTS
                              break
                        }
                     }

                     array.push(arrayTemp)
                  }
                  this.Package = array
                  //console.log(jsData)

               } else {
                  alert(result.Message)
               }
            }else if(type == 2){
               let result = JSON.parse(await (await apiTICKET_LIST_TYPE2(data)).data);

               if (result.Status) {
                  let jsData = JSON.parse(result.Data)
                  for(let i in jsData){
                     // console.log(jsData[i])

                     arrayTemp = {
                        id:jsData[i].TKT_ID,
                        title: jsData[i].TKT_NAME,
                        price: jsData[i].TKT_PRICE,
                        priceOrigin: jsData[i].TKT_PRICE,
                        offer: jsData[i].TKT_IS_OFFER,
                        ticketCatalog: this.select.ticketCatalog.name,
                        // ticketTypeName: jsData[i].TKT_TYPEcn,
                        ticketTypeName: '會員會期',
                        ticketType:'card',
                        //value: jsData[i].TKT_QUANTITY,
                        info: {
                           //title : jsData[i].TKT_VALID_DATE+"天"
                           // quantity:jsData[i].TKT_QUANTITY,
                           validDay:jsData[i].TKT_VALID_DATE,
                           enableDay:jsData[i].TKT_ENABLE_DATE
                        }
                     }

                     //如果為特價時間
                     if(jsData[i].TKT_IS_OFFER == 1 && (new Date() <= new Date(jsData[i].TKT_OFFER_DATE)))
                     {
                        if(jsData[i].TKT_OFFER_AMOUNT != null)
                           arrayTemp.price = jsData[i].TKT_OFFER_AMOUNT
                     }
                     else if(this.IsNull(this.$session.get('PPL_NID_IDENTITY')) != ""){
                        switch(this.$session.get('PPL_NID_IDENTITY'))
                        {
                           case 1:
                              if(jsData[i].TKT_EXEMPATION_STUDENT != null)
                                 arrayTemp.price = jsData[i].TKT_EXEMPATION_STUDENT
                              break
                           case 2:
                              if(jsData[i].TKT_EXEMPATION_STAFF != null)
                                 arrayTemp.price = jsData[i].TKT_EXEMPATION_STAFF
                              break
                           case 3:
                              if(jsData[i].TKT_EXEMPATION_ALUMNI != null)
                                 arrayTemp.price = jsData[i].TKT_EXEMPATION_ALUMNI
                              break
                           case 4:
                              if(jsData[i].TKT_EXEMPATION_DEPENDENTS != null)
                                 arrayTemp.price = jsData[i].TKT_EXEMPATION_DEPENDENTS
                              break
                        }
                     }

                     array.push(arrayTemp)
                  }
                  this.cycle = array

                  //console.log(jsData)

               } else {
                  alert(result.Message)
               }
            }
            
            this.loadSet.show = false

            

            //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false
            //console.log(e)
            alert(e)
         }
      },
      
      async getMemberProfileCheck(){
         var status = false
         try {
            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               Token:Token
            }
            data = this.BaseModel(data)

            
            var result = JSON.parse(await (await apiMEMBER_PROFILE_CHECK(data)).data);
            this.loadSet.show = false

            if (result.Status) {
               var jsData = JSON.parse(result.Data)
               // console.log(jsData)
               status = jsData.status

            } else {
               alert(result.Message)
            }

            //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false
            //console.log(e)
            alert(e)
         }

         return status
      },
      clearReceipt(){
         this.select.receipt.OD_RECIPT_TITLE = null
         this.select.receipt.OD_UNICODE_NUMBER = null
      },
   },
   components: {
      Curriculum2,
      TicketView,
      Header
   }
}
</script>

<style lang="postcss" scoped>
.option {
   @apply hover:-translate-y-1 duration-300;
}
.fade-select-enter {
   opacity: 0;
}
.fade-select-enter-active {
   transition: all 0.5s ease;
}
.fade-select-enter-to {
}
.fade-select-leave {
}
.fade-select-leave-active {
   transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.fade-select-leave-to {
   /* transform: translateX(10px); */
   opacity: 0;
}

.fade-enter-active {
   transition: all .3s ease;
   transition-delay: 0.5s;

}

.fade-leave-active {
   transition: all;
   /* transition-delay: 0.6s; */
   opacity: 0;
}

.fade-enter {
   transform: translatey(10px);
   opacity: 0;
}

.fade-leave-to {
   transform: translatey(10px);
   opacity: 0;
}

.paymentMethod {
      @apply flex flex-wrap gap-1;
      li {
         @apply bg-secondary-200 text-secondary-800 px-4 py-1 leading-none rounded-lg;
      }
   }


.selected {
   @apply px-4 sm:px-0;
   li {
      @apply border border-secondary-500 px-4 py-2 mb-2 rounded-lg flex justify-between font-bold;
   }
}


.curriculum {
   @apply grid sm:grid-cols-7;
}

.step-control {
   @apply  flex justify-center space-x-4 sticky sm:relative bottom-0 z-40 py-4 bg-secondary-200 sm:bg-transparent bg-opacity-70 -mx-4 sm:mx-0 mt-4 sm:mt-0;
}
</style>