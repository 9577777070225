<template>
    <div class="py-24 bg-secondary-100 min-h-screen">
        <div class="container mx-auto">
            <h1 class="title-01">健身期課</h1>
            <div class="flex flex-wrap items-center justify-center mb-8">
                <ul class="steps steps-vertical lg:steps-horizontal">
                    <li class="step w-48" :class="{ 'step-primary': step >= 0 }">
                        <span>新增訂單</span>
                    </li>
                    <li class="step w-48" :class="{ 'step-primary': step >= 1 }">
                        <span>課程確認前往付款</span>
                    </li>
                    <li class="step w-48" :class="{ 'step-primary': step >= 2 }">
                        <span>完成課程報名</span>
                    </li>
                </ul>
            </div>

            <!-- 步驟0 -->
            <template v-if="step == 0">
                <div class="card border shadow bg-base-100 mt-12 sm:w-1/2 mx-auto">
                    <div class="card-body items-center text-center">
                        <table class="table w-full">
                            <tbody>
                                <tr>
                                    <th>商品明細</th>
                                    <td class="whitespace-pre-line">
                                        <div>名稱：健身期課_{{ item.title2 }}</div>
                                        <div>日期：{{ item.timeDate }}</div>
                                        <div>時段：{{ item.time }}</div>
                                        <div>堂數：{{ item.timeCount }}</div>
                                        <div>教練：{{ item.trainerName }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>課程規範</th>
                                    <td class="whitespace-pre-line">
                                        <div v-html="criterion"></div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>訂單金額</th>
                                    <td class="nt">
                                        NT$<strong class="text-2xl">{{ item.price }}</strong>
                                    </td>
                                </tr>
                                <tr></tr>
                            </tbody>
                        </table>
                        <div class="card-actions"></div>
                    </div>
                </div>
                <div class="card border shadow bg-base-100 sm:w-1/2 mx-auto" style="margin-top: 1rem">
                    <div class="card-body">
                        <h2 class="card-title">收據選項</h2>
                        <div class="form-control w-full">
                            <label class="flex items-center space-x-2 mb-2">
                                <input
                                    v-model="receipt.OD_RECEIPT_TYPE"
                                    :value="0"
                                    type="radio"
                                    name="radio-1"
                                    class="radio"
                                    @click="clearReceipt"
                                />
                                <span>個人收據</span>
                            </label>
                            <label v-if="$session.get('PPL_NID_IDENTITY') == 0" class="flex items-center space-x-2">
                                <input
                                    v-model="receipt.OD_RECEIPT_TYPE"
                                    :value="1"
                                    type="radio"
                                    name="radio-1"
                                    class="radio"
                                /><span>公司收據</span>
                            </label>
                        </div>
                        <template v-if="receipt.OD_RECEIPT_TYPE == 1">
                            <div class="form-control w-full max-w-xs">
                                <label class="label">
                                    <span class="label-text">收據抬頭</span>
                                </label>
                                <input
                                    v-model="receipt.OD_RECIPT_TITLE"
                                    type="text"
                                    placeholder=""
                                    class="input input-bordered w-full max-w-xs"
                                />
                            </div>
                            <div class="form-control w-full max-w-xs">
                                <label class="label">
                                    <span class="label-text">統一編號</span>
                                </label>
                                <input
                                    v-model="receipt.OD_UNICODE_NUMBER"
                                    type="text"
                                    placeholder=""
                                    class="input input-bordered w-full max-w-xs"
                                />
                            </div>
                        </template>
                        <div class="divider"></div>
                        <div class="card-body items-center text-center">
                            <label for="" class="flex">
                                <input v-model="regulations" type="checkbox" class="checkbox mr-2" />
                                <div>
                                    我已詳閱並同意遵守<a
                                        target="_blank"
                                        href="https://drive.google.com/file/d/1s1Tr-prbzPrKdILCy8K8CDGK8xUUiyCr/view?usp=sharing"
                                        >『健身期課課程規定及注意事項』</a
                                    >
                                    相關條款之說明。並同意接受本館之會員規章之一切條款所約束。
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="text-center mt-12">
                    <div class="btn btn-primary" @click="addOrder(item.id)">送出訂單</div>
                </div>
            </template>

            <!-- 步驟ㄧ -->
            <template v-if="step == 1">
                <div class="card border shadow bg-base-100 mt-12 sm:w-1/2 mx-auto">
                    <div class="card-body items-center text-center">
                        <table class="table w-full">
                            <tbody>
                                <tr>
                                    <th>訂單編號</th>
                                    <td>{{ orderDetails.ORDER.OD_ID }}</td>
                                </tr>
                                <tr>
                                    <th>會員明稱</th>
                                    <td>{{ $session.get('NAME') }}</td>
                                </tr>
                                <tr>
                                    <th>商品明細</th>
                                    <td
                                        v-for="(item, index) in orderDetails.ORDER_PRODUCT"
                                        :key="index"
                                        class="whitespace-pre-line"
                                    >
                                        <div v-for="(item2, index2) in item.OP_NAME" :key="index2">{{ item2 }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>課程規範</th>
                                    <td class="whitespace-pre-line">
                                        <div v-html="criterion"></div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>訂單金額</th>
                                    <td class="nt">
                                        NT$<strong class="text-2xl">{{ orderDetails.ORDER.OD_PRICE }}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <th>付款方式</th>
                                    <td>
                                        <ul class="paymentMethod">
                                            <li>
                                                <img
                                                    class="h-4"
                                                    alt="逢甲大學體育館"
                                                    src="@/assets/image/paymentIcon/atm.jpg"
                                                />
                                            </li>
                                            <li>
                                                <img
                                                    class="h-4"
                                                    alt="逢甲大學體育館"
                                                    src="@/assets/image/paymentIcon/LINE_Pay_logo.png"
                                                />
                                            </li>
                                            <li>
                                                <img
                                                    class="h-4"
                                                    alt="逢甲大學體育館"
                                                    src="@/assets/image/paymentIcon/taiwanpaylogo.png"
                                                />
                                            </li>
                                            <li>
                                                <img
                                                    class="h-4"
                                                    alt="逢甲大學體育館"
                                                    src="@/assets/image/paymentIcon/1200px-JKOPAY_logo.svg.png"
                                                />
                                            </li>
                                            <li>
                                                <img
                                                    class="h-4"
                                                    alt="逢甲大學體育館"
                                                    src="@/assets/image/paymentIcon/logo-mark.svg"
                                                />
                                            </li>
                                            <li>
                                                <img
                                                    class="h-4"
                                                    alt="逢甲大學體育館"
                                                    src="@/assets/image/paymentIcon/Alipay_logo.png"
                                                />
                                            </li>
                                            <li>超商代收</li>
                                            <li>信用卡</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="card-actions"></div>
                    </div>
                </div>
                <div class="text-center mt-12">
                    <!-- <a :href="$appDomain+'/FcuPay/FcuPay?OD_ID='+orderDetails.ORDER.OD_ID" > -->
                    <a
                        v-if="
                            orderDetails.ORDER.OD_PAYMENT_STATUS === 0 &&
                            new Date() <= new Date(orderDetails.ORDER.OD_PAYMENT_DEADLINE)
                        "
                        :href="$appDomain + '/FcuPay/FcuPay.aspx?OD_ID=' + orderDetails.ORDER.OD_ID"
                    >
                        <button class="btn btn-primary">付款</button>
                    </a>
                    <span v-else>已過期</span>
                    <!-- <button @click="step = 5" class="btn btn-primary">付款成功就到-步驟5</button> -->
                </div>
            </template>

            <template v-else-if="step == 2">
                <div class="card border shadow bg-base-100 mt-12 sm:w-1/2 mx-auto">
                    <div class="card-body items-center text-center">
                        <h2 class="card-title flex-col">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="fill-green-500"
                                viewBox="0 0 24 24"
                                width="48"
                                height="48"
                            >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path
                                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-.997-6l7.07-7.071-1.414-1.414-5.656 5.657-2.829-2.829-1.414 1.414L11.003 16z"
                                />
                            </svg>
                            付款成功，感謝您的購買。
                        </h2>
                        <!-- <table class="table w-full">
                           <tbody>
                              <tr>
                              <th>訂單編號</th>
                              <td>{{orderDetails.ORDER.OD_ID}}</td>
                           </tr>
                           <tr>
                              <th>會員明稱</th>
                              <td>{{$session.get('NAME')}}</td>
                           </tr>
                           <tr>
                              <th>商品明細</th>
                              <td v-for="(item,index) in orderDetails.ORDER_PRODUCT" :key="index" class=" whitespace-pre-line">
                                 <div v-for="(item2,index2) in item.OP_NAME" :key="index2">{{item2}}</div>
                              </td>
                           </tr>
                           <tr>
                              <th>付款金額</th>
                              <td>NT<strong class="text-2xl text-green-500">${{orderDetails.ORDER.OD_PRICE}}</strong></td>
                           </tr>
                           <tr>
                              <th>付款方式</th>
                              <td>
                                 逢甲pay
                              </td>
                           </tr>
                        </tbody>
                     </table>      -->
                        <p>您可以進行以下操作：</p>
                        <div class="step-control">
                            <button style="margin: 0.5rem" class="btn">繼續購票</button>
                            <router-link style="margin: 0.5rem" to="/member/owns" class="btn">前往我的商品</router-link>
                            <router-link style="margin: 0.5rem" to="/member/purchase" class="btn"
                                >前往訂單記錄</router-link
                            >
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <LoadingPage :loadSet="loadSet"></LoadingPage>
    </div>
</template>
<script>
    import { apiToken, apiGET_COURSE_CRITERION, apiORDER_GET, apiORDER_ADD } from '../../api/API';
    export default {
        data() {
            return {
                step: 0,

                item: null,
                regulations: false,
                receipt: {
                    OD_RECEIPT_TYPE: 0,
                    OD_RECIPT_TITLE: null,
                    OD_UNICODE_NUMBER: null,
                },
                details: {
                    TKT_CAT_ID: null,
                },
                orderDetails: {
                    ORDER: null,
                    ORDER_PRODUCT: null,
                    ORDER_DISCOUNT: null,
                },
                OD_ID: null,
                criterion: null,

                loadSet: { show: false, fullPage: true, type: 'dots', color: 'rgba(0,0,0,0.7)' },
            };
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                if (this.$route.query.TKT_CAT_ID != undefined) {
                    this.details.TKT_CAT_ID = this.$route.query.TKT_CAT_ID;
                    if (this.$route.params.item != undefined && this.$route.params.item != null) {
                        this.item = this.$route.params.item;
                        // console.log(this.item)
                        await this.getCoureseCriterion();
                        this.step = 0;
                        //   this.orderDetails = this.$route.params.orderDetails
                    } else if (this.IsNull(this.$route.query.OD_ID) != '') {
                        this.OD_ID = this.$route.query.OD_ID;
                        await this.getOrder();
                        this.step = 2;
                    } else {
                        this.$router.push({ name: 'Course', query: { TKT_CAT_ID: this.details.TKT_CAT_ID } });
                    }
                }
            },
            async getCoureseCriterion() {
                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        CUS_ID: this.item.id,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiGET_COURSE_CRITERION(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        this.criterion = jsData.criterion;
                        // console.log(jsData)
                    } else {
                        console.log(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    console.log(e);
                }
            },
            async getOrder() {
                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        OD_ID: this.OD_ID,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiORDER_GET(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        for (var i in jsData.ORDER_PRODUCT) {
                            let name = jsData.ORDER_PRODUCT[i].OP_NAME.split('§');
                            jsData.ORDER_PRODUCT[i].OP_NAME = name;
                        }
                        this.orderDetails = jsData;

                        // console.log(jsData)
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },

            async addOrder(id) {
                if (confirm('送出訂單並完成繳費後，才報名成功') == false) {
                    return;
                }

                var msg = '';
                if (id == null) msg += '請先選購商品\n';

                if (this.receipt.OD_RECEIPT_TYPE == 1) {
                    if (this.IsNull(this.receipt.OD_RECIPT_TITLE) == '') {
                        msg += '請填寫收據抬頭\n';
                    }

                    if (this.IsNull(this.receipt.OD_UNICODE_NUMBER) == '') {
                        msg += '請填寫統一編號\n';
                    }
                }

                if (!this.regulations) msg += '請勾選同意『健身期課課程規定及注意事項』\n';

                if (msg == '') {
                    try {
                        this.loadSet.show = true;
                        var Token = await (await apiToken()).data;
                        var COURSE = [];

                        COURSE.push({ ID: id, COUNT: 1 });

                        var data = {
                            TICKET: [],
                            TICKET_ONE_COURSE: [],
                            COURSE: COURSE,
                            SPACE_RENT: [],
                            OD_RECEIPT_TYPE: this.receipt.OD_RECEIPT_TYPE,
                            OD_RECIPT_TITLE: this.receipt.OD_RECIPT_TITLE,
                            OD_UNICODE_NUMBER: this.receipt.OD_UNICODE_NUMBER,
                            DIS_ID: null,
                            Token: Token,
                        };
                        data = this.BaseModel(data);

                        //console.log(data)

                        var result = JSON.parse(await (await apiORDER_ADD(data)).data);
                        // console.log(result)

                        this.loadSet.show = false;

                        if (result.Status) {
                            var jsData = JSON.parse(result.Data);
                            for (var i in jsData.ORDER_PRODUCT) {
                                let name = jsData.ORDER_PRODUCT[i].OP_NAME.split('§');
                                jsData.ORDER_PRODUCT[i].OP_NAME = name;
                            }
                            this.orderDetails = jsData;
                            if (this.orderDetails.ORDER.OD_PAYMENT_STATUS == 1) {
                                this.step = 2;
                            } else {
                                this.step = 1;
                            }
                            // alert("訂單已送出")
                        } else {
                            //console.log(result.Message)
                            if (result.Data != '') {
                                alert(result.Data);
                            } else {
                                alert(result.Message);
                            }
                        }

                        //console.log(result.Message)
                    } catch (e) {
                        this.loadSet.show = false;
                        //console.log(e,"66666666666")
                    }
                } else {
                    alert(msg);
                }
            },

            clearReceipt() {
                this.receipt.OD_RECIPT_TITLE = null;
                this.receipt.OD_UNICODE_NUMBER = null;
            },
        },
    };
</script>
