<template>
   <div class="container mx-auto">
      <Header title="瑜珈有氧-課程預約"></Header>
      <template v-if="details.AE_CATALOG == 0">
         <div class="flex space-x-4 items-center mb-4">
            <div class="flex space-x-1 items-center justify-cente">
               <button class="btn btn-sm btn-outline btn-secondary">今天</button>
               <button class="btn btn-sm btn-outline btn-secondary" @click="updateData(-7)">
                  <svg class=" fill-secondary-500 hover:fill-secondary-200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z"/></svg>
               </button>
               <button class="btn btn-sm btn-outline btn-secondary" @click="updateData(7)">
                  <svg class=" fill-secondary-500 hover:fill-secondary-200"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"/></svg>
               </button>
            </div>
            <div class="text-center text-secondary-500">{{dateRange.min.year}} 年 {{dateRange.min.month}} 月 {{dateRange.min.day}} 日 <span class=" hidden sm:inline">星期{{dateRange.min.week}}</span>  ~  <span class=" hidden sm:inline">{{dateRange.max.year}}</span> 年 {{dateRange.max.month}} 月 {{dateRange.max.day}} 日 <span class=" hidden sm:inline">星期{{dateRange.max.week}}</span></div>
         </div>
         <div class="curriculum mb-8">
            <div v-for="(item, index) in curriclum" class="day" :key="index">
               <div class="p-2 flex justify-center text-white space-x-2 text-sm" :class="[ nowDate == item.month+'/'+item.day ? 'bg-primary-500 text-black' :'bg-secondary-500 ' ]">
                  <span class="week">{{item.month}}月{{item.day}}日</span>
                  <strong class="inline-block mr-2">星期{{item.week}}</strong>
               </div>
               <div class="pt-2 pb-10 sm:px-2">
                  <template v-for="(course, i) in item.courses">
                     <label for="my-modal" v-if="course.state == 0" :key="i" @click="openAccessReser(index,i)">
                        <Curriculum2 :info="course"></Curriculum2>
                     </label>
                     <label v-if="course.state ==1"  :key="i" >
                        <Curriculum2 :info="course"></Curriculum2>
                     </label>
                  </template>
               </div>
            </div>
         </div>
         <div class="text-center">
            <router-link class="btn" to="/member/owns">取消</router-link>
         </div>
      </template>
      <template v-else-if="details.AE_CATALOG==1">
         <section class="flex justify-center">
            <div class="card w-full lg:w-1/3">
               <div class="form-group">
                  <label for="">日期</label>
                  <input v-model="spaceData.date" type="date" class="!w-full" required>
               </div>
               <div class="form-group">
                  <label for="">時間</label>
                  <input v-model="spaceData.time" type="time" class="!w-full" required>
               </div>
               <template v-if="details.AE_TYPE == 0">
                  <button class="btn primary" @click="reserveSpace">確認</button>
               </template>
               <template v-else-if="details.AE_TYPE == 1">
                  <button class="btn primary" @click="reserveSpace2">確認</button>
               </template>
               <template v-else-if="details.AE_TYPE == 2">
                  <button class="btn primary" @click="reserveSpace3">確認</button>
               </template>
            </div>
         </section>
      </template>
      
      <!-- Put this part before </body> tag -->
      <input type="checkbox" id="my-modal" class="modal-toggle" />
      <div class="modal">
         <div class="modal-box relative">
         <label for="my-modal" class="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
         <h3 class="font-bold text-lg">課程預約</h3>
            <ul class="info mb-5">
               <li><span>課程名稱</span>{{reservationModal.data.name2}}</li>
               <li><span>授課教練</span>{{reservationModal.data.trainer}}</li>
               <li><span>課程日期</span>{{reservationModal.data.day}} {{reservationModal.data.starTime}}~{{reservationModal.data.endTime}}</li>
               <li><span>課程地點</span>{{reservationModal.data.location}}</li>
            </ul>
            <div class="mb-5">
               <h6 class="font-bold mb-1">注意事項</h6>
               <ol class="list-disc mb-3">
                  <li class="ml-6">無報到者視同使用票券。</li>
                  <li class="ml-6">館方不定期至課堂上查票，一經發現票券身分別與持有人不符，一律補差額至單次購票價格。</li>
                  <li class="ml-6">敬請注意課程日期及時間，票券過期即為無效票，不得要求更換其他活動、場次或要求退費。</li>
                  <li class="ml-6">瑜珈墊屬個人用品，請自行攜帶。</li>
               </ol>
            </div>
            
            <div class="flex justify-center space-x-2">
               <template v-if="details.AE_TYPE == 0">
                  <button class="btn primary" @click="reserveCourse">預約</button>
                  <!-- <button class="btn link" @click="reservationModal.show=!reservationModal.show">取消</button> -->
               </template>
               <template v-else-if="details.AE_TYPE == 1">
                  <button class="btn primary" @click="reserveCourse2">預約</button>
                  <!-- <button class="btn link" @click="reservationModal.show=!reservationModal.show">取消</button> -->
               </template>
               <template v-else-if="details.AE_TYPE == 2">
                  <button class="btn primary" @click="reserveCourse3">預約</button>
                  <!-- <button class="btn link" @click="reservationModal.show=!reservationModal.show">取消</button> -->
               </template>
               
            </div>
      </div>
      </div>



      <LoadingPage :loadSet="loadSet"></LoadingPage>
   </div>
</template>
<script>
import {
   apiToken,
   apiCURRICULUM_LIST,
   apiTICKET_COURSE_CATALOG_UPPER_LIST,
   apiTICKET_CATALOG_GET,
   apiACCESS_TICKET_CATALOG_GET,
   apiACCESS_GET,
   apiACCESS_RESERVATION_TYPE0_CATALOG0,
   apiACCESS_RESERVATION_TYPE1_CATALOG0,
   apiACCESS_RESERVATION_TYPE2_CATALOG0,
   apiACCESS_RESERVATION_TYPE0_CATALOG1,
   apiACCESS_RESERVATION_TYPE1_CATALOG1,
   apiACCESS_RESERVATION_TYPE2_CATALOG1,
} from '../../api/API'
// import CustomizeSection from '@/components/CustomizeSection.vue'
import Curriculum2 from '@/components/CourseTemplate2.vue'
import moment from "moment";
import Header from '@/components/MemberHeader.vue'
export default {
   components: {
      Header,
      Curriculum2,
      // CustomizeSection,
   },
   created() {
      switch (this.$route.query.type) {
         case 'aerobic':
               this.title = '瑜珈有氧預約'
               this.bookingType = 1
            break; 
         case 'weight':
               this.title = '健身房預約'
               this.bookingType = 2
            break; 
         case 'swimming':
               this.title = '游泳池預約'
               this.bookingType = 2
            break; 
         // case 'trainer':
         //       this.title = '私人教練預約'
         //       this.bookingType = 1
         //    break; 
         default:
            break;
      }
      this.nowDate = moment(this.nowDate).format('MM/DD')
   },
   data() {
      return {
         nowDate:new Date().getTime(),
         title: '預約1',
         bookingType: 1,
         list: true,
         calendar: false,
         coursesTest: [{
               mm: "3",
               dd: "11",
               week: "星期五",
               courses: [{

                     starTime: "12:10",
                     endTime: "12:10",
                     name: "哈達瑜珈哈達瑜珈",
                     trainer: "蔡郁青",
                     location: "第二舞蹈教室（2F）",
                     state: 1,
                  },
                  {

                     starTime: "12:10",
                     endTime: "12:10",
                     name: "哈達瑜珈",
                     trainer: "蔡郁青",
                     location: "第二舞蹈教室（2F）",
                     state: 1,

                  },
                  {

                     starTime: "12:10",
                     endTime: "12:10",
                     name: "哈達瑜珈",
                     trainer: "蔡郁青",
                     location: "第二舞蹈教室（2F）",
                     state: 0,

                  }
               ]
            },
            {
               mm: "3",
               dd: "12",
               week: "星期六",
               courses: []
            },
            {
               mm: "3",
               dd: "13",
               week: "星期日",
               courses: []
            },
            {
               mm: "3",
               dd: "14",
               week: "星期一",
               courses: [{

                     starTime: "12:10",
                     endTime: "12:10",
                     name: "哈達瑜珈",
                     trainer: "蔡郁青",
                     location: "第二舞蹈教室（2F）",
                     state: 1,
                  },
                  {

                     starTime: "12:10",
                     endTime: "12:10",
                     name: "哈達瑜珈",
                     trainer: "蔡郁青",
                     location: "第二舞蹈教室（2F）",
                     state: 1,
                  },
                  {

                     starTime: "12:10",
                     endTime: "12:10",
                     name: "哈達瑜珈",
                     trainer: "蔡郁青",
                     location: "第二舞蹈教室（2F）",
                     state: 1,
                  }
               ]
            },
            {
               mm: "3",
               dd: "15",
               week: "星期二",
               courses: [{

                     starTime: "12:10",
                     endTime: "12:10",
                     name: "哈達瑜珈",
                     trainer: "蔡郁青",
                     location: "第二舞蹈教室（2F）",
                     state: 1,
                  },
                  {

                     starTime: "12:10",
                     endTime: "12:10",
                     name: "哈達瑜珈",
                     trainer: "蔡郁青",
                     location: "第二舞蹈教室（2F）",
                     state: 1,
                  }
               ]
            },
            {
               mm: "3",
               dd: "16",
               week: "星期三",
               courses: [{

                     starTime: "12:10",
                     endTime: "12:10",
                     name: "哈達瑜珈",
                     trainer: "蔡郁青",
                     location: "第二舞蹈教室（2F）",
                     state: 1,
                  },
                  {

                     starTime: "12:10",
                     endTime: "12:10",
                     name: "哈達瑜珈",
                     trainer: "蔡郁青",
                     location: "第二舞蹈教室（2F）",
                     state: 1,
                  }
               ]
            },
            {
               mm: "3",
               dd: "17",
               week: "星期四",
               courses: [{

                     starTime: "12:10",
                     endTime: "12:10",
                     name: "哈達瑜珈",
                     trainer: "蔡郁青",
                     location: "第二舞蹈教室（2F）",
                     state: 1,
                  },
                  {

                     starTime: "12:10",
                     endTime: "12:10",
                     name: "哈達瑜珈",
                     trainer: "蔡郁青",
                     location: "第二舞蹈教室（2F）",
                     state: 1,
                  },
                  {

                     starTime: "12:10",
                     endTime: "12:10",
                     name: "哈達瑜珈",
                     trainer: "蔡郁青",
                     location: "第二舞蹈教室（2F）",
                     state: 1,
                  }
               ]
            }
         ],


         
         AE_ID:null,
         details:{
            TKT_CAT_ID:null,
            AE_CATALOG:null,
         },
         upper:[],
         
         dateRange:{
               day:0,
               min:{
                  week:null,
                  day:null,
                  month:null,
                  year:null
               },
               max:{
                  week:null,
                  day:null,
                  month:null,
                  year:null
               },
         },

         curriclum:[],
         curriclumIndex:{},

         reservationModal:{
            show:false,
            data:{}
         },

         spaceData:{
            date:null,
            time:null,
         },

         loadSet:{show: false,fullPage: true,type:'dots',color:'rgba(0,0,0,0.7)'}

      }
   },
   mounted(){
      this.init()
   },
   methods: {
      async init(){
         this.loadSet.show = false
         this.changeDate(0)
         if (this.$route.query.AE_ID != undefined) {
            this.AE_ID = this.$route.query.AE_ID

            await this.getAccess()
            await this.getAccessTicketCatalog()

            //課程票卷
            if(this.details.AE_CATALOG == 0){
               await this.getTicketCatalog()
               await this.getTicketCourseCatalogUpperList()
               await this.getCurriclum()
            }
            
         }else{
               this.$router.push('/member/owns')
         }
      },
      async updateData(day){
            this.changeDate(day)
            await this.getTicketCourseCatalogUpperList()
            await this.getCurriclum()
      },
      changeDate(day){
         this.dateRange.day += day

         if(this.dateRange.day < 0)
            this.dateRange.day += Math.abs(day)

         //將today改成每週的禮拜三
         //var today = new Date(new Date().setDate(new Date().getDate() + (this.dateRange.day - 1)))
         var nowWeek = new Date().getDay()
         var today = new Date(new Date().setDate(new Date().getDate() + (this.dateRange.day - 1 - nowWeek)))
         //console.log(today,"me")

         this.curriclumIndex = []
         this.curriclum = []
         for(var i = 0;i < 7;i++){
            new Date(today.setDate(today.getDate() + 1))
            let year = today.getFullYear()
            let month = this.TransformTime2String(today.getMonth() + 1)
            let day = this.TransformTime2String(today.getDate())
            let week = this.getWeek(today.getDay())

            if(i == 0){
               this.dateRange.min.year = year
               this.dateRange.min.month = month
               this.dateRange.min.day = day
               this.dateRange.min.week = week
            }else if(i == 6){
               this.dateRange.max.year = year
               this.dateRange.max.month = month
               this.dateRange.max.day = day
               this.dateRange.max.week = week
            }

            this.curriclumIndex[year+"-"+month+"-"+day] = i
            this.curriclum.push({
               year:year,
               month:month,
               day:day,
               week:week,
               courses:[]
            })
         }
         
      },
      getWeek(week){
         var result = ""
         switch(week){
               case 0:
                  result = "日"
                  break
               case 1:
                  result = "一"
                  break
               case 2:
                  result = "二"
                  break
               case 3:
                  result = "三"
                  break
               case 4:
                  result = "四"
                  break
               case 5:
                  result = "五"
                  break
               case 6:
                  result = "六"
                  break
         }
         return result
      },
      async getAccess(){
         try {
               this.loadSet.show = true
               var Token = await (await apiToken()).data
               //console.log(Token);
               var data = {
                  AE_ID:this.AE_ID,
                  Token:Token
               }
               data = this.BaseModel(data)

               
               var result = JSON.parse(await (await apiACCESS_GET(data)).data);
               this.loadSet.show = false

               if (result.Status) {
                  var jsData = JSON.parse(result.Data)

                  if(jsData.AE_FORM_TYPE == 3)
                     this.$router.push('/member/owns')


                  if(jsData.AE_CATALOG == 2 || jsData.AE_CATALOG == 3){
                     this.$router.push('/member/owns')
                     alert("此門禁票不能進行預約")
                  }else{
                     if(jsData.AE_TYPE == 0 && jsData.AE_QUANTITY <= jsData.AE_USE_QUANTITY){
                        this.$router.push('/member/owns')
                        alert("此門禁票已達使用上限")
                     }
                     else if(jsData.AE_TYPE == 1 && jsData.AE_QUANTITY <= (jsData.AE_USE_QUANTITY+jsData.AE_SPLIT_QUANTITY)){
                        this.$router.push('/member/owns')
                        alert("此門禁票已達使用上限")
                     }
                  }

                  if(new Date() > new Date(jsData.AE_EXPIRATION_DATE) && this.IsNull(jsData.AE_EXPIRATION_DATE) != ''){
                     this.$router.push('/member/owns')
                     alert("此門禁票已逾期")
                  }

                  this.details = jsData
                  //this.details = jsData
                  //console.log(jsData)
         
               } else {
                  this.$router.push('/member/owns')
                  alert(result.Message)
               }

               //console.log(result.Message)
         } catch (e) {
               this.loadSet.show = false
               //console.log(e)
               alert(e)
         }
      },
      async getAccessTicketCatalog(){
         try {
               this.loadSet.show = true
               var Token = await (await apiToken()).data
               //console.log(Token);
               var data = {
                  AE_ID:this.AE_ID,
                  Token:Token
               }
               data = this.BaseModel(data)

               
               var result = JSON.parse(await (await apiACCESS_TICKET_CATALOG_GET(data)).data);
               this.loadSet.show = false

               if (result.Status) {
                  var jsData = JSON.parse(result.Data)

                  this.details.TKT_CAT_ID = jsData.TKT_CAT_ID
                  // console.log(jsData,789)
         
               } else {
                  this.$router.push('/member/owns')
                  alert(result.Message)
               }

               //console.log(result.Message)
         } catch (e) {
               this.loadSet.show = false
               //console.log(e)
               alert(e)
         }
      },
      async getTicketCatalog(){
         try {
               this.loadSet.show = true
               var Token = await (await apiToken()).data
               //console.log(Token);
               var data = {
                  TKT_CAT_ID:this.details.TKT_CAT_ID,
                  Token:Token
               }
               data = this.BaseModel(data)

               
               var result = JSON.parse(await (await apiTICKET_CATALOG_GET(data)).data);
               this.loadSet.show = false

               if (result.Status) {
                  var jsData = JSON.parse(result.Data)
                  // this.details = jsData
                  console.log(jsData)

         
               } else {
                  alert(result.Message)
               }

               //console.log(result.Message)
         } catch (e) {
               this.loadSet.show = false
               //console.log(e)
               alert(e)
         }
      },
      async getTicketCourseCatalogUpperList(){
         try {
            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               TKT_CAT_ID:this.details.TKT_CAT_ID,
               Token:Token
            }
            data = this.BaseModel(data)

            
            var result = JSON.parse(await (await apiTICKET_COURSE_CATALOG_UPPER_LIST(data)).data);
            this.loadSet.show = false

            if (result.Status) {
               var jsData = JSON.parse(result.Data)
               this.upper = []
               for(var i in jsData){
                  this.upper.push(jsData[i].CUS_CAT_ID)
               }
            } else {
               alert(result.Message)
            }

               //console.log(result.Message)
         } catch (e) {
               this.loadSet.show = false
               //console.log(e)
               alert(e)
         }
      },
      async getCurriclum(){
         try {
            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               CUS_CAT_UPPER:this.upper,
               sDate:this.dateRange.min.year+"-"+this.dateRange.min.month+"-"+this.dateRange.min.day,
               eDate:this.dateRange.max.year+"-"+this.dateRange.max.month+"-"+this.dateRange.max.day,
               CUS_TYPE:0,
               Token:Token
            }
            data = this.BaseModel(data)

            
            var result = JSON.parse(await (await apiCURRICULUM_LIST(data)).data);
            this.loadSet.show = false

            if (result.Status) {
               
               var jsData = JSON.parse(result.Data)

               
               for(var i in jsData){
                  this.curriclum[this.curriclumIndex[jsData[i].CT_DATE]].courses.push({
                     id: jsData[i].CT_CUS_ID,
                     starTime: jsData[i].CT_STAR_TIME,
                     endTime: jsData[i].CT_END_TIME,
                     title:jsData[i].CUS_CAT_NAME,
                     name2: jsData[i].CUS_CAT_NAME,
                     name: jsData[i].CUS_NAME,
                     trainer: jsData[i].PPL_NAME,
                     location: jsData[i].SP_NAME,
                     state: jsData[i].status,
                     style:{
                           backgroundColor: jsData[i].CUS_CAT_COLOR
                        },
                     })
               }

               //  console.log(this.curriclumIndex)
               //  console.log(this.curriclum)
               //  console.log(jsData)
            
      
            } else {
               alert(result.Message)
            }

               //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false
            //console.log(e)
            alert(e)
         }
      },
      openAccessReser(curriclumIndex,courseIndex){
         this.reservationModal.data = this.curriclum[curriclumIndex].courses[courseIndex]
         this.reservationModal.data.day = this.curriclum[curriclumIndex].year + "-" + this.curriclum[curriclumIndex].month + "-" + this.curriclum[curriclumIndex].day
         // this.reservationModal.show =true
      },
      async reserveCourse(){
         if (confirm("確定要預約？") == false) {
            return;
         }

         if(new Date() > new Date(this.reservationModal.data.day+"T"+this.reservationModal.data.endTime))
         {
            alert("日期已過")
            return;
         }



         try {
            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               AE_ID:this.AE_ID,
               CUS_ID:this.reservationModal.data.id,
               Token:Token
            }
            data = this.BaseModel(data)

            
            var result = JSON.parse(await (await apiACCESS_RESERVATION_TYPE0_CATALOG0(data)).data);
            this.loadSet.show = false

            if (result.Status) {
               this.$router.push('/member/owns')
               //var jsData = JSON.parse(result.Data)
            
               //  console.log(this.curriclumIndex)
               //  console.log(this.curriclum)
               //console.log(jsData)
            } else {
               if(result.Data != "")
               {
                  alert(result.Data)
               }else{
                  alert(result.Message)
               }
            }

               //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false
            //console.log(e)
            alert(e)
         }
      },
      //多張票卷預約
      async reserveCourse2(){
         if (confirm("確定要預約？") == false) {
            return;
         }

         if(new Date() > new Date(this.reservationModal.data.day+"T"+this.reservationModal.data.endTime))
         {
            alert("日期已過")
            return;
         }



         try {
            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               AE_ID:this.AE_ID,
               CUS_ID:this.reservationModal.data.id,
               Token:Token
            }
            data = this.BaseModel(data)

            
            var result = JSON.parse(await (await apiACCESS_RESERVATION_TYPE1_CATALOG0(data)).data);
            this.loadSet.show = false

            if (result.Status) {
               this.$router.push({ name: 'package', query: { AE_ID: this.AE_ID }})
               //var jsData = JSON.parse(result.Data)
            
               //  console.log(this.curriclumIndex)
               //  console.log(this.curriclum)
               //console.log(jsData)
            } else {
               if(result.Data != "")
               {
                  alert(result.Data)
               }else{
                  alert(result.Message)
               }
            }

               //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false
            //console.log(e)
            alert(e)
         }
      },
      //會員會期預約
      async reserveCourse3(){
         if (confirm("確定要預約？") == false) {
            return;
         }

         if(new Date() > new Date(this.reservationModal.data.day+"T"+this.reservationModal.data.endTime))
         {
            alert("日期已過")
            return;
         }



         try {
            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               AE_ID:this.AE_ID,
               CUS_ID:this.reservationModal.data.id,
               Token:Token
            }
            data = this.BaseModel(data)

            
            var result = JSON.parse(await (await apiACCESS_RESERVATION_TYPE2_CATALOG0(data)).data);
            this.loadSet.show = false

            if (result.Status) {
               this.$router.push({ name: 'cycle', query: { AE_ID: this.AE_ID }})
               //var jsData = JSON.parse(result.Data)
            
               //  console.log(this.curriclumIndex)
               //  console.log(this.curriclum)
               //console.log(jsData)
            } else {
               if(result.Data != "")
               {
                  alert(result.Data)
               }else{
                  alert(result.Message)
               }
            }

               //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false
            //console.log(e)
            alert(e)
         }
      },

      //舊寫法
      async reserveSpace(){
         if (confirm("確定要預約？") == false) {
            return;
         }

         var day = new Date(this.spaceData.date)

         if(new Date() > day)
         {
            alert("日期已過")
            return;
         }

         


         var msg = ""

         if(this.IsNull(this.spaceData.date) == '')
            msg += "請填寫日期\n"

         if(this.IsNull(this.spaceData.time) == '')
            msg += "請填寫時間\n"

         
         if(msg == "")
         {
            //平日
            var openTime = "07:00"
            var closeTime = "22:00"

            let week = day.getDay()
            if(week == 6){
               openTime = "08:00"
               closeTime = "22:00"
            }
            else if (week == 0){
               openTime = "08:00"
               closeTime = "17:00"
            }

            var sDate = new Date(this.spaceData.date + "T" + this.spaceData.time)
            var openDate = new Date(this.spaceData.date + "T" + openTime)
            var closeDate = new Date(this.spaceData.date + "T" + closeTime)
            
            if(sDate < openDate){
               alert("尚未到開放時間")
               return;
            }else if(sDate > closeDate){
               alert("開放時間已過")
               return;
            }
            
            try {
               this.loadSet.show = true
               var Token = await (await apiToken()).data
               //console.log(Token);
               var data = {
                  AE_ID:this.AE_ID,
                  S_DATE:this.spaceData.date,
                  S_TIME:this.spaceData.time,
                  Token:Token
               }
               data = this.BaseModel(data)

               
               var result = JSON.parse(await (await apiACCESS_RESERVATION_TYPE0_CATALOG1(data)).data);
               this.loadSet.show = false

               if (result.Status) {
                  this.$router.push('/member/owns')
                  //var jsData = JSON.parse(result.Data)
               
                  //  console.log(this.curriclumIndex)
                  //  console.log(this.curriclum)
                  //console.log(jsData)
               } else {
                  if(result.Data != "")
                  {
                     alert(result.Data)
                  }else{
                     alert(result.Message)
                  }
               }

                  //console.log(result.Message)
            } catch (e) {
               this.loadSet.show = false
               //console.log(e)
               alert(e)
            }
         }
         else{
            alert(msg)
         }
         
      },
      //多張票卷預約 舊寫法
      async reserveSpace2(){
         if (confirm("確定要預約？") == false) {
            return;
         }

         var day = new Date(this.spaceData.date)

         if(new Date() > day)
         {
            alert("日期已過")
            return;
         }

         


         var msg = ""

         if(this.IsNull(this.spaceData.date) == '')
            msg += "請填寫日期\n"

         if(this.IsNull(this.spaceData.time) == '')
            msg += "請填寫時間\n"

         
         if(msg == "")
         {
            //平日
            var openTime = "07:00"
            var closeTime = "22:00"

            let week = day.getDay()
            if(week == 6){
               openTime = "08:00"
               closeTime = "22:00"
            }
            else if (week == 0){
               openTime = "08:00"
               closeTime = "17:00"
            }

            var sDate = new Date(this.spaceData.date + "T" + this.spaceData.time)
            var openDate = new Date(this.spaceData.date + "T" + openTime)
            var closeDate = new Date(this.spaceData.date + "T" + closeTime)
            
            if(sDate < openDate){
               alert("尚未到開放時間")
               return;
            }else if(sDate > closeDate){
               alert("開放時間已過")
               return;
            }
            
            try {
               this.loadSet.show = true
               var Token = await (await apiToken()).data
               //console.log(Token);
               var data = {
                  AE_ID:this.AE_ID,
                  S_DATE:this.spaceData.date,
                  S_TIME:this.spaceData.time,
                  Token:Token
               }
               data = this.BaseModel(data)

               
               var result = JSON.parse(await (await apiACCESS_RESERVATION_TYPE1_CATALOG1(data)).data);
               this.loadSet.show = false

               if (result.Status) {
                  this.$router.push({ name: 'package', query: { AE_ID: this.AE_ID }})
                  //var jsData = JSON.parse(result.Data)
               
                  //  console.log(this.curriclumIndex)
                  //  console.log(this.curriclum)
                  //console.log(jsData)
               } else {
                  if(result.Data != "")
                  {
                     alert(result.Data)
                  }else{
                     alert(result.Message)
                  }
               }

                  //console.log(result.Message)
            } catch (e) {
               this.loadSet.show = false
               //console.log(e)
               alert(e)
            }
         }
         else{
            alert(msg)
         }
         
      },
      //會員會期卷預約 舊寫法
      async reserveSpace3(){
         if (confirm("確定要預約？") == false) {
            return;
         }

         var day = new Date(this.spaceData.date)

         if(new Date() > day)
         {
            alert("日期已過")
            return;
         }

         


         var msg = ""

         if(this.IsNull(this.spaceData.date) == '')
            msg += "請填寫日期\n"

         if(this.IsNull(this.spaceData.time) == '')
            msg += "請填寫時間\n"

         
         if(msg == "")
         {
            //平日
            var openTime = "07:00"
            var closeTime = "22:00"

            let week = day.getDay()
            if(week == 6){
               openTime = "08:00"
               closeTime = "22:00"
            }
            else if (week == 0){
               openTime = "08:00"
               closeTime = "17:00"
            }

            var sDate = new Date(this.spaceData.date + "T" + this.spaceData.time)
            var openDate = new Date(this.spaceData.date + "T" + openTime)
            var closeDate = new Date(this.spaceData.date + "T" + closeTime)
            
            if(sDate < openDate){
               alert("尚未到開放時間")
               return;
            }else if(sDate > closeDate){
               alert("開放時間已過")
               return;
            }
            
            try {
               this.loadSet.show = true
               var Token = await (await apiToken()).data
               //console.log(Token);
               var data = {
                  AE_ID:this.AE_ID,
                  S_DATE:this.spaceData.date,
                  S_TIME:this.spaceData.time,
                  Token:Token
               }
               data = this.BaseModel(data)

               
               var result = JSON.parse(await (await apiACCESS_RESERVATION_TYPE2_CATALOG1(data)).data);
               this.loadSet.show = false

               if (result.Status) {
                  this.$router.push({ name: 'cycle', query: { AE_ID: this.AE_ID }})
                  //var jsData = JSON.parse(result.Data)
               
                  //  console.log(this.curriclumIndex)
                  //  console.log(this.curriclum)
                  //console.log(jsData)
               } else {
                  if(result.Data != "")
                  {
                     alert(result.Data)
                  }else{
                     alert(result.Message)
                  }
               }

                  //console.log(result.Message)
            } catch (e) {
               this.loadSet.show = false
               //console.log(e)
               alert(e)
            }
         }
         else{
            alert(msg)
         }
         
      },

   },

}
</script>


<style lang="postcss" scoped>
.catalogs {
   @apply sm:flex justify-center gap-4 mb-8 hidden;

   a {
      @apply border-2 border-secondary-400 rounded-full px-6 py-1 hover:bg-primary-500 hover:border-primary-600 cursor-pointer hover:text-secondary-900;
      &.is-active {
         @apply bg-primary-500 border-primary-600 text-secondary-900;
      }
   }
}
   .test {
      >div {
         .title {
            @apply text-2xl
         }
         .price {
            span {
               @apply text-3xl;
            }
         }
         @apply rounded-md bg-white shadow-lg p-8;
      }
   }
   .sportList {
      @apply relative w-full flex gap-6 snap-x snap-mandatory overflow-x-auto pb-14;
      &::-webkit-scrollbar {
         display: none;
      }
      .first-item, .last-item {
         width: calc(50vw - 184px);
      }
      .item {
         @apply snap-center shrink-0 w-80 border shadow-lg p-8 rounded-xl;
         
         .title {
            @apply text-2xl text-center mb-4;
         }
      }
   }
   .sport {
      .item {
         .photo {
            @apply border aspect-video rounded mb-1 ;
         }
         &:nth-child(4n+1){
            margin-top:0rem;
         }
         &:nth-child(4n+2){
            margin-top:4rem;
         }
         &:nth-child(4n+3){
            margin-top:8rem;
         }
         &:nth-child(4n+4){
            margin-top:12rem;
         }
      }
   }
.curriculum {
   @apply grid sm:grid-cols-7 ;
}
.info {
				dt {
					@apply text-gray-500 text-sm;
				}
				dd {
					@apply ml-2 mb-2;
				}
				li {
					@apply py-2 border-b;
					&:last-child {
						@apply border-b-0
					}
					span {
						@apply text-gray-400 pr-3 text-xs ;
					}
				}
			}
</style>

