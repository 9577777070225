<template>
    <div>
        <header class="pt-24 sm:pt-36 pb-10 sm:pb-24">
            <h1 class="title-01">場館介紹</h1>
        </header>
        <CustomizeSection tranglert tranglelt tranglelb>
            <div class="main-description container mx-auto">
                <h2 class="title">多功能運動場館</h2>
                <div class="describe">
                    <div class="text">
                        <p>
                            以「綠建築」為核心設計理念之多功能現代化體育館
                            場館規劃為地下二層、地上六層，擁有時尚健身房、專業飛輪教室
                            室內多功能球場、200公尺室內跑道及國際標準戶外游泳池等多樣設施。
                        </p>
                    </div>
                </div>
            </div>
            <div class="main-img bg-01" v-rellax="{ speed: 1 }"></div>
        </CustomizeSection>

        <CustomizeSection tranglert tranglelb>
            <div class="sm:hidden mb-5 flex sm:justify-end">
                <div class="dropdown dropdown-hover dropdown-end">
                    <label tabindex="0" class="btn"
                        >{{ tabName }}
                        <svg
                            class="fill-white"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                        >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                        </svg>
                    </label>
                    <ul tabindex="0" class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                        <li v-for="(item, index) in spaceCatalog" :key="index">
                            <button @click="changeData(index, item.name)">{{ item.name }}</button>
                        </li>
                    </ul>
                </div>
            </div>
            <nav class="w-full sm:w-fit bg-white mx-auto rounded-full mb-12 hidden sm:block" data-aos="fade-down">
                <div class="btn-group">
                    <a
                        v-for="(item, index) in spaceCatalog"
                        :key="index"
                        class="btn text-white"
                        :class="[item.status ? 'btn-active' : '']"
                        @click="changeData(index, item.name)"
                        >{{ item.name }}
                    </a>
                </div>
            </nav>
            <div class="building">
                <template v-for="(item, i) in dataItemsTest">
                    <div v-if="item.space.length != 0" :key="i" class="floor">
                        <h3 class="title title-decorate" data-aos="fade-down">{{ item.floor }}</h3>
                        <div class="rooms">
                            <div v-for="(space, ii) in item.space" :key="ii" data-aos="fade-left">
                                <label class="room" for="my-modal" @click="showDetail(space)">
                                    <div class="card bg-base-100 shadow-xl image-full">
                                        <figure class="relative">
                                            <img v-if="space.PHOTO_URL" :src="space.PHOTO_URL" alt="" class="w-full" />
                                            <img v-else src="@/assets/image/no-image169.png" />
                                        </figure>
                                        <div class="card-body p-6">
                                            <span class="badge badge-primary badge-lg">{{ space.SC_NAME }}</span>
                                            <h2 class="card-title text-white">{{ space.SP_NAME }}</h2>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="divider"></div>
            </div>
            <div class="flex flex-col sm:flex-row justify-center items-center space-y-2 sm:space-y-0 sm:space-x-2">
                <!-- <template v-if="$Test">
                  <router-link class="btn btn-primary w-full sm:w-auto" to="/reservationSpace">場地預約</router-link>
               </template> -->
                <a
                    class="btn btn-lg w-full sm:w-auto text-white"
                    href="https://drive.google.com/file/d/1B5DOY8Yl7WZnswR5Yt9P_t3_sUaC3GQG/view"
                    style="margin-top: 50px; z-index: 30"
                    target=" _black"
                    >逢甲大學體育館場地租借收費標準</a
                >
            </div>
        </CustomizeSection>
        <input type="checkbox" id="my-modal" class="modal-toggle" />
        <label v-if="spaceDetail" for="my-modal" class="modal cursor-pointer">
            <label class="modal-box w-11/12 max-w-5xl relative" for="">
                <label for="my-modal" class="btn btn-sm btn-circle absolute right-2 top-2">
                    <svg xmlns="http://www.w3.org/2000/svg" class="fill-white h-5" viewBox="0 0 24 24">
                        <path
                            d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12L20 6.91Z"
                        />
                    </svg>
                </label>
                <div class="flex flex-col sm:flex-row items-center justify-start sm:space-x-6">
                    <div class="w-full sm:w-2/3">
                        <figure
                            class="aspect-[4/3] flex items-center overflow-hidden mb-4 sm:mb-0 rounded-xl bg-gray-100 border"
                        >
                            <img v-if="spaceDetail.PHOTO_URL" :src="spaceDetail.PHOTO_URL" alt="" class="w-auto" />
                            <img v-else src="@/assets/image/no-image169.png" />
                        </figure>
                    </div>
                    <div class="w-full sm:w-1/3">
                        <span class="badge badge-primary mb-3">{{ spaceDetail.SC_NAME }}</span>
                        <h3 class="text-lg font-bold">{{ spaceDetail.SP_NAME }}</h3>
                        <p v-html="spaceDetail.SP_DISCRIPTION"></p>
                    </div>
                </div>
            </label>
        </label>
        <LoadingPage :loadSet="loadSet"></LoadingPage>
    </div>
</template>
<script>
    import { apiToken, apiSPACE_CATALOG_LIST, apiSPACE_CATALOG_SPACE_LIST } from '../../api/API';
    import CustomizeSection from '@/components/CustomizeSection.vue';
    import Splide from '@splidejs/splide';
    import '@splidejs/splide/dist/css/splide.min.css';
    export default {
        head: {
            title: '場館介紹',
            meta: [{ name: 'description', content: '場館介紹' }],
        },
        data() {
            return {
                tabName: '所有空間',
                showSpaceDetail: false,
                spaceDetail: null,
                spaceCatalog: [],
                dataItemsAll: [],
                dataItems: [],
                dataItemsTest: [
                    {
                        floor: '1F',
                        space: [],
                    },
                    {
                        floor: '2F',
                        space: [],
                    },
                    {
                        floor: '3F',
                        space: [],
                    },
                    {
                        floor: '4F',
                        space: [],
                    },
                    {
                        floor: '5F',
                        space: [],
                    },
                    {
                        floor: '6F',
                        space: [],
                    },
                    {
                        floor: 'B1F',
                        space: [],
                    },
                    {
                        floor: 'B2F',
                        space: [],
                    },
                ],

                loadSet: {
                    show: false,
                    fullPage: true,
                    type: 'dots',
                    color: 'rgba(0,0,0,0.7)',
                },
            };
        },
        mounted() {
            this.init();
            new Splide('.splide', {
                type: 'fade',
                rewind: true,
                autoplay: true,
                pagination: false,
                perPage: 1,
                rewindSpeed: 5000,
                speed: 2500,
                breakpoints: {
                    640: {
                        perPage: 1,
                    },
                },
                focus: 'center',
            }).mount();
        },
        methods: {
            showDetail(item) {
                console.log(item);
                this.spaceDetail = item;
                // this.showSpaceDetail = true;
                // this.spaceDetail.push(item);
            },
            async init() {
                await this.getSpaceCatalogList();
            },
            changeData(index, tabName) {
                //console.log(AC_ID)
                this.tabName = tabName;
                if (index == 0) {
                    this.dataItems = this.dataItemsAll;
                    this.setFloor();
                } else {
                    this.dataItems = [];

                    for (let i = 0; i < this.spaceCatalog.length; i++) {
                        this.spaceCatalog[i].status = false;
                    }

                    this.spaceCatalog[index].status = true;

                    for (let i = 0; i < this.dataItemsAll.length; i++) {
                        if (this.spaceCatalog[index].id == this.dataItemsAll[i].SP_TYPE)
                            this.dataItems.push(this.dataItemsAll[i]);
                    }
                    this.setFloor();
                }
            },
            setFloor() {
                for (let i = 0; i < this.dataItemsTest.length; i++) {
                    this.dataItemsTest[i].space = [];
                }
                for (let i = 0; i < this.dataItems.length; i++) {
                    if (this.dataItems[i].SP_FLOOR == 1) {
                        this.dataItemsTest[0].space.push(this.dataItems[i]);
                    }
                    if (this.dataItems[i].SP_FLOOR == 2) {
                        this.dataItemsTest[1].space.push(this.dataItems[i]);
                    }
                    if (this.dataItems[i].SP_FLOOR == 3) {
                        this.dataItemsTest[2].space.push(this.dataItems[i]);
                    }
                    if (this.dataItems[i].SP_FLOOR == 4) {
                        this.dataItemsTest[3].space.push(this.dataItems[i]);
                    }
                    if (this.dataItems[i].SP_FLOOR == 5) {
                        this.dataItemsTest[4].space.push(this.dataItems[i]);
                    }
                    if (this.dataItems[i].SP_FLOOR == 6) {
                        this.dataItemsTest[5].space.push(this.dataItems[i]);
                    }
                    if (this.dataItems[i].SP_FLOOR == -1) {
                        this.dataItemsTest[6].space.push(this.dataItems[i]);
                    }
                    if (this.dataItems[i].SP_FLOOR == -2) {
                        this.dataItemsTest[7].space.push(this.dataItems[i]);
                    }
                }
            },
            async getSpaceCatalogList() {
                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiSPACE_CATALOG_LIST(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        //console.log(jsData)
                        this.spaceCatalog = [
                            {
                                id: null,
                                name: '全部',
                                status: true,
                            },
                        ];
                        if (jsData.length > 0) {
                            for (let i in jsData) {
                                this.spaceCatalog.push({
                                    id: jsData[i].SC_ID,
                                    name: jsData[i].SC_NAME,
                                    status: false,
                                });
                            }
                        }
                        this.getSpaceCatalogSpaceList();
                        //console.log(this.spaceCatalog)
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            async getSpaceCatalogSpaceList() {
                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var SC_ID = [];
                    for (var i = 1; i < this.spaceCatalog.length; i++) {
                        SC_ID.push(this.spaceCatalog[i].id);
                    }

                    data.SC_ID = SC_ID;

                    var result = JSON.parse(await (await apiSPACE_CATALOG_SPACE_LIST(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        this.dataItemsAll = jsData;
                        this.dataItems = jsData;
                        this.setFloor();
                        //console.log(jsData)
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
        },
        components: {
            CustomizeSection,
        },
    };
</script>

<style lang="postcss" scoped>
    .bg-01 {
        background-image: url('@/assets/image/page-header-building.jpg');
    }

    .building {
        @apply space-y-8  sm:p-8;

        .floor {
            @apply flex flex-col sm:flex-row sm:space-x-8;

            .title {
                @apply sm:text-8xl text-secondary-500 font-light sm:w-48 pt-4 text-left;
            }

            .rooms {
                @apply flex-1 grid sm:grid-cols-3 gap-8;

                .room {
                    @apply mb-8 rounded-2xl overflow-hidden cursor-pointer;

                    &:hover {
                        figure img {
                            -webkit-transform: scale(1.2);
                            transform: scale(1.2);
                        }
                    }

                    figure {
                        @apply aspect-video w-full relative overflow-hidden;
                        margin: 0;
                        padding: 0;
                        background: #fff;
                    }

                    figure img {
                        -webkit-transform: scale(1.5);
                        transform: scale(1);
                        -webkit-transition: 0.3s ease-in-out;
                        transition: 0.3s ease-in-out;
                    }

                    .card {
                        &::before {
                            @apply bg-gradient-to-b from-secondary-500 bg-transparent;
                        }
                    }
                }
            }
        }
    }
</style>
