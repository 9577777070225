import { render, staticRenderFns } from "./OwnedProducts.vue?vue&type=template&id=6456684a&scoped=true&"
import script from "./OwnedProducts.vue?vue&type=script&lang=js&"
export * from "./OwnedProducts.vue?vue&type=script&lang=js&"
import style0 from "./OwnedProducts.vue?vue&type=style&index=0&id=6456684a&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6456684a",
  null
  
)

export default component.exports