import axios from 'axios'

//HostURL = "http://localhost:8497";
function HostURL() {
  if (process.env.NODE_ENV == 'development') {
    return '/api'// 開發環境使用
  } else {
    // return '/API'// 開發環境使用
    // return "../API";// 正機使用
    // return "../";// 正機使用
    return "https://sportscenter.fcu.edu.tw/API";// 正機使用
  }
}


const Default = axios.create({
  baseURL: HostURL() + "/API/Default"
})


export const apiToken = () => Default.post('/Token')

//驗證碼
export const apiGetImageCode = (data) => Default.post('/GetImageCode', data)

//會員登陸
export const apiMEMBER_Login = (data) => Default.post('/Front/MEMBER_Login', data)

//忘記密碼
export const apiLastPass = (data) => Default.post('/Front/MemberLastPass', data)

//檢查修改密碼token
export const apiMemberCheckPassToken = (data) => Default.post('/Front/MemberCheckPassToken', data)

//忘記密碼_修改密碼
export const apiMemberUpdateLastPass = (data) => Default.post('/Front/MemberUpdateLastPass', data)

//查詢縣市
export const apiSelectCity = (data) => Default.post('/SelectCity', data)

//查詢鄉鎮
export const apiSelectTown = (data) => Default.post('/SelectTown', data)


//-------------------------遊客------------------------------------------------------
//票卷類別
export const apiTICKET_CATALOG_GET = (data) => Default.post('/TICKET_CATALOG_GET', data)

//票卷類別下的票卷類型清單
export const apiTICKET_CATALOG_TICKET_TYPE_LIST = (data) => Default.post('/Front/TICKET_CATALOG_TICKET_TYPE_LIST', data)

//票卷類別清單
export const apiTICKET_CATALOG_LIST = (data) => Default.post('/TICKET_CATALOG_LIST', data)

//票卷類別清單 -有啟用
export const apiTICKET_CATALOG_LIST2 = (data) => Default.post('/Front/TICKET_CATALOG_LIST', data)

//購物車票卷清單
export const apiSHOPPING_TICKET_LIST = (data) => Default.post('/Front/SHOPPING_TICKET_LIST', data)

//購物車課程清單
export const apiSHOPPING_COURSE_LIST = (data) => Default.post('/Front/SHOPPING_COURSE_LIST', data)

//票卷清單-單次
export const apiTICKET_LIST_TYPE0 = (data) => Default.post('/Front/TICKET_LIST_TYPE0', data)

//票卷清單-套票
export const apiTICKET_LIST_TYPE1 = (data) => Default.post('/Front/TICKET_LIST_TYPE1', data)

//票卷清單-會員會期
export const apiTICKET_LIST_TYPE2 = (data) => Default.post('/Front/TICKET_LIST_TYPE2', data)

//票卷清單
export const apiTICKET_LIST2 = (data) => Default.post('/Front/TICKET_LIST2', data)

//票卷下課程類別清單
export const apiTICKET_COURSE_CATALOG_UPPER_LIST = (data) => Default.post('/Front/TICKET_COURSE_CATALOG_UPPER_LIST', data)

//票卷類別清單
export const apiTICKET_COURSE_CATALOG_LIST = (data) => Default.post('/Front/TICKET_COURSE_CATALOG_LIST', data)

//課程類別頂層清單
export const apiCOURSE_CATALOG_UPPER_LIST = (data) => Default.post('/COURSE_CATALOG_UPPER_LIST', data)

//課程清單
export const apiCOURSE_LIST2 = (data) => Default.post('/Front/COURSE_LIST2', data)

//課程清單上層類別
export const apiCOURSE_TYPE_CATALOG_UPPER = (data) => Default.post('/Front/COURSE_TYPE_CATALOG_UPPER', data)


//課程圖片-前台有上架
export const apiCOURSE_IMAGE_GET = (data) => Default.post('/Front/COURSE_IMAGE_GET', data)

//課程時間資訊
export const apiCOURSE_TIME_INFO = (data) => Default.post('/Front/COURSE_TIME_INFO', data)

//場地類別清單
export const apiSPACE_CATALOG_LIST = (data) => Default.post('/SPACE_CATALOG_LIST', data)

//場域類別下場域清單
export const apiSPACE_CATALOG_SPACE_LIST = (data) => Default.post('/Front/SPACE_CATALOG_SPACE_LIST', data)

//瑜珈有氧 課表清單
export const apiCURRICULUM_LIST = (data) => Default.post('/Front/CURRICULUM_LIST', data)

//主圖清單
export const apiMAIN_PHOTO_LIST = (data) => Default.post('/MAIN_PHOTO_LIST', data)

//文章類別清單
export const apiARTICLE_CATALOG_LIST = (data) => Default.post('/ARTICLE_CATALOG_LIST', data)

//文章清單
export const apiARTICLE_LIST2 = (data) => Default.post('/Front/ARTICLE_LIST2', data)

//文章
export const apiARTICLE_GET = (data) => Default.post('/ARTICLE_GET', data)

//場地清單
export const apiSPACE_LIST = (data) => Default.post('/SPACE_LIST', data)

//場域租借清單
export const apiSPACE_RENT_LIST = (data) => Default.post('/Front/SPACE_RENT_LIST', data)

//場地租借清單 -根據使用用途
export const apiSPACE_RENT_LIST2 = (data) => Default.post('/Front/SPACE_RENT_LIST2', data)

//場域用途清單 - 根據使用用途 只取最上層
export const apiSPACE_RENT_LIST2_UPPER = (data) => Default.post('/Front/SPACE_RENT_LIST2_UPPER', data)

//場地租借清單 -檢查範圍
export const apiCHECK_SPACE_RENT_TIME = (data) => Default.post('/Front/CHECK_SPACE_RENT_TIME', data)

//場域用途清單 - 場域用途清單-有被選為場租的用途才會顯示
export const apiSPACE_USE_LIST = (data) => Default.post('/SPACE_USE_LIST2', data)

//購物車判斷場地租借
export const apiSHOPPING_SPACE_RENT_LIST = (data) => Default.post('/Front/SHOPPING_SPACE_RENT_LIST', data)

//場域用途下空間清單
export const apiSPACE_RENT_SPACE_GROUP_LIST = (data) => Default.post('/Front/SPACE_RENT_SPACE_GROUP_LIST', data)

//折扣碼
export const apiDISCOUNT_CODE_GET = (data) => Default.post('/Front/DISCOUNT_CODE_GET', data)

//報名人數
export const apiCOURSE_APPLY = (data) => Default.post('/Front/COURSE_APPLY', data)

//課程是否報名過
export const apiIS_COURSE = (data) => Default.post('/Front/IS_COURSE', data)

//取課程規範
export const apiGET_COURSE_CRITERION = (data) => Default.post('/Front/GET_COURSE_CRITERION', data)


//場地租借時間
export const apSPACE_RENT_LIST_DATE = (data) => Default.post('/Front/SPACE_RENT_LIST_DATE', data)

//是否被租借
export const apiIS_SPACE_RENT = (data) => Default.post('/Front/IS_SPACE_RENT', data)

//教練清單-已啟用
export const apiTRAINER_LIST = (data) => Default.post('/Front/TRAINER_LIST', data)

//會員註冊
export const apiMEMBER_ADD = (data) => Default.post('/Front/MEMBER_ADD', data)


//----------會員---------------------------------------------------------

//會員指定
export const apiMEMBER_GET = (data) => Default.post('/Front/MEMBER_GET', data)

//會員個人資料
export const apiMEMBER_PROFILE = (data) => Default.post('/Front/MEMBER_PROFILE', data)

//會員個人資料-檢查是否完整
export const apiMEMBER_PROFILE_CHECK = (data) => Default.post('/Front/MEMBER_PROFILE_CHECK', data)

//驗證逢甲權限
export const apiCheckFcuAuth = (data) => Default.post('/Front/CheckFcuAuth', data)

//會員資料修改
export const apiMEMBER_PROFILE_UPDATE = (data) => Default.post('/Front/MEMBER_PROFILE_UPDATE', data)

//會員資料修改-NID
export const apiMEMBER_NID_UPDATE = (data) => Default.post('/Front/MEMBER_NID_UPDATE', data)

//會員密碼修改
export const apiMEMBER_UPDATE_PASSWORD = (data) => Default.post('/Front/MEMBER_UPDATE_PASSWORD', data)

//會員等級
export const apiMEMBER_GRADE_GET = (data) => Default.post('/MEMBER_GRADE_GET', data)

//新增訂單
export const apiORDER_ADD = (data) => Default.post('/Front/ORDER_ADD', data)

//新增訂單-重啟門禁票
export const apiORDER_OVERDUE_ADD = (data) => Default.post('/Front/ORDER_OVERDUE_ADD', data)

//訂單清單
export const apiORDER_LIST = (data) => Default.post('/Front/ORDER_LIST', data)

//訂單
export const apiORDER_GET = (data) => Default.post('/Front/ORDER_GET', data)

//訂單-取得分票瑪
export const apiORDER_PRODUCT_GET_SPLIT_CODE = (data) => Default.post('/Front/ORDER_PRODUCT_GET_SPLIT_CODE', data)

//訂單
export const apiORDER_PAYMENT_STATUS2 = (data) => Default.post('/Front/ORDER_PAYMENT_STATUS2', data)

//取得歷史紀錄數
export const apiACCESS_IS_REUND = (data) => Default.post('/Front/ACCESS_IS_REUND', data)

//申請訂單商品退款
export const apiORDER_PRODUCT_REFUNDT = (data) => Default.post('/Front/ORDER_PRODUCT_REFUND', data)

//產品下產生的門禁票
export const apiPRODUCT_ACCESS_GET = (data) => Default.post('/Front/PRODUCT_ACCESS_GET', data)

//門禁票
export const apiACCESS_GET = (data) => Default.post('/Front/ACCESS_GET', data)

//場地租借下分票碼
export const apiACCESS_SPLIT_CODE = (data) => Default.post('/Front/ACCESS_SPLIT_CODE', data)

//門禁票清單
export const apiACCESS_LIST = (data) => Default.post('/Front/ACCESS_LIST', data)

//門禁票清單 -沒有子項
export const apiACCESS_LIST_NO_TYPE3 = (data) => Default.post('/Front/ACCESS_LIST_NO_TYPE3', data)

//門禁票清單-子 產製
export const apiACCESS_MAKE_CHIDREN_LIST = (data) => Default.post('/Front/ACCESS_MAKE_CHIDREN_LIST', data)

//門禁票清單-子 分票
export const apiACCESS_SPLIT_CHIDREN_LIST = (data) => Default.post('/Front/ACCESS_SPLIT_CHIDREN_LIST', data)

//門禁票的票卷類別
export const apiACCESS_TICKET_CATALOG_GET = (data) => Default.post('/Front/ACCESS_TICKET_CATALOG_GET', data)

//門禁票的票卷-課程 單張預約
export const apiACCESS_RESERVATION_TYPE0_CATALOG0 = (data) => Default.post('/Front/ACCESS_RESERVATION_TYPE0_CATALOG0', data)

//門禁票的票卷-課程 多張預約
export const apiACCESS_RESERVATION_TYPE1_CATALOG0 = (data) => Default.post('/Front/ACCESS_RESERVATION_TYPE1_CATALOG0', data)

//門禁票的票卷-課程 會員會期
export const apiACCESS_RESERVATION_TYPE2_CATALOG0 = (data) => Default.post('/Front/ACCESS_RESERVATION_TYPE2_CATALOG0', data)

//門禁票的票卷-場地 單張預約
export const apiACCESS_RESERVATION_TYPE0_CATALOG1 = (data) => Default.post('/Front/ACCESS_RESERVATION_TYPE0_CATALOG1', data)

//門禁票的票卷-場地 多張預約
export const apiACCESS_RESERVATION_TYPE1_CATALOG1 = (data) => Default.post('/Front/ACCESS_RESERVATION_TYPE1_CATALOG1', data)

//門禁票的票卷-場地 會員會期
export const apiACCESS_RESERVATION_TYPE2_CATALOG1 = (data) => Default.post('/Front/ACCESS_RESERVATION_TYPE2_CATALOG1', data)

//分票碼
export const apiSPLIT_CODE_GET = (data) => Default.post('/Front/SPLIT_CODE_GET', data)

//分票碼清單-特定門禁票下
export const apiACESS_SPLIT_CODE_LIST = (data) => Default.post('/Front/ACESS_SPLIT_CODE_LIST', data)

//分票碼清單-沒有場地租借
export const apiSPLIT_CODE_LIST = (data) => Default.post('/Front/SPLIT_CODE_LIST', data)

//分票碼清單-只有場地租借
export const apiSPLIT_CODE_LIST2 = (data) => Default.post('/Front/SPLIT_CODE_LIST2', data)

//門禁票分票-新增
export const apiACCESS_SPLIT_ADD = (data) => Default.post('/Front/ACCESS_SPLIT_ADD', data)

//門禁票分票-更新
export const apiACCESS_SPLIT_UPDATE = (data) => Default.post('/Front/ACCESS_SPLIT_UPDATE', data)

//門禁票分票-取消
export const apiACCESS_SPLIT_CANCEL = (data) => Default.post('/Front/ACCESS_SPLIT_CANCEL', data)

//取票 -套票
export const apiSPLIT_CODE_CHECKIN = (data) => Default.post('/Front/SPLIT_CODE_CHECKIN', data)

//門禁票的票卷-可分票
export const apiACCESS_LIST_SPLIT = (data) => Default.post('/Front/ACCESS_LIST_SPLIT', data)

//新增門禁票QRCODE
export const apiACCESS_QRCODE_ADD = (data) => Default.post('/Front/ACCESS_QRCODE_ADD', data)

//空間使用時間清單
export const apiSPACE_RENT_USED_LIST = (data) => Default.post('/SPACE_RENT_USED_LIST', data)

//場地所有上層(包括自己)
export const apiSPACE_UPPER_LIST = (data) => Default.post('/Front/SPACE_UPPER_LIST', data)

//檢查閉館時間
export const apiCHECK_CLOSED_DATE = (data) => Default.post('/Front/CHECK_CLOSED_DATE', data)
