<template>
    <div class="stack relative w-full sm:w-[420px]" style="place-items: unset; align-items: unset">
        <!-- <div class="whitespace-pre">{{ticketDetails}}</div> -->
        <div class="cardWrap" :class="[ticketDetails.ticketType, { '': ticketDetails.ticketType == 'ticketPackage' }]">
            <div class="cardLeft">
                <div class="header">
                    {{ ticketDetails.ticketCatalog
                    }}<template v-if="IsNull(ticketDetails.ticketTypeName) != ''"
                        >・{{ ticketDetails.ticketTypeName }}</template
                    >
                    <!-- <template v-if="ticketDetails.sportType != 'cycle-aerobic'">・{{ticketDetails.ticketTypeName}}</template> -->
                </div>
                <div v-if="ticketDetails.info" class="info">
                    <!-- 單次 -->
                    <ul v-if="ticketDetails.ticketType == 'ticket'" class="text-sm">
                        <!-- <li v-if="ticketDetails.info.validDay"><span>使用天數</span>{{ticketDetails.info.validDay}} 天</li> -->
                        <li v-if="ticketDetails.info.enableDay">
                            <span>啟用天數</span>{{ ticketDetails.info.enableDay }}天
                        </li>
                        <template v-if="ticketDetails.catalog == 0">
                            <template v-if="ticketDetails.status == 1 || ticketDetails.status == 3">
                                <li><span>有效期限</span>{{ ticketDetails.deadline }}</li>
                            </template>
                            <template v-if="ticketDetails.status == 2 || ticketDetails.status == 4">
                                <li><span>課程名稱</span> {{ ticketDetails.info.title }}</li>
                                <li><span>課程日期</span>{{ ticketDetails.info.date }}</li>
                                <li><span>課程時段</span>{{ ticketDetails.info.time }}</li>
                                <li><span>上課地點</span>{{ ticketDetails.info.location }}</li>
                            </template>
                        </template>
                        <template v-else-if="ticketDetails.catalog == 3">
                            <!-- <li><span>租借場地</span>{{ticketDetails.info.location}}</li> -->
                            <li><span>租借場地</span>{{ ticketDetails.title }}</li>
                            <li><span>租借日期</span>{{ ticketDetails.info.date }}</li>
                            <li><span>租借時段</span>{{ ticketDetails.info.time }}</li>
                        </template>
                        <template v-else>
                            <li v-if="ticketDetails.info.location">
                                <span>使用空間</span>{{ ticketDetails.info.location }}
                            </li>
                            <li v-if="ticketDetails.deadline">
                                <span>
                                    <template v-if="ticketDetails.status == 1">啟用期限</template>
                                    <template v-else>有效期限</template>
                                </span>
                                {{ ticketDetails.deadline }}
                            </li>
                        </template>
                        <template v-if="ticketDetails.info.description">
                            <dd class="whitespace-pre">{{ ticketDetails.info.description }}</dd>
                        </template>
                    </ul>
                    <!-- 套票 -->
                    <ul v-else-if="ticketDetails.ticketType == 'ticketPackage'" class="text-sm">
                        <li v-if="ticketDetails.info.quantity != null">
                            <span>票券數量</span>{{ ticketDetails.info.quantity }} {{ ticketDetails.value }} 張
                            <strong v-if="ticketDetails.info.isSplit" class="!inline-block text-sm text-green-500">
                                / 可分票</strong
                            >
                        </li>
                        <li v-if="ticketDetails.status != 1 && ticketDetails.info.quantityUse != null">
                            <span>已用數量</span>{{ ticketDetails.info.quantityUse }} 張
                        </li>
                        <li v-if="ticketDetails.status != 1 && ticketDetails.info.quantitySplit != null">
                            <span>分出數量</span>{{ ticketDetails.info.quantitySplit }} 張
                        </li>
                        <li v-if="ticketDetails.deadline"><span>有效期限</span>{{ ticketDetails.deadline }}</li>
                        <li v-if="ticketDetails.info.validDay">
                            <span>使用天數</span>{{ ticketDetails.info.validDay }} 天
                        </li>
                        <li v-if="ticketDetails.info.enableDay">
                            <span>啟用天數</span>{{ ticketDetails.info.enableDay }}天
                        </li>
                        <template v-if="ticketDetails.info.description">
                            <dd class="whitespace-pre">{{ ticketDetails.info.description }}</dd>
                        </template>
                    </ul>
                    <!-- 會員會期 -->
                    <ul v-else-if="ticketDetails.ticketType == 'card'" class="text-sm">
                        <template v-if="ticketDetails.sportType == 'cycle-aerobic'">
                            <li><span>課程名稱</span>{{ ticketDetails.info.title }}</li>
                            <li><span>課程日期</span>{{ ticketDetails.info.date }}</li>
                            <li><span>課程時間</span>{{ ticketDetails.info.time }}</li>
                            <li><span>課程堂數</span>{{ ticketDetails.info.quantityUse }} 堂</li>
                        </template>
                        <template v-else>
                            <li v-if="ticketDetails.info.validDay">
                                <span>使用天數</span>{{ ticketDetails.info.validDay }} 天
                            </li>
                            <li v-if="ticketDetails.info.enableDay">
                                <span>啟用天數</span>{{ ticketDetails.info.enableDay }}天
                            </li>
                            <li v-if="ticketDetails.deadline">
                                <span>
                                    <template v-if="ticketDetails.status == 1">啟用期限</template>
                                    <template v-else>有效期限</template>
                                </span>
                                {{ ticketDetails.deadline }}
                            </li>
                            <template v-if="ticketDetails.info.description">
                                <dd class="whitespace-pre">{{ ticketDetails.info.description }}</dd>
                            </template>
                        </template>
                    </ul>
                </div>
                <div v-if="ticketDetails.price != null" class="px-6 nt">
                    <template v-if="ticketDetails.priceOrigin != ticketDetails.price">
                        <!-- 有其他身份價格 -->
                        <!-- <span class="text-gray-500 line-through	"> NT$ <strong >{{ticketDetails.priceOrigin}}</strong></span><br /> -->

                        NT$
                        <strong class="text-2xl">
                            <template v-if="$session.get('MG_DISCOUNT') < 1 && IsNull($session.get('MG_NAME')) != ''">
                                {{ Math.round(ticketDetails.price * $session.get('MG_DISCOUNT')) }}
                            </template>
                            <template v-else>
                                <div class="inline-flex items-center gap-2">
                                    {{ ticketDetails.price }}
                                    <!-- <span class="badge badge-sm">{{$session.get('PPL_NID_IDENTITYcn')}}</span>  -->
                                    <template v-if="ticketDetails.offer">
                                        <span class="badge badge-accent badge-xs">特價</span>
                                    </template>
                                    <template v-else>
                                        <span class="badge badge-secondary badge-xs">{{
                                            $session.get('PPL_NID_IDENTITYcn')
                                        }}</span>
                                    </template>
                                </div>
                            </template>
                        </strong>

                        <div v-if="$session.get('MG_DISCOUNT') < 1 && IsNull($session.get('MG_NAME')) != ''">
                            <small class="text-gray-400">
                                NT${{ ticketDetails.price }}
                                <template v-if="ticketDetails.offer">
                                    <span class="badge badge-accent badge-xs">特價</span>
                                </template>
                                <template v-else>
                                    <span class="badge badge-secondary badge-xs">{{
                                        $session.get('PPL_NID_IDENTITYcn')
                                    }}</span>
                                </template>
                                x{{ $session.get('MG_DISCOUNT') }}
                                <span class="badge badge-primary badge-xs">{{ $session.get('MG_NAME') }}</span>
                            </small>
                        </div>
                    </template>

                    <template v-else>
                        <!-- 沒輸入其他身份價格 -->
                        NT$
                        <div class="inline-flex items-center gap-2">
                            <strong class="text-2xl">
                                <template
                                    v-if="$session.get('MG_DISCOUNT') < 1 && IsNull($session.get('MG_NAME')) != ''"
                                >
                                    {{ Math.round(ticketDetails.price * $session.get('MG_DISCOUNT')) }}
                                </template>
                                <template v-else>
                                    {{ ticketDetails.price }}
                                </template>
                            </strong>
                        </div>
                        <div v-if="$session.get('MG_DISCOUNT') < 1 && IsNull($session.get('MG_NAME')) != ''">
                            <small class="text-gray-400">
                                NT${{ ticketDetails.price }}
                                <template v-if="$session.get('PPL_NID_IDENTITY') != 0">
                                    <span class="badge badge-secondary badge-xs">{{
                                        $session.get('PPL_NID_IDENTITYcn')
                                    }}</span>
                                </template>
                                x {{ $session.get('MG_DISCOUNT') }}
                                <span class="badge badge-primary badge-xs">{{ $session.get('MG_NAME') }}</span></small
                            >
                        </div>
                    </template>
                </div>
                <slot name="cardLeft"></slot>
            </div>
            <div
                class="cardRight w-auto"
                :class="{ 'opacity-50': ticketDetails.status == 4 && ticketDetails.ticketType == 'ticket' }"
            >
                <div class="header">
                    <template v-if="ticketDetails.sportType == 'aerobic'"></template>
                    <template v-else-if="ticketDetails.sportType == 'cycle-aerobic'"></template>
                    <template v-else-if="ticketDetails.sportType == 'trainer'"></template>
                    <template v-else-if="ticketDetails.sportType == 'weight'"></template>
                    <template v-else-if="ticketDetails.sportType == 'swimming'"></template>
                    <template v-else></template>
                </div>
                <div class="flex flex-col items-center space-y-1 pt-2">
                    <slot></slot>
                </div>
            </div>
        </div>
        <template v-if="ticketDetails.ticketType == 'ticketPackage'">
            <div class="fake-cardWrap"></div>
            <div class="fake-cardWrap"></div>
            <div class="ribbon ribbon-bottom-right"><span class="-rotate-45">單次套票</span></div>
        </template>
    </div>
</template>
<script>
    export default {
        props: {
            ticketDetails: {
                type: Object,
            },
        },
    };
</script>
<style lang="scss" scoped>
    @media screen and (max-width: 991px) {
        .fake-cardWrap {
            max-height: 200px;
        }
    }
</style>
<style lang="postcss" scoped>
    .fake-cardWrap {
        @apply bg-white border border-secondary-400 flex w-full rounded-2xl relative overflow-hidden transition ease-in-out delay-150;
        aspect-ratio: 1.618 / 1;
    }
    .cardWrap {
        @apply flex rounded-2xl transition ease-in-out delay-150 flex-row w-full;
        .cardLeft {
            @apply border-l border-t border-b border-secondary-400 flex-1 bg-white rounded-l-2xl;
            .header {
                @apply bg-secondary-500 px-6 text-xl text-white h-12 flex items-center rounded-tl-xl justify-between;
            }
            .info {
                @apply px-6 py-3;
                dt {
                    @apply text-gray-500 text-sm;
                }
                dd {
                    @apply ml-2 mb-2;
                }
                li {
                    @apply py-2 border-b flex;
                    &:last-child {
                        @apply border-b-0;
                    }
                    span {
                        @apply text-gray-400 pr-3 text-xs whitespace-nowrap;
                    }
                }
            }
        }

        .cardRight {
            @apply border-r border-t border-b border-secondary-400 relative bg-white rounded-r-2xl w-20;
            .header {
                @apply bg-secondary-500 px-4 text-xl text-white h-12 flex items-center rounded-tr-xl;
            }
        }
        &.ticket {
            .cardLeft {
                @apply border-l border-t border-b border-secondary-400  border-r-2  relative;
                border-right: 2px dotted #aaa !important ;
                .header {
                    @apply bg-secondary-500;
                }
                &:before,
                &:after {
                    @apply w-6 h-6 -right-3 absolute bg-secondary-100 rounded-full z-10;
                    content: '';
                }

                &:before {
                    @apply -top-3 border-b border-secondary-400;
                }

                &:after {
                    @apply -bottom-3 border-t border-secondary-400;
                }
            }
            .cardRight {
                .header {
                    @apply bg-secondary-500;
                }
            }
        }
        &.ticketPackage {
            .cardLeft {
                @apply border-l border-t border-b border-secondary-400  border-r-2  relative;
                border-right: 2px dotted #aaa !important ;
                .header {
                    @apply bg-secondary-600;
                }
                &:before,
                &:after {
                    @apply w-6 h-6 -right-3 absolute bg-secondary-100 rounded-full z-10;
                    content: '';
                }

                &:before {
                    @apply -top-3 border-b border-secondary-400;
                }

                &:after {
                    @apply -bottom-3 border-t border-secondary-400;
                }
            }
            .cardRight {
                .header {
                    @apply bg-secondary-600;
                }
            }
        }

        &.card {
            .cardLeft {
                @apply rounded-2xl rounded-r-none border-r-0;
                .header {
                    @apply bg-primary-500 rounded-t-2xl rounded-r-none border-0;
                }
            }
            .cardRight {
                /* @apply hidden; */
                .header {
                    @apply bg-primary-500 border-0;
                }
            }
        }
    }

    .ribbon {
        @apply w-28 sm:w-36 h-28 sm:h-36 absolute overflow-hidden z-30;
    }
    .ribbon::before,
    .ribbon::after {
        @apply border-rose-500 absolute -z-10 block;
        content: '';
        border: 5px solid;
    }
    .ribbon span {
        @apply bg-rose-500 absolute block w-56 sm:text-xl py-1 sm:py-2 font-bold shadow-lg;
        color: #fff;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
        text-transform: uppercase;
        text-align: center;
    }
    .ribbon-bottom-right {
        @apply -bottom-1 -right-4 opacity-100;
    }
    .ribbon-bottom-right::before,
    .ribbon-bottom-right::after {
        border-bottom-color: transparent;
        border-right-color: transparent;
    }
    .ribbon-bottom-right::before {
        @apply bottom-0 left-0;
    }
    .ribbon-bottom-right::after {
        @apply top-0 right-0;
    }
    .ribbon-bottom-right span {
        @apply -left-[35px] sm:-left-[20px] bottom-[40px];
        transform: rotate(-45deg);
    }
</style>
