<template>
    <header class="member-header">
        <div class="left">
            <router-link  class="btn btn-xs btn-circle" v-if="backTo" :to="backTo">
                <svg class="h-4 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" /></svg>
            </router-link>
        </div>
        <h1 class="title">{{title}}</h1>
        <div class="right"></div>
    </header>
</template>
<script>
export default {
    props: {
        title: String,
        backTo: String
    }
}
</script>