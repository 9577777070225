<template>
    <main>
        <Splide class="homeSplide" v-if="splideRead" :options="silderOption">
            <SplideSlide class="slide" v-for="(item, index) in silder" :key="index">
                <a :href="item.link" target="_blank">
                    <figure>
                        <div class="image" v-bind:style="{ 'background-image': 'url(\'' + item.image + '\')' }"></div>
                        <figcaption>
                            <h3 class="title">{{ item.title }}</h3>
                            <p class="sub-title">{{ item.description }}</p>
                        </figcaption>
                    </figure></a
                >
            </SplideSlide>
        </Splide>
        <CustomizeSection class="!pb-0">
            <div class="flex justify-center flex-col sm:flex-row sm:space-x-8">
                <div class="news" data-aos="fade-down">
                    <h2 class="title" data-aos="fade-down">最新消息</h2>
                    <ul v-for="(month, index) in this.dataItems" :key="index">
                        <li v-for="(item, index) in month.content" :key="index">
                            <router-link :to="{ name: 'gymnasiumNewsDetails', query: { AR_ID: item.id } }">
                                <div>
                                    <div class="catalog">
                                        <span class="date">{{ item.date }}</span
                                        >{{ item.catalog }}
                                    </div>
                                    <p class="text">{{ item.title }}</p>
                                </div>
                                <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path
                                        d="M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M6,13H14L10.5,16.5L11.92,17.92L17.84,12L11.92,6.08L10.5,7.5L14,11H6V13Z"
                                    />
                                </svg>
                            </router-link>
                        </li>
                    </ul>
                    <div class="text-center mt-8">
                        <router-link to="/gymnasiumNews" class="btn text-white">更多</router-link>
                    </div>
                </div>
                <div class="self-end">
                    <div class="flex">
                        <img class="rounded-3xl w-1/2 sm:w-auto" src="@/assets/image/model-01.png" />
                        <a
                            href="https://line.me/R/ti/p/%40873aalha"
                            class="self-start sm:-mt-36 w-48 rounded-full bg-opacity-60 aspect-square bg-primary-500 flex justify-center items-center text-white text-2xl"
                        >
                            JOIN US</a
                        >
                    </div>
                </div>
            </div>
        </CustomizeSection>
        <LoadingPage :loadSet="loadSet"></LoadingPage>
    </main>
</template>

<script>
    import { apiToken, apiMAIN_PHOTO_LIST, apiARTICLE_LIST2 } from '../../api/API';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import '@splidejs/splide/dist/css/themes/splide-default.min.css';
    import CustomizeSection from '@/components/CustomizeSection.vue';

    export default {
        name: 'siteHome',
        components: {
            Splide,
            SplideSlide,
            CustomizeSection,
        },
        data() {
            return {
                splideRead: false,
                silder: [],
                dataItemsAll: [],
                dataItems: [],
                loadSet: {
                    show: false,
                    fullPage: true,
                    type: 'dots',
                    color: 'rgba(0,0,0,0.7)',
                },
                silderOption: {
                    type: 'loop',
                    rewind: true,
                    autoplay: true,
                    pagination: false,
                    perPage: 1,
                    rewindSpeed: 5000,
                    speed: 500,
                    breakpoints: {
                        640: {
                            perPage: 1,
                        },
                    },
                },
            };
        },
        mounted() {
            // new Splide('.splide', {
            // 	rewind: true,
            // 	autoplay: true,
            // 	type: 'fade',
            // 	pagination: false,
            // 	perPage: 1,
            // 	rewindSpeed: 5000,
            // 	speed: 2500,
            // 	breakpoints: {
            // 		640: {
            // 			perPage: 1,
            // 		},
            // 	},
            // 	focus: 'center',
            // }).mount();
            this.init();
        },
        methods: {
            async init() {
                this.loadSet.show = false;
                await this.getMainPhotoList();
                await this.getArticleList();
            },
            async getMainPhotoList() {
                try {
                    this.silder = [];
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiMAIN_PHOTO_LIST(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        //console.log(jsData)
                        if (jsData.length > 0) {
                            for (let i in jsData) {
                                this.silder.push({
                                    title: jsData[i].MP_TITLE,
                                    image: jsData[i].PHOTO_URL,
                                    description: jsData[i].MP_DISCRIPTION,
                                    link: jsData[i].MP_URL,
                                });
                            }
                        }
                        console.log(this.silder);
                        this.splideRead = true;
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            async getArticleList() {
                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        count: 5,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiARTICLE_LIST2(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        console.log(jsData);
                        this.dataItemsAll = [];
                        this.dataItems = [];
                        var month = null;
                        if (jsData.length > 0) {
                            for (let i in jsData) {
                                var date = new Date(jsData[i].AR_DATE);
                                var nowyear = date.getFullYear();
                                var nowMonth = this.TransformTime2String(date.getMonth() + 1);
                                var nowday = this.TransformTime2String(date.getDate());

                                if (month != nowMonth) {
                                    month = nowMonth;
                                    this.dataItemsAll.push({
                                        title: nowMonth + '月',
                                        content: [],
                                    });
                                }

                                this.dataItemsAll[this.dataItemsAll.length - 1].content.push({
                                    id: jsData[i].AR_ID,
                                    date: nowyear + '-' + nowMonth + '-' + nowday,
                                    catalog: jsData[i].AR_TYPEcn,
                                    catalogId: jsData[i].AR_TYPE,
                                    title: jsData[i].AR_TITLE,
                                });

                                this.dataItems = this.dataItemsAll;
                            }

                            console.log(this.dataItemsAll);
                        }
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            showNext() {
                this.$refs.carousel.next();
            },
            showPrev() {
                this.$refs.carousel.prev();
            },
        },
    };
</script>
<style lang="postcss" scoped>
    .homeSplide {
        .slide {
            @apply h-screen;
            figure {
                @apply relative h-screen;
                .image {
                    @apply bg-secondary-100 w-screen h-screen absolute left-0 top-0 bg-cover bg-center z-10;
                }
                figcaption {
                    @apply absolute left-16 bottom-32 z-50;
                    opacity: 0;
                    transition: all 2s ease-out;
                    transition-delay: 0.5s;
                    .title {
                        @apply text-4xl sm:text-7xl mb-2 sm:mb-4 pb-4 tracking-wider border-dotted text-white;
                        text-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
                    }
                    .sub-title {
                        @apply text-secondary-50;
                    }
                }
                &::after {
                    @apply w-screen h-screen absolute left-0 top-0 bg-cover bg-center bg-gradient-to-t from-black to-transparent opacity-50 z-30;
                    content: '';
                }
            }
            &.is-active {
                figure {
                    @apply opacity-100;
                    figcaption {
                        opacity: 1;
                    }
                }
            }
        }
    }

    @media (min-width: 640px) {
        .splide {
            &.extend {
                @apply h-screen;
            }
        }
    }
</style>
