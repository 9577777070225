<script>
//import API from "../api/API";
export default {
  name: "Mixin-vue",
  data() {
    return {
      
    };
  },
  created() {
    
  },
  mounted() {   
    
  },  
  computed: {
    
  },
  methods: {
    BaseModel(data){
      //data.TokenID= this.$session.get('ACID')
      if(this.IsNull(this.$session.get('ACID')) == "" )
        data.TokenID = "/sXlpfsE3N5pCbo7wqXqJdvxfI/S8zY2CzXWy547qPw=" //遊客權限
      else
        data.TokenID = this.$session.get('ACID')
      return data
    },
    IsNull(data,err='') {
      if (data == "" || data == null || data == undefined) {
        return err;
      } else {
        return data;
      }
    },
    CheckMail(data) {
      const mailRegExp = new RegExp(
        "^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z0-9]+$"
      );
      return mailRegExp.test(data);
    },
    CheckidNum(data) {
      if(data.length == 10){
        const idRegExp = new RegExp(
        "^[A-Z][1289A-Z][0-9]{8}$"
        );
        return idRegExp.test(data)
      }else{
        return false
      }
      
    },
    CheckPhone(data) {
      const MobileReg = /^(09)[0-9]{8}$/;
      return MobileReg.test(data);
    },
    TransformTimeFormatYYYMMDD(data){
      if(this.IsNull(data) == "")
        return ""
      else{
        let d = new Date(data)
        return d.getFullYear() + "-" + this.TransformTime2String(d.getMonth() + 1) + "-" + this.TransformTime2String(d.getDate())
      }
    },
    TransformTimeFormatYYYMMDDhhmm(data){
      if(this.IsNull(data) == "")
        return ""
      else{
        let d = new Date(data)
        return d.getFullYear() + "-" + this.TransformTime2String(d.getMonth() + 1) + "-" + this.TransformTime2String(d.getDate()) + " " + this.TransformTime2String(d.getHours()) + ":" + this.TransformTime2String(d.getMinutes())
      }
    },
    TransformTimeFormatYYYMMDDhhmm2(data){
      if(this.IsNull(data) == "")
        return ""
      else{
        let d = new Date(data)
        return d.getFullYear() + "-" + this.TransformTime2String(d.getMonth() + 1) + "-" + this.TransformTime2String(d.getDate()) + "T" + this.TransformTime2String(d.getHours()) + ":" + this.TransformTime2String(d.getMinutes())
      }
    },
    TransformTimeFormathhmm(data){
      if(this.IsNull(data) == "")
        return ""
      else{
        let d = new Date(data)
        return this.TransformTime2String(d.getHours()) + ":" + this.TransformTime2String(d.getMinutes())
      }
    },
    TransformTime2String(num){
      if (num < 10)
        return "0" + num.toString();
      else
        return num.toString();
    },
    Options2String(items){
      let values = ""
      for(let i=0;i<items.length;i++){
        values += items[i].value+"§"
      }

      if(values != "")
        return values.slice(0,values.length-1)
      else
        return null
     },
     String2Options(str,items){
      let splits = str.split("§")
      let values = []
      for(let i in splits){
        //是否交集
        let Intersection = items.filter((e) => {
          return  (e.value == splits[i])
        })
        
        if(Intersection.length > 0)
          values.push(Intersection[0])
      }
      return values
     },
     String2OptionString(str,items){
      let splits = str.split("§")
      let values = ''
      for(let i in splits){
        //是否交集
        let Intersection = items.filter((e) => {
          return  (e.value == splits[i])
        })
        values += Intersection[0].text + "、"
      }
      
      if(values != "")
        return values.slice(0,values.length-1)
      else
        return null
     },
     date_diff(date1, date2,get_item)//日期相減
      {
        var Difference_In_Time = date1.getTime() - date2.getTime();
        //round ??????
        switch (get_item) {
            case 'month':
                return Math.floor(Difference_In_Time / (1000 * 3600 * 24 * 30));
            case 'day':
                return Math.floor(Difference_In_Time / (1000 * 3600 * 24));
            case 'hour':
                return Math.floor(Difference_In_Time / (1000 * 3600));
            case 'minute':
                return Math.floor(Difference_In_Time / (1000 * 60));
            case 'second':
                return Math.floor(Difference_In_Time / 1000);    
            default:
                break;
        }
        return Difference_In_Time
      }
  },
};
</script>

