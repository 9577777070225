import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSession from 'vue-session'
import './assets/app.scss';
import VueRellax from 'vue-rellax'
Vue.use(VueRellax)
Vue.use(require('vue-moment'));
import mixin from './components/Mixin.vue'
import LoadingPage from './components/LoadingPage.vue'
import 'animate.css/animate.css'
// import animated from 'animate.css/animate.css'
// Vue.use(animated);
import VueMeta from 'vue-meta'

Vue.use(VueMeta, {
  keyName: 'head',
});

//日期選擇器
import { Datetime } from 'vue-datetime'
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'
Vue.use(Datetime)

import AOS from 'aos'
import 'aos/dist/aos.css'

Vue.component('LoadingPage', LoadingPage)
Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(VueSession)
Vue.mixin(mixin)
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
import VueCookie from 'vue-cookies'
Vue.use(VueCookie)



import { library } from '@fortawesome/fontawesome-svg-core'
// import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas, far, fab)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// Vue.use(BootstrapVue)
// Vue.use(IconsPlugin)

new Vue({
  router,
  store,

  created() {
    AOS.init()
  },

  render: h => h(App)
}).$mount('#app')

router.afterEach(() => {
  window.scrollTo(0, 0);
});


// Vue.prototype.$appDomain = "http://140.134.48.75/Stadium"
// Vue.prototype.$Test = true



// Vue.prototype.$appDomain = "https://sportscenter.fcu.edu.tw/FcuPay"
// Vue.prototype.$appDomain = "https://stadium.artinuro.com"
Vue.prototype.$appDomain = "https://sportscenter.fcu.edu.tw"
Vue.prototype.$Test = false
