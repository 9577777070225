<template>
    <div class="class shadow bg-white">
        <div class="time" :style="info.style">
            <span class="star">{{ info.starTime }}</span>
            <span class="end">{{ info.endTime }}</span>
        </div>
        <div class="flex justify-between flex-col h-44 py-3 text-center">
            <ul class="space-y-2">
                <li class="course-name">
                    <div class="text-xl">{{ info.title }}</div>
                    <div v-if="IsNull(info.name) != ''" class="text-gray-500">{{ info.name }}</div>
                </li>
                <li v-if="IsNull(info.name2)">
                    <span :style="info.style" class="rounded-full py-0.5 px-4 text-sm text-white">{{
                        info.name2
                    }}</span>
                </li>
                <li class="catalog-name">
                    <strong v-if="info.state == 0" class="text-green-500 self-center text-sm">可預約</strong>
                    <strong v-if="info.state == 1" class="text-red-500 self-center text-sm">已額滿</strong>
                </li>
            </ul>
            <span class="text-lg">{{ info.trainer }}</span>
        </div>
        <div class="location">{{ info.location }}</div>
    </div>
</template>
<script>
    export default {
        props: {
            info: Object,
        },
    };
</script>
<style lang="postcss" scoped>
    .class {
        @apply mb-2 rounded-xl overflow-hidden border border-secondary-200  hover:border hover:border-secondary-400 cursor-pointer;
        .time {
            @apply text-center text-white p-2;
            span {
                /* @apply -ml-1.5 sm:ml-0 leading-none ; */
                /* &::before {
               @apply w-3 h-3 rounded-full border-2 inline-block bg-secondary-900 mr-2 sm:hidden;
               content:"";
            }
            &.star {
               @apply items-center -mt-1 sm:mt-0;
            }
            &.end {
               @apply items-center -mb-1 sm:mb-0;
            }
            &:first-child {
               &::after {
                  @apply hidden sm:block mx-2 ;
                  content:"~";
               }
            } */
                &:first-child {
                    &::after {
                        content: '~';
                    }
                }
            }
        }
        .info {
            @apply h-44;
            .course-name {
                @apply text-2xl mb-2;
            }
            .catalog-name {
                @apply text-xl text-secondary-500 mb-4;
            }
            @apply flex flex-wrap sm:py-4 sm:px-4  lg:block justify-between flex-1;
            li {
                @apply sm:text-center;
                &::after {
                    @apply px-2 sm:hidden;
                    content: '/';
                }
                &:last-child {
                    &::after {
                        content: '';
                    }
                }
            }
        }
        .location {
            @apply bg-secondary-100 text-center p-2 border-t text-sm;
        }
    }
</style>
