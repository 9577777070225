<template>
    <section>123</section>
</template>
<script>
    export default {
        name: 'FcuError',
        data() {
            return {};
        },
        created() {
            this.$router.push('/');
        },
        mounted() {},
        methods: {},

        components: {},
    };
</script>
<style lang="scss" scoped></style>
