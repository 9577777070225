<template>
      <main>
         <Header title="分票紀錄"></Header>
         <section class="main">
            <div class="overflow-x-auto">
            <table class="table w-full tableGrid">
               <thead>
                  <tr>
                     <th class="w-px">分票時間</th>
                     <th>票券名稱</th>
                     <th class="w-px">分票數量</th>
                     <th class="w-px">分票代碼</th>
                     <th class="w-px">取票狀態</th>
                     <th class="w-px">取票會員</th>
                     <th class="w-px">取票時間</th>
                     <th class="w-px">操作</th>
                  </tr>
               </thead>
               <tbody>
                  <tr v-for="(item, index) in dataItems" :key="index">
                     <td data-label="分票時間：">{{ item.SQ_DATE }}</td>
                     <td data-label="票券名稱：">{{ item.AE_NAME }}</td>
                     <td data-label="分票數量：" class="text-center"> {{ item.SQ_QUANTITY }}</td>
                     <td data-label="分票代碼："><strong class=" text-green-500">{{ item.SQ_ID }}</strong></td>
                     <td data-label="取票狀態：">
                        <template v-if="item.SQ_QUANTITY == item.SQ_USE_QUANTITY">已取票</template>
                        <template v-else>尚未取票</template>
                     </td>
                     <td data-label="取票會員："> {{ item.PPL_NAME }}</td>
                     <td data-label="取票時間："> {{ item.SQ_CHECKIN_DATE }}</td>
                     <td class="col-span-3 flex justify-center">
                        <template v-if="item.AE_CATALOG != 3 & item.SQ_USE_QUANTITY == 0"><button v-if="!item.status" class="btn btn-sm btn-accent" @click="cancelSplit(item.AE_ID, item.SQ_ID)">取消分票</button></template>
                     </td>
                  </tr>
               </tbody>
            </table>
            </div>
            <!-- <div class="bg-white p-8 rounded-xl border">
               <h6>注意事項</h6>
               <ol class=" list-disc list-inside">
                  <li>接受分票的人需要是逢甲大學體育館會員</li>
                  <li>票券為不記名票券</li>
               </ol>
            </div> -->
         </section>
         <LoadingPage :loadSet="loadSet"></LoadingPage>
      </main>
</template>

<script>
import { apiToken, apiSPLIT_CODE_LIST, apiACCESS_SPLIT_CANCEL, } from '../../api/API'
import Header from '@/components/MemberHeader.vue'
export default {
   name: 'SplitTicket',
   components: {
      Header
   },
   data() {
      return {
         reservation: false,
         dataItemsAll: [],
         dataItems: [],
         loadSet: { show: false, fullPage: true, type: 'dots', color: 'rgba(0,0,0,0.7)' }
      }
   },
   mounted() {
      this.init()
   },
   methods: {
      async init() {
         if (this.IsNull(this.$session.get('ACID')) == "" ) {
               this.$router.push("/Login")
         }else{
            await this.getSplitCodeList()
         }
         
      },
      async getSplitCodeList() {
         try {
            this.dataItemsAll = []
            this.dataItems = []

            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               Token: Token
            }
            data = this.BaseModel(data)


            var result = JSON.parse(await (await apiSPLIT_CODE_LIST(data)).data);
            this.loadSet.show = false;

            if (result.Status) {
               var jsData = JSON.parse(result.Data);
               console.log(jsData)
               if (jsData.length > 0) {
                  for (let i in jsData) {
                     jsData[i].AE_EXPIRATION_DATE = this.TransformTimeFormatYYYMMDDhhmm(jsData[i].AE_EXPIRATION_DATE)
                     jsData[i].SQ_DATE = this.TransformTimeFormatYYYMMDDhhmm(jsData[i].SQ_DATE)
                     jsData[i].SQ_CHECKIN_DATE = this.TransformTimeFormatYYYMMDDhhmm(jsData[i].SQ_CHECKIN_DATE)
                     
                     this.dataItemsAll.push(jsData[i])
                     this.dataItems.push(jsData[i])
                  }
               }
               console.log(jsData)
            } else {
               alert(result.Message)
            }

            //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false;
            //console.log(e)
            alert(e)
         }
      },
      async cancelSplit(AE_ID, SQ_ID) {
         if (confirm("確定取消分票？") == false) {
            return;
         }

         try {
            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               AE_ID: AE_ID,
               SQ_ID: SQ_ID,
               Token: Token
            }
            data = this.BaseModel(data)


            var result = JSON.parse(await (await apiACCESS_SPLIT_CANCEL(data)).data);
            this.loadSet.show = false;

            if (result.Status) {
               //var jsData = JSON.parse(result.Data);
               await this.getSplitCodeList()
               alert("取消成功")
               //console.log(jsData)
            } else {
               if (result.Data != "") {
                  alert(result.Data)
               } else {
                  alert(result.Message)
               }
            }

            //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false;
            //console.log(e)
            alert(e)
         }
      },
   }
}
</script>