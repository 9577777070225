<template>
    <div>
        <header class="pt-24 sm:pt-36 pb-10 sm:pb-24">
            <h1 class="title-01">{{ details.TKT_CAT_NAME }}</h1>
        </header>

        <!-- <div class="pb-32">{{details}}</div> -->
        <CustomizeSection v-if="details.PHOTO_URL || details.TKT_CAT_DISCRIPTION" tranglert tranglelt tranglelb>
            <div class="main-description container mx-auto">
                <div v-html="details.TKT_CAT_DISCRIPTION"></div>
            </div>
            <div
                class="main-img"
                :style="{
                    'background-image': 'url(' + details.PHOTO_URL + ')',
                    'background-position-y': positionY1 + 'px',
                }"
                v-rellax="{ speed: 1 }"
            ></div>
        </CustomizeSection>
        <!-- 游泳池 -->
        <template v-if="details.TKT_CAT_ID == 'TKC38874b78-198c-48d0-b08c-3b429cb20603'">
            <CustomizeSection tranglelt tranglelb>
                <h2 class="title">搶先了解場內區域</h2>
                <div class="flex justify-center mb-12 px-8 mx-auto">
                    <p class="sm:w-1/2">
                        國際標準50公尺長水道，120至140公分之露天泳池，搭配戶外淋浴區及室內更衣室，打造舒適戶外水上運動空間。場域佔地面積約965坪，腹地寬敞可用以辦理游泳賽事、水上主題等活動。也規劃做為游泳教學、救生員培訓班、水上瑜珈課程等使用。
                    </p>
                </div>
                <div class="gallery">
                    <div class="item">
                        <img src="@/assets/image/pool-001.jpg" class="photo" data-aos="fade-right" />
                        <div class="describe" data-aos="fade-down">
                            <h4 class="title title-decorate">
                                <strong>01</strong>
                                國際標準50公尺長水道
                            </h4>
                            <p class="text">場域佔地面積約965坪，腹地寬敞可用以辦理游泳賽事、水上主題等活動。</p>
                        </div>
                    </div>
                </div>
            </CustomizeSection>
        </template>
        <!-- 健身房 -->
        <template v-else-if="details.TKT_CAT_ID == 'TKC7df7eda9-a4c5-4959-9db9-76ba53f674d7'">
            <CustomizeSection>
                <h2 class="title">無推銷、免綁約、專注健身</h2>
                <div class="flex justify-center mb-12 sm:px-8">
                    <p class="w-full sm:w-1/2">
                        館內健身房具備專業防撞地板，並搭配商用頂級心肺訓練及重量器材。外加明亮舒適的運動空間，環繞對外窗；開放時段皆有一位具備合格證照的健身指導員，可現場提供訓練協助及運動諮詢建議。
                    </p>
                </div>
                <div class="gallery mb-96 sm:mb-24">
                    <div class="item">
                        <img src="@/assets/image/gym-003.jpg" class="photo" data-aos="fade-right" />
                        <div class="describe" data-aos="fade-down">
                            <h4 class="title title-decorate">
                                <strong>01</strong>
                                有氧運動區
                            </h4>
                            <p class="text"></p>
                        </div>
                    </div>
                    <div class="item">
                        <img src="@/assets/image/gym-001.jpg" class="photo" data-aos="fade-left" />
                        <div class="describe" data-aos="fade-down">
                            <h4 class="title title-decorate">
                                <strong>02</strong>
                                固定器械區
                            </h4>
                            <p class="text"></p>
                        </div>
                    </div>
                    <div class="item">
                        <img src="@/assets/image/gym-002.jpg" class="photo" data-aos="fade-right" />
                        <div class="describe" data-aos="fade-down">
                            <h4 class="title title-decorate">
                                <strong>03</strong>
                                自由重量區
                            </h4>
                            <p class="text"></p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row-reverse absolute right-4 bottom-0">
                    <img
                        v-rellax="{ speed: -0.5 }"
                        data-aos="fade-left"
                        class="rounded-3xl"
                        src="@/assets/image/model-01.png"
                    />
                    <a
                        href="https://line.me/R/ti/p/%40873aalha"
                        target="_blank"
                        v-rellax="{ speed: 0.3 }"
                        data-aos="fade-right"
                        class="mt-20 self-center w-48 rounded-full bg-opacity-60 aspect-square bg-primary-500 flex justify-center items-center text-white text-2xl"
                    >
                        JOIN US</a
                    >
                </div>
                <div class="flex flex-col sm:flex-row justify-center items-center space-y-2 sm:space-y-0 sm:space-x-2">
                    <a
                        class="btn btn-lg w-full sm:w-auto"
                        style="position: relative; z-index: 10"
                        href="https://drive.google.com/file/d/1sJXRf8PtP-X7DI6Y8px1W_jjFg-RdZSa/view"
                        target=" _black"
                        >健身房進場規範</a
                    >
                </div>
            </CustomizeSection>
        </template>
        <!-- 預設 -->
        <template v-else> </template>

        <CustomizeSection v-if="showPriceList" class="!bg-secondary-50">
            <h2 class="text-center text-5xl mb-8">收費方式</h2>
            <PriceList :priceList="priceList" @changeNID="changeNID" @buy="goBuyTicket"></PriceList>
        </CustomizeSection>
        <LoadingPage :loadSet="loadSet"></LoadingPage>
    </div>
</template>

<script>
    import { apiToken, apiTICKET_CATALOG_GET, apiTICKET_LIST2 } from '../../api/API';
    import CustomizeSection from '@/components/CustomizeSection.vue';
    import PriceList from '@/components/PriceList.vue';
    export default {
        name: 'SpaceAA',
        data() {
            return {
                showPriceList: true,
                priceList: [
                    {
                        title: '單堂課程',
                        description: '免綁約，隨時進場體驗',
                        plan: [],
                    },
                    {
                        title: '單次套票',
                        description: '為不同身分別的學員客製精選套票方案，親民的價格學習多樣化的運動課程。',
                        plan: [],
                    },
                    {
                        title: '會員會期',
                        description: '單月使用輕鬆無負擔',
                        plan: [],
                    },
                ],

                priceListDescription: [
                    '免綁約，輕鬆體驗無負擔。',
                    '為不同身分別的學員客製精選套票方案，親民的價格學習多樣化的運動課程。',
                    '依照不同需求客製超值優惠專案，超值的價格享受高規格的運動環境。',
                ],

                ticketListTemp: [[], [], []],

                details: {
                    TKT_CAT_ID: null,
                    PHOTO_URL: null,
                    TKT_CAT_DISCRIPTION: null,
                    TKT_CAT_NAME: null,
                },

                loadSet: {
                    show: false,
                    fullPage: true,
                    type: 'dots',
                    color: 'rgba(0,0,0,0.7)',
                },
            };
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                this.loadSet.show = false;
                if (this.$route.query.TKT_CAT_ID != undefined) {
                    this.details.TKT_CAT_ID = this.$route.query.TKT_CAT_ID;
                    await this.getTicketCatalog();
                    await this.getTicketList();
                } else {
                    this.$router.push('/');
                }
            },
            changeNID(nidIndex) {
                this.priceList = [
                    {
                        title: '單堂課程',
                        description: '免綁約，輕鬆體驗無負擔。',
                        plan: [],
                    },
                    {
                        title: '單次套票',
                        description: '為不同身分別的學員客製精選套票方案，親民的價格學習多樣化的運動課程。',
                        plan: [],
                    },
                    {
                        title: '會員會期',
                        description: '依照不同需求客製超值優惠專案，超值的價格享受高規格的運動環境。',
                        plan: [],
                    },
                ];
                this.priceList[0].description = this.priceListDescription[0];
                this.priceList[1].description = this.priceListDescription[1];
                this.priceList[2].description = this.priceListDescription[2];

                for (let i in this.ticketListTemp) {
                    var typeIndex = this.ticketListTemp[i].TKT_TYPE;

                    var plan = {
                        title: this.ticketListTemp[i].TKT_NAME,
                        pricing: null,
                    };
                    switch (nidIndex) {
                        case 0:
                            plan.pricing = this.ticketListTemp[i].TKT_PRICE;
                            break;
                        case 1:
                            plan.pricing = this.ticketListTemp[i].TKT_EXEMPATION_STUDENT;
                            break;
                        case 2:
                            plan.pricing = this.ticketListTemp[i].TKT_EXEMPATION_STAFF;
                            break;
                        case 3:
                            plan.pricing = this.ticketListTemp[i].TKT_EXEMPATION_ALUMNI;
                            break;
                        case 4:
                            plan.pricing = this.ticketListTemp[i].TKT_EXEMPATION_DEPENDENTS;
                            break;
                    }

                    //如果為特價時間
                    if (
                        this.ticketListTemp[i].TKT_IS_OFFER == 1 &&
                        new Date() <= new Date(this.ticketListTemp[i].TKT_OFFER_DATE)
                    ) {
                        if (this.ticketListTemp[i].TKT_OFFER_AMOUNT != null)
                            plan.pricing = this.ticketListTemp[i].TKT_OFFER_AMOUNT;
                    }

                    if (plan.pricing == null) plan.pricing = this.ticketListTemp[i].TKT_PRICE;

                    switch (typeIndex) {
                        case 0:
                            plan.title += ' 1張';
                            this.priceList[0].plan.push(plan);
                            break;
                        case 1:
                            plan.title += ' ' + this.ticketListTemp[i].TKT_QUANTITY + '張';
                            this.priceList[1].plan.push(plan);
                            break;
                        case 2:
                            plan.title += ' ' + this.ticketListTemp[i].TKT_VALID_DATE + '天';
                            this.priceList[2].plan.push(plan);
                            break;
                    }
                }
            },
            async getTicketCatalog() {
                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        TKT_CAT_ID: this.details.TKT_CAT_ID,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiTICKET_CATALOG_GET(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        this.details = jsData;
                        this.priceListDescription[0] = this.details.TKT_CAT_ONCE_DESCRIPTION;
                        this.priceListDescription[1] = this.details.TKT_CAT_PACKAGE_DESCRIPTION;
                        this.priceListDescription[2] = this.details.TKT_CAT_CYCLE_DESCRIPTION;
                        //console.log(jsData)
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            async getTicketList() {
                try {
                    this.priceList = [
                        {
                            title: '單次入場',
                            description: '免綁約，輕鬆體驗無負擔。',
                            plan: [],
                        },
                        {
                            title: '單次套票',
                            description: '為不同身分別的學員客製精選套票方案，親民的價格學習多樣化的運動課程。',
                            plan: [],
                        },
                        {
                            title: '會員會期',
                            description: '依照不同需求客製超值優惠專案，超值的價格享受高規格的運動環境。',
                            plan: [],
                        },
                    ];
                    this.priceList[0].description = this.priceListDescription[0];
                    this.priceList[1].description = this.priceListDescription[1];
                    this.priceList[2].description = this.priceListDescription[2];

                    this.ticketListTemp = [];

                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        TKT_CAT_ID: this.details.TKT_CAT_ID,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiTICKET_LIST2(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        this.ticketListTemp = jsData;
                        // console.log(jsData.length)
                        if (jsData.length >= 1) {
                            this.showPriceList = true;
                        } else {
                            this.showPriceList = false;
                        }
                        for (let i in jsData) {
                            var typeIndex = jsData[i].TKT_TYPE;

                            var plan = {
                                tkcid: jsData[i].TKT_SPORT,
                                title: jsData[i].TKT_NAME,
                                pricing: null,
                            };
                            plan.pricing = jsData[i].TKT_PRICE;

                            //如果為特價時間
                            if (jsData[i].TKT_IS_OFFER == 1 && new Date() <= new Date(jsData[i].TKT_OFFER_DATE)) {
                                if (jsData[i].TKT_OFFER_AMOUNT != null) plan.pricing = jsData[i].TKT_OFFER_AMOUNT;
                            }

                            switch (typeIndex) {
                                case 0:
                                    plan.title += ' 1張';
                                    this.priceList[0].plan.push(plan);
                                    break;
                                case 1:
                                    plan.title += ' ' + jsData[i].TKT_QUANTITY + '張';
                                    this.priceList[1].plan.push(plan);
                                    break;
                                case 2:
                                    plan.title += ' ' + jsData[i].TKT_VALID_DATE + '天';
                                    this.priceList[2].plan.push(plan);
                                    break;
                            }
                        }
                        //console.log(this.priceList)
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            goBuyTicket(tkcid, catalog) {
                this.$router.push({ name: 'buyTickets', params: { tkcid: tkcid, catalog: catalog } });
            },
        },
        watch: {
            // 禁用下一行eslint检查
            // eslint-disable-next-line
            $route(to, from) {
                if (to.path === '/Space') {
                    location.reload();
                }
            },
        },
        components: {
            CustomizeSection,
            PriceList,
        },
    };
</script>

<style lang="postcss" scoped>
    .gallery {
        @apply flex flex-col justify-center space-y-12;
        .item {
            @apply flex flex-col sm:flex-row gap-4 sm:gap-12 justify-center items-center;
            .photo {
                @apply sm:w-1/2 rounded-3xl border-8 border-secondary-100 overflow-hidden;
            }
            .describe {
                @apply w-full sm:w-1/4;
                .title {
                    @apply pt-6 pb-4 mb-0 text-left;
                    strong {
                        @apply block text-6xl text-yellow-500 mb-4 font-light;
                    }
                }
                .text {
                    @apply border-t pt-4;
                }
            }
            &:nth-child(even) {
                .photo {
                    @apply sm:order-1;
                }
            }
        }
    }
</style>
