<template>
    <div>
        <ul tabindex="0" class="tabs">
            <li
                v-for="(tab, index) in tabs"
                :key="tab.title"
                class="tab tab-bordered pb-3 sm:px-8"
                :class="{ 'tab-active': index == selectedIndex }"
            >
                <button @click="selectTab(index)">{{ tab.title }}</button>
            </li>
        </ul>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                selectedIndex: 0, // the index of the selected tab,
                tabs: [], // all of the tabs
            };
        },
        created() {
            this.tabs = this.$children;
        },
        mounted() {
            this.selectTab(0);
        },
        methods: {
            selectTab(i) {
                this.selectedIndex = i;
                // loop over all the tabs
                this.tabs.forEach((tab, index) => {
                    tab.isActive = index === i;
                });
            },
        },
    };
</script>
<style lang="postcss" scoped>
    .tabs {
        @apply flex justify-center mb-8;
        li {
            @apply flex-1 sm:flex-none;
            a {
                @apply cursor-pointer;

                &.tabs__selected {
                    @apply text-slate-500;
                }
            }
        }
    }
</style>
