<template>
    <div>
        <div class="flex justify-center mb-12" data-aos="fade-down">
            <div class="btn-group">
                <template v-if="index == 0"><a class="btn text-white btn-xs sm:btn-md btn-active">會員</a></template
                ><template v-else><a class="btn btn-xs sm:btn-md text-white" @click="changeNID(0)">會員</a></template>
                <template v-if="index == 1"><a class="btn text-white btn-xs sm:btn-md btn-active">學生</a></template
                ><template v-else><a class="btn btn-xs sm:btn-md text-white" @click="changeNID(1)">學生</a></template>
                <template v-if="index == 2"><a class="btn text-white btn-xs sm:btn-md btn-active">教職員</a></template
                ><template v-else><a class="btn btn-xs sm:btn-md text-white" @click="changeNID(2)">教職員</a></template>
                <template v-if="index == 3"><a class="btn text-white btn-xs sm:btn-md btn-active">校友</a></template
                ><template v-else><a class="btn btn-xs sm:btn-md text-white" @click="changeNID(3)">校友</a></template>
                <template v-if="index == 4"
                    ><a class="btn text-white btn-xs sm:btn-md btn-active">教職員眷屬</a></template
                ><template v-else
                    ><a class="btn btn-xs sm:btn-md text-white" @click="changeNID(4)">教職員眷屬</a></template
                >
            </div>
        </div>
        <div class="priceList">
            <template v-for="(item, index) in priceList">
                <div
                    v-if="item.plan.length != 0"
                    class="card w-full sm:w-96 bg-base-100 shadow-lg"
                    :key="index"
                    data-aos="fade-down"
                    :data-aos-delay="index + '00'"
                >
                    <!-- <figure><img src="https://placeimg.com/400/225/arch" alt="Shoes" /></figure> -->
                    <div class="card-body">
                        <h2 class="card-title mb-0">{{ item.title }}</h2>
                        <div class="mb-4 whitespace-pre">{{ item.description }}</div>
                        <div class="border border-secondary-300 rounded-xl overflow-hidden mb-4">
                            <div class="border-b p-4 last:border-b-0" v-for="(plan, i) in item.plan" :key="i">
                                <div class="stat-title">{{ plan.title }}</div>
                                <div class="text-right nt">
                                    <span class="text-base">NT$</span>
                                    <strong class="text-4xl text-secondary-700">{{ plan.pricing }}</strong>
                                </div>
                                <!-- <div class="stat-desc">Jan 1st - Feb 1st</div> -->
                                <!-- <ul v-if="plan.discount.length" class="discount-list">
                     <li v-for="(discount, index3) in plan.discount" :key="index3"> <span class="identity">{{discount.identity}}</span> <span>NT<strong class="price">${{discount.price}}</strong></span> </li>
                     </ul> -->
                            </div>
                        </div>
                        <div class="card-actions justify-end">
                            <!-- <router-link v-if="$Test" to="/buyTickets" class="btn btn-primary">購買</router-link> -->
                            <template v-if="item.plan.length > 0">
                                <template v-if="$Test && tktcid == 'TKC33a76759-4591-4277-8ae1-fea401a59a1d'">
                                    <a
                                        class="btn btn-primary btn-block"
                                        href="https://line.me/R/ti/p/%40873aalha"
                                        target="_blank"
                                        >LINE@預約</a
                                    >
                                </template>
                                <template v-else>
                                    <button
                                        v-if="$Test"
                                        @click="buy(item.plan[0].tkcid, index)"
                                        class="btn btn-primary btn-block"
                                    >
                                        購買
                                    </button>
                                </template>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'price-list',
        data() {
            return {
                index: 0,
            };
        },
        methods: {
            changeNID(index) {
                this.index = index;
                this.$emit('changeNID', index);
            },
            buy(tkcid, catalog) {
                this.$emit('buy', tkcid, catalog);
            },
        },
        props: {
            priceList: {
                type: Array,
                default: null,
            },
            tktcid: {
                type: String,
                default: null,
            },
        },
    };
</script>

<style lang="postcss" scoped>
    .priceList {
        @apply flex justify-center flex-col sm:flex-row items-start space-y-8 sm:space-y-0 sm:space-x-4;
        .single {
            @apply sm:flex gap-4;
        }
        .unit {
            @apply w-full sm:w-96 bg-white text-secondary-900 p-4 sm:p-8 rounded-lg shadow border border-secondary-200;
            .title {
                @apply text-center text-2xl text-secondary-800 font-bold;
            }
            .item {
                @apply bg-white mb-1 rounded-md p-4 flex justify-between border border-secondary-300;
            }
        }
        .discount-list {
            @apply flex flex-col sm:flex-row w-full divide-x mt-4 pt-4 border-t border-secondary-300;
            li {
                @apply flex-1 flex flex-row sm:flex-col justify-between items-center whitespace-nowrap w-full text-center;
                .identity {
                    @apply text-gray-500 text-sm;
                }
                .price {
                    @apply text-xl;
                }
            }
        }
    }
</style>
