<template>
   <div>
      <header class="pt-24 sm:pt-36 pb-10 sm:pb-24">
         <h1 class="title-01">最新消息</h1>
      </header>
      <CustomizeSection tranglert tranglelt tranglelb>
         <div class="main-img bg-01" v-rellax="{speed: 1}" ></div>
      </CustomizeSection>
      <CustomizeSection tranglert tranglelt>
            <div class="news-test">
               <router-link class="btn btn-circle" to="/gymnasiumNews">
                  <svg class=" fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z"/></svg>
               </router-link>
               <div class="news-detail">
                  <div class="header">
                     <ul class="sub">
                        <li>{{details.AR_DATE | moment('YYYY-MM-DD')}}</li>
                        <li>{{details.AR_TYPEcn}}</li>
                     </ul>
                     <h4>{{details.AR_TITLE}}</h4>
                  </div>
                  <div v-html="details.AR_DISCRIPTION"></div>
               </div>
            </div>
      </CustomizeSection>
      <LoadingPage :loadSet="loadSet"></LoadingPage>
   </div>
</template>

<script>
import {
   apiToken,
   apiARTICLE_GET
} from '../../api/API'
import CustomizeSection from '@/components/CustomizeSection.vue'

export default {
   head: {
      title: "最新消息",
      meta:[
         { name: 'description', content: '最新消息'}
      ]
   },
   data() {
      return {
      
         imageBg: require('@/assets/image/news.jpg'),
         details: {
            AR_ID:null,
            AR_TITLE:null,
            AR_TYPE:null,
            AR_DISCRIPTION:null,
            AR_DATE:null,
            AR_STATUS:null,
            AR_CREATE_TIME:null,
            AR_CREATE_ACCOUNT:null,
            AR_EDIT_DATE:null,
            AR_EDIT_ACCOUNT:null,
         },
			loadSet:{show: false,fullPage: true,type:'dots',color:'rgba(0,0,0,0.7)'}
      }
   },
   mounted() {
		this.init()
	},
	methods:{
		async init(){
         this.loadSet.show = false;
         if (this.$route.query.AR_ID != undefined) {
            this.details.AR_ID = this.$route.query.AR_ID
            this.getArticle()
         }else{
            this.$router.push("gymnasiumNews")
         }
		},
      async getArticle(){
         try {

            this.loadSet.show = true
            var Token = await (await apiToken()).data
            //console.log(Token);
            var data = {
               AR_ID:this.details.AR_ID,
               Token:Token
            }
            data = this.BaseModel(data)

            
            var result = JSON.parse(await (await apiARTICLE_GET(data)).data);
            this.loadSet.show = false;

            if (result.Status) {
               var jsData = JSON.parse(result.Data);
               this.details = jsData
               // console.log(jsData)
            } else {
               alert(result.Message)
            }

            //console.log(result.Message)
         } catch (e) {
            this.loadSet.show = false;
            //console.log(e)
            alert(e)
         }
		},
	},
   components: {
      CustomizeSection
   }
}
</script>

<style lang="postcss" scoped>
.bg-01 {
   @apply bg-cover;
   background-image: url('@/assets/image/news.jpg');
}
.news-test {
   @apply container mx-auto flex justify-center gap-8 sm:gap-12 flex-col-reverse sm:flex-row;
}
.news-detail {
   @apply w-full sm:w-2/3;
   .header {
      @apply border-b mb-8 pb-8;
      .sub {
         @apply flex mb-2 gap-4
      }
   }
}
</style>