<template>
    <main>
        <header class="pt-24 sm:pt-36 pb-10 sm:pb-24">
            <h1 class="title-01">{{ details.TKT_CAT_NAME }}</h1>
        </header>

        <CustomizeSection v-if="details.PHOTO_URL || details.TKT_CAT_DISCRIPTION" tranglelt tranglert tranglelb>
            <div class="main-description container mx-auto">
                <div v-html="details.TKT_CAT_DISCRIPTION"></div>
            </div>
            <div
                class="main-img"
                :style="{
                    'background-image': 'url(' + details.PHOTO_URL + ')',
                    'background-position-y': positionY1 + 'px',
                }"
                v-rellax="{ speed: 1 }"
            ></div>
        </CustomizeSection>
        <!-- 瑜珈有氧 -->
        <template v-if="details.TKT_TEMPLATE == 2">
            <CustomizeSection tranglerb tranglert>
                <h2 class="title">每日不同課程可自由選擇</h2>
                <div class="sm:hidden mb-5 flex justify-center">
                    <div class="dropdown dropdown-hover dropdown-end">
                        <label tabindex="0" class="btn"
                            >{{ tabName }}
                            <svg
                                class="fill-white"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                            >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                            </svg>
                        </label>
                        <ul tabindex="0" class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                            <li v-for="(item, index) in ticketCatalogUpper" :key="index">
                                <button @click="getTicketCourseCatalogList(index)">{{ item.name }}</button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="hidden sm:block w-full sm:w-fit bg-white mx-auto rounded-full mb-12" data-aos="fade-down">
                    <div class="btn-group">
                        <template v-for="(item, index) in ticketCatalogUpper">
                            <a
                                :key="index"
                                class="btn btn-active text-white"
                                v-if="item.status"
                                @click="getTicketCourseCatalogList(index)"
                                >{{ item.name }}</a
                            >
                            <a
                                :key="index"
                                class="btn text-white"
                                v-if="!item.status"
                                @click="getTicketCourseCatalogList(index)"
                                >{{ item.name }}</a
                            >
                        </template>
                    </div>
                </div>
                <CourseCatalog :items="catalog" v-on:childValue="childValue"></CourseCatalog>
            </CustomizeSection>

            <CustomizeSection tranglelt>
                <div class="main-description">
                    <div class="text-center">
                        <h2 class="text-2xl text-primary-400 text-center my-12">
                            JOIN US <span class="text-white">感受身心靈的成長 </span>
                        </h2>
                        <div class="space-x-4">
                            <router-link
                                class="btn glass btn-lg mb-3"
                                :to="{ name: 'Curriculum', query: { TKT_CAT_ID: details.TKT_CAT_ID } }"
                                >課程表</router-link
                            >
                            <a
                                class="btn glass btn-lg mb-3"
                                target="_blank"
                                href="https://drive.google.com/file/d/1sRMPLFbmOYTxb5nGt2RDA_TUmJOSlM3r/view?usp=sharing"
                                >瑜珈有氧課程及票券使用說明</a
                            >
                        </div>
                    </div>
                </div>
                <div class="main-img bg-01" v-rellax="{ speed: 0.5, center: true }"></div>
            </CustomizeSection>
        </template>
        <!-- 健身期課 -->
        <template v-if="details.TKT_TEMPLATE == 3">
            <h2 class="title text-center my-5">近期課程</h2>
            <div
                class="text-center sm:block w-full sm:w-fit bg-white mx-auto rounded-full mb-12"
                data-aos="fade-down"
                id="dropdown"
            >
                <div class="dropdown">
                    <button
                        class="btn dropdown-toggle m-1 text-light"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        {{ CourseCatalog }}
                    </button>
                    <ul
                        class="dropdown-menu p-2 shadow bg-base-100 rounded-box w-52"
                        aria-labelledby="dropdownMenuButton1"
                    >
                        <li
                            class="p-2"
                            style="cursor: pointer"
                            v-for="(item, index) in courseCatalogUpper"
                            :key="index"
                            @click="changeCourseCatalogList(index)"
                        >
                            <a>{{ item.name }}</a>
                        </li>
                    </ul>
                </div>
                <div class="dropdown">
                    <button
                        class="btn dropdown-toggle m-1 text-light"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        {{ CourseWeek }}
                    </button>
                    <ul
                        class="dropdown-menu p-2 shadow bg-base-100 rounded-box w-52"
                        aria-labelledby="dropdownMenuButton1"
                    >
                        <li
                            class="p-2"
                            style="cursor: pointer"
                            v-for="(item, index) in days"
                            :key="index"
                            @click="changeCourseCatalogList2(index)"
                        >
                            <a>{{ item.name }}</a>
                        </li>
                    </ul>
                </div>
                <div class="dropdown">
                    <button
                        class="btn dropdown-toggle m-1 text-light"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <span v-if="CourseStatus == 0">全部</span>
                        <span v-if="CourseStatus == 1">開放報名中</span>
                        <span v-if="CourseStatus == 2">開課中</span>
                        <span v-if="CourseStatus == 3"> 課程狀態</span>
                    </button>
                    <ul
                        class="dropdown-menu p-2 shadow bg-base-100 rounded-box w-52"
                        aria-labelledby="dropdownMenuButton1"
                    >
                        <li @click="changeCourseCatalogList3(0)" class="p-2" style="cursor: pointer">
                            <a>全部</a>
                        </li>
                        <li @click="changeCourseCatalogList3(1)" class="p-2" style="cursor: pointer">
                            <a>開放報名中</a>
                        </li>
                        <li @click="changeCourseCatalogList3(2)" class="p-2" style="cursor: pointer">
                            <a>開課中</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div :style="catalog.length > 0 ? '' : 'margin-bottom: 15rem;'" class="container mx-auto">
                <div class="col-12" v-if="catalog.length > 0">
                    <div v-for="(item, i) in catalog" :key="i" class="courses-new">
                        <figure class="figure">
                            <img v-if="item.url" :src="item.url" data-aos="fade-up" />
                            <img v-else src="@/assets/image/no-image.png" data-aos="fade-up" />
                        </figure>
                        <div class="info">
                            <h2 class="title">
                                <span> {{ item.title2 }} </span><br />
                                <small class="badge badge-lg badge-secondary text-white">{{ item.title }}</small>
                            </h2>
                            <div class="mb-8" v-if="item.introduce" v-html="item.introduce"></div>
                            <div class="stats stats-vertical md:stats-horizontal shadow mb-4 border w-full">
                                <div class="stat">
                                    <div class="stat-title">費用</div>
                                    <div class="stat-value text-3xl text-center">
                                        <span class="text-lg">NT$</span><span>{{ item.price }}</span>
                                    </div>
                                </div>
                                <div class="stat">
                                    <div class="stat-title">堂數</div>
                                    <div class="stat-value text-3xl text-center">{{ item.timeCount }}</div>
                                </div>

                                <div class="stat">
                                    <div class="stat-title">課程時間</div>
                                    <div class="stat-value text-base text-center">
                                        {{ item.timeDate }} <br />每週{{ item.timeWeek }} {{ item.time }}
                                    </div>
                                </div>
                                <div class="stat" v-if="IsNull(item.trainerName) != ''">
                                    <div class="stat-title">教練</div>
                                    <div class="avatar online" v-if="IsNull(item.trainerUrl) != ''">
                                        <div class="w-16 rounded-full">
                                            <img :src="item.trainerUrl" alt="" />
                                        </div>
                                    </div>
                                    <div class="stat-value text-base text-center">
                                        {{ item.trainerName }}
                                    </div>
                                </div>
                            </div>
                            <button
                                v-if="item.enerollCount > item.applyCount && item.isApply == 0 && item.canApply"
                                class="btn w-full sm:w-auto text-white"
                                @click="addShopCart(item.id)"
                            >
                                報名課程
                            </button>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-12" v-else>尚無課程</div> -->
            </div>
        </template>
        <!-- 私人教練 -->
        <template v-else-if="details.TKT_TEMPLATE == 4">
            <CustomizeSection tranglelb>
                <div class="relative z-30 mb-96 sm:mb-0">
                    <h2 class="title">私人教練課程的好處？</h2>
                    <div class="flex justify-end">
                        <ul class="feature">
                            <li class="item" data-aos="fade-down">
                                <img src="@/assets/image/Frame_001.png" class="mx-auto" />
                                <strong>專屬課表</strong>
                                依照訓練目標打造個人專屬訓練菜單，教練就是您的專屬陪練員。
                            </li>
                            <li class="item" data-aos="fade-down" data-aos-delay="50">
                                <img src="@/assets/image/Frame-002.png" class="mx-auto" />
                                <strong>彈性時段</strong>
                                上課時間彈性，可依照自己的行程規劃安排課程。
                            </li>
                            <li class="item" data-aos="fade-down" data-aos-delay="100">
                                <img src="@/assets/image/Frame-003.png" class="mx-auto" />
                                <strong>動作指導</strong>
                                完整的提供課前的熱身到課後的伸展放鬆。
                            </li>
                            <li class="item" data-aos="fade-down" data-aos-delay="150">
                                <img src="@/assets/image/Frame-004.png" class="mx-auto" />
                                <strong>完整流程</strong>
                                專業的動作指導、確保操作過程安全正確。
                            </li>
                            <li class="item" data-aos="fade-down" data-aos-delay="200">
                                <img src="@/assets/image/Frame-005.png" class="mx-auto" />
                                <strong>專業建議</strong>
                                可給予不同面向的建議，達到最佳的健身效果。
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="flex absolute left-4 bottom-0 items-start">
                    <img
                        v-rellax="{ speed: -0.5 }"
                        data-aos="fade-left"
                        class="rounded-3xl"
                        src="@/assets/image/role/role-01.png"
                    />
                    <a
                        style="z-index: 50"
                        href="https://line.me/R/ti/p/%40873aalha"
                        target="_blank"
                        v-rellax="{ speed: 1 }"
                        data-aos="fade-right"
                        class="self-start w-48 rounded-full bg-opacity-60 aspect-square bg-primary-300 flex justify-center items-center text-secondary text-2xl -ml-32"
                    >
                        JOIN US</a
                    >
                </div>
            </CustomizeSection>
            <CustomizeSection tranglelb>
                <h2 class="title">我們的教練</h2>
                <CourseCatalog :items="trainer" perPage="5" v-on:childValue="childValue"></CourseCatalog>
            </CustomizeSection>
            <CustomizeSection tranglerb>
                <h2 class="title">如何邁向您的目標呢？</h2>
                <ul class="progress_a">
                    <li data-aos="fade-right">
                        <div>
                            <div><img src="@/assets/image/aaa-01.png" class="mx-auto" /><br />線上諮詢</div>
                            <strong class="number">1</strong>
                        </div>
                    </li>
                    <li data-aos="fade-right" data-aos-delay="50">
                        <span class="arrow"></span>
                        <div>
                            <div><img src="@/assets/image/aaa-02.png" class="mx-auto" /><br />確認方案</div>
                            <strong class="number">2</strong>
                        </div>
                    </li>
                    <li data-aos="fade-right" data-aos-delay="100">
                        <span class="arrow"></span>
                        <div>
                            <div><img src="@/assets/image/aaa-03.png" class="mx-auto" /><br />媒合教練</div>
                            <strong class="number">3</strong>
                        </div>
                    </li>
                    <li data-aos="fade-right" data-aos-delay="150">
                        <span class="arrow"></span>
                        <div>
                            <div><img src="@/assets/image/aaa-04.png" class="mx-auto" /><br />堅持約課</div>
                            <strong class="number">4</strong>
                        </div>
                    </li>
                </ul>
            </CustomizeSection>
        </template>
        <!-- 自訂項目 -->

        <!-- 通用收費別表 -->
        <CustomizeSection tranglert v-if="details.TKT_TEMPLATE != 3" class="!bg-secondary-50">
            <h2 class="title">收費方式</h2>
            <PriceList
                :priceList="priceList"
                :tktcid="details.TKT_CAT_ID"
                @changeNID="changeNID"
                @buy="goBuyTicket"
            ></PriceList>
        </CustomizeSection>

        <input type="checkbox" id="show-modal" v-model="showModal" class="modal-toggle" />
        <div class="modal">
            <label class="modal-box w-11/12 max-w-5xl relative">
                <label @click="showModal = !showModal" class="btn btn-sm btn-circle absolute right-2 top-2 z-[100]">
                    <svg xmlns="http://www.w3.org/2000/svg" class="fill-white h-5" viewBox="0 0 24 24">
                        <path
                            d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12L20 6.91Z"
                        />
                    </svg>
                </label>
                <div class="flex sm:flex-row flex-col sm:space-x-6">
                    <div class="avatar flex-1">
                        <div class="rounded-xl mb-4 sm:mb-0">
                            <img v-if="moreInfo.url" :src="moreInfo.url" :alt="moreInfo.title" />
                            <img v-else src="../../assets/image/no-image.png" :alt="moreInfo.title" />
                        </div>
                    </div>
                    <div class="flex-1">
                        <h3 class="text-lg font-bold">{{ moreInfo.title }}</h3>
                        <p v-if="moreInfo.introduce" v-html="moreInfo.introduce" class="py-4"></p>
                        <dl class="trainerInfo" v-if="moreInfo.trainer">
                            <template v-if="IsNull(moreInfo.discription)">
                                <dt>介紹</dt>
                                <dd v-html="moreInfo.discription" style="white-space: pre-wrap"></dd>
                            </template>
                            <template v-if="IsNull(moreInfo.experience)">
                                <dt>經歷</dt>
                                <dd v-html="moreInfo.experience" style="white-space: pre-wrap"></dd>
                            </template>
                            <template v-if="IsNull(moreInfo.expertise)">
                                <dt>專長</dt>
                                <dd v-html="moreInfo.expertise" style="white-space: pre-wrap"></dd>
                            </template>
                            <template v-if="IsNull(moreInfo.license)">
                                <dt>證照</dt>
                                <dd v-html="moreInfo.license" style="white-space: pre-wrap"></dd>
                            </template>
                        </dl>
                    </div>
                </div>
            </label>
        </div>
        <LoadingPage :loadSet="loadSet"></LoadingPage>
    </main>
</template>

<script>
    import {
        apiToken,
        apiTICKET_CATALOG_GET,
        apiTICKET_LIST2,
        apiCOURSE_CATALOG_UPPER_LIST,
        apiTICKET_COURSE_CATALOG_UPPER_LIST,
        apiCOURSE_TYPE_CATALOG_UPPER,
        apiTICKET_COURSE_CATALOG_LIST,
        apiCOURSE_TIME_INFO,
        apiCOURSE_LIST2,
        apiCOURSE_IMAGE_GET,
        apiIS_COURSE,
        apiTRAINER_LIST,
        apiCOURSE_APPLY,
        apiMEMBER_PROFILE_CHECK,
    } from '../../api/API';
    import CustomizeSection from '@/components/CustomizeSection.vue';
    import PriceList from '@/components/PriceList.vue';
    import CourseCatalog from '@/components/CourseCatalog.vue';

    export default {
        name: 'CourseAA',
        data() {
            return {
                CourseCatalog: '課程類別',
                CourseWeek: '上課星期',
                CourseStatus: 3,
                tabName: '全部',
                ticketCatalogUpper: [],
                courseCatalogUpper: [],
                courseCatalogUpperIdTemp: [],
                days: [
                    { id: null, name: '全部', status: true },
                    { id: '一', name: '星期一', status: false },
                    { id: '二', name: '星期二', status: false },
                    { id: '三', name: '星期三', status: false },
                    { id: '四', name: '星期四', status: false },
                    { id: '五', name: '星期五', status: false },
                    { id: '六', name: '星期六', status: false },
                    { id: '日', name: '星期日', status: false },
                ],
                daysSelected: 0,
                catalogSelected: 0,
                statusSelected: 0,
                catalog: [],
                moreInfo: {},
                showModal: false,
                catalogTemp: [],

                receipt: {
                    OD_RECEIPT_TYPE: 0,
                    OD_RECIPT_TITLE: null,
                    OD_UNICODE_NUMBER: null,
                },
                priceList: [
                    {
                        title: '單堂課程',
                        description: '免綁約，輕鬆體驗無負擔。',
                        plan: [],
                    },
                    {
                        title: '單次套票',
                        description: '為不同身分別的學員客製精選套票方案，親民的價格學習多樣化的運動課程。',
                        plan: [],
                    },
                    {
                        title: '會員會期',
                        description: '依照不同需求客製超值優惠專案，超值的價格享受高規格的運動環境。',
                        plan: [],
                    },
                ],

                priceListDescription: [
                    '免綁約，輕鬆體驗無負擔。',
                    '為不同身分別的學員客製精選套票方案，親民的價格學習多樣化的運動課程。',
                    '依照不同需求客製超值優惠專案，超值的價格享受高規格的運動環境。',
                ],

                ticketListTemp: [[], [], []],
                trainer: [],

                details: {
                    TKT_CAT_ID: null,
                    PHOTO_URL: null,
                    TKT_CAT_DISCRIPTION: null,
                    TKT_CAT_NAME: null,
                    TKT_TEMPLATE: null,
                    TKT_CAT_ONCE_DESCRIPTION: null,
                    TKT_CAT_PACKAGE_DESCRIPTION: null,
                    TKT_CAT_CYCLE_DESCRIPTION: null,
                },

                checkCourse: 0,

                loadSet: { show: false, fullPage: true, type: 'dots', color: 'rgba(0,0,0,0.7)' },
            };
        },
        mounted() {
            let container = this.$refs.sportList;
            console.log(container);
            this.init();
        },
        methods: {
            childValue: function (childValue) {
                this.moreInfo = childValue;
                this.showModal = true;
            },
            async init() {
                this.loadSet.show = false;
                if (this.$route.query.TKT_CAT_ID != undefined) {
                    this.details.TKT_CAT_ID = this.$route.query.TKT_CAT_ID;
                    await this.getTicketCatalog();
                    await this.getTicketList();

                    //瑜珈有氧
                    if (this.details.TKT_TEMPLATE == 2) {
                        await this.getCourseCatalogUpperList(null);
                        // await this.getCourseCatalogUpperList('CAT610fbb5e-7011-4a7c-b3d8-2668a7a7a465');
                        await this.getTicketCourseCatalogList(0);
                        // await this.getCourseTypeCatalogUpper();
                    }
                    //健身期課版面
                    else if (this.details.TKT_TEMPLATE == 3) {
                        //await this.getCourseTypeCatalogUpper()
                        this.courseCatalogUpper = [
                            {
                                id: null,
                                name: '全部',
                                status: false,
                            },
                        ];
                        this.courseCatalogUpperIdTemp = [];
                        if (this.IsNull(this.$session.get('ShoppingCart')) != '') {
                            this.loadSet.show = true;
                        }
                        await this.getCourseList(0);
                        if (this.IsNull(this.$session.get('ShoppingCart')) != '') {
                            this.loadSet.show = false;
                        }
                    }
                    //私人教練版面
                    else if (this.details.TKT_TEMPLATE == 4) {
                        await this.getTrainerList();
                    }

                    if (this.IsNull(this.$session.get('ShoppingCart')) != '') {
                        var shop = this.$session.get('ShoppingCart');
                        this.addShopCart(shop.values.id);
                    }
                    document.getElementById('dropdown').style.zIndex = '50';
                    document.getElementById('dropdown').style.position = 'relative';
                } else {
                    this.$router.push('/');
                }
            },
            changeNID(nidIndex) {
                this.priceList = [
                    {
                        title: '單堂課程',
                        description: '免綁約，輕鬆體驗無負擔。',
                        plan: [],
                    },
                    {
                        title: '單次套票',
                        description: '為不同身分別的學員客製精選套票方案，親民的價格學習多樣化的運動課程。',
                        plan: [],
                    },
                    {
                        title: '會員會期',
                        description: '依照不同需求客製超值優惠專案，超值的價格享受高規格的運動環境。',
                        plan: [],
                    },
                ];
                this.priceList[0].description = this.priceListDescription[0];
                this.priceList[1].description = this.priceListDescription[1];
                this.priceList[2].description = this.priceListDescription[2];

                for (let i in this.ticketListTemp) {
                    var typeIndex = this.ticketListTemp[i].TKT_TYPE;

                    var plan = {
                        title: this.ticketListTemp[i].TKT_NAME,
                        pricing: null,
                    };
                    switch (nidIndex) {
                        case 0:
                            plan.pricing = this.ticketListTemp[i].TKT_PRICE;
                            break;
                        case 1:
                            plan.pricing = this.ticketListTemp[i].TKT_EXEMPATION_STUDENT;
                            break;
                        case 2:
                            plan.pricing = this.ticketListTemp[i].TKT_EXEMPATION_STAFF;
                            break;
                        case 3:
                            plan.pricing = this.ticketListTemp[i].TKT_EXEMPATION_ALUMNI;
                            break;
                        case 4:
                            plan.pricing = this.ticketListTemp[i].TKT_EXEMPATION_DEPENDENTS;
                            break;
                    }

                    //如果為特價時間
                    if (
                        this.ticketListTemp[i].TKT_IS_OFFER == 1 &&
                        new Date() <= new Date(this.ticketListTemp[i].TKT_OFFER_DATE)
                    ) {
                        if (this.ticketListTemp[i].TKT_OFFER_AMOUNT != null)
                            plan.pricing = this.ticketListTemp[i].TKT_OFFER_AMOUNT;
                    }

                    if (plan.pricing == null) plan.pricing = this.ticketListTemp[i].TKT_PRICE;

                    switch (typeIndex) {
                        case 0:
                            plan.title += ' 1張';
                            this.priceList[0].plan.push(plan);
                            break;
                        case 1:
                            plan.title += ' ' + this.ticketListTemp[i].TKT_QUANTITY + '堂';
                            this.priceList[1].plan.push(plan);
                            break;
                        case 2:
                            plan.title += ' ' + this.ticketListTemp[i].TKT_VALID_DATE + '天';
                            this.priceList[2].plan.push(plan);
                            break;
                    }
                }
            },
            //課程報名人數
            async getCourseApply(id) {
                var count = 0;
                try {
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        CUS_ID: id,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiCOURSE_APPLY(data)).data);

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        count = jsData.applyCpunt;
                        // console.log(jsData.applyCpunt)
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    //console.log(e)
                    alert(e);
                }
                return count;
            },
            //沒有購物車 直接購買  寫法改變
            async addShopCart(id) {
                if (this.IsNull(this.$session.get('ACID')) != '') {
                    await this.getIsCourse2(id);
                    this.$session.set('ShoppingCart', '');
                    if (this.checkCourse == null || this.checkCourse > 0) {
                        alert('您已經報名過此課程');
                    } else {
                        var status = await this.getMemberProfileCheck();
                        if (status) {
                            for (let i in this.catalog) {
                                if (id == this.catalog[i].id) {
                                    this.$router.push({
                                        name: 'buyCourse',
                                        query: { TKT_CAT_ID: this.details.TKT_CAT_ID },
                                        params: {
                                            item: this.catalog[i],
                                        },
                                    });
                                }
                                // console.log(this.catalog[i])
                            }
                        } else {
                            alert('請先填妥基本資料，以利進行後續購買程序。');
                            this.$router.push({ name: 'profile' });
                        }

                        //await this.addOrder(id)
                    }
                } else {
                    alert('請先登入');
                    var data = {
                        type: 'course',
                        values: { TKT_CAT_ID: this.details.TKT_CAT_ID, id: id },
                    };
                    this.$session.set('ShoppingCart', data);

                    this.$router.push('/Login');
                }
            },
            async getTicketCatalog() {
                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    var data = {
                        TKT_CAT_ID: this.details.TKT_CAT_ID,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiTICKET_CATALOG_GET(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        this.details = jsData;
                        this.priceListDescription[0] = this.details.TKT_CAT_ONCE_DESCRIPTION;
                        this.priceListDescription[1] = this.details.TKT_CAT_PACKAGE_DESCRIPTION;
                        this.priceListDescription[2] = this.details.TKT_CAT_CYCLE_DESCRIPTION;
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            async getTicketList() {
                try {
                    this.priceList = [
                        {
                            title: '單堂課程',
                            description: '免綁約，輕鬆體驗無負擔。',
                            plan: [],
                        },
                        {
                            title: '課程套票',
                            description: '為不同身分別的學員客製精選套票方案，親民的價格學習多樣化的運動課程。',
                            plan: [],
                        },
                        {
                            title: '包月課程',
                            description: '依照不同需求客製超值優惠專案，超值的價格享受高規格的運動環境。',
                            plan: [],
                        },
                    ];
                    this.priceList[0].description = this.priceListDescription[0];
                    this.priceList[1].description = this.priceListDescription[1];
                    this.priceList[2].description = this.priceListDescription[2];

                    this.ticketListTemp = [];

                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        TKT_CAT_ID: this.details.TKT_CAT_ID,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiTICKET_LIST2(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        this.ticketListTemp = jsData;
                        for (let i in jsData) {
                            var typeIndex = jsData[i].TKT_TYPE;

                            var plan = {
                                tkcid: jsData[i].TKT_SPORT,
                                title: jsData[i].TKT_NAME,
                                pricing: null,
                            };
                            plan.pricing = jsData[i].TKT_PRICE;

                            //如果為特價時間
                            if (jsData[i].TKT_IS_OFFER == 1 && new Date() <= new Date(jsData[i].TKT_OFFER_DATE)) {
                                if (jsData[i].TKT_OFFER_AMOUNT != null) plan.pricing = jsData[i].TKT_OFFER_AMOUNT;
                            }

                            switch (typeIndex) {
                                case 0:
                                    plan.title += ' 1堂';
                                    this.priceList[0].plan.push(plan);
                                    break;
                                case 1:
                                    plan.title += ' ' + this.ticketListTemp[i].TKT_QUANTITY + '堂';
                                    this.priceList[1].plan.push(plan);
                                    break;
                                case 2:
                                    plan.title += ' ' + this.ticketListTemp[i].TKT_VALID_DATE + '天';
                                    this.priceList[2].plan.push(plan);
                                    break;
                            }
                        }
                        //console.log(this.priceList)
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            async getCourseCatalogUpperList(CUS_CAT_ID) {
                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        CUS_CAT_ID: CUS_CAT_ID,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiCOURSE_CATALOG_UPPER_LIST(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        console.log(jsData);
                        this.ticketCatalogUpper = [
                            {
                                id: null,
                                name: '全部',
                                status: false,
                            },
                        ];
                        if (jsData.length > 0) {
                            for (let i in jsData) {
                                if (jsData[i].CUS_CAT_ISDISPLAY == 1) {
                                    this.ticketCatalogUpper.push({
                                        id: jsData[i].CUS_CAT_ID,
                                        name: jsData[i].CUS_CAT_NAME,
                                        status: false,
                                    });
                                }
                            }
                        }
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            async getTicketCourseCatalogUpperList() {
                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        TKT_CAT_ID: this.details.TKT_CAT_ID,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiTICKET_COURSE_CATALOG_UPPER_LIST(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        this.ticketCatalogUpper = [
                            {
                                id: null,
                                name: '全部',
                                status: false,
                            },
                        ];
                        if (jsData.length > 0) {
                            for (let i in jsData) {
                                this.ticketCatalogUpper.push({
                                    id: jsData[i].CUS_CAT_ID,
                                    name: jsData[i].CUS_CAT_NAME,
                                    status: false,
                                });
                            }
                        }
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            //取健身期課有啟用 的最上層類別
            async getCourseTypeCatalogUpper() {
                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        CUS_TYPE: 1,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiCOURSE_TYPE_CATALOG_UPPER(data)).data);
                    this.loadSet.show = false;
                    console.log(data);

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        console.log(jsData);
                        this.courseCatalogUpper = [
                            {
                                id: null,
                                name: '全部',
                                status: false,
                            },
                        ];
                        if (jsData.length > 0) {
                            for (let i in jsData) {
                                this.courseCatalogUpper.push({
                                    id: jsData[i].CUS_CAT_ID,
                                    name: jsData[i].CUS_CAT_NAME,
                                    status: false,
                                });
                            }
                        }
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            async getTicketCourseCatalogList(index) {
                try {
                    this.loadSet.show = true;

                    for (let i = 0; i < this.ticketCatalogUpper.length; i++) {
                        this.ticketCatalogUpper[i].status = false;
                    }
                    this.ticketCatalogUpper[index].status = true;
                    this.tabName = this.ticketCatalogUpper[index].name;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        CUS_CAT_ID: this.ticketCatalogUpper[index].id,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiTICKET_COURSE_CATALOG_LIST(data)).data);

                    this.loadSet.show = false;
                    if (result.Status) {
                        this.catalog = [];
                        var jsData = JSON.parse(result.Data);
                        for (let i in jsData) {
                            if (jsData[i].CUS_CAT_ISDISPLAY == 1) {
                                this.catalog.push({
                                    id: jsData[i].CUS_CAT_ID,
                                    title: jsData[i].CUS_CAT_NAME,
                                    introduce: jsData[i].CUS_CAT_DISCRIPTION,
                                    url: jsData[i].PHOTO_URL,
                                });
                            }
                        }
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },

            //課程類別
            changeCourseCatalogList(index) {
                this.catalogSelected = index;
                this.CourseCatalog = this.courseCatalogUpper[index].name;

                for (let i = 0; i < this.courseCatalogUpper.length; i++) {
                    this.courseCatalogUpper[i].status = false;
                }
                this.courseCatalogUpper[index].status = true;

                this.SelectCourseCatalog();
            },
            //上課星期
            changeCourseCatalogList2(index) {
                this.daysSelected = index;
                this.CourseWeek = this.days[index].name;

                for (let i = 0; i < this.days.length; i++) {
                    this.days[i].status = false;
                }
                this.days[index].status = true;

                this.SelectCourseCatalog();

                // if(index == 0){
                //     this.catalog = this.catalogTemp
                //     return
                // }

                // // console.log(this.catalogTemp)
                // var data1=[]

                // for(let i in this.catalogTemp){
                //     let item = this.catalogTemp[i]
                //     if(((item.catId == this.courseCatalogUpper[this.catalogSelected].id || item.catUpId == this.courseCatalogUpper[this.catalogSelected].id ) || this.catalogSelected == 0) &&
                //         item.timeWeek.indexOf(this.days[index].id) == 0){
                //         data1.push(item)
                //     }
                // }

                // this.catalog = data1
            },
            //課程狀態
            changeCourseCatalogList3(status) {
                this.statusSelected = status;
                this.CourseStatus = status;
                this.SelectCourseCatalog();
                // if(status == 0){
                //     this.catalog = this.catalogTemp
                //     return
                // }

                // var data1=[]

                // for(let i in this.catalogTemp){
                //     let item = this.catalogTemp[i]
                //     if(item.status == status){
                //         data1.push(item)
                //     }
                // }

                // this.catalog = data1
            },
            // 篩選
            SelectCourseCatalog() {
                this.catalog = [];
                if (this.catalogSelected == 0 && this.daysSelected == 0 && this.statusSelected == 0) {
                    this.catalog = this.catalogTemp;
                    return;
                }
                //console.log(this.search.type1)
                var data1 = [];

                for (let i in this.catalogTemp) {
                    let item = this.catalogTemp[i];
                    let a1 = 0,
                        a2 = 0;

                    // 類別
                    if (this.catalogSelected != 0) {
                        a1 += 1;
                        if (
                            item.catId == this.courseCatalogUpper[this.catalogSelected].id ||
                            item.catUpId == this.courseCatalogUpper[this.catalogSelected].id
                        ) {
                            a2 += 1;
                        }
                    }

                    // 星期
                    if (this.daysSelected != 0) {
                        a1 += 1;
                        console.log(item.timeWeek);
                        if (item.timeWeek.indexOf(this.days[this.daysSelected].id) >= 0) {
                            a2 += 1;
                        }
                    }

                    // 狀態
                    if (this.statusSelected != 0) {
                        a1 += 1;
                        if (item.status == this.statusSelected) {
                            a2 += 1;
                        }
                    }

                    if (a1 == a2) {
                        data1.push(item);
                    }
                }

                this.catalog = data1;

                if (this.catalog.length == 0) {
                    var msg = '';
                    if (this.catalogSelected != 0) msg += '類別、';
                    if (this.daysSelected != 0) msg += '星期、';
                    if (this.statusSelected != 0) msg += '狀態、';

                    alert('您篩選的' + msg.substring(0, msg.length - 1) + '目前尚未有課程，請變更您的篩選條件');
                }
            },

            async getCourseList(index) {
                try {
                    this.catalog = [];
                    this.courseCatalogUpperIdTemp = [];
                    // this.loadSet.show = true
                    for (let i = 0; i < this.courseCatalogUpper.length; i++) {
                        this.courseCatalogUpper[i].status = false;
                    }
                    this.courseCatalogUpper[index].status = true;

                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        CUS_CAT_ID: this.courseCatalogUpper[index].id,
                        CUS_TYPE: 1,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    this.loadSet.show = true;
                    var result = JSON.parse(await (await apiCOURSE_LIST2(data)).data);

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        // console.log(jsData)
                        if (jsData.length > 0) {
                            // var catalogTemp = []
                            for (let i in jsData) {
                                // if(catalogTemp.includes(jsData[i].))
                                let isApply = await this.getIsCourse(jsData[i].CUS_ID);
                                let applyCount = await this.getCourseApply(jsData[i].CUS_ID);
                                let time = await this.getCourseTimeInfo(jsData[i].CUS_ID);
                                var week = [];
                                for (let j in time) {
                                    var day = new Date(time[j].CT_DATE);
                                    let wk = day.getDay();
                                    if (!week.includes(wk)) {
                                        if (wk == 0) week.push(7);
                                        else week.push(wk);
                                    }
                                }

                                week.sort();
                                var weekChinese = '';
                                for (let j in week) {
                                    let wk = week[j];
                                    weekChinese += this.getWeek(wk) + '、';
                                }
                                weekChinese = weekChinese.slice(0, weekChinese.length - 1);
                                // console.log(weekChinese)

                                var url = '';
                                if (this.IsNull(jsData[i].CUS_MCID) != '') {
                                    var image = await this.getCourseImage(jsData[i].CUS_ID);
                                    if (image != null) url = image.PHOTO_URL;
                                } else url = jsData[i].PHOTO_URL;

                                var array = {
                                    id: jsData[i].CUS_ID,
                                    catId: jsData[i].CUS_CAT_ID,
                                    catUpId: jsData[i].CUS_CAT_UPPER,
                                    title: jsData[i].CUS_CAT_NAME,
                                    title2: jsData[i].CUS_NAME,
                                    introduce: jsData[i].CUS_DESCRIPTION,
                                    url: url,
                                    price: jsData[i].CUS_FIXED_PRICE,
                                    timeWeek: weekChinese,
                                    isApply: isApply,
                                    applyCount: applyCount,
                                    enerollCount: jsData[i].CUS_ENROLLMENT,
                                    timeCount: null,
                                    timeDate: null,
                                    time: null,
                                    trainerName: jsData[i].PPL_NAME,
                                    trainerUrl: jsData[i].TR_PHOTO_URL,
                                    canApply:
                                        time.length > 0
                                            ? new Date() < new Date(time[0].CT_DATE + 'T' + time[0].CT_STAR_TIME)
                                            : false,
                                    status: null,
                                };

                                if (time.length > 0) {
                                    array.timeCount = time.length;
                                    if (time[0].CT_DATE != null && time[time.length - 1].CT_DATE != null) {
                                        array.timeDate = time[0].CT_DATE + ' ~ ' + time[time.length - 1].CT_DATE;
                                        array.time = time[0].CT_STAR_TIME + ' ~ ' + time[0].CT_END_TIME;

                                        if (new Date() < new Date(time[0].CT_DATE + 'T' + time[0].CT_STAR_TIME))
                                            array.status = 1;
                                        else {
                                            if (
                                                new Date(time[0].CT_DATE + 'T' + time[0].CT_STAR_TIME) <= new Date() &&
                                                new Date() <=
                                                    new Date(
                                                        time[time.length - 1].CT_DATE +
                                                            'T' +
                                                            time[time.length - 1].CT_END_TIME,
                                                    )
                                            )
                                                array.status = 2;
                                        }
                                    }
                                }

                                if (this.IsNull(array.introduce) == '') array.introduce = jsData[i].CUS_CAT_DISCRIPTION;

                                //如果為特價時間
                                if (jsData[i].CUS_IS_OFFER == 1 && new Date() <= new Date(jsData[i].CUS_OFFER_EDATE)) {
                                    if (jsData[i].CUS_OFFER_AMOUNT != null) array.price = jsData[i].CUS_OFFER_AMOUNT;
                                } else if (this.IsNull(this.$session.get('PPL_NID_IDENTITY')) != '') {
                                    switch (this.$session.get('PPL_NID_IDENTITY')) {
                                        case 1:
                                            if (jsData[i].CUS_STUDENT_PRICE != null)
                                                array.price = jsData[i].CUS_STUDENT_PRICE;
                                            break;
                                        case 2:
                                            if (jsData[i].CUS_STAFF_PRICE != null)
                                                array.price = jsData[i].CUS_STAFF_PRICE;
                                            break;
                                        case 3:
                                            if (jsData[i].CUS_ALUMNI_PRICE != null)
                                                array.price = jsData[i].CUS_ALUMNI_PRICE;
                                            break;
                                        case 4:
                                            if (jsData[i].CUS_DEPENDENTS_PRICE != null)
                                                array.price = jsData[i].CUS_DEPENDENTS_PRICE;
                                            break;
                                    }
                                }

                                this.catalogTemp.push(array);
                                if (
                                    jsData[i].CUS_CAT_UPPER == 'CATc528215b-3290-44a7-b917-5a5b396d9d0b' &&
                                    this.IsNull(jsData[i].CUS_CAT_ID) != '' &&
                                    !this.courseCatalogUpperIdTemp.includes(jsData[i].CUS_CAT_ID)
                                ) {
                                    this.courseCatalogUpper.push({
                                        id: jsData[i].CUS_CAT_ID,
                                        name: jsData[i].CUS_CAT_NAME,
                                        status: false,
                                    });
                                    this.courseCatalogUpperIdTemp.push(jsData[i].CUS_CAT_ID);
                                }
                            }
                            this.catalog = this.catalogTemp;
                        }
                    } else {
                        alert(result.Message);
                    }

                    this.loadSet.show = false;
                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            async getCourseTimeInfo(id) {
                try {
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        CUS_ID: id,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiCOURSE_TIME_INFO(data)).data);

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);

                        return jsData;
                    } else {
                        return null;
                    }

                    //console.log(result.Message)
                } catch (e) {
                    //console.log(e)
                    return null;
                }
            },
            async getCourseImage(id) {
                try {
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        CUS_ID: id,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiCOURSE_IMAGE_GET(data)).data);

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);

                        return jsData;
                    } else {
                        return null;
                    }

                    //console.log(result.Message)
                } catch (e) {
                    //console.log(e)
                    return null;
                }
            },
            async getIsCourse(id) {
                var isApply = 0;
                try {
                    // this.checkCourse = 0;

                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        CUS_ID: id,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiIS_COURSE(data)).data);

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        isApply = jsData.IsCourse;
                        // this.checkCourse = jsData.IsCourse
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    //console.log(e)
                    alert(e);
                }
                return isApply;
            },
            async getIsCourse2(id) {
                try {
                    this.checkCourse = 0;

                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        CUS_ID: id,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiIS_COURSE(data)).data);

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        this.checkCourse = jsData.IsCourse;
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    //console.log(e)
                    alert(e);
                }
            },
            async getTrainerList() {
                try {
                    this.trainer = [];
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiTRAINER_LIST(data)).data);

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        for (var i in jsData) {
                            this.trainer.push({
                                TR_ID: jsData[i].TR_ID,
                                PPL_ID: jsData[i].TR_PPL_ID,
                                title: jsData[i].PPL_NAME,
                                discription: jsData[i].TR_DISCRIPTION,
                                experience: jsData[i].TR_EXPERIENCE,
                                expertise: jsData[i].TR_EXPERTISE,
                                license: jsData[i].TR_LICENSE,
                                url: jsData[i].PHOTO_URL,
                                trainer: true,
                            });
                        }
                        //console.log(jsData)
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    //console.log(e)
                    alert(e);
                }
            },
            getWeek(week) {
                var result = '';
                switch (week) {
                    case 7:
                        result = '日';
                        break;
                    case 1:
                        result = '一';
                        break;
                    case 2:
                        result = '二';
                        break;
                    case 3:
                        result = '三';
                        break;
                    case 4:
                        result = '四';
                        break;
                    case 5:
                        result = '五';
                        break;
                    case 6:
                        result = '六';
                        break;
                }
                return result;
            },
            goBuyTicket(tkcid, catalog) {
                this.$router.push({ name: 'buyTickets', params: { tkcid: tkcid, catalog: catalog } });
            },
            async getMemberProfileCheck() {
                var status = false;
                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiMEMBER_PROFILE_CHECK(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        // console.log(jsData)
                        status = jsData.status;
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }

                return status;
            },
        },
        watch: {
            // 禁用下一行eslint检查
            // eslint-disable-next-line
            $route(to, from) {
                if (to.path === '/Course') {
                    location.reload();
                }
            },
            'receipt.OD_RECEIPT_TYPE': function () {
                this.receipt.OD_RECIPT_TITLE = null;
                this.receipt.OD_UNICODE_NUMBER = null;
            },
        },
        components: {
            CustomizeSection,
            PriceList,
            CourseCatalog,
        },
    };
</script>
<style lang="postcss" scoped>
    .courses-new {
        @apply bg-white shadow-xl sm:shadow-none flex flex-col lg:flex-row lg:space-x-8  justify-center border sm:border-0 border-secondary-300 py-4 lg:py-12 px-6 lg:px-0 mb-8 lg:mb-0 rounded-2xl;
        .figure {
            @apply w-full lg:w-1/3 xl:w-2/5 flex-initial relative mb-4 sm:mb-0;
            &::after {
                clip-path: polygon(0 10%, 100% 0%, 100% 90%, 0% 100%);
                @apply w-4/5 absolute right-0 top-0 z-30 block bg-primary-500  aspect-square rounded-lg;
                content: '';
            }
            img {
                clip-path: polygon(0 10%, 100% 0%, 100% 90%, 0% 100%);
                @apply w-4/5 relative z-40 mt-8 rounded-lg;
            }
        }
        .info {
            @apply w-full lg:w-2/3 xl:w-3/5 flex-initial self-start p-0 rounded;
            .title {
                @apply text-left lg:border-t border-primary-500 sm:pt-8 text-slate-700 mb-4 sm:mb-4;
            }
            .datetime {
                @apply sm:bg-secondary-500  sm:py-3 sm:px-16 sm:text-center rounded-3xl sm:rounded-full sm:text-white sm:inline-block;
                li {
                    @apply border-b border-secondary-500 py-4 sm:py-0;
                }
                .attr {
                    @apply sm:hidden text-secondary-400;
                }
                .date {
                    @apply sm:text-2xl;
                }
            }
            .trainer {
                @apply text-center mt-8;
                .photo {
                    @apply w-48 aspect-square rounded-full overflow-hidden border-4 border-secondary-300 mx-auto;
                }
                .name {
                    @apply -mt-12;
                    span {
                        @apply origin-center rotate-6 border bg-white inline-block px-6 py-2 shadow;
                    }
                }
            }
            .price {
                @apply text-2xl text-red-700;
            }
        }
    }

    .progress_a {
        @apply flex justify-center flex-col sm:flex-row;
        li {
            @apply w-full sm:w-1/4 text-center border-primary-300 text-2xl flex flex-col sm:flex-row items-center border-b-2 sm:border-b-0 sm:border-l-2;
            > div {
                @apply flex justify-between items-end w-full p-8;
            }
            .arrow {
                @apply w-8 h-8 border-r-2 border-b-2 sm:border-b-0 sm:border-t-2 border-primary-300 block bg-white -mt-4 sm:mt-0 sm:-ml-4 relative;
                transform: rotate(45deg);
                &::after {
                    @apply w-8 h-8 border-r-8 border-b-8 sm:border-b-0 sm:border-t-8 border-primary-500 block absolute -right-3 sm:-right-4 -bottom-3 sm:bottom-3;
                    content: '';
                }
            }
            &:first-child {
                @apply border-l-0;
                &::before {
                    @apply hidden;
                }
            }
            &:last-child {
                @apply border-b-0;
            }
            .number {
                @apply text-9xl text-secondary-400 font-light -mb-4;
            }
        }
    }

    .bg-01 {
        background-image: url('@/assets/image/situation-001.jpg');
    }
</style>
