<template>
    <main>
        <header class="pt-24 sm:pt-36 pb-10 sm:pb-24">
            <h1 class="title-01">{{ details.TKT_CAT_NAME }}-課程表</h1>
        </header>
        <CustomizeSection class="fdhsjkdh">
            <div class="sm:text-3xl text-center mb-8 text-primary-500">
                {{ dateRange.min.year }} 年 {{ dateRange.min.month }} 月 {{ dateRange.min.day }} 日
                <span class="hidden sm:inline">星期{{ dateRange.min.week }}</span> ~
                <span class="hidden sm:inline">{{ dateRange.max.year }}</span> 年 {{ dateRange.max.month }} 月
                {{ dateRange.max.day }} 日 <span class="hidden sm:inline">星期{{ dateRange.max.week }}</span>
            </div>
            <div class="flex gap-4 items-center justify-center mb-8">
                <button
                    class="btn btn-outline btn-warning text-white"
                    @click="updateData(-7)"
                    style="border-color: #f6d67f"
                >
                    <svg
                        class="fill-primary-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                    >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
                    </svg>
                </button>
                <button class="btn btn-warning text-white" @click="resetData">今天</button>
                <button
                    class="btn btn-outline btn-warning text-white"
                    style="border-color: #f6d67f"
                    @click="updateData(7)"
                >
                    <svg
                        class="fill-primary-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                    >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                    </svg>
                </button>
            </div>
            <div class="curriculum">
                <div v-for="(item, index) in curriclum" class="day" :key="index">
                    <div
                        class="p-2 flex justify-center"
                        :class="[
                            nowDate == item.month + '/' + item.day
                                ? 'bg-primary-500 text-black'
                                : 'bg-secondary-100 bg-opacity-20 text-white ',
                        ]"
                    >
                        <strong class="inline-block week mr-2"> {{ item.month }}/{{ item.day }} </strong>
                        <strong>星期 {{ item.week }}</strong>
                    </div>
                    <div class="pt-2 pb-10 sm:px-4">
                        <template v-for="(course, i) in item.courses">
                            <template>
                                <Curriculum2 :key="i" :info="course"> </Curriculum2>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
        </CustomizeSection>
        <LoadingPage :loadSet="loadSet"></LoadingPage>
    </main>
</template>

<script>
    import {
        apiToken,
        apiCURRICULUM_LIST,
        apiTICKET_COURSE_CATALOG_UPPER_LIST,
        apiTICKET_CATALOG_GET,
    } from '../../api/API';
    import CustomizeSection from '@/components/CustomizeSection.vue';
    import Curriculum2 from '@/components/CourseTemplate2.vue';
    import moment from 'moment';
    export default {
        name: 'CurriculumAA',
        data() {
            return {
                details: {
                    TKT_CAT_ID: null,
                },
                upper: {},
                nowDate: new Date().getTime(),
                dateRange: {
                    day: 0,
                    min: {
                        week: null,
                        day: null,
                        month: null,
                        year: null,
                    },
                    max: {
                        week: null,
                        day: null,
                        month: null,
                        year: null,
                    },
                },

                curriclum: [],
                curriclumIndex: {},

                loadSet: { show: false, fullPage: true, type: 'dots', color: 'rgba(0,0,0,0.7)' },
            };
        },
        created() {
            // 转换时间格式年月日时分秒
            this.nowDate = moment(this.nowDate).format('MM/DD');
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                this.loadSet.show = false;
                this.changeDate(0);
                if (this.$route.query.TKT_CAT_ID != undefined) {
                    this.details.TKT_CAT_ID = this.$route.query.TKT_CAT_ID;
                    await this.getTicketCatalog();
                    await this.getTicketCourseCatalogUpperList();
                    await this.getCurriclum();
                } else {
                    this.$router.push('/');
                }
            },
            async resetData() {
                this.dateRange.day = 0;
                this.changeDate(0);
                await this.getTicketCourseCatalogUpperList();
                await this.getCurriclum();
            },
            async updateData(day) {
                this.changeDate(day);
                await this.getTicketCourseCatalogUpperList();
                await this.getCurriclum();
            },
            changeDate(day) {
                this.dateRange.day += day;
                //將today改成每週的禮拜三
                //var today = new Date(new Date().setDate(new Date().getDate() + (this.dateRange.day - 1)))
                var nowWeek = new Date().getDay();
                var today = new Date(new Date().setDate(new Date().getDate() + (this.dateRange.day - 1 - nowWeek)));
                //console.log(today,"me")

                this.curriclumIndex = [];
                this.curriclum = [];
                for (var i = 0; i < 7; i++) {
                    new Date(today.setDate(today.getDate() + 1));
                    let year = today.getFullYear();
                    let month = this.TransformTime2String(today.getMonth() + 1);
                    let day = this.TransformTime2String(today.getDate());
                    let week = this.getWeek(today.getDay());

                    if (i == 0) {
                        this.dateRange.min.year = year;
                        this.dateRange.min.month = month;
                        this.dateRange.min.day = day;
                        this.dateRange.min.week = week;
                    } else if (i == 6) {
                        this.dateRange.max.year = year;
                        this.dateRange.max.month = month;
                        this.dateRange.max.day = day;
                        this.dateRange.max.week = week;
                    }

                    this.curriclumIndex[year + '-' + month + '-' + day] = i;
                    this.curriclum.push({
                        year: year,
                        month: month,
                        day: day,
                        week: week,
                        courses: [],
                    });
                }
            },
            getWeek(week) {
                var result = '';
                switch (week) {
                    case 0:
                        result = '日';
                        break;
                    case 1:
                        result = '一';
                        break;
                    case 2:
                        result = '二';
                        break;
                    case 3:
                        result = '三';
                        break;
                    case 4:
                        result = '四';
                        break;
                    case 5:
                        result = '五';
                        break;
                    case 6:
                        result = '六';
                        break;
                }
                return result;
            },
            async getTicketCatalog() {
                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        TKT_CAT_ID: this.details.TKT_CAT_ID,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiTICKET_CATALOG_GET(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        this.details = jsData;
                        //console.log(jsData)
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            async getTicketCourseCatalogUpperList() {
                try {
                    this.upper = {};
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        TKT_CAT_ID: this.details.TKT_CAT_ID,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiTICKET_COURSE_CATALOG_UPPER_LIST(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        for (var i in jsData) {
                            this.upper[jsData[i].CUS_CAT_ID] = {
                                CUS_CAT_ID: jsData[i].CUS_CAT_ID,
                                CUS_CAT_NAME: jsData[i].CUS_CAT_NAME,
                            };
                        }
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            async getCurriclum() {
                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var CAT_UPPER = [];
                    for (var it in this.upper) {
                        if (this.IsNull(this.upper[it].CUS_CAT_ID) != '') {
                            CAT_UPPER.push(this.upper[it].CUS_CAT_ID);
                        }
                    }

                    var data = {
                        CUS_CAT_UPPER: CAT_UPPER,
                        sDate: this.dateRange.min.year + '-' + this.dateRange.min.month + '-' + this.dateRange.min.day,
                        eDate: this.dateRange.max.year + '-' + this.dateRange.max.month + '-' + this.dateRange.max.day,
                        CUS_TYPE: 0,
                        Token: Token,
                    };
                    data = this.BaseModel(data);
                    // console.log(data);

                    var result = JSON.parse(await (await apiCURRICULUM_LIST(data)).data);
                    this.loadSet.show = false;
                    // console.log(result);
                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        console.log(jsData);
                        for (var i in jsData) {
                            //console.log(jsData[i]);
                            var CUS_CAT_NAME = this.upper[jsData[i].CUS_CAT_UPPER].CUS_CAT_NAME;
                            this.curriclum[this.curriclumIndex[jsData[i].CT_DATE]].courses.push({
                                starTime: jsData[i].CT_STAR_TIME,
                                endTime: jsData[i].CT_END_TIME,
                                title: jsData[i].CUS_CAT_NAME,
                                name: jsData[i].CUS_NAME,
                                name2: CUS_CAT_NAME,
                                trainer: jsData[i].PPL_NAME,
                                location: jsData[i].SP_NAME,
                                state: jsData[i].status,
                                style: {
                                    backgroundColor: jsData[i].CUS_CAT_COLOR,
                                },
                            });
                        }

                        //  console.log(this.curriclumIndex)
                        //  console.log(this.curriclum)
                        //  console.log(jsData)
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
        },

        components: {
            CustomizeSection,
            Curriculum2,
        },
    };
</script>
<style lang="postcss" scoped>
    .catalogs {
        @apply sm:flex justify-center gap-4 mb-8 hidden;

        a {
            @apply border-2 border-secondary-400 rounded-full px-6 py-1 hover:bg-primary-500 hover:border-primary-600 cursor-pointer hover:text-secondary-900;
            &.is-active {
                @apply bg-primary-500 border-primary-600 text-secondary-900;
            }
        }
    }
    .test {
        > div {
            .title {
                @apply text-2xl;
            }
            .price {
                span {
                    @apply text-3xl;
                }
            }
            @apply rounded-md bg-white shadow-lg p-8;
        }
    }
    .sportList {
        @apply relative w-full flex gap-6 snap-x snap-mandatory overflow-x-auto pb-14;
        &::-webkit-scrollbar {
            display: none;
        }
        .first-item,
        .last-item {
            width: calc(50vw - 184px);
        }
        .item {
            @apply snap-center shrink-0 w-80 border shadow-lg p-8 rounded-xl;

            .title {
                @apply text-2xl text-center mb-4;
            }
        }
    }
    .sport {
        .item {
            .photo {
                @apply border aspect-video rounded mb-1;
            }
            &:nth-child(4n + 1) {
                margin-top: 0rem;
            }
            &:nth-child(4n + 2) {
                margin-top: 4rem;
            }
            &:nth-child(4n + 3) {
                margin-top: 8rem;
            }
            &:nth-child(4n + 4) {
                margin-top: 12rem;
            }
        }
    }
    .curriculum {
        @apply grid sm:grid-cols-7;
    }
    .fdhsjkdh {
        background-image: url('../../assets/image/gym-004.jpg');
        background-size: cover;
        background-position: center;
    }
</style>
