<template>
   <div class="form-control w-full max-w-xs">
      <label class="label">
         <span class="label-text">{{label}}</span>
      </label>
      <select v-model="type" @change="change" class="select select-bordered" >
         <option value="" selected>{{initial}}</option>
         <option v-for="(item,index) in option" :key="index" :value="item.value">{{item.text}}</option>
      </select>
   </div>
</template>
<script>
export default {
   data() {
      return {
         filter: true,
         type: "",
      }
   },
   methods:{
      change(){
         this.$emit('change',this.type);
      }
   },
   props: {
      option: Array,
      initial: {
         type: String,
         default: '全部'
      },
      label: {
         type: String,
         default: '篩選標題'
      },
   },
}
</script>
<style lang="postcss" scoped>
.filter {
   .btn-filter {
      @apply block sm:hidden px-4 py-2;
   }
   .filterOptions {
      @apply bg-secondary-900 sm:bg-transparent p-4 sm:p-0 rounded w-full flex mb-8 sm:mb-0;
      .item {
         @apply w-full
      }
   }
}
@media (max-width: 640px) { 
   .aaaaa {
      @apply !hidden;
   }
}
</style>