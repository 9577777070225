import { render, staticRenderFns } from "./STabs.vue?vue&type=template&id=8067310a&scoped=true&"
import script from "./STabs.vue?vue&type=script&lang=js&"
export * from "./STabs.vue?vue&type=script&lang=js&"
import style0 from "./STabs.vue?vue&type=style&index=0&id=8067310a&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8067310a",
  null
  
)

export default component.exports