<template>
    <main>
        <Header title="場地租借紀錄"></Header>
        <div class="differential">
            <!-- <section class="mobile">
            <router-link :to="{ path: '/member/rentalDetails', query: { AE_ID: item.AE_ID }}" v-for="(item,index) in dataItems.reverse()" :key="index" class="item">

               <div class="calendar">
                  <span class="year">{{item.day.substr(0, 4)}}</span>
                  <div class="month-day">
                     <span>{{item.day.substr(5, 2)}}月</span><br />
                     <span class="day">{{item.day.substr(8, 2)}}</span>
                  </div>
               </div>

               <div class="info">
                  <h3 class="title">{{item.AE_SP_NAME}}</h3>
                  <ul>
                     <li> <span>時段</span>{{item.sTime}} ~ {{item.eTime}}</li>
                     <li> <span>人數</span>{{item.SQ_USE_QUANTITY}}／{{item.SQ_QUANTITY}}</li>
                  </ul>
               </div>
            </router-link>
         </section> -->
            <section class="main">
                <div style="text-align: right">提醒：如需入館QRcode，請至[我的商品]/[代碼取票]</div>
                <div class="overflow-x-auto">
                    <table class="table w-full tableGrid mt-3">
                        <thead style="background: #e5e5e5">
                            <tr>
                                <th class="fs-6" style="line-height: 1.5rem; border-bottom: unset" width="1%">
                                    租借日期
                                </th>
                                <th class="fs-6" style="line-height: 1.5rem; border-bottom: unset" width="1%">
                                    租借時段
                                </th>
                                <th class="fs-6" style="line-height: 1.5rem; border-bottom: unset">租借場地</th>
                                <th class="fs-6" style="line-height: 1.5rem; border-bottom: unset" width="1%">
                                    門禁開通代碼
                                </th>
                                <th class="fs-6" style="line-height: 1.5rem; border-bottom: unset" width="1%">
                                    門禁開通數量
                                </th>
                                <th class="fs-6" style="line-height: 1.5rem; border-bottom: unset" width="1%">
                                    已開通人數
                                </th>
                                <th class="fs-6" style="line-height: 1.5rem; border-bottom: unset" width="1%">
                                    詳細資料
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in dataItems" :key="index">
                                <td data-label="租借日期：">{{ item.day }}</td>
                                <td data-label="租借時段：">{{ item.sTime }} ~ {{ item.eTime }}</td>
                                <td data-label="租借場地：">{{ item.AE_NAME }}</td>
                                <td data-label="門禁開通代碼：">{{ item.SQ_ID }}</td>
                                <td data-label="門禁開通數量：">{{ item.SQ_QUANTITY }}</td>
                                <td data-label="已開通人數：">{{ item.SQ_USE_QUANTITY }}</td>
                                <td data-label="詳細資料：">
                                    <router-link
                                        class="btn btn-sm"
                                        :to="{ path: '/member/rentalDetails', query: { AE_ID: item.AE_ID } }"
                                        >檢視</router-link
                                    >
                                </td>
                            </tr>
                            <tr>
                                <td v-if="dataItems.length == 0">無資料</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
            <LoadingPage :loadSet="loadSet"></LoadingPage>
        </div>
    </main>
</template>
<script>
    import { apiToken, apiSPLIT_CODE_LIST2 } from '../../api/API';
    import Header from '@/components/MemberHeader.vue';
    export default {
        name: 'SpaceRental',
        components: {
            Header,
        },
        data() {
            return {
                dataItemsAll: [],
                dataItems: [],
                loadSet: { show: false, fullPage: true, type: 'dots', color: 'rgba(0,0,0,0.7)' },
            };
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                if (this.IsNull(this.$session.get('ACID')) == '') {
                    this.$router.push('/Login');
                } else {
                    await this.getSplitCodeList();
                }
            },
            async getSplitCodeList() {
                try {
                    this.dataItemsAll = [];
                    this.dataItems = [];

                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiSPLIT_CODE_LIST2(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        if (jsData.length > 0) {
                            for (let i in jsData) {
                                jsData[i].day = this.TransformTimeFormatYYYMMDD(jsData[i].AE_USE_DATE);

                                jsData[i].sTime = this.TransformTimeFormathhmm(jsData[i].AE_USE_DATE);
                                jsData[i].eTime = this.TransformTimeFormathhmm(jsData[i].AE_EXPIRATION_DATE);

                                jsData[i].AE_EXPIRATION_DATE = this.TransformTimeFormatYYYMMDDhhmm(
                                    jsData[i].AE_EXPIRATION_DATE,
                                );
                                jsData[i].SQ_DATE = this.TransformTimeFormatYYYMMDDhhmm(jsData[i].SQ_DATE);
                                jsData[i].AE_USE_DATE = this.TransformTimeFormatYYYMMDDhhmm(jsData[i].AE_USE_DATE);
                                this.dataItemsAll.push(jsData[i]);
                                this.dataItems.push(jsData[i]);
                            }
                        }
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
        },
    };
</script>

<style lang="postcss" scoped>
    .mobile {
        .item {
            @apply flex space-x-4 shadow p-4 bg-white mb-2 rounded-xl;
            .calendar {
                @apply w-24 text-center flex flex-col items-center rounded bg-opacity-30 shadow-lg border border-secondary-400 bg-white;
                .month-day {
                    @apply py-1;
                }
                span {
                    @apply w-full leading-none;
                    &.year {
                        @apply border-b py-2 border-secondary-400;
                    }
                    &.day {
                        @apply text-2xl font-bold;
                    }
                }
            }
            .info {
                @apply flex-1 flex justify-center flex-col;
                .title {
                    @apply text-xl mb-2;
                }
                ul {
                    @apply flex flex-wrap space-x-2;
                    li {
                        @apply mb-2  flex-auto;
                        &.space-name {
                            @apply w-full;
                        }
                        span {
                            @apply text-sm block text-gray-400;
                        }
                    }
                }
            }
        }
    }
</style>
