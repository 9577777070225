<template>
    <main>
        <Header backTo="/member/owns" title="我的商品"></Header>
        <div class="flex w-100 flex-wrap">
            <section class="col-md-4 col-12">
                <TicketView class="mb-8 w-100" :ticketDetails="ticketDetails">
                    <template v-if="details.AE_CATALOG == 0">
                        <router-link
                            v-if="
                                new Date() < new Date(details.AE_EXPIRATION_DATE) ||
                                this.IsNull(details.AE_EXPIRATION_DATE) == ''
                            "
                            :to="{ path: '/member/reservation', query: { AE_ID: details.AE_ID } }"
                            class="btn"
                        >
                            <!-- {{ $t("base.reserve") }} -->
                        </router-link>
                    </template>
                    <template v-if="details.AE_CATALOG == 1">
                        <a
                            v-if="
                                new Date() < new Date(details.AE_EXPIRATION_DATE) ||
                                this.IsNull(details.AE_EXPIRATION_DATE) == ''
                            "
                            @click="reserveSpace3"
                            class="btn"
                        >
                            預約
                        </a>
                    </template>
                </TicketView>
                <div>
                    課程注意事項
                    <div style="margin-top: 1rem; background: unset" v-html="criterion" id="criterion"></div>
                </div>
                <!-- <div class="whitespace-pre">{{ticketDetails}}</div> -->
            </section>
            <section class="flex-1 col-md-8 col-12 mt-md-0 mt-5 ps-0 ps-md-3">
                <div class="overflow-x-auto">
                    <table class="table w-full tableGrid">
                        <thead style="background: #e6e5e5">
                            <tr>
                                <th class="w-px p-3">課程日期</th>
                                <th v-if="details.AE_CATALOG == 0">課程名稱</th>
                                <th>課程地點</th>
                                <th class="w-px">操作</th>
                            </tr>
                        </thead>
                        <tbody v-if="productAll != ''" style="background: #fff">
                            <tr v-for="(item, index) in productAll" :key="index">
                                <td data-label="日期">
                                    <div>
                                        {{ item.info.date }}
                                        <br />
                                        {{ item.info.time }}
                                    </div>
                                </td>
                                <td v-if="details.AE_CATALOG == 0">{{ item.info.CUS_NAME }}</td>
                                <td data-label="上課地點">{{ item.info.location }}</td>
                                <td>
                                    <template v-if="item.status == 4"> 以轉為紙本票卷 </template>
                                    <template v-else>
                                        <button
                                            v-if="DayFormatter(item.usetime) == NowDay"
                                            class="btn"
                                            @click="openModal(item.id)"
                                        >
                                            領取進場QRcode
                                        </button>
                                        <span v-else-if="DayFormatter(item.usetime) > NowDay">未到領取時間</span>
                                        <span v-else>已過期</span>
                                    </template>
                                    <!-- <label for="open-qrcode" class="btn modal-button">二維條碼</label> -->
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else style="background: #fff">
                            <tr>
                                <td class="!text-center" colspan="4">
                                    此票卡尚未使用！
                                    <router-link
                                        v-if="new Date() < new Date(details.AE_EXPIRATION_DATE)"
                                        :to="{ path: '/member/reservation', query: { AE_ID: details.AE_ID } }"
                                    >
                                        <!-- {{ $t("base.reserve") }} -->
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
        <!-- The button to open modal -->

        <!-- Put this part before </body> tag -->
        <input type="checkbox" v-model="modal.qrcode" id="open-qrcode" class="modal-toggle" />
        <div class="modal">
            <div class="modal-box">
                <div class="info">
                    <!-- <h3 class="text-lg mb-3 font-bold"></h3> -->
                    <ul>
                        <li>票券編號：{{ modal.details.AE_ID }}</li>
                        <li>課程名稱：{{ modal.details.AE_NAME }}</li>
                        <li>課程日期：{{ modal.details.day }} {{ modal.details.sTime }}~{{ modal.details.eTime }}</li>
                        <li>課程地點：{{ modal.details.AE_SP_NAME }}</li>
                        <!-- <li>失效日期：{{modal.details.AQ_USE_DATE}} ~ {{modal.details.AQ_EXPIRATION_DATE}}</li> -->
                    </ul>
                </div>
                <div class="note">
                    <div class="p-4 flex justify-center">
                        <img
                            v-if="modal.details.AQ_CODE"
                            :src="'data:image/png;base64,' + modal.details.AQ_CODE"
                            class="w-2/3 p-4 border border-secondary-500 rounded"
                            alt=""
                        />
                    </div>

                    <template v-if="details.AE_TYPE == 2">
                        <h4 class="font-bold mb-1">課程券使用說明</h4>
                        <ol class="list-disc">
                            <li class="ml-6">每張課程券限該課程時段及本人使用，無法重複使用。</li>
                            <li class="ml-6">
                                敬請注意課程日期及時間，課程券過期即為無效票券，不得要求更換其他課程、場次或要求退費。
                            </li>
                        </ol>
                    </template>
                    <template v-else>
                        <h4 class="font-bold mb-1">票券使用說明</h4>
                        <ol class="list-disc">
                            <li class="ml-6">每張電子票券限一人及該場次使用一次，無法重複使用。</li>
                            <li class="ml-6">
                                敬請注意課程日期及時間，票券過期即為無效票，不得要求更換其他活動、場次或要求退費。
                            </li>
                        </ol>
                    </template>
                </div>
                <div class="modal-action">
                    <label for="open-qrcode" class="btn">關閉</label>
                </div>
            </div>
        </div>
        <LoadingPage :loadSet="loadSet"></LoadingPage>
    </main>
</template>

<script>
    import {
        apiToken,
        apiGET_COURSE_CRITERION,
        apiACCESS_GET,
        apiACCESS_MAKE_CHIDREN_LIST,
        apiACCESS_RESERVATION_TYPE2_CATALOG1,
        apiACCESS_QRCODE_ADD,
    } from '../../api/API';
    import TicketView from '@/components/TicketView.vue';
    import Header from '@/components/MemberHeader.vue';
    import moment from 'moment';
    export default {
        data() {
            return {
                group: false,
                getQrcode: false,
                modal: {
                    qrcode: false,
                    difference: false,
                    enable: false,
                    split: false,
                    refund: false,
                    autonomous: false,
                    details: {},
                },
                cards: [
                    {
                        category: '單次票券1',
                        type: '瑜珈有氧-唱跳無極限',
                        datetime: '2021-01-01',
                        location: '第一舞蹈教室',
                        number: 'T123456',
                    },
                ],

                productAll: [],
                details: {
                    AE_ID: null,
                },
                ticketDetails: {},
                CUS_ID: null,
                criterion: null,

                NowTime: '',
                loadSet: { show: false, fullPage: true, type: 'dots', color: 'rgba(0,0,0,0.7)' },
            };
        },
        filters: {
            DateFormatter(value) {
                return moment(value).format('YYYY-MM-DD HH:mm');
            },
            DayFormatter(value) {
                return moment(value).format('YYYY-MM-DD');
            },
        },
        mounted() {
            this.NowDay = moment(new Date()).format('YYYY-MM-DD');
            this.NowTime = moment(new Date()).format('YYYY-MM-DD HH:mm');
            console.log(this.NowTime);
            this.init();
        },
        methods: {
            async init() {
                this.loadSet.show = false;
                if (this.$route.query.AE_ID != undefined) {
                    this.details.AE_ID = this.$route.query.AE_ID;
                    await this.getAccess();
                    await this.getAccessChidren();
                    console.log(this.productAll);
                    if (this.ticketDetails.ticketCatalog == '健身期課') await this.getCoureseCriterion();
                } else {
                    this.$router.push('/member/owns');
                }
            },
            async getCoureseCriterion() {
                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        CUS_ID: this.details.AE_TRAGET_ID,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiGET_COURSE_CRITERION(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        this.criterion = jsData.criterion;
                        // console.log(jsData)
                    } else {
                        console.log(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    console.log(e);
                }
            },
            async openModal(AE_ID) {
                await this.getModalAccess(AE_ID);
                // console.log(this.modal.details)

                //前30分鐘能產製QRCODE
                var USE_DATE = new Date(this.modal.details.AE_USE_DATE);
                USE_DATE = new Date(USE_DATE.setMinutes(USE_DATE.getMinutes() - 30));

                var EXPIRATION_DATE = new Date(this.modal.details.AE_EXPIRATION_DATE);

                //如果是課程類的當天都能申請
                if (this.modal.details.AE_CATALOG == 0 || this.modal.details.AE_CATALOG == 2) {
                    var day = this.TransformTimeFormatYYYMMDD(this.modal.details.AE_USE_DATE);

                    USE_DATE = new Date(day + 'T00:00');
                    EXPIRATION_DATE = new Date(day + 'T23:59:59');
                }

                // if(this.IsNull(this.modal.details.AQ_ID) == '' && new Date(this.modal.details.AE_USE_DATE) < new Date() && new Date < new Date(this.modal.details.AE_EXPIRATION_DATE))
                //     await this.addQRcode(AE_ID)
                // else if(new Date(this.modal.details.AQ_EXPIRATION_DATE) < new Date() && new Date < new Date(this.modal.details.AE_EXPIRATION_DATE))
                //     await this.addQRcode(AE_ID)

                if (
                    this.IsNull(this.modal.details.AQ_ID) == '' &&
                    USE_DATE <= new Date() &&
                    new Date() <= EXPIRATION_DATE
                )
                    await this.addQRcode(AE_ID);
                else if (new Date(this.modal.details.AQ_EXPIRATION_DATE) < new Date() && new Date() <= EXPIRATION_DATE)
                    await this.addQRcode(AE_ID);

                //不能刪掉 不知道為甚麼
                this.modal.qrcode = !this.modal.qrcode;
            },
            async getAccess() {
                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        AE_ID: this.details.AE_ID,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiACCESS_GET(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        this.details = jsData;

                        if (jsData.AE_CATALOG == 0 && jsData.AE_CATALOG == 1) this.$router.push('/member/owns');
                        else if (jsData.AE_TYPE != 2) this.$router.push('/member/owns');

                        jsData.AE_USE_DATE = this.TransformTimeFormatYYYMMDDhhmm(jsData.AE_USE_DATE);
                        jsData.AE_EXPIRATION_DATE = this.TransformTimeFormatYYYMMDDhhmm(jsData.AE_EXPIRATION_DATE);

                        var array = {
                            id: jsData.AE_ID,
                            title: jsData.AE_NAME,
                            type: jsData.AE_TYPE,
                            ticketType: null,
                            sportType: null,
                            catalog: jsData.AE_CATALOG,
                            SPORT_ID: jsData.SPORT_ID,
                            status: 1,
                            usetime: jsData.AE_USE_DATE,
                            deadline: jsData.AE_EXPIRATION_DATE,
                            info: {
                                title: jsData.AE_NAME,
                                date: null,
                                time: null,
                                trainer: null,
                                location: jsData.AE_SP_NAME,
                                isSplit: null,
                                quantity: jsData.AE_QUANTITY,
                                quantityUse: jsData.AE_USE_QUANTITY,
                                quantitySplit: jsData.AE_SPLIT_QUANTITY,
                            },
                            isRefund: false,
                        };

                        switch (array.type) {
                            case 0:
                                array.ticketType = 'ticket';
                                break;
                            case 1:
                                array.ticketType = 'ticketPackage';
                                break;
                            case 2:
                                array.ticketType = 'card';
                                break;
                        }

                        array.info.isSplit = jsData.AE_ISSPLIT;
                        array.ticketTypeName = '會員會期';

                        //類型-------------
                        //健身期課
                        if (jsData.SPORT_ID == '1') {
                            (array.sportType = 'cycle-aerobic'), (array.ticketCatalog = '健身期課');
                            array.ticketTypeName = '';
                            var list = jsData.AE_NAME.split('§');
                            if (list.length > 3) {
                                array.info.title = list[0].split('：')[1];
                                array.info.date = list[1].split('：')[1];
                                array.info.time = list[2].split('：')[1];
                            }
                        }
                        //租借
                        else if (jsData.SPORT_ID == '2') {
                            (array.sportType = null), (array.ticketCatalog = '場地租借');
                        }
                        //瑜珈有氧
                        else if (jsData.SPORT_ID == 'TKC08e0a3ba-8016-4ca1-9dde-eabf181c0767') {
                            (array.sportType = 'aerobic'), (array.ticketCatalog = '瑜珈有氧');
                        }
                        //私人教練
                        else if (jsData.SPORT_ID == '3') {
                            (array.sportType = 'trainer'), (array.ticketCatalog = '私人教練');
                        }
                        //健身房
                        else if (jsData.SPORT_ID == 'TKC7df7eda9-a4c5-4959-9db9-76ba53f674d7') {
                            (array.sportType = 'weight'), (array.ticketCatalog = '健身房');
                        }
                        //游泳池
                        else if (jsData.SPORT_ID == 'TKC38874b78-198c-48d0-b08c-3b429cb20603') {
                            (array.sportType = 'swimming'), (array.ticketCatalog = '游泳池');
                        }

                        this.ticketDetails = array;
                        // console.log(jsData)
                    } else {
                        this.$router.push('/member/owns');
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            async getModalAccess(AE_ID) {
                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        AE_ID: AE_ID,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiACCESS_GET(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        jsData.day = this.TransformTimeFormatYYYMMDD(jsData.AE_USE_DATE);
                        jsData.sTime = this.TransformTimeFormathhmm(jsData.AE_USE_DATE);
                        jsData.eTime = this.TransformTimeFormathhmm(jsData.AE_EXPIRATION_DATE);
                        jsData.AQ_USE_DATE = this.TransformTimeFormatYYYMMDDhhmm(jsData.AQ_USE_DATE);
                        jsData.AQ_EXPIRATION_DATE = this.TransformTimeFormatYYYMMDDhhmm(jsData.AQ_EXPIRATION_DATE);
                        this.modal.details = jsData;

                        var list = jsData.AE_NAME.split('§');
                        if (list.length > 3) {
                            jsData.AE_NAME = list[0].split('：')[1];
                        }
                        //console.log(jsData)
                    } else {
                        this.$router.push('/member/rental');
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            async getAccessChidren() {
                try {
                    this.productAll = [];
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        AE_ID: this.details.AE_ID,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiACCESS_MAKE_CHIDREN_LIST(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        // console.log(jsData)
                        if (jsData.length > 0) {
                            for (let i in jsData) {
                                jsData[i].AE_USE_DATE = this.TransformTimeFormatYYYMMDDhhmm(jsData[i].AE_USE_DATE);
                                jsData[i].AE_EXPIRATION_DATE = this.TransformTimeFormatYYYMMDDhhmm(
                                    jsData[i].AE_EXPIRATION_DATE,
                                );

                                var array = {
                                    id: jsData[i].AE_ID,
                                    title: jsData[i].AE_NAME,
                                    type: jsData[i].AE_TYPE,
                                    ticketType: null,
                                    sportType: null,
                                    catalog: jsData[i].AE_CATALOG,
                                    SPORT_ID: jsData[i].SPORT_ID,
                                    status: 1,
                                    usetime: jsData[i].AE_USE_DATE,
                                    deadline: jsData[i].AE_EXPIRATION_DATE,
                                    info: {
                                        CUS_NAME: jsData[i].CUS_NAME,
                                        title: null,
                                        date: null,
                                        time: null,
                                        trainer: null,
                                        location: jsData[i].AE_SP_NAME,
                                        isSplit: null,
                                        quantity: jsData[i].AE_QUANTITY,
                                        quantityUse: jsData[i].AE_USE_QUANTITY,
                                        quantitySplit: jsData[i].AE_SPLIT_QUANTITY,
                                    },
                                    isRefund: false,
                                };

                                switch (array.type) {
                                    case 0:
                                        array.ticketType = 'ticket';
                                        break;
                                    case 1:
                                        array.ticketType = 'ticketPackage';
                                        break;
                                    case 2:
                                        array.ticketType = 'card';
                                        break;
                                }

                                var status = true;

                                //票卷 多張
                                if (
                                    (jsData[i].AE_CATALOG == 0 || jsData[i].AE_CATALOG == 1) &&
                                    jsData[i].AE_TYPE == 1
                                ) {
                                    array.info.isSplit = jsData[i].AE_ISSPLIT;
                                }
                                //健身期課
                                else if (jsData[i].AE_CATALOG == 2) {
                                    array.title = '健身期課';
                                    var list = jsData[i].AE_NAME.split('§');
                                    if (list.length > 3) {
                                        array.info.title = list[0].split('：')[1];
                                        array.info.date = list[1].split('：')[1];
                                        array.info.time = list[2].split('：')[1];
                                    }
                                    //將style改為全版
                                    array.ticketType = 'card';
                                } //主租借則不顯示
                                else if (jsData[i].AE_CATALOG == 3) {
                                    if (jsData[i].AE_TYPE == 1) status = false;
                                }

                                //類型-------------
                                //健身期課
                                if (jsData[i].SPORT_ID == '1') {
                                    array.sportType = 'cycle-aerobic';
                                }
                                //租借
                                else if (jsData[i].SPORT_ID == '2') {
                                    array.sportType = null;
                                }
                                //瑜珈有氧
                                else if (jsData[i].SPORT_ID == 'TKC08e0a3ba-8016-4ca1-9dde-eabf181c0767') {
                                    array.sportType = 'aerobic';
                                }
                                //私人教練
                                else if (jsData[i].SPORT_ID == '3') {
                                    array.sportType = 'trainer';
                                }
                                //健身房
                                else if (jsData[i].SPORT_ID == 'TKC7df7eda9-a4c5-4959-9db9-76ba53f674d7') {
                                    array.sportType = 'weight';
                                }
                                //游泳池
                                else if (jsData[i].SPORT_ID == 'TKC38874b78-198c-48d0-b08c-3b429cb20603') {
                                    array.sportType = 'swimming';
                                }

                                if (jsData[i].AE_STATUS == 0) {
                                    array.status = 1;
                                    array.isRefund = true;
                                } else {
                                    array.status = 2;
                                }

                                //判斷有無過期
                                if (new Date() > new Date(array.deadline)) {
                                    array.status = 3;
                                }

                                //如果轉為紙本
                                if (jsData[i].AE_STATUS == 2) {
                                    array.status = 4;
                                }

                                if (status) {
                                    if (this.IsNull(array.usetime) != '') {
                                        var sdate = array.usetime.split(' ');
                                        var edate = array.deadline.split(' ');
                                        array.info.date = sdate[0];
                                        array.info.time = sdate[1] + ' ~ ' + edate[1];
                                    }
                                    this.productAll.push(array);
                                }
                            }
                        }
                        //console.log(this.productAll)
                    } else {
                        this.$router.push('/member/owns');
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            //多張票卷預約
            async reserveSpace3() {
                if (confirm('確定要預約？') == false) {
                    return;
                }

                var msg = '';

                if (msg == '') {
                    //平日
                    var openTime = '07:00';
                    var closeTime = '22:00';

                    let week = new Date().getDay();
                    if (week == 6) {
                        openTime = '08:00';
                        closeTime = '22:00';
                    } else if (week == 0) {
                        openTime = '08:00';
                        closeTime = '17:00';
                    }

                    var sDate = new Date();
                    var openDate = new Date(this.TransformTimeFormatYYYMMDD(sDate) + 'T' + openTime);
                    var closeDate = new Date(this.TransformTimeFormatYYYMMDD(sDate) + 'T' + closeTime);

                    if (sDate < openDate) {
                        alert('尚未到開放時間');
                        return;
                    } else if (sDate > closeDate) {
                        alert('開放時間已過');
                        return;
                    }

                    try {
                        this.loadSet.show = true;
                        var Token = await (await apiToken()).data;
                        //console.log(Token);
                        var data = {
                            AE_ID: this.details.AE_ID,
                            S_DATE: null,
                            S_TIME: null,
                            Token: Token,
                        };
                        data = this.BaseModel(data);

                        var result = JSON.parse(await (await apiACCESS_RESERVATION_TYPE2_CATALOG1(data)).data);
                        this.loadSet.show = false;

                        if (result.Status) {
                            this.init();
                            alert('預約成功');
                            //var jsData = JSON.parse(result.Data)

                            //  console.log(this.curriclumIndex)
                            //  console.log(this.curriclum)
                            //console.log(jsData)
                        } else {
                            if (result.Data != '') {
                                alert(result.Data);
                            } else {
                                alert(result.Message);
                            }
                        }

                        //console.log(result.Message)
                    } catch (e) {
                        this.loadSet.show = false;
                        //console.log(e)
                        alert(e);
                    }
                } else {
                    alert(msg);
                }
            },

            async addQRcode(AE_ID) {
                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        AE_ID: AE_ID,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiACCESS_QRCODE_ADD(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        await this.getModalAccess(AE_ID);
                    } else {
                        if (result.Data != '') {
                            alert(result.Data);
                        } else {
                            alert(result.Message);
                        }
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            DateFormatter(value) {
                return moment(value).format('YYYY-MM-DD HH:mm');
            },
            DayFormatter(value) {
                return moment(value).format('YYYY-MM-DD');
            },
        },
        components: {
            TicketView,
            Header,
        },
    };
</script>

<style lang="scss" scoped>
    table {
        tbody {
            td {
                border-radius: 0;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .member-header {
            width: 100%;
            left: 0;
        }
        table {
            tbody {
                background: unset !important;
                td {
                    padding: 10px;
                    background: #fff;
                    &::before {
                        width: 25%;
                        white-space: pre-wrap;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 640px) {
        .member-header {
            position: absolute;
        }
    }
</style>

<style lang="postcss" scoped>
    .cardTest {
        @apply bg-white p-4 w-96 h-48 text-secondary-500 flex flex-col justify-between transform hover:-translate-y-2 transition rounded-lg cursor-pointer;
        background-image: linear-gradient(
                112.5deg,
                rgb(214, 214, 214) 0%,
                rgb(214, 214, 214) 10%,
                rgb(195, 195, 195) 10%,
                rgb(195, 195, 195) 53%,
                rgb(176, 176, 176) 53%,
                rgb(176, 176, 176) 55%,
                rgb(157, 157, 157) 55%,
                rgb(157, 157, 157) 60%,
                rgb(137, 137, 137) 60%,
                rgb(137, 137, 137) 88%,
                rgb(118, 118, 118) 88%,
                rgb(118, 118, 118) 91%,
                rgb(99, 99, 99) 91%,
                rgb(99, 99, 99) 100%
            ),
            linear-gradient(
                157.5deg,
                rgb(214, 214, 214) 0%,
                rgb(214, 214, 214) 10%,
                rgb(195, 195, 195) 10%,
                rgb(195, 195, 195) 53%,
                rgb(176, 176, 176) 53%,
                rgb(176, 176, 176) 55%,
                rgb(157, 157, 157) 55%,
                rgb(157, 157, 157) 60%,
                rgb(137, 137, 137) 60%,
                rgb(137, 137, 137) 88%,
                rgb(118, 118, 118) 88%,
                rgb(118, 118, 118) 91%,
                rgb(99, 99, 99) 91%,
                rgb(99, 99, 99) 100%
            ),
            linear-gradient(
                135deg,
                rgb(214, 214, 214) 0%,
                rgb(214, 214, 214) 10%,
                rgb(195, 195, 195) 10%,
                rgb(195, 195, 195) 53%,
                rgb(176, 176, 176) 53%,
                rgb(176, 176, 176) 55%,
                rgb(157, 157, 157) 55%,
                rgb(157, 157, 157) 60%,
                rgb(137, 137, 137) 60%,
                rgb(137, 137, 137) 88%,
                rgb(118, 118, 118) 88%,
                rgb(118, 118, 118) 91%,
                rgb(99, 99, 99) 91%,
                rgb(99, 99, 99) 100%
            ),
            linear-gradient(90deg, rgb(195, 195, 195), rgb(228, 228, 228));
        background-blend-mode: overlay, overlay, overlay, normal;
        .title {
            @apply font-bold text-lg;
        }
        .btng {
            @apply flex justify-end space-x-1;
        }
    }
    .modal {
        @apply flex justify-center items-center fixed left-0 top-0 w-screen h-screen z-50 text-secondary-500;
        .mask {
            @apply fixed left-0 top-0 w-screen h-screen bg-black opacity-50 flex;
        }
    }
    .calendar {
        @apply w-24 text-center flex flex-col items-center rounded  bg-opacity-30 shadow-lg border;
        .month-day {
            @apply py-1;
        }
        span {
            @apply w-full leading-none;
            &.year {
                @apply border-b py-2 text-primary-400;
            }
            &.day {
                @apply text-2xl font-bold;
            }
        }
    }
    .owned {
        @apply flex flex-wrap gap-8 justify-center px-4 w-full;
        a {
            @apply block w-full sm:w-auto;
        }
    }
</style>
