<template>
    <main>
        <Header title="我的商品"></Header>

        <div class="main">
            <!-- 商品篩選 -->
            <div class="flex justify-center mb-8 space-x-2">
                <div class="dropdown dropdown-hover w-1/2 sm:w-auto">
                    <label tabindex="0" class="btn w-full text-white">
                        {{ statusName }}
                        <svg
                            class="fill-white"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                        >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 15l-4.243-4.243 1.415-1.414L12 12.172l2.828-2.829 1.415 1.414z" />
                        </svg>
                    </label>
                    <ul tabindex="0" class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                        <li>
                            <template v-for="(item, index) in tktCatalog">
                                <button v-if="item.status" :key="index" @click="reData(item.id, item.name)">
                                    {{ item.name }}
                                </button>
                                <button v-if="!item.status" :key="index" @click="reData(item.id, item.name)">
                                    {{ item.name }}
                                </button>
                            </template>
                        </li>
                    </ul>
                </div>
                <label for="take-qrcode" class="btn modal-button justify-self-end w-1/2 sm:w-auto text-white"
                    >代碼取票</label
                >
            </div>

            <!-- 商品 -->
            <Tabs centered>
                <Tab title="可使用">
                    <div class="owned">
                        <div class="item" v-for="(item, index) in productUse" :key="index">
                            <!-- 單次 -->
                            <template v-if="item.type === 0">
                                <a
                                    v-if="
                                        item.catalog == 1 && IsNull(item.usetime) == '' && IsNull(item.deadline) == ''
                                    "
                                    @click="reserveSpace(item.id)"
                                    class="cursor-pointer"
                                >
                                    <TicketView :ticketDetails="item"></TicketView>
                                </a>
                                <a v-else @click="openModal(item.id)">
                                    <TicketView :ticketDetails="item"></TicketView>
                                </a>
                                <!-- <TicketView v-if="item.catalog == 2" :key="index" :ticketDetails="item"></TicketView> -->
                            </template>
                            <!-- 套票 -->
                            <template v-if="item.type === 1">
                                <router-link :to="{ path: '/member/package', query: { AE_ID: item.id } }">
                                    <TicketView :ticketDetails="item"></TicketView>
                                </router-link>
                            </template>
                            <!-- 會員會期 -->
                            <template v-if="item.type === 2">
                                <router-link :to="{ path: '/member/cycle', query: { AE_ID: item.id } }">
                                    <TicketView :ticketDetails="item"></TicketView>
                                </router-link>
                            </template>
                        </div>
                    </div>
                    <div class="divider py-8">以下為未啟用票券</div>
                    <div class="owned">
                        <div class="item" v-for="(item, index) in product" :key="index">
                            <!-- 單次 -->
                            <template v-if="item.type === 0">
                                <template v-if="item.info.quantity > item.info.quantityUse + item.info.quantitySplit">
                                    <router-link
                                        v-if="item.catalog == 0"
                                        :to="{ path: '/member/reservation', query: { AE_ID: item.id } }"
                                    >
                                        <TicketView :ticketDetails="item"></TicketView>
                                    </router-link>
                                    <a v-if="item.catalog == 1" @click="reserveSpace(item.id)" class="cursor-pointer">
                                        <TicketView :ticketDetails="item"></TicketView>
                                    </a>
                                </template>
                            </template>
                            <!-- 套票 -->
                            <template v-if="item.type === 1">
                                <router-link
                                    v-if="item.status == 1"
                                    :to="{ path: '/member/package', query: { AE_ID: item.id } }"
                                >
                                    <TicketView :ticketDetails="item"></TicketView>
                                </router-link>
                            </template>
                            <!-- 會員會期 -->
                            <template v-if="item.type === 2">
                                <router-link :to="{ path: '/member/cycle', query: { AE_ID: item.id } }">
                                    <TicketView :ticketDetails="item"></TicketView>
                                </router-link>
                            </template>
                        </div>
                    </div>
                </Tab>

                <Tab title="使用完畢">
                    <div class="owned">
                        <div class="item" :key="index" v-for="(item, index) in productUsed">
                            <!-- 單次 -->
                            <template v-if="item.type === 0">
                                <!-- 判斷是否由課程的套票組合單次票 -->
                                <a @click="openModal(item.id)">
                                    <TicketView :ticketDetails="item"></TicketView>
                                </a>
                                <!-- <TicketView v-if="item.catalog == 2" :key="index" :ticketDetails="item"></TicketView> -->
                            </template>
                            <!-- 套票 -->
                            <template v-if="item.type === 1">
                                <router-link :to="{ path: '/member/package', query: { AE_ID: item.id } }">
                                    <TicketView :ticketDetails="item"></TicketView>
                                </router-link>
                            </template>
                            <!-- 會員會期 -->
                            <template v-if="item.type === 2">
                                <router-link :to="{ path: '/member/cycle', query: { AE_ID: item.id } }">
                                    <TicketView :ticketDetails="item"></TicketView>
                                </router-link>
                            </template>
                        </div>
                    </div>
                </Tab>

                <Tab title="已逾期">
                    <div class="owned">
                        <div class="item" v-for="(item, index) in productExpired" :key="index">
                            <!-- 單次 -->
                            <TicketView v-if="item.type === 0" :ticketDetails="item">
                                <label
                                    v-if="item.overdue != null"
                                    for="overdue-modal"
                                    class="btn btn-sm btn-outline btn-secondary modal-button"
                                    @click="openOverDue(item)"
                                    >啟用</label
                                >
                                <button
                                    v-if="
                                        item.AE_FROM_TYPE == 1 &&
                                        item.OD_PAYMENT_STATUS === 1 &&
                                        item.OP_REFUND_STATUS == 0
                                    "
                                    @click="checkAccessStatus(item.OP_ID)"
                                    class="btn btn-sm btn-outline btn-secondary"
                                >
                                    退費
                                </button>
                            </TicketView>
                            <!-- 套票 -->
                            <TicketView v-if="item.type === 1" :ticketDetails="item">
                                <label
                                    v-if="item.overdue != null"
                                    for="overdue-modal"
                                    class="btn btn-sm btn-outline btn-secondary modal-button"
                                    @click="openOverDue(item)"
                                    >啟用</label
                                >
                                <button
                                    v-if="
                                        item.AE_FROM_TYPE == 1 &&
                                        item.OD_PAYMENT_STATUS === 1 &&
                                        item.OP_REFUND_STATUS == 0 &&
                                        item.info.isSplit == 1 &&
                                        item.info.quantity > item.info.quantityUse + item.info.quantitySplit
                                    "
                                    @click="checkAccessStatus(item.OP_ID)"
                                    class="btn btn-sm btn-outline btn-secondary"
                                >
                                    退費
                                </button>
                            </TicketView>
                            <!-- 會員會期 -->
                            <TicketView v-if="item.type === 2" :ticketDetails="item">
                                <label
                                    v-if="item.overdue != null"
                                    for="overdue-modal"
                                    class="btn btn-sm btn-outline btn-secondary modal-button"
                                    @click="openOverDue(item)"
                                    >啟用</label
                                >
                                <button
                                    v-if="
                                        item.AE_FROM_TYPE == 1 &&
                                        item.OD_PAYMENT_STATUS === 1 &&
                                        item.OP_REFUND_STATUS == 0
                                    "
                                    @click="checkAccessStatus(item.OP_ID)"
                                    class="btn btn-sm btn-outline btn-secondary"
                                >
                                    退費
                                </button>
                            </TicketView>
                        </div>
                    </div>
                </Tab>
            </Tabs>
        </div>

        <!-- 取票 -->
        <input type="checkbox" id="take-qrcode" class="modal-toggle" />
        <div class="modal">
            <div class="modal-box relative">
                <label for="take-qrcode" class="btn btn-sm btn-circle absolute right-2 top-2">
                    <svg class="fill-white h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path
                            d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12L20 6.91Z"
                        />
                    </svg>
                </label>
                <h3 class="text-lg text-center">取票</h3>
                <Take @click:checked="getAccessList"></Take>
            </div>
        </div>

        <!-- 逾期啟用  -->
        <input type="checkbox" id="overdue-modal" class="modal-toggle" />
        <div class="modal">
            <div class="modal-box">
                <h3 class="font-bold text-lg">逾期啟用</h3>
                <table class="table w-full">
                    <tbody>
                        <tr>
                            <th>商品明細</th>
                            <td class="whitespace-pre-line">{{ overdueMoal.name }}</td>
                        </tr>
                        <tr>
                            <th>金額</th>
                            <td>
                                NT<strong class="text-2xl text-green-500">${{ overdueMoal.price }}</strong>
                            </td>
                        </tr>
                        <tr>
                            <th>付款方式</th>
                            <td>
                                <ul class="paymentMethod">
                                    <li>
                                        <img
                                            class="h-4"
                                            alt="逢甲大學體育館"
                                            src="@/assets/image/paymentIcon/atm.jpg"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            class="h-4"
                                            alt="逢甲大學體育館"
                                            src="@/assets/image/paymentIcon/LINE_Pay_logo.png"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            class="h-4"
                                            alt="逢甲大學體育館"
                                            src="@/assets/image/paymentIcon/taiwanpaylogo.png"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            class="h-4"
                                            alt="逢甲大學體育館"
                                            src="@/assets/image/paymentIcon/1200px-JKOPAY_logo.svg.png"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            class="h-4"
                                            alt="逢甲大學體育館"
                                            src="@/assets/image/paymentIcon/logo-mark.svg"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            class="h-4"
                                            alt="逢甲大學體育館"
                                            src="@/assets/image/paymentIcon/Alipay_logo.png"
                                        />
                                    </li>
                                    <li>超商代收</li>
                                    <li>信用卡</li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="modal-action">
                    <button class="btn btn-primary" @click="addOverdueOrder">去付款</button>
                    <label for="overdue-modal" class="btn">取消</label>
                </div>
            </div>
        </div>

        <!-- 二維條碼 -->
        <input type="checkbox" v-model="modal.qrcode" id="open-qrcode" class="modal-toggle" />
        <div class="modal">
            <div class="modal-box">
                <h3 class="font-bold text-lg">
                    <template v-if="modal.details.AE_CATALOG == 3">入館QRcode</template>
                    <template v-else>進場QRcode</template>
                </h3>
                <div class="info">
                    <ul>
                        <li><span>票券編號</span>{{ modal.details.AE_ID }}</li>
                        <template v-if="modal.details.AE_CATALOG == 1">
                            <li><span>使用日期</span>{{ modal.details.day }}</li>
                            <li><span>使用地點</span>{{ modal.details.AE_SP_NAME }}</li>
                        </template>
                        <template v-else-if="modal.details.AE_CATALOG == 3">
                            <li><span>租借日期</span>{{ modal.details.day }}</li>
                            <li><span>租借時段</span>{{ modal.details.sTime }}~{{ modal.details.eTime }}</li>
                            <li><span>租借場地</span>{{ modal.details.AE_NAME }}</li>
                        </template>
                        <template v-else>
                            <li><span>課程日期</span>{{ modal.details.day }}</li>
                            <li><span>課程時段</span>{{ modal.details.sTime }}~{{ modal.details.eTime }}</li>
                            <li><span>課程地點</span>{{ modal.details.AE_SP_NAME }}</li>
                        </template>
                        <!-- <li>
                        <span>
                           <template v-if="modal.details.AE_CATALOG == 1">使用日期</template>
                           <template v-else-if="modal.details. AE_CATALOG == 3">租借日期</template>
                           <template v-else>課程日期</template>
                        </span>{{modal.details.day}}
                     </li>
                     <li v-if="modal.details.AE_CATALOG != 1">
                        <span>
                           <template v-if="modal.details.AE_CATALOG == 3">租借時段</template>
                           <template v-else>課程時段</template>
                        </span>{{modal.details.sTime}}~{{modal.details.eTime}}</li> -->
                        <!-- 場租顯示最上層 不顯示實際地點 -->
                        <!-- <template v-if="modal.details.AE_CATALOG == 1">
                        <li><span>使用地點</span>{{modal.details.AE_SP_NAME}}</li>
                     </template>
                     <template v-else-if="modal.details.AE_CATALOG == 3">
                        <li>
                           <span>
                              <template v-if="modal.details.AE_CATALOG == 3">租借場地</template>
                              <template v-else>課程地點</template>
                           </span>{{modal.details.AE_NAME}}</li>
                     </template>
                     <template v-else>
                        <li><span>課程地點</span>{{modal.details.AE_SP_NAME}}</li>
                     </template> -->
                        <!-- <li>失效日期：{{modal.details.AQ_USE_DATE}} ~ {{modal.details.AQ_EXPIRATION_DATE}}</li> -->
                    </ul>
                </div>
                <div class="note">
                    <div class="p-4 flex justify-center">
                        <template v-if="IsNull(modal.details.AQ_CODE) == ''">
                            <template
                                v-if="
                                    modal.details.AE_CATALOG == 3 &&
                                    new Date() < new Date(modal.details.day + 'T' + modal.details.sTime)
                                "
                            >
                                場地租借時間未到，QRcode將於租借時段前30分鐘顯示
                            </template>
                        </template>
                        <template v-else
                            ><img
                                :src="'data:image/png;base64,' + modal.details.AQ_CODE"
                                class="w-2/3 border p-6 bg-white my-8 rounded-lg shadow-md"
                                alt=""
                        /></template>
                    </div>
                </div>
                <div class="alert shadow-lg flex-col justify-start items-start">
                    <template v-if="modal.details.AE_CATALOG == 3">
                        <h6 class="font-bold mb-1">入館QRcode使用說明</h6>
                        <ol class="list-disc flex-col justify-start items-start">
                            <li class="ml-6">QRcode將於租借時段前30分鐘顯示。</li>
                            <li class="ml-6">每張入場QRcode限一人及該場次使用。</li>
                        </ol>
                    </template>
                    <template v-else>
                        <h6 class="font-bold mb-1">票券使用說明</h6>
                        <ol class="list-disc flex-col justify-start items-start">
                            <li class="ml-6">每張電子票券限本人當天使用一次，無法重複使用。</li>
                            <li class="ml-6">敬請注意使用日期，票券過期即為無效票，不得要求更換或要求退費。</li>
                        </ol>
                    </template>
                </div>
                <div class="modal-action">
                    <label for="open-qrcode" class="btn">關閉</label>
                </div>
            </div>
        </div>
        <LoadingPage :loadSet="loadSet"></LoadingPage>
    </main>
</template>

<script>
    import {
        apiToken,
        apiACCESS_LIST_NO_TYPE3,
        apiACCESS_GET,
        apiTICKET_CATALOG_LIST,
        apiACCESS_RESERVATION_TYPE0_CATALOG1,
        apiORDER_OVERDUE_ADD,
        apiACCESS_QRCODE_ADD,
        apiACCESS_IS_REUND,
        apiORDER_PRODUCT_REFUNDT,
    } from '../../api/API';
    import Tab from '@/components/STab.vue';
    import Tabs from '@/components/STabs.vue';
    import TicketView from '@/components/TicketView.vue';
    import Take from '@/views/memberCenter/TakeTicket.vue';
    import Header from '@/components/MemberHeader.vue';
    export default {
        name: 'OwnedProducts',
        data() {
            return {
                statusName: '',
                catalogOpen: false,
                spliteTicket: false,
                showNow: 'used',
                used: true,
                unused: false,
                overdue: false,
                display: {
                    used: true,
                    unused: false,
                },
                modalOpen: false,
                modal: {
                    qrcode: false,
                    difference: false,
                    enable: false,
                    split: false,
                    refund: false,
                    autonomous: false,
                    details: {},
                },

                overdueMoal: {
                    AE_ID: null,
                    name: null,
                    price: null,
                },

                tktCatalog: [],
                productAll: [],
                product: [],
                productUse: [],
                productUsed: [],
                productExpired: [],

                loadSet: { show: false, fullPage: true, type: 'dots', color: 'rgba(0,0,0,0.7)' },
            };
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                if (this.IsNull(this.$session.get('ACID')) == '') {
                    this.$router.push('/Login');
                } else {
                    await this.getTicketCatalogList();
                    await this.getAccessList();
                }
            },
            async openModal(AE_ID) {
                await this.getAccess(AE_ID);
                // console.log(this.modal.details)

                //前30分鐘能產製QRCODE
                var USE_DATE = new Date(this.modal.details.AE_USE_DATE);
                USE_DATE = new Date(USE_DATE.setMinutes(USE_DATE.getMinutes() - 30));

                var EXPIRATION_DATE = new Date(this.modal.details.AE_EXPIRATION_DATE);

                //如果是課程類的當天都能申請
                if (this.modal.details.AE_CATALOG == 0 || this.modal.details.AE_CATALOG == 2) {
                    var day = this.TransformTimeFormatYYYMMDD(this.modal.details.AE_USE_DATE);

                    USE_DATE = new Date(day + 'T00:00');
                    EXPIRATION_DATE = new Date(day + 'T23:59:59');
                }

                if (
                    this.IsNull(this.modal.details.AQ_ID) == '' &&
                    USE_DATE <= new Date() &&
                    new Date() <= EXPIRATION_DATE
                )
                    await this.addQRcode(AE_ID);
                else if (new Date(this.modal.details.AQ_EXPIRATION_DATE) < new Date() && new Date() <= EXPIRATION_DATE)
                    await this.addQRcode(AE_ID);

                //不能刪掉 不知道為甚麼
                this.modal.qrcode = !this.modal.qrcode;
            },
            async openOverDue(item) {
                this.overdueMoal = {
                    AE_ID: null,
                    name: null,
                    price: null,
                };
                this.overdueMoal.AE_ID = item.id;
                this.overdueMoal.name = item.title;

                if (item.ticketType == 'ticketPackage') this.overdueMoal.price = item.overdue * item.info.quantity;
                else this.overdueMoal.price = item.overdue;
            },
            reData(sportId, sportName) {
                this.loadSet.show = true;
                for (let i in this.tktCatalog) {
                    if (sportId == this.tktCatalog[i].id) this.tktCatalog[i].status = true;
                    else this.tktCatalog[i].status = false;
                }

                this.product = [];
                this.productUse = [];
                this.productUsed = [];
                this.productExpired = [];

                for (var i in this.productAll) {
                    //不是選擇的類別
                    if (sportId != '0' && sportId != this.productAll[i].SPORT_ID) continue;

                    if (this.productAll[i].status == 1) this.product.push(this.productAll[i]);
                    else if (this.productAll[i].status == 2) this.productUse.push(this.productAll[i]);
                    else if (this.productAll[i].status == 4) this.productUsed.push(this.productAll[i]);
                    else if (this.productAll[i].status == 3) this.productExpired.push(this.productAll[i]);
                }

                this.loadSet.show = false;
                this.statusName = sportName;
            },
            async getTicketCatalogList() {
                try {
                    this.tktCatalog = [{ name: '全部', id: '0', status: true }];
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiTICKET_CATALOG_LIST(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        //console.log(jsData)

                        for (let i in jsData) {
                            //如果是健身期課改為 1
                            if (jsData[i].TKT_CAT_ID == 'TKC4a72a104-ec14-4fa9-b99c-93f5a59a76dd')
                                this.tktCatalog.push({ name: jsData[i].TKT_CAT_NAME, id: '1', status: false });
                            else
                                this.tktCatalog.push({
                                    name: jsData[i].TKT_CAT_NAME,
                                    id: jsData[i].TKT_CAT_ID,
                                    status: false,
                                });
                        }

                        this.tktCatalog.push({ name: '場地租借', id: '2', status: false });

                        //console.log(this.tktCatalog)
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            async getAccessList() {
                try {
                    this.productAll = [];
                    this.course = [];

                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiACCESS_LIST_NO_TYPE3(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        if (jsData.length > 0) {
                            // console.log(jsData)
                            for (let i in jsData) {
                                //由票卷產生不顯示
                                if (jsData[i].AE_FROM_TYPE == 3 || jsData[i].AE_STATUS == 2) continue;

                                jsData[i].AE_USE_DATE = this.TransformTimeFormatYYYMMDDhhmm(jsData[i].AE_USE_DATE);
                                jsData[i].AE_EXPIRATION_DATE = this.TransformTimeFormatYYYMMDDhhmm(
                                    jsData[i].AE_EXPIRATION_DATE,
                                );

                                var array = {
                                    id: jsData[i].AE_ID,
                                    title: jsData[i].AE_NAME,
                                    type: jsData[i].AE_TYPE,
                                    ticketType: null,
                                    sportType: null,
                                    catalog: jsData[i].AE_CATALOG,
                                    SPORT_ID: jsData[i].SPORT_ID,
                                    status: 1,
                                    usetime: jsData[i].AE_USE_DATE,
                                    deadline: jsData[i].AE_EXPIRATION_DATE,
                                    overdue: jsData[i].AE_OVERDUE_AMOUNT,
                                    info: {
                                        title: null,
                                        date: null,
                                        time: null,
                                        trainer: null,
                                        location: jsData[i].AE_SP_NAME,
                                        isSplit: null,
                                        quantity: jsData[i].AE_QUANTITY,
                                        quantityUse: jsData[i].AE_USE_QUANTITY,
                                        quantitySplit: jsData[i].AE_SPLIT_QUANTITY,
                                    },
                                    isRefund: false,
                                    AE_FROM_TYPE: jsData[i].AE_FROM_TYPE,
                                    OD_PAYMENT_STATUS: jsData[i].OD_PAYMENT_STATUS,
                                    OP_ID: jsData[i].OP_ID,
                                    OP_REFUND_STATUS: jsData[i].OP_REFUND_STATUS,
                                };

                                switch (array.type) {
                                    case 0:
                                        array.ticketType = 'ticket';
                                        array.ticketTypeName = '單次';
                                        break;
                                    case 1:
                                        array.ticketType = 'ticketPackage';
                                        array.ticketTypeName = '單次套票';
                                        break;
                                    case 2:
                                        array.ticketType = 'card';
                                        array.ticketTypeName = '會員會期';
                                        break;
                                }

                                var status = true;

                                //票卷 套票
                                if (
                                    (jsData[i].AE_CATALOG == 0 || jsData[i].AE_CATALOG == 1) &&
                                    jsData[i].AE_TYPE == 1
                                ) {
                                    array.info.isSplit = jsData[i].AE_ISSPLIT;
                                }
                                //健身期課
                                else if (jsData[i].AE_CATALOG == 2) {
                                    array.title = '健身期課';
                                    array.ticketTypeName = '';
                                    var list = jsData[i].AE_NAME.split('§');
                                    if (list.length > 3) {
                                        array.info.title = list[0].split('：')[1];
                                        array.info.date = list[1].split('：')[1];
                                        array.info.time = list[2].split('：')[1];
                                    }

                                    //被分配的單張健身期課
                                    if (jsData[i].AE_TYPE == 0) {
                                        array.info.title = jsData[i].AE_NAME;
                                    }

                                    //將style改為全版
                                    array.ticketType = 'card';
                                } //主租借則不顯示
                                else if (jsData[i].AE_CATALOG == 3) {
                                    if (jsData[i].AE_TYPE == 1) status = false;
                                }

                                //類型-------------
                                //健身期課
                                if (jsData[i].SPORT_ID == '1') {
                                    (array.sportType = 'cycle-aerobic'), (array.ticketCatalog = '健身期課');
                                }
                                //租借
                                else if (jsData[i].SPORT_ID == '2') {
                                    (array.sportType = null), (array.ticketCatalog = '場地租借');
                                }
                                //私人教練
                                else if (jsData[i].SPORT_ID == '3') {
                                    (array.sportType = 'trainer'), (array.ticketCatalog = '私人教練');
                                } else if (jsData[i].SPORT_ID == 'TKC33a76759-4591-4277-8ae1-fea401a59a1d') {
                                    (array.sportType = 'trainer'), (array.ticketCatalog = '私人教練');
                                }
                                //瑜珈有氧
                                else if (jsData[i].SPORT_ID == 'TKC08e0a3ba-8016-4ca1-9dde-eabf181c0767') {
                                    (array.sportType = 'aerobic'), (array.ticketCatalog = '瑜珈有氧');
                                    array.info.title = jsData[i].CUS_NAME;
                                }
                                //健身房
                                else if (jsData[i].SPORT_ID == 'TKC7df7eda9-a4c5-4959-9db9-76ba53f674d7') {
                                    (array.sportType = 'weight'), (array.ticketCatalog = '健身房');
                                }
                                //游泳池
                                else if (jsData[i].SPORT_ID == 'TKC38874b78-198c-48d0-b08c-3b429cb20603') {
                                    (array.sportType = 'swimming'), (array.ticketCatalog = '游泳池');
                                }

                                if (jsData[i].AE_STATUS == 0) {
                                    array.status = 1;
                                    array.isRefund = true;
                                } else if (jsData[i].AE_STATUS == 1) {
                                    if (
                                        new Date(jsData[i].AE_EXPIRATION_DATE) > new Date() ||
                                        this.IsNull(jsData[i].AE_EXPIRATION_DATE) == ''
                                    ) {
                                        array.status = 2; //使用中
                                    } else {
                                        array.status = 4; //已使用
                                    }

                                    if (this.IsNull(array.usetime) != '') {
                                        var sdate = array.usetime.split(' ');
                                        var edate = array.deadline.split(' ');
                                        array.info.date = sdate[0];
                                        array.info.time = sdate[1] + ' ~ ' + edate[1];
                                    }
                                }

                                if (
                                    jsData[i].AE_TYPE == 1 &&
                                    jsData[i].AE_QUANTITY <= jsData[i].AE_USE_QUANTITY + jsData[i].AE_SPLIT_QUANTITY &&
                                    array.status == 2
                                ) {
                                    array.status = 4;
                                }

                                //判斷有無過期
                                if (new Date() > new Date(array.deadline) && array.status == 1) {
                                    array.status = 3;
                                }

                                if (status) {
                                    this.productAll.push(array);
                                }
                            }
                        }

                        //console.log(this.productAll)
                    } else {
                        alert(result.Message);
                    }

                    this.reData('0');
                    this.statusName = '所有類別';

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            async getAccess(AE_ID) {
                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        AE_ID: AE_ID,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiACCESS_GET(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        jsData.day = this.TransformTimeFormatYYYMMDD(jsData.AE_USE_DATE);
                        jsData.sTime = this.TransformTimeFormathhmm(jsData.AE_USE_DATE);
                        jsData.eTime = this.TransformTimeFormathhmm(jsData.AE_EXPIRATION_DATE);
                        jsData.AQ_USE_DATE = this.TransformTimeFormatYYYMMDDhhmm(jsData.AQ_USE_DATE);
                        jsData.AQ_EXPIRATION_DATE = this.TransformTimeFormatYYYMMDDhhmm(jsData.AQ_EXPIRATION_DATE);
                        this.modal.details = jsData;
                        //console.log(jsData)
                    } else {
                        this.$router.push('/member/rental');
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            async reserveSpace(AE_ID) {
                if (confirm('確定要預約？') == false) {
                    return;
                }

                var msg = '';

                if (msg == '') {
                    //平日
                    var openTime = '07:00';
                    var closeTime = '22:00';

                    let week = new Date().getDay();
                    if (week == 6) {
                        openTime = '08:00';
                        closeTime = '22:00';
                    } else if (week == 0) {
                        openTime = '08:00';
                        closeTime = '17:00';
                    }

                    var sDate = new Date();
                    var openDate = new Date(this.TransformTimeFormatYYYMMDD(sDate) + 'T' + openTime);
                    var closeDate = new Date(this.TransformTimeFormatYYYMMDD(sDate) + 'T' + closeTime);

                    if (sDate < openDate) {
                        alert('尚未到開放時間');
                        return;
                    } else if (sDate > closeDate) {
                        alert('開放時間已過');
                        return;
                    }

                    try {
                        this.loadSet.show = true;
                        var Token = await (await apiToken()).data;
                        //console.log(Token);
                        var data = {
                            AE_ID: AE_ID,
                            S_DATE: null,
                            S_TIME: null,
                            Token: Token,
                        };
                        data = this.BaseModel(data);

                        var result = JSON.parse(await (await apiACCESS_RESERVATION_TYPE0_CATALOG1(data)).data);
                        this.loadSet.show = false;

                        if (result.Status) {
                            this.init();
                            alert('預約成功');
                            //this.$router.push('/member/owns')
                            //var jsData = JSON.parse(result.Data)

                            //  console.log(this.curriclumIndex)
                            //  console.log(this.curriclum)
                            //console.log(jsData)
                        } else {
                            if (result.Data != '') {
                                alert(result.Data);
                            } else {
                                alert(result.Message);
                            }
                        }

                        //console.log(result.Message)
                    } catch (e) {
                        this.loadSet.show = false;
                        //console.log(e)
                        alert(e);
                    }
                } else {
                    alert(msg);
                }
            },
            async addOverdueOrder() {
                if (confirm('確定要逾期啟用？') == false) {
                    return;
                }

                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        AE_ID: this.overdueMoal.AE_ID,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiORDER_OVERDUE_ADD(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        var a = document.createElement('a');
                        a.id = 'test';
                        a.href = this.$appDomain + '/FcuPay/FcuPay?OD_ID=' + jsData.ORDER.OD_ID;
                        a.click();

                        console.log();
                    } else {
                        if (result.Data != '') {
                            alert(result.Data);
                        } else {
                            alert(result.Message);
                        }
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },

            async addQRcode(AE_ID) {
                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        AE_ID: AE_ID,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiACCESS_QRCODE_ADD(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        await this.getAccess(AE_ID);
                    } else {
                        if (result.Data != '') {
                            alert(result.Data);
                        } else {
                            alert(result.Message);
                        }
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },

            async checkAccessStatus(OP_ID) {
                if (confirm('確定要申請退款？') == false) {
                    return;
                }

                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        OP_ID: OP_ID,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiACCESS_IS_REUND(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        if (jsData.status == 1) alert('不符合退款條件');
                        else this.refund(OP_ID);
                        //console.log(jsData)
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            async refund(OP_ID) {
                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        OP_ID: OP_ID,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiORDER_PRODUCT_REFUNDT(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        //var jsData = JSON.parse(result.Data);
                        //console.log(jsData)
                        this.getAccessList();
                        alert('申請成功');
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
        },
        components: {
            Tab,
            Tabs,
            Take,
            TicketView,
            Header,
        },
    };
</script>

<style lang="postcss" scoped>
    .info {
        dt {
            @apply text-gray-500 text-sm;
        }
        dd {
            @apply ml-2 mb-2;
        }
        li {
            @apply py-2 border-b;
            &:last-child {
                @apply border-b-0;
            }
            span {
                @apply text-gray-400 pr-3 text-xs;
            }
        }
    }
    .category {
        @apply mb-8;
        ul {
            @apply flex flex-row p-4 flex-nowrap overflow-scroll sm:justify-center snap-x;
            li {
                @apply whitespace-nowrap p-1 snap-center;
                button {
                    @apply border border-secondary-500 flex justify-center items-center w-20 rounded bg-secondary-600 shadow text-sm hover:bg-slate-500;
                    aspect-ratio: 1 / 1;
                }
            }
        }
        .control {
            @apply flex sm:hidden justify-between items-center;
            .title {
                @apply block pl-2 font-bold;
            }
            button {
                @apply p-2 h-full w-full text-white;
            }
        }
        &.open {
            @apply flex-col;
            ul {
                @apply flex-wrap;
                li {
                    @apply w-1/4;
                    button {
                        @apply w-full;
                    }
                }
            }
        }
    }

    .owned {
        @apply flex flex-wrap justify-center w-full space-y-6 sm:space-y-0;
        .item {
            @apply p-0 w-full sm:w-auto sm:p-3;
        }
    }

    .filter {
        @apply space-x-2;
        .condition {
            @apply border border-primary-500 rounded-full text-sm px-4 py-1 text-primary-500;
            &.isactive {
                @apply text-secondary-500 bg-primary-500;
            }
        }
    }
</style>
