<template>
    <div>
        <header class="pt-24 sm:pt-36 pb-10 sm:pb-24">
            <h1 class="title-01">最新消息</h1>
        </header>
        <CustomizeSection tranglert tranglelt tranglelb>
            <div class="main-img bg-01" v-rellax="{ speed: 1 }"></div>
        </CustomizeSection>
        <CustomizeSection tranglert tranglelt>
            <div class="sm:hidden mb-5 flex justify-end" style="margin-bottom: 15rem">
                <div class="dropdown dropdown-hover dropdown-end">
                    <label tabindex="0" class="btn text-white"
                        >{{ tabName }}
                        <svg
                            class="fill-white"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                        >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                        </svg>
                    </label>
                    <ul tabindex="0" class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                        <li v-for="(item, index) in arCatalog" :key="index">
                            <button @click="changeData(item.id, index)">{{ item.name }}</button>
                        </li>
                    </ul>
                </div>
            </div>
            <nav class="hidden sm:block w-full sm:w-fit bg-white mx-auto rounded-full mb-12" data-aos="fade-down">
                <div class="btn-group">
                    <template v-for="(item, index) in arCatalog">
                        <a
                            :key="index"
                            class="btn btn-active text-white"
                            v-if="item.status"
                            @click="changeData('', index)"
                            >{{ item.name }}</a
                        >
                        <a
                            :key="index"
                            class="btn text-white"
                            v-if="!item.status"
                            @click="changeData(item.id, index)"
                            >{{ item.name }}</a
                        >
                    </template>
                </div>
            </nav>
            <div class="container mx-auto">
                <div v-for="(month, index) in dataItems" :key="index" class="month">
                    <h4 class="month-title">{{ month.Month }}月</h4>
                    <ul class="news flex-1">
                        <li v-for="(item, index) in month.content" :key="index">
                            <router-link :to="{ name: 'gymnasiumNewsDetails', query: { AR_ID: item.Id } }">
                                <div>
                                    <div class="catalog">
                                        <span class="date">{{ item.Date | DayFormatter }}</span
                                        >{{ item.TypeName }}
                                    </div>
                                    <p class="text">{{ item.Title }}</p>
                                </div>
                                <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path
                                        d="M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M6,13H14L10.5,16.5L11.92,17.92L17.84,12L11.92,6.08L10.5,7.5L14,11H6V13Z"
                                    />
                                </svg>
                            </router-link>
                        </li>
                    </ul>
                    <!-- <ul class="news flex-1">
                        <li v-for="(item, index) in month.content" :key="index">
                            <router-link :to="{ name: 'gymnasiumNewsDetails', query: { AR_ID: item.id } }">
                                <div>
                                    <div class="catalog">
                                        <span class="date">{{ item.date }}</span
                                        >{{ item.catalog }}
                                    </div>
                                    <p class="text">{{ item.title }}</p>
                                </div>
                                <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path
                                        d="M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M6,13H14L10.5,16.5L11.92,17.92L17.84,12L11.92,6.08L10.5,7.5L14,11H6V13Z"
                                    />
                                </svg>
                            </router-link>
                        </li>
                    </ul> -->
                </div>
                <div class="alert alert-light" role="alert" v-if="dataItems.length == 0">查無資料</div>
            </div>
            <div class="text-center mt-8" v-if="this.Page.Paging.pageSize < this.pageHeader.totalCount">
                <button class="btn text-white" @click="More()">更多</button>
            </div>
        </CustomizeSection>

        <LoadingPage :loadSet="loadSet"></LoadingPage>
    </div>
</template>
<script>
    import axios from 'axios';
    import moment from 'moment';
    import { apiToken, apiARTICLE_CATALOG_LIST } from '../../api/API';
    import CustomizeSection from '@/components/CustomizeSection.vue';
    export default {
        head: {
            title: '最新消息',
            meta: [{ name: 'description', content: '最新消息' }],
        },
        data() {
            return {
                tabName: '所有消息',
                arCatalog: [],
                imageBg: require('@/assets/image/news.jpg'),
                newsList: [],
                dataItemsAll: [],
                dataItems: [],
                loadSet: { show: false, fullPage: true, type: 'dots', color: 'rgba(0,0,0,0.7)' },

                Page: {},
                TestURL: '',
                pageHeader: {},
            };
        },
        filters: {
            DayFormatter(value) {
                return moment(value).format('YYYY-MM-DD');
            },
            TimeFormatter(value) {
                return moment(value).format('HH:mm');
            },
            MonthFormatter(value) {
                return moment(value).format('MM');
            },
            DateFormatter(value) {
                return moment(value).format('YYYY-MM-DD HH:mm');
            },
        },
        mounted() {
            this.Page = this.$store.getters.getPage;
            this.TestURL = this.$store.getters.getTestURL;
            this.init();
        },
        methods: {
            async init() {
                this.loadSet.show = true;
                await this.getArticleCatalogList();

                await this.getArticleList();
            },
            changeData(id, index) {
                if (id != '') {
                    this.Page.Search.Type = id;
                    this.getArticleList();
                } else {
                    delete this.Page.Search.Type;
                    this.getArticleList();
                }
                //console.log(AC_ID)
                for (let i = 0; i < this.arCatalog.length; i++) {
                    this.arCatalog[i].status = false;
                }
                this.arCatalog[index].status = true;

                // var AC_ID = this.arCatalog[index].id;
                // if (AC_ID == 0) {
                //     this.dataItems = this.dataItemsAll;
                // } else {
                //     this.dataItems = [];

                //     for (var i = 0; i < this.dataItemsAll.length; i++) {
                //         var data = {
                //             title: this.dataItemsAll[i].title,
                //             content: [],
                //         };

                //         for (var j = 0; j < this.dataItemsAll[i].content.length; j++) {
                //             if (this.dataItemsAll[i].content[j].catalogId == AC_ID) {
                //                 data.content.push(this.dataItemsAll[i].content[j]);
                //             }
                //         }

                //         if (data.content.length > 0) this.dataItems.push(data);
                //     }
                // }
            },
            async getArticleCatalogList() {
                try {
                    this.arCatalog = [
                        {
                            id: 0,
                            name: '全部',
                            status: true,
                        },
                    ];
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiARTICLE_CATALOG_LIST(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        //console.log(jsData)
                        if (jsData.length > 0) {
                            for (let i in jsData) {
                                this.arCatalog.push({
                                    id: jsData[i].AC_ID,
                                    name: jsData[i].AC_NAME,
                                    status: false,
                                });
                            }
                        }
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            async getArticleList() {
                await axios
                    .post(this.TestURL + 'System/ArticleList', this.Page)
                    .then(async (response) => {
                        // this.dataItems = response.data;
                        this.loadSet.show = false;
                        this.pageHeader = JSON.parse(response.headers['list-header']).Paging;
                        this.dataItems = [];
                        var month = null;
                        if (response.data.length > 0) {
                            for (let i in response.data) {
                                var date = new Date(response.data[i].Date);
                                var nowMonth = this.TransformTime2String(date.getMonth() + 1);

                                if (month != nowMonth) {
                                    month = nowMonth;
                                    this.dataItems.push({
                                        Month: nowMonth,
                                        content: [],
                                    });
                                }

                                this.dataItems[this.dataItems.length - 1].content.push({
                                    Id: response.data[i].Id,
                                    Date: response.data[i].Date,
                                    TypeName: response.data[i].TypeName,
                                    Title: response.data[i].Title,
                                });
                            }
                        }
                        console.log(this.dataItems);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                // try {
                //     this.loadSet.show = true;
                //     var Token = await (await apiToken()).data;
                //     //console.log(Token);
                //     var data = {
                //         count: 0,
                //         Token: Token,
                //     };
                //     data = this.BaseModel(data);

                //     var result = JSON.parse(await (await apiARTICLE_LIST2(data)).data);
                //     this.loadSet.show = false;

                //     if (result.Status) {
                //         var jsData = JSON.parse(result.Data);
                //         //console.log(jsData)
                //         this.dataItemsAll = [];
                //         this.dataItems = [];
                //         var month = null;
                //         if (jsData.length > 0) {
                //             for (let i in jsData) {
                //                 var date = new Date(jsData[i].AR_DATE);
                //                 var nowyear = date.getFullYear();
                //                 var nowMonth = this.TransformTime2String(date.getMonth() + 1);
                //                 var nowday = this.TransformTime2String(date.getDate());

                //                 if (month != nowMonth) {
                //                     month = nowMonth;
                //                     this.dataItemsAll.push({
                //                         title: nowMonth + '月',
                //                         content: [],
                //                     });
                //                 }

                //                 this.dataItemsAll[this.dataItemsAll.length - 1].content.push({
                //                     id: jsData[i].AR_ID,
                //                     date: nowyear + '-' + nowMonth + '-' + nowday,
                //                     catalog: jsData[i].AR_TYPEcn,
                //                     catalogId: jsData[i].AR_TYPE,
                //                     title: jsData[i].AR_TITLE,
                //                 });

                //                 this.dataItems = this.dataItemsAll;
                //             }

                //             console.log(this.dataItemsAll);
                //         }
                //     } else {
                //         alert(result.Message);
                //     }

                //     //console.log(result.Message)
                // } catch (e) {
                //     this.loadSet.show = false;
                //     //console.log(e)
                //     alert(e);
                // }
            },
            More() {
                if (this.Page.Paging.pageSize + 10 < this.pageHeader.totalCount) {
                    console.log(1);
                    this.Page.Paging.pageSize = this.Page.Paging.pageSize + 10;
                } else {
                    console.log(2);
                    this.Page.Paging.pageSize = this.pageHeader.totalCount;
                }
                this.getArticleList();
            },
        },
        components: {
            CustomizeSection,
        },
    };
</script>
<style lang="scss" scoped>
    .bg-01 {
        background-image: url('@/assets/image/news.jpg');
    }
    .btn {
    }
</style>
