<template>
    <section class="container">
        <div class="row">付款失敗，請重新操作。</div>
    </section>
</template>
<script>
    export default {
        head: {
            title: '付款失敗',
            meta: [{ name: 'FcuError', content: '付款失敗' }],
        },
        name: 'FcuError',
        data() {
            return {};
        },
        created() {
            var ErrorCode = this.$route.query.ErrorCode;
            switch (ErrorCode) {
                case '0':
                    alert('訂單不存在，請重新操作或與體育館人員確認。');
                    this.$router.push('/member/purchase');
                    break;
                case '1':
                    alert('訂單已逾期，請重新操作或與體育館人員確認。');
                    this.$router.push('/member/purchase');
                    break;
                case '2':
                    alert('訂單已取消，請重新操作或與體育館人員確認。');
                    this.$router.push('/member/purchase');
                    break;
                default:
                    alert('未知錯誤，請重新操作或與體育館人員確認。');
                    this.$router.push('/member/purchase');
                    break;
            }
        },
        mounted() {},
        methods: {},

        components: {},
    };
</script>
<style lang="scss" scoped></style>
