<template>
<main>
   <section class="mb-12">
      <DataFilter></DataFilter>
      <table class="tableResponsive">
         <thead>
            <tr>
               <th width="1%">申請日期</th>
               <th>標題</th>
               <th width="1%">退款金額</th>
               <th width="1%">狀態</th>
               <th width="1%">操作</th>
            </tr>
         </thead>
         <tbody>
            <tr v-for="(item, index) in refund" :key="index">
               <td data-label="申請日期">{{ item.date }}</td>
               <td data-label="標題">{{ item.title }}</td>
               <td data-label="退款金額" class="nt">NT$ {{ item.amount }}</td>
               <td data-label="狀態">
                  <span v-if="item.status===1" class="text-green-400">已退款</span>
                  <span v-else-if="item.status===2" class="text-yellow-400">處理中</span>
                  <span v-else-if="item.status===3" class="text-red-400">已取消</span> </td>
               <td>
                  <button class="btn" v-if="item.status===2">取消</button>
               </td>
            </tr>
         </tbody>
      </table>
      <div class="notes">
         <h6 class="title">注意事項</h6>
         <ul class="list-disc list-inside">
            <li>退款時間約為Ｎ天</li>
            <li>票券為不記名票券</li>
         </ul>
      </div>
   </section>
</main>
</template>
<script>
import DataFilter from '@/components/DataFilter.vue'
export default {
   name: 'RefundHistory',
   data() {
      return {
         refund: [
            {
               date: '2022/03/30',
               title: '健身房單次x1',
               amount: '1000',
               status: 1,
            },
            {
               date: '2022/03/30',
               title: '健身房單次x1',
               amount: '2000',
               status: 2,
            },
            {
               date: '2022/03/30',
               title: '健身房單次x1',
               amount: '3000',
               status: 3,
            }
         ]
      }
   },
   components: {
      DataFilter
   }
}
</script>