<template>
    <main>
        <Header title="訂單查詢"></Header>

        <div class="main">
            <div class="flex justify-between sm:items-end mb-4 space-x-4">
                <DataFilter
                    class="flex-1 border-0"
                    style="background: unset"
                    initial="所有狀態"
                    label="訂單狀態"
                    :option="dataFilter.option"
                    @change="TSelectF1"
                >
                </DataFilter>
                <div class="stats shadow flex-none">
                    <div class="stat">
                        <div class="stat-title">訂單數量</div>
                        <div class="stat-value text-center">{{ dataItems.length }}</div>
                    </div>
                    <!-- <div class="stat">
            <div class="stat-title">累計消費</div>
            <div class="stat-value">{{this.$session.get('MBR_TOTAL_AMOUNT')}}</div>
         </div> -->
                </div>
            </div>

            <div class="mb-8 rounded-3" style="background: #fff">
                <table class="table w-full tableGrid">
                    <thead style="background: #e5e5e5">
                        <tr>
                            <th scope="col" style="line-height: 1.5rem; border-bottom: unset" width="1%">日期</th>
                            <th scope="col" style="line-height: 1.5rem; border-bottom: unset">訂單編號</th>
                            <th scope="col" style="line-height: 1.5rem; border-bottom: unset">商品名稱</th>
                            <th scope="col" style="line-height: 1.5rem; border-bottom: unset">金額</th>
                            <th scope="col" style="line-height: 1.5rem; border-bottom: unset" width="1%">訂單狀態</th>
                            <th width="1%" style="line-height: 1.5rem; border-bottom: unset">結帳</th>
                        </tr>
                    </thead>
                    <tbody style="background: #fff">
                        <!-- <tr v-for="(item, index) in dataItems" :key="index">
                  <td colspan="6">{{item}}</td>
               </tr> -->

                        <tr v-for="(item, index) in dataItems" :key="index">
                            <td data-label="購買日期：">{{ item.OD_DATE }}</td>
                            <td data-label="來源訂單：">
                                {{ item.OD_ID }}
                            </td>
                            <td data-label="商品名稱" class="whitespace-pre-wrap">
                                <div v-for="(item2, index2) in item.OP_NAME" :key="index2">{{ item2 }}</div>
                            </td>
                            <td data-label="訂單金額：" class="nt">NT$ {{ item.OD_PRICE }}</td>
                            <td data-label="訂單狀態：">
                                <!-- <template v-if="item.OD_STATUS === 0"> <span class="text-yellow-400">處理中</span> </template>
                     <template v-else-if="item.OD_STATUS === 1"> <span class="text-green-400">已完成</span> </template>
                     <template v-else-if="item.OD_STATUS === 2"> <span class="text-red-400">已取消</span> </template> -->
                                <template v-if="item.status === '等待付款' || item.status === '退款申請中'">
                                    <span class="text-yellow-400">{{ item.status }}</span>
                                </template>
                                <template v-else-if="item.status === '已付款'">
                                    <span class="text-green-400">{{ item.status }}</span>
                                </template>
                                <template v-else>
                                    <span class="text-red-400">{{ item.status }}</span>
                                </template>
                            </td>
                            <!-- <td data-label="付款狀態">
                     <template v-if="item.OD_PAYMENT_STATUS === 0"> <span class="text-yellow-400">等待付款</span> </template>
                     <template v-else-if="item.OD_PAYMENT_STATUS === 1"> <span class="text-green-400">已付款</span> </template>
                     <template v-else-if="item.OD_PAYMENT_STATUS === 2"> <span class="text-red-400">已退款</span> </template>
                  </td> -->
                            <td style="background: #fff">
                                <div class="space-x-2">
                                    <template v-if="item.OD_STATUS === 0">
                                        <a
                                            v-if="
                                                item.OD_PAYMENT_STATUS === 0 &&
                                                new Date() <= new Date(item.OD_PAYMENT_DEADLINE)
                                            "
                                            :href="$appDomain + '/FcuPay/FcuPay.aspx?OD_ID=' + item.OD_ID"
                                        >
                                            <button class="btn btn-sm btn-primary">付款</button></a
                                        >
                                        <a
                                            v-else
                                            :href="$appDomain + '/FcuPay/FcuPay.aspx?OD_ID=' + item.OD_ID"
                                            class="d-none"
                                        >
                                            <button class="btn btn-sm btn-primary">測試付款失敗</button></a
                                        >
                                        <button
                                            class="btn btn-sm btn-accent"
                                            v-if="item.OD_PAYMENT_STATUS === 0 && item.status != '已取消'"
                                            @click="updateStatus2(item.OD_ID)"
                                        >
                                            取消
                                        </button>
                                    </template>
                                    <template v-if="item.OD_STATUS === 1">
                                        <button
                                            class="btn btn-sm"
                                            v-if="
                                                item.OD_PAYMENT_STATUS === 1 &&
                                                item.OP_REFUND_STATUS == 0 &&
                                                item.AE_STATUS == 0
                                            "
                                            @click="checkAccessStatus(item.OP_ID)"
                                        >
                                            退款
                                        </button>
                                    </template>

                                    <!-- <template v-if="item.OP_REFUND_STATUS === 1">
                            <label>退款申請處理中</label>
                        </template> -->
                                    <!-- <router-link class="btn btn-sm" :to="{ path: '/member/order', query: { OD_ID: item.OD_ID }}">查看訂單</router-link> -->
                                    <!-- <button v-if="item.OD_PAYMENT_STATUS === 1 && item.OD_STATUS === 1" class="btn" >申請退款</button> -->
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td v-if="dataItems.length == 0">無資料</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <LoadingPage :loadSet="loadSet"></LoadingPage>
    </main>
</template>
<script>
    import {
        apiToken,
        apiORDER_LIST,
        apiORDER_PAYMENT_STATUS2,
        apiACCESS_IS_REUND,
        apiORDER_PRODUCT_REFUNDT,
    } from '../../api/API';
    import DataFilter from '@/components/DataFilter.vue';
    import Header from '@/components/MemberHeader.vue';
    export default {
        head: {
            title: '',
        },
        name: 'PurchaseHistory',
        components: {
            Header,
            DataFilter,
        },
        data() {
            return {
                wiu: window.screen.width,
                filter: true,
                dataFilter: {
                    option: [
                        { value: '等待付款', text: '等待付款' },
                        { value: '已付款', text: '已付款' },
                        { value: '已取消', text: '已取消' },
                        { value: '已退款', text: '已退款' },
                        { value: '退款申請中', text: '退款申請中' },
                    ],
                },

                order: [
                    {
                        date: '2022/03/30',
                        orderID: 'O000001',
                        status: 1,
                        cancel: true,
                        price: 3000,
                        product: '場地租借',
                        describe: '1F風雨球場・2021-02-07 20:00~22:00',
                    },
                    {
                        date: '2022/03/30',
                        orderID: 'O000003',
                        status: 1,
                        cancel: false,
                        price: 3000,
                        product: '健身房單次入場',
                        describe: null,
                    },
                    {
                        date: '2022/03/30',
                        orderID: 'O000002',
                        status: 2,
                        cancel: false,
                        price: 3000,
                        product: '瑜珈有氧單次套票8張',
                        describe: null,
                    },
                    {
                        date: '2022/03/30',
                        orderID: 'O000002',
                        status: 2,
                        cancel: false,
                        price: 3000,
                        product: '健身房單次套票12張',
                        describe: null,
                    },
                    {
                        date: '2022/03/30',
                        orderID: 'O000002',
                        status: 2,
                        cancel: false,
                        price: 3000,
                        product: '游泳池單次套票12張',
                        describe: null,
                    },

                    {
                        date: '2022/03/30',
                        orderID: 'O000004',
                        status: 0,
                        cancel: false,
                        price: 3000,
                        product: '游泳池單次入場',
                        describe: null,
                    },
                    {
                        date: '2022/03/30',
                        orderID: 'O000005',
                        status: 0,
                        cancel: false,
                        price: 3000,
                        product: '瑜珈有氧單堂課程',
                        describe: '哈達瑜珈・2022-02-07 20:00~22:00',
                    },
                ],

                dataItemsAll: [],
                dataItems: [],

                loadSet: { show: false, fullPage: true, type: 'dots', color: 'rgba(0,0,0,0.7)' },
            };
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                if (this.IsNull(this.$session.get('ACID')) == '') {
                    this.$router.push('/Login');
                } else {
                    await this.getOrderList();
                }
            },
            // 篩選
            TSelectF1(type) {
                if (type == '') {
                    this.dataItems = this.dataItemsAll;
                    return;
                }

                var STATUS = type;

                var data1 = [];

                for (let i in this.dataItemsAll) {
                    if (this.dataItemsAll[i].status == STATUS) data1.push(this.dataItemsAll[i]);
                }

                this.dataItems = data1;
            },
            async getOrderList() {
                try {
                    this.dataItemsAll = [];
                    this.dataItems = [];

                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiORDER_LIST(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        if (jsData.length > 0) {
                            for (let i in jsData) {
                                jsData[i].OD_DATE = this.TransformTimeFormatYYYMMDDhhmm(jsData[i].OD_DATE);

                                if (jsData[i].OP_REFUND_STATUS == 1) {
                                    jsData[i].status = '退款申請中';
                                } else if (jsData[i].OD_PAYMENT_STATUS == 2) {
                                    jsData[i].status = '已退款';
                                } else if (
                                    jsData[i].OD_STATUS == 2 ||
                                    (new Date() >= new Date(jsData[i].OD_PAYMENT_DEADLINE) && jsData[i].OD_STATUS != 1)
                                ) {
                                    jsData[i].status = '已取消';
                                } else if (jsData[i].OD_PAYMENT_STATUS == 0) {
                                    jsData[i].status = '等待付款';
                                } else if (jsData[i].OD_PAYMENT_STATUS == 1) {
                                    jsData[i].status = '已付款';
                                }

                                if (this.IsNull(jsData[i].OP_NAME) != '') {
                                    let name = jsData[i].OP_NAME.split('§');
                                    jsData[i].OP_NAME = name;
                                }

                                this.dataItemsAll.push(jsData[i]);
                                this.dataItems.push(jsData[i]);
                            }
                        }
                        //console.log(jsData)
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            async updateStatus2(OD_ID) {
                try {
                    if (confirm('確定要取消定單？') == false) {
                        return;
                    }

                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        OD_ID: OD_ID,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiORDER_PAYMENT_STATUS2(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        //var jsData = JSON.parse(result.Data);
                        //console.log(jsData)
                        await this.getOrderList();
                        alert('取消成功');
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            async checkAccessStatus(OP_ID) {
                if (confirm('確定要申請退款？') == false) {
                    return;
                }

                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        OP_ID: OP_ID,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiACCESS_IS_REUND(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        if (jsData.status == 1) alert('不符合退款條件');
                        else this.refund(OP_ID);
                        //console.log(jsData)
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            async refund(OP_ID) {
                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        OP_ID: OP_ID,
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiORDER_PRODUCT_REFUNDT(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        //var jsData = JSON.parse(result.Data);
                        //console.log(jsData)
                        this.getOrderList();
                        alert('申請成功');
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    @media screen and (max-width: 768px) {
        .member-header {
            width: 100%;
            left: 0;
        }
        table {
            background: unset;
            tbody {
                background: unset !important;
            }
        }
    }
    @media screen and (max-width: 640px) {
        table {
            tbody {
                background: unset !important;
                td {
                    padding: 10px;
                    background: #fff;
                    flex-wrap: wrap;
                    &::before {
                        width: 25%;
                        white-space: pre-wrap;
                    }
                    &:nth-child(3) {
                        position: relative;
                        padding-left: 25%;
                        &::before {
                            width: 24%;
                            left: 10px;
                            position: absolute;
                        }
                        div {
                            width: 75%;
                        }
                    }
                }
            }
        }
    }
</style>

<style lang="postcss" scoped>
    .filter {
        @apply mb-4;

        .btn-filter {
            @apply block sm:hidden px-4 py-2;
        }

        .filterOptions {
            @apply bg-secondary-900 sm:bg-transparent p-4 sm:p-0 rounded w-full flex;

            .item {
                @apply w-full;
            }
        }
    }

    @media (max-width: 640px) {
        .aaaaa {
            @apply !hidden;
        }
    }
</style>
