<template>
    <div class="signin">
        <div class="card">
            <div class="card-body">
                <!-- <h2 class="card-title justify-center">{{ $t("base.login") }}</h2> -->
                <h2 class="card-title justify-center"></h2>
                <form class="mb-6 space-y-2" @keydown.enter.prevent="login">
                    <div class="form-control w-full max-w-xs border-0">
                        <label class="label">
                            <span class="label-text">電子信箱</span>
                        </label>
                        <input
                            type="text"
                            placeholder="請輸入電子信箱"
                            v-model="loginData.Id"
                            class="input input-bordered w-full max-w-xs"
                        />
                    </div>
                    <div class="form-control w-full max-w-xs border-0">
                        <label class="label">
                            <span class="label-text">密碼</span>
                        </label>
                        <input
                            type="password"
                            placeholder="請輸入電子密碼"
                            v-model="loginData.DecryptionPass"
                            class="input input-bordered w-full max-w-xs"
                        />
                    </div>
                    <div class="form-control w-full max-w-xs mb-4 border-0">
                        <label class="label">
                            <span class="label-text">驗證碼</span>
                        </label>
                        <div class="flex space-x-2">
                            <input
                                placeholder="驗證碼"
                                type="text"
                                v-model="loginData.CodePass"
                                class="input input-bordered w-full max-w-xs"
                                required
                            />
                            <img :src="imageCode" class="img-fluid h-12 border rounded-lg" alt="captchaImage" />
                        </div>
                    </div>
                    <label class="flex py-2">
                        <input v-model="remmber" type="checkbox" checked="checked" class="checkbox mr-3" /> 記住我
                    </label>
                    <!-- <button type="button" class="btn btn-block"  @click="login" >{{ $t("base.login") }}</button> -->
                    <button type="button" class="btn btn-block text-white" @click="login">確定</button>
                </form>
                <ul class="other-Link">
                    <li>
                        <router-link to="/forgot">忘記密碼？</router-link>
                    </li>
                    <li>
                        <router-link to="/register">註冊新帳號</router-link>
                    </li>
                </ul>
            </div>
        </div>
        <LoadingPage :loadSet="loadSet"></LoadingPage>
    </div>
</template>
<script>
    import { apiToken, apiMEMBER_Login, apiGetImageCode, apiMEMBER_GET } from '@/api/API';

    export default {
        name: 'SignIn',
        head: {
            title: '登入',
            meta: [{ name: 'description', content: '登入' }],
        },
        data() {
            return {
                imageCode: null,
                loginData: {
                    Id: '',
                    Type: 'login',
                    DecryptionPass: '',
                    CodePass: '',
                    DecryptionCodePass: '',

                    TokenID: '',
                    Token: '',
                    Page: '',
                },
                remmber: false,
                loadSet: {
                    show: false,
                    fullPage: true,
                    type: 'dots',
                    color: 'rgba(0,0,0,0.7)',
                },
            };
        },
        mounted() {
            if (this.IsNull(this.$session.get('ACID')) == '') {
                this.getImageCode();
                //window.addEventListener('keydown', this.checkLogin);
            } else {
                this.$router.push('/');
            }
        },
        methods: {
            checkLogin(event) {
                if (event.keyCode === 13) {
                    //console.log(event.keyCode);
                    this.login();
                }
            },
            async login() {
                var msg = '';

                if (this.IsNull(this.loginData.Id) == '') msg += '請填寫帳號\n';

                if (this.IsNull(this.loginData.DecryptionPass) == '') msg += '請填寫密碼\n';

                if (this.IsNull(this.loginData.CodePass) == '') msg += '請填寫驗證碼\n';

                if (msg == '') {
                    try {
                        this.loadSet.show = true;
                        var Token = await (await apiToken()).data;
                        //console.log(Token);
                        this.loginData.Token = Token;

                        var result = JSON.parse(await (await apiMEMBER_Login(this.loginData)).data);

                        this.loadSet.show = false;

                        if (result.Status) {
                            var id = result.Data;
                            //this.$session.start()
                            // console.log(id)
                            this.$session.set('ACID', JSON.parse(id).ID);
                            this.$session.set('NAME', JSON.parse(id).NAME);
                            if (this.remmber) {
                                this.$cookies.set('ACID', JSON.parse(id).ID, '7d');
                                this.$cookies.set('NAME', JSON.parse(id).NAME, '7d');
                            }

                            await this.getMember();
                        } else {
                            alert(result.Message);
                        }

                        //console.log(result.Message)
                    } catch (e) {
                        this.loadSet.show = false;
                        //console.log(e)
                        alert(e);
                    }
                } else {
                    alert(msg);
                }
            },
            async getMember() {
                try {
                    this.loadSet.show = true;
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiMEMBER_GET(data)).data);

                    this.loadSet.show = false;

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        // console.log(jsData)
                        this.$session.set('MBR_GRADE', jsData.MBR_GRADE);
                        this.$session.set('MG_NAME', jsData.MG_NAME);
                        this.$session.set('MG_DISCOUNT', jsData.MG_DISCOUNT);
                        this.$session.set('PPL_SSN', jsData.PPL_SSN);
                        this.$session.set('MBR_TOTAL_AMOUNT', jsData.MBR_TOTAL_AMOUNT);
                        this.$session.set('PPL_NID_IDENTITY', jsData.PPL_NID_IDENTITY);
                        switch (jsData.PPL_NID_IDENTITY) {
                            case 0:
                                this.$session.set('PPL_NID_IDENTITYcn', '無');
                                break;
                            case 1:
                                this.$session.set('PPL_NID_IDENTITYcn', '學生');
                                break;
                            case 2:
                                this.$session.set('PPL_NID_IDENTITYcn', '教職員');
                                break;
                            case 3:
                                this.$session.set('PPL_NID_IDENTITYcn', '校友');
                                break;
                            case 4:
                                this.$session.set('PPL_NID_IDENTITYcn', '眷屬');
                                break;
                        }
                        if (this.IsNull(this.$session.get('ShoppingCart')) != '') {
                            var shop = this.$session.get('ShoppingCart');

                            if (shop.type == 'ticket') this.$router.push('/buyTickets');
                            else if (shop.type == 'course')
                                this.$router.push({ name: 'Course', query: { TKT_CAT_ID: shop.values.TKT_CAT_ID } });
                            else if (shop.type == 'space') this.$router.push('/reservationSpace');
                        } else {
                            this.$router.push('/member/owns');
                        }
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
            async getImageCode() {
                try {
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        Id: this.account,
                        DecryptionPass: this.passwd,
                        Token: Token,
                    };

                    this.loadSet.show = true;
                    var result = JSON.parse(await (await apiGetImageCode(data)).data);
                    this.loadSet.show = false;

                    if (result.Status) {
                        const jsonImage = JSON.parse(result.Data);
                        this.imageCode = jsonImage.Image;
                        this.loginData.DecryptionCodePass = jsonImage.Code;
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    this.loadSet.show = false;
                    //console.log(e)
                    alert(e);
                }
            },
        },
    };
</script>
