<template>
   <div class="bg-secondary-100 min-h-screen py-[64px]">
      <div class="container mx-auto">
         <router-view />
      </div>
   </div>
</template>
<script>
export default {
   name: 'MemberCenter',
   data() {
      return {
      }
   },
   mounted(){
      this.init()
   },
   methods:{
      async init(){
         if (this.IsNull(this.$session.get('ACID')) == "" ) {
            this.$router.push("/Login")
         }
      },
   }
}
</script>