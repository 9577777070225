<template>
    <section class="section bg-slate-300" :class="{ lb: !this.$store.state.inModal }">
        <div v-if="tranglelt" class="trangle lt"></div>
        <div v-if="tranglert" class="trangle rt"></div>
        <div v-if="tranglelb" class="trangle lb"></div>
        <div v-if="tranglerb" class="trangle rb"></div>
        <slot></slot>
    </section>
</template>
<script>
    export default {
        props: {
            tranglelt: {
                type: Boolean,
                default: false,
            },
            tranglert: {
                type: Boolean,
                default: false,
            },
            tranglelb: {
                type: Boolean,
                default: false,
            },
            tranglerb: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
