<template>
    <div id="app">
        <SiteHeader :ACID="$session.get('ACID')"></SiteHeader>
        <router-view />
        <SiteFooter></SiteFooter>
    </div>
</template>
<script>
    import SiteHeader from '@/components/SiteHeader.vue';
    import SiteFooter from '@/components/SiteFooter.vue';
    import { apiToken, apiMEMBER_GET } from '@/api/API';
    export default {
        head: {
            titleTemplate(title) {
                title = typeof title === 'function' ? title(this.$store) : title;
                return title ? `${title}｜逢甲大學體育館 FCU SPORTS CENTER` : '逢甲大學體育館 FCU SPORTS CENTER';
            },
        },
        components: {
            SiteHeader,
            SiteFooter,
        },
        mounted() {
            if (this.IsNull(this.$session.get('ACID')) == '' && this.IsNull(this.$cookies.get('ACID')) != '') {
                this.$session.set('ACID', this.$cookies.get('ACID'));
                this.$session.set('NAME', this.$cookies.get('NAME'));
                this.getMember();
            }
        },
        methods: {
            async getMember() {
                try {
                    var Token = await (await apiToken()).data;
                    //console.log(Token);
                    var data = {
                        Token: Token,
                    };
                    data = this.BaseModel(data);

                    var result = JSON.parse(await (await apiMEMBER_GET(data)).data);

                    if (result.Status) {
                        var jsData = JSON.parse(result.Data);
                        // console.log(jsData)
                        this.$session.set('MBR_GRADE', jsData.MBR_GRADE);
                        this.$session.set('MG_NAME', jsData.MG_NAME);
                        this.$session.set('MG_DISCOUNT', jsData.MG_DISCOUNT);
                        this.$session.set('PPL_SSN', jsData.PPL_SSN);
                        this.$session.set('MBR_TOTAL_AMOUNT', jsData.MBR_TOTAL_AMOUNT);
                        this.$session.set('PPL_NID_IDENTITY', jsData.PPL_NID_IDENTITY);
                        switch (jsData.PPL_NID_IDENTITY) {
                            case 0:
                                this.$session.set('PPL_NID_IDENTITYcn', '無');
                                break;
                            case 1:
                                this.$session.set('PPL_NID_IDENTITYcn', '學生');
                                break;
                            case 2:
                                this.$session.set('PPL_NID_IDENTITYcn', '教職員');
                                break;
                            case 3:
                                this.$session.set('PPL_NID_IDENTITYcn', '校友');
                                break;
                            case 4:
                                this.$session.set('PPL_NID_IDENTITYcn', '眷屬');
                                break;
                        }
                        if (this.IsNull(this.$session.get('ShoppingCart')) != '') {
                            var shop = this.$session.get('ShoppingCart');

                            if (shop.type == 'ticket') this.$router.push('/buyTickets');
                            else if (shop.type == 'course')
                                this.$router.push({ name: 'Course', query: { TKT_CAT_ID: shop.values.TKT_CAT_ID } });
                            else if (shop.type == 'space') this.$router.push('/reservationSpace');
                        } else {
                            this.$router.push('/member/owns');
                        }
                    } else {
                        alert(result.Message);
                    }

                    //console.log(result.Message)
                } catch (e) {
                    //console.log(e)
                    alert(e);
                }
            },
        },
    };
</script>
<style lang="scss"></style>
